import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { H4, H5, P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { SendRecieveStyle } from "./Send-Recieve.Styled";
import ic_transfer_success from "../../../../assets/images/ic-transfer-success.svg";

const SendCrypto = ({ history }) => {
  const Location = useLocation();

  const [state, setstate] = useState(
    Location && Location.state && Location.state.details
      ? Location.state.details
      : null
  );

  console.log(Location, state);

  return (
    <div className="p-3">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SendRecieveStyle width="70%">
          <div className="Success-banner text-center px-28">
            <div className="">
              <div className="success-img-div my-10">
                <img src={ic_transfer_success} alt="texticon" />
              </div>
              <div className="mb-2.5">
                <H5 color={"#FFF"} fontWeight="700">
                  Successfully sent!
                </H5>
              </div>
              <H4 color={"#FFF"} fontWeight="700">
                {state ? state.amount + state.amount_currency : null}
              </H4>
              <P fontSize="13px" color={"#D3D8F7"}>
                ₦ {state ? state.priceNG : null}
              </P>
            </div>
          </div>

          <div className="my-4 mx-5">
            <Card>
              <div>
                <div className="mt-12 mb-10 text-center text-uppercase">
                  <P fontSize="12px" color={"#BFC4D5"}>
                    {state ? new Date(state.timestamp).toString() : null}
                  </P>
                </div>

                <div className="mb-32 text-center">
                  <div className="mb-1.5">
                    <P fontSize="13px" color={"#7A809B"}>
                      Recipient
                    </P>
                  </div>
                  <P fontSize="13px" color={"#005674"}>
                    {state ? state.recipient : null}
                  </P>
                </div>

                <div className="px-5 mb-6">
                  <DefaultButton
                    small
                    bg={"#00BFFF"}
                    color={"#FFFFFF"}
                    p={"12px 12px 12px 12px"}
                    width={"100%"}
                    border={"1px solid #00BFFF"}
                    onClick={() => {
                      history.push("/dashboard/send-and-recieve");
                    }}
                  >
                    Done
                  </DefaultButton>
                </div>

                <div className="text-center mb-7">
                  <Link to="">
                    <P fontSize="12px" color={"#006F95"}>
                      Transaction details
                    </P>
                  </Link>
                </div>
              </div>
            </Card>
          </div>
        </SendRecieveStyle>
      </div>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(SendCrypto));
