import React, { lazy, useEffect } from "react";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
import { Card } from "../../../../Units/Card.styled";
import { CardHolder, CardTitle } from "../../../../Units/CardTitle.styled";
//import { ActivitiesTable } from "./ActivitiesTable";
import ic_activities from "../../../../../assets/images/ic-activities-db.svg";
import { groupActivitiesByDaysOrMonth } from "../../../../../utils/specialFunctions";
import { Link, withRouter } from "react-router-dom";
import { ActivitiesTableCardStyle } from "./Activities.styled";
import { connect } from "react-redux";
import { getSavings } from "../../../../../store/Actions/SavingsActions";
import { getAnnouncementAction } from "../../../../../store/Actions/Messages_AnnouncementsAction";
import ActivitiesTable from "./ActivitiesTable";
import { getCardActivities } from "../../../../../store/Actions/CardsAction";

const ActivitiesCardTable = ({
  activities,
  getCardActivities,
  handleActiveTab,
  // getAnnouncementAction,
}) => {
  useEffect(() => {
    getCardActivities();
  }, [getCardActivities]);

  return (
    <ActivitiesTableCardStyle className="pt-6">
      <Card>
        <CardHolder>
          <div className="flex">
            <div className="w-12/12">
              <CardTitle img={ic_activities} text={"Activities"} />
            </div>
          </div>
        </CardHolder>

        <div className="lg:px-4">
          <ActivitiesTable
            activities={groupActivitiesByDaysOrMonth(
              activities ? null : null,
              "days"
            ).slice(0, 5)}
          />
          {/* {activities && activities.length !== 0 ? ( */}
          <div className="text-center flex justify-center pt-5">
            <h3
              className="viewAll cursor-pointer"
              onClick={() => handleActiveTab(1)}
            >
              See more
            </h3>
          </div>
          {/* ) : null} */}
        </div>
      </Card>
    </ActivitiesTableCardStyle>
  );
};

const mapStateToProps = (state) => {
  return {
    activities: state.savings.savings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCardActivities: (query) => dispatch(getCardActivities(query)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesCardTable);
