import React, { useState, useEffect } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Card } from "../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../Units/TabsAndLinkStyled";
import UserBank from "./UserBank";
import UserProfile from "./UserProfile";
import { Div } from "./Users.styled";
import UserWallets from "./UserWallets";
import { connect } from "react-redux";
import { getUser } from "../../../../store/Actions/UsersAction";
import { TradeActivitiesTable } from "../Activities/ActivitiesTable";
import { UserTransactionsTable } from "../Transactions/TransactionsTable";
import DashboardHeader from "../DashboardHeader";
import { Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import caret_down from "../../../../assets/images/caret-down1.svg";
import { groupActivitiesByDaysOrMonth } from "../../../../utils/specialFunctions";
import { UserPromoTable, UserReferralsTable } from "./UsersTables";
import { withTheme } from "styled-components";
import { getBanksAction } from "../../../../store/Actions/TransactionActions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import UserSavings from "./UsersSavings/index";
import UserCards from "./UsersCards";
import UserControl from "./UserControl";

const SingleUser = ({
  getUser,
  getBanksAction,
  singleUser,
  loading,
  history,
  match,
  theme,
}) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState(0);

  const links1 = [
    { text: "Profile" },
    { text: "Wallet" },
    { text: "Bank" },
    { text: "Trades" },
    { text: "Transactions" },
    { text: "Referrals" },
    { text: "Promo" },
    { text: "Savings" },
    { text: "Cards" },
    { text: "Control" },
  ];

  useEffect(() => {
    getUser(match.params.id);
    getBanksAction();
  }, [getUser, getBanksAction, match]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User {links1[activeLink1]?.text}</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>
          {singleUser
            ? singleUser.profile.first_name +
              "\n" +
              singleUser.profile.last_name
            : ""}
        </DashboardHeader>
        {loading ? (
          <div
            className="flex items-center justify-center h-100"
            style={{ height: "100vh" }}
          >
            <h1>Loading........</h1>
          </div>
        ) : (
          <div>
            <Div className="pb-6 flex justify-between">
              <Link to="/dashboard/users" className="backTo">
                &nbsp; To Users
              </Link>

              <div>
                <DefaultButton
                  bg={theme.colors.bg}
                  color={theme.colors.color}
                  p={"8px 16px"}
                >
                  <Link to={"/dashboard/users/" + match.params.id + "/balance"}>
                    Balance
                  </Link>
                </DefaultButton>
              </div>
            </Div>

            <Card>
              <TabHeader>
                <div className="flex">
                  <div className="w-4/5">
                    <TableTab
                      margin
                      tabs={links1}
                      activeTab={activeLink1}
                      handleTab={handleActiveLink1}
                      callback={(active) => {
                        // console.log(active)
                      }}
                    />
                  </div>

                  <div className="w-1/6  my-1.5 mx-1.5">
                    <Select
                      bgImg={caret_down}
                      bgColor={theme.colors.bg}
                      color={theme.colors.color}
                      border={"1px solid #fff"}
                      borderRadius={"4px"}
                      p={"5px 15px"}
                      data={links1}
                      defaultValue={"-- Select --"}
                      properyToShow={"text"}
                      hoverBg={"#FFF"}
                      hoverColor={"#112E46"}
                      fontSize={"16px"}
                      callback={(option, idx, arr) => {
                        console.log(option, idx, arr);
                        handleActiveLink1(idx);
                      }}
                    />
                  </div>
                </div>
              </TabHeader>

              {activeLink1 === 0 ? (
                <div className="">
                  <UserProfile user={singleUser ? singleUser : null} />
                </div>
              ) : activeLink1 === 1 ? (
                <div className="">
                  <UserWallets user={singleUser ? singleUser : null} />
                </div>
              ) : activeLink1 === 2 ? (
                <div className="">
                  <UserBank user={singleUser ? singleUser : null} />
                </div>
              ) : activeLink1 === 3 ? (
                <div className="">
                  <TradeActivitiesTable
                    activities={
                      singleUser
                        ? groupActivitiesByDaysOrMonth(
                            singleUser ? singleUser.trades : null,
                            "days"
                          )
                        : null
                    }
                  />
                </div>
              ) : activeLink1 === 4 ? (
                <div className="">
                  <UserTransactionsTable
                    links1={groupActivitiesByDaysOrMonth(
                      singleUser ? singleUser.transactions : null,
                      "days"
                    )}
                  />
                </div>
              ) : activeLink1 === 5 ? (
                <div className="">
                  <UserReferralsTable
                    user={groupActivitiesByDaysOrMonth(
                      singleUser ? singleUser.referrals : null,
                      "days"
                    )}
                  />
                </div>
              ) : activeLink1 === 6 ? (
                <div className="">
                  <UserPromoTable user={singleUser && singleUser} />
                </div>
              ) : activeLink1 === 7 ? (
                <div className="">
                  <UserSavings user={singleUser && singleUser} />
                </div>
              ) : activeLink1 === 8 ? (
                <div className="">
                  <UserCards user={singleUser && singleUser} />
                </div>
              ) : activeLink1 === 9 ? (
                <div className="">
                  <UserControl user={singleUser && singleUser} />
                </div>
              ) : null}
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    singleUser: state.users.singleUser,
    loading: state.users.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getUser(id)),
    getBanksAction: () => dispatch(getBanksAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(SingleUser)));
