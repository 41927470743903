import React, { useState } from "react";
import { Badge, DefaultButton } from "../../../../UI toolkit/Button/Button";
import { H1, P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import {
  AddBankModal,
  ConfirmBankAccountDeletionModal,
  DeleteBankAccountDeletionModal,
} from "./UsersModal";

const UserBank = ({ user }) => {
  const [addBankModal, setaddBankModal] = useState(false);
  const [confirmBankDeletionModal, setconfirmBankDeletionModal] =
    useState(false);

  const [deleteBankAccountModal, setdeleteBankAccountModal] = useState(false);

  const [selectedBankAccount, setSelectedBankAccount] = useState({
    bank_account_id: "",
    platform: "",
  });

  return (
    <div>
      {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

      {(user &&
        user.coinprofile_accounts &&
        user.coinprofile_accounts.length !== 0) ||
      user.bani_accounts !== 0 ? (
        <div>
          <div>
            {user.coinprofile_accounts.map((account, idx, arr) => {
              return (
                <div
                  className={`lg:w-7/12 ${idx > 0 ? "mt-5" : ""}`}
                  key={account.id}
                >
                  <div className="pt-6">
                    {idx > 0 ? (
                      <H1
                        fontSize="16px"
                        className="my-5 text-center"
                        color={"#7A809B"}
                      >
                        Bank {idx + 1}
                      </H1>
                    ) : null}
                    <Card className="flex justify-between items-center">
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          Bank Name
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {account.bank.name}
                        </P>
                      </div>
                      <Badge>Coinprofile</Badge>
                    </Card>
                  </div>

                  <div className="pt-6">
                    <Card>
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          Account Name
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {account.account_name}
                        </P>
                      </div>
                    </Card>
                  </div>

                  <div className="pt-6">
                    <Card>
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          Account Number
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {account.account_number}
                        </P>
                      </div>
                    </Card>
                  </div>

                  <div className="py-6">
                    <Card>
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          BVN
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {user.profile.bvn}
                        </P>
                      </div>
                    </Card>
                  </div>
                  <DefaultButton
                    bg={"#D60000"}
                    color={"#fff"}
                    p={"8px 16px"}
                    onClick={() => {
                      setSelectedBankAccount({
                        bank_account_id: account.id,
                        platform: "coinprofile",
                      });
                      setconfirmBankDeletionModal(true);
                    }}
                  >
                    Delete account
                  </DefaultButton>
                </div>
              );
            })}
          </div>
          <div>
            {user.bani_accounts.map((account, idx, arr) => {
              return (
                <div
                  className={`lg:w-7/12 ${idx > 0 ? "mt-5" : ""}`}
                  key={account.id}
                >
                  <div className="pt-6">
                    {idx > 0 ? (
                      <H1
                        fontSize="16px"
                        className="my-5 text-center"
                        color={"#7A809B"}
                      >
                        Bank {idx + 1}
                      </H1>
                    ) : null}
                    <Card className="flex items-center justify-between">
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          Bank Name
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {account.bank.name}
                        </P>
                      </div>
                      <Badge>Bani</Badge>
                    </Card>
                  </div>

                  <div className="pt-6">
                    <Card>
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          Account Name
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {account.account_name}
                        </P>
                      </div>
                    </Card>
                  </div>

                  <div className="pt-6">
                    <Card>
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          Account Number
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {account.account_number}
                        </P>
                      </div>
                    </Card>
                  </div>

                  <div className="py-6">
                    <Card>
                      <div>
                        <P fontSize="12px" color={"#7A809B"}>
                          BVN
                        </P>
                        <P fontSize="16px" color={"#374072"} fontWeight="500">
                          {user.profile.bvn}
                        </P>
                      </div>
                    </Card>
                  </div>
                  <DefaultButton
                    bg={"#D60000"}
                    color={"#fff"}
                    p={"8px 16px"}
                    onClick={() => {
                      setSelectedBankAccount({
                        bank_account_id: account.id,
                        platform: "bani",
                      });
                      setconfirmBankDeletionModal(true);
                    }}
                  >
                    Delete account
                  </DefaultButton>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="pt-6">
          <Card>
            <div className="text-center px-5 py-5">
              <P fontSize="16px" color={"#374072"} fontWeight="500">
                no Bank details avaliable
              </P>
            </div>
          </Card>
        </div>
      )}
      {user && user.coinprofile_accounts ? (
        <div className="py-6">
          <DefaultButton
            bg={"#112E46"}
            color={"#fff"}
            p={"8px 16px"}
            onClick={() => {
              setaddBankModal(true);
            }}
            className="mr-10"
          >
            Add bank account
          </DefaultButton>
          {/* <DefaultButton
            bg={"#D60000"}
            color={"#fff"}
            p={"8px 16px"}
            onClick={() => {
              setconfirmBankDeletionModal(true);
            }}
          >
            Delete active account
          </DefaultButton> */}
        </div>
      ) : null}
      {addBankModal ? (
        <AddBankModal
          width={"30%"}
          border={"none"}
          closeModalCallback={() => {
            setaddBankModal(false);
          }}
        />
      ) : null}

      {confirmBankDeletionModal ? (
        <ConfirmBankAccountDeletionModal
          width={"30%"}
          border={"none"}
          closeModalCallback={() => {
            setconfirmBankDeletionModal(false);
          }}
          selectedBankAccount={selectedBankAccount}
          yesAction={() => {
            setconfirmBankDeletionModal(false);
            setdeleteBankAccountModal(true);
          }}
        />
      ) : null}
      {deleteBankAccountModal ? (
        <DeleteBankAccountDeletionModal
          width={"30%"}
          border={"none"}
          selectedBankAccount={selectedBankAccount}
          closeModalCallback={() => {
            setdeleteBankAccountModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default UserBank;
