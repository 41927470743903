import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getUserSavings,
  getUserSavingsStats,
} from "../../../../../store/Actions/SavingsActions";
import { H1, H5 } from "../../../../../UI toolkit/Text/Text";
import { groupActivitiesByDaysOrMonth } from "../../../../../utils/specialFunctions";
import UsersSavingsTable from "./UsersSavingsTable";

const UserSavings = ({
  getUserSavingsStats,
  savingsStats,
  savings,
  match,
  getUserSavings,
  filter,
}) => {
  const headerStats = [
    {
      title: "Savings Wallet Balance",
      key: "savingsWalletBalance",
      bgColour: "#00BFFF",
    },
    {
      title: "Ongoing Flexible Savings",
      key: "totalOngoingFlexibleSavings",
      bgColour: "#24B57A",
    },
    {
      title: "Ongoing Fixed Savings",
      key: "totalOngoingFixedSavings",
      bgColour: "#FFBE40",
    },
    {
      title: "Interest Claimed on Fixed",
      key: "totalClaimedFixedInterest",
      bgColour: "#B8BDBB",
    },
  ];

  const filterSavingsBasedOnStatus = (savings, filterPayload) => {
    console.log(filterPayload);
    if (savings) {
      if (
        filterPayload?.status === "claimed" ||
        filterPayload?.status === "unclaimed"
      ) {
        const interestStatus =
          filterPayload?.status === "claimed" ? "paid" : "pending";
        return savings.filter(
          (curr) =>
            curr.savings_type.type === "fixed" &&
            curr.interest_status === interestStatus
        );
      }

      if (
        filterPayload?.status !== undefined &&
        filterPayload?.type !== undefined
      ) {
        return savings.filter(
          (curr) =>
            curr.status === filterPayload.status &&
            curr.savings_type.type === filterPayload.type
        );
      } else if (filterPayload?.status) {
        return savings.filter((curr) => curr.status === filterPayload.status);
      } else if (filterPayload?.type) {
        return savings.filter(
          (curr) => curr.savings_type.type === filterPayload.type
        );
      }
      return savings;
    }
  };

  useEffect(() => {
    console.log(filter);
    getUserSavingsStats(match.params.id);
    getUserSavings(match.params.id);
  }, [getUserSavingsStats, getUserSavings]);
  return (
    <div>
      <div className="flex justify-around mt-2">
        {/* <pre>{JSON.stringify(savingsStats, null, 2)}</pre> */}
        {headerStats.map((stat, index) => (
          <div
            key={index}
            className="px-4 py-6 flex flex-col items-center rounded-lg"
            style={{ background: stat.bgColour }}
          >
            <H5 color="#FFFFFF" fontSize="16px">
              {stat.title}{" "}
            </H5>
            <H5
              color={"#FFFFFF"}
              fontWeight="400"
              fontSize="20px"
              className="pr-4"
            >
              ${" "}
              {savingsStats
                ? Number(savingsStats[stat.key]).toFixed(2).toLocaleString()
                : 0}
            </H5>
          </div>
        ))}
      </div>

      <div className="mt-4">
        <UsersSavingsTable
          links1={groupActivitiesByDaysOrMonth(
            savings ? filterSavingsBasedOnStatus(savings, filter) : null,
            "days"
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savingsStats: state.savings.user_savings_stats,
    savings: state.savings.user_savings,
    filter: state.savings.user_savings_filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSavingsStats: (id) => dispatch(getUserSavingsStats(id)),
    getUserSavings: (id) => dispatch(getUserSavings(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSavings));
