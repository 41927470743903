import React from "react";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { H6, P } from "../../../../UI toolkit/Text/Text";
import ic_sent_icon from "../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../assets/images/ic-received-coin.svg";
import times_solid from "../../../../assets/images/times-solid.svg";
import { connect } from "react-redux";
import {
  approveDepositsAction,
  approveWithdrawalAction,
  rejectDepositsAction,
} from "../../../../store/Actions/TransactionActions";
import { capitalizeFirstLetter } from "../../../../utils/specialFunctions";
import { ViewActivityModalStyle } from "./Activities.styled";

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    verificationsList: state.verification.verificationsList,
    verificationDoc: state.verification.verificationDoc,
    loadVerificationDoc: state.verification.loadVerificationDoc,
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    approveDepositsAction: (id, successCallback) =>
      dispatch(approveDepositsAction(id, successCallback)),
    approveWithdrawalAction: (id, successCallback) =>
      dispatch(approveWithdrawalAction(id, successCallback)),
    rejectDepositsAction: (id, successCallback) =>
      dispatch(rejectDepositsAction(id, successCallback)),
  };
};

export const ViewActivityModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ width, border, closeModalCallback, transaction, user }) => {
  return (
    <div>
      <Modal width={width} border={border} callback={closeModalCallback}>
        {transaction ? (
          <ViewActivityModalStyle>
            <div className="-mx-8">
              <div className="flex items-center justify-between px-6 py-3 border-bottom bg-white">
                <div className="flex items-center">
                  <div>
                    <img
                      src={
                        transaction.type === "send"
                          ? ic_sent_icon
                          : transaction.type === "receive"
                          ? ic_receive_icon
                          : ""
                      }
                      alt="img"
                    />
                  </div>
                  &nbsp;
                  <div>
                    <H6
                      color={"#646A86"}
                      className="text-center"
                      fontWeight="700"
                    >
                      {transaction && transaction.type
                        ? capitalizeFirstLetter(transaction.type)
                        : null}
                    </H6>
                  </div>
                </div>

                <div className="flex">
                  <div>
                    <img
                      src={times_solid}
                      alt="cancel"
                      style={{
                        height: "15px",
                        width: "15px",
                        cursor: "pointer",
                      }}
                      onClick={closeModalCallback}
                    />
                  </div>
                </div>
              </div>

              <div
                className="px-6"
                style={{ maxHeight: "80vh", overflow: "auto" }}
              >
                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Amount
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.currency}
                                            ${transaction.amount.toLocaleString()}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Approximate value
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    USD{" "}
                    {transaction && transaction.amount_usd_equivalent
                      ? transaction.amount_usd_equivalent
                      : null}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Fee
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {transaction && transaction.fee ? transaction.fee : 0.0}
                  </P>
                </div>

                <>
                  {transaction &&
                  transaction.blockchain_fee &&
                  transaction.blockchain_fee ? (
                    <div className="pt-4 pb-4 border-bottom">
                      <div className="mb-1">
                        <P fontSize="13px" color={"#646A86"} fontWeight="500">
                          Blockchain Fee
                        </P>
                      </div>
                      <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                        {transaction && transaction.blockchain_fee
                          ? transaction.blockchain_fee
                          : 0.0}
                      </P>
                    </div>
                  ) : null}
                </>

                {transaction.from ? (
                  <div className="pt-4 pb-4 border-bottom">
                    <div className="mb-1">
                      <P fontSize="13px" color={"#646A86"} fontWeight="500">
                        Sender
                      </P>
                    </div>
                    <P fontSize="16px" color={"#4B4F52"} fontWeight="700">
                      {transaction && transaction.from
                        ? transaction.from
                        : null}
                    </P>
                  </div>
                ) : null}

                {transaction.to ? (
                  <div className="pt-4 pb-4 border-bottom">
                    <div className="mb-1">
                      <P fontSize="13px" color={"#646A86"} fontWeight="500">
                        Recipient
                      </P>
                    </div>
                    <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                      {transaction && transaction.to ? transaction.to : null}
                    </P>
                  </div>
                ) : null}

                {transaction.address ? (
                  <div className="pt-4 pb-4 border-bottom">
                    <div className="mb-1">
                      <P fontSize="13px" color={"#646A86"} fontWeight="500">
                        Address
                      </P>
                    </div>
                    <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                      {transaction && transaction.address
                        ? transaction.address
                        : null}
                    </P>
                  </div>
                ) : null}

                {transaction && transaction.blockchain_tx_id ? (
                  <div className="pt-4 pb-8 border-bottom">
                    <div className="mb-1">
                      <P fontSize="13px" color={"#646A86"} fontWeight="500">
                        Blockchain Transaction id
                      </P>
                    </div>
                    <P
                      fontSize="16px"
                      color={"#4B4F52"}
                      fontWeight="500"
                      style={{ wordWrap: "break-word" }}
                    >
                      {transaction && transaction.blockchain_tx_id
                        ? transaction.blockchain_tx_id
                        : null}
                    </P>
                  </div>
                ) : null}
                {transaction && !transaction.blockchain_tx_id ? (
                  <div className="pt-4 pb-8 border-bottom">
                    <div className="mb-1">
                      <P fontSize="13px" color={"#646A86"} fontWeight="500">
                        internal Transaction id
                      </P>
                    </div>
                    <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                      {transaction && transaction.id ? transaction.id : null}
                    </P>
                  </div>
                ) : null}
              </div>
            </div>
          </ViewActivityModalStyle>
        ) : (
          <P fontSize="13px" color={"#374072"} fontWeight="500">
            Loading.....
          </P>
        )}
      </Modal>
    </div>
  );
});
