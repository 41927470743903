import styled from "styled-components";

export const ActivitiesTablesHeaderStyle = styled.table`
  background: #e7eaf4;

  @media (max-width: 768px) {
    &.w-full {
      width: 160% !important;
    }
  }
  @media (max-width: 992px) {
    &.w-full {
      width: 160% !important;
    }
  }
  .Table-heading {
    background: #e7eaf4;
  }
`;

export const SendReceiveTableStyle = styled.table`
  @media (max-width: 768px) {
    &.w-full {
      width: 160% !important;
    }
  }
  @media (max-width: 992px) {
    &.w-full {
      width: 160% !important;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 10%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;

export const ActivitiesTableStyle = styled.table`
  @media (max-width: 768px) {
    &.w-full {
      width: 160% !important;
    }
  }
  @media (max-width: 992px) {
    &.w-full {
      width: 160% !important;
    }
  }
  .Table-heading {
    background: #e7eaf4;
  }
`;

export const ActivitiesTableCardStyle = styled.div`
  @media (max-width: 768px) {
    &.w-full {
      width: 160% !important;
    }
  }
  @media (max-width: 992px) {
    &.w-full {
      width: 160% !important;
    }
  }
`;

export const ActivitiesSummaryCardScrollStyle = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
    cursor: pointer;
  }
`;

export const ViewActivityModalStyle = styled.div`
  .border-bottom {
    border-bottom: 1px solid #eff0f5;
  }
`;
