import React from "react";
import { H5, SubHeader } from "../../../../../UI toolkit/Text/Text";

const Statbox = ({ name, value, isMoney }) => {
  return (
    <div className="w-6/12">
      <SubHeader color={"#7C8191"} fontWeight="500">
        {name}
      </SubHeader>
      <div className="flex pt-4">
        <H5 color={"#112E46"} fontWeight="700" className="pr-4">
          {isMoney ? "USDT" : ""}{" "}
          {value ? Number(value).toFixed(2).toLocaleString() : 0}
        </H5>
      </div>
    </div>
  );
};

export default Statbox;
