import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { TableTab } from "../../../Units/TabsAndLinkStyled";
import DashboardHeader from "../DashboardHeader";
import Admin from "./Admin";
import Coins from "./Coins";
import Price from "./Price";
import roqqu from "../../../../assets/images/roqqu-logo.svg";
import coinMarket from "../../../../assets/images/coinmarketcap-logo.svg";
import binanceLogo from "../../../../assets/images/binance-logo.svg";
import Wallet from "./Wallet";
import ApiKey from "./ApiKey/ApiKey";
import { adminSettingsAction } from "../../../../store/Actions/SettingsActions";
import PlatformControl from "./PlatformControl/index";
import Transaction from "./Transaction";
import { overviewAction } from "../../../../store/Actions/OverviewActions";

const Settings = ({ currencies, adminSettingsAction }) => {
  const location = useLocation();
  const [activeTab, handleActiveTab] = useState(0);

  const links = [
    { text: "Wallets" },
    { text: "Coins" },
    { text: "Trade Price" },
    { text: "Platform Control" },
    { text: "Transaction" },
    { text: "Admins" },
    { text: "API keys" },
  ];

  const images = [binanceLogo, coinMarket, roqqu];

  useEffect(() => {
    adminSettingsAction();
    overviewAction();
  }, [adminSettingsAction]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Settings</DashboardHeader>

        <div>
          <TableTab
            margin
            tabs={links}
            activeTab={activeTab}
            handleTab={handleActiveTab}
            p={"16px 0px"}
            boxShadow={"0px"}
            borderRadius={"0px"}
            activeBg={"#FAFBFD"}
            activeColor={"#005674"}
            color={"#646A86"}
            activeOutline={"none"}
            activeOutlineBottom={"2px solid #005674"}
            tailwindpad
            callback={(active) => {
              console.log(active);
            }}
          />
        </div>

        {activeTab === 0 ? (
          <div className="">
            <Wallet />
          </div>
        ) : activeTab === 1 ? (
          <div>
            <Coins
              currencies={currencies ? currencies : null}
              images={images}
            />
          </div>
        ) : activeTab === 2 ? (
          <div>
            <Price images={images} />
          </div>
        ) : activeTab === 3 ? (
          <div>
            <PlatformControl />
          </div>
        ) : activeTab === 4 ? (
          <div>
            <Transaction />
          </div>
        ) : activeTab === 5 ? (
          <div>
            <Admin images={images} />
          </div>
        ) : activeTab === 6 ? (
          <div>
            <ApiKey />
          </div>
        ) : null}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    admin_settings: state.settings.admin_settings,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.settings.loading,
    error: state.settings.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    adminSettingsAction: (successCallback) =>
      dispatch(adminSettingsAction(successCallback)),
    overAction: () => dispatch(overviewAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
