import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { getUsers } from "../../../../store/Actions/UsersAction";
import {
  ApproveUserAction,
  VerificationListAction,
  updateVerificationPage,
} from "../../../../store/Actions/VerificationActions";
import { Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { SearchInput } from "../../../../UI toolkit/Input/Input";
import { Card } from "../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../Units/TabsAndLinkStyled";
import DashboardHeader from "../DashboardHeader";
import { VerificationsListTable } from "./VerificationTable";
import search_icon from "../../../../assets/images/search.svg";
import caret_down from "../../../../assets/images/caret-down1.svg";
import {
  filterUsersonChange,
  filterVerificationUsers,
} from "../../../../utils/specialFunctions";
import { withTheme } from "styled-components";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P } from "../../../../UI toolkit/Text/Text";

const Verification = ({
  verificationsList,
  loading,
  VerificationListAction,
  theme,
  page,
  totalPage,
  updatePage,
}) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState(0);
  const [keyword, setkeyword] = useState(null);

  const links1 = [{ text: "Verify Level 3" }];

  useEffect(() => {
    VerificationListAction(page);
  }, [VerificationListAction, page]);

  const handleNextPage = () => {
    console.log(page, totalPage);
    if (page < totalPage - 1) {
      let nextPage = page + 1;
      updatePage(nextPage);
    }
  };

  const prevButtonDisabled = () => {
    return !(page > 0);
  };

  const nextButtonDisabled = () => {
    return !(page < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (page > 0) {
      updatePage(page - 1);
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Verifications</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>
          Verifications ({verificationsList ? verificationsList.length : 0})
        </DashboardHeader>
        {loading ? (
          <div
            className="flex items-center justify-center h-100"
            style={{ height: "100vh" }}
          >
            <h1>Loading........</h1>
          </div>
        ) : (
          <div>
            <div className="flex justify-between">
              <div className="flex w-full md:w-8/12 pb-6">
                <div className="w-full md:w-8/12 pr-5">
                  <SearchInput
                    placeholder="Search verification list"
                    search_icon={search_icon}
                    bg={"#FFFFFF"}
                    color={"#646A86"}
                    p={"8px 16px"}
                    callback={(e) => {
                      console.log(e.target.value);
                      setkeyword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <Card>
              <TabHeader>
                <div className="md:flex">
                  <div className="md:flex md:w-10/12 items-center">
                    <TableTab
                      tabs={links1}
                      activeTab={activeLink1}
                      handleTab={handleActiveLink1}
                      callback={(active) => {
                        // console.log(active)
                      }}
                    />
                  </div>
                  <div className="md:w-2/12  my-1.5 mx-1.5">
                    <Select
                      bgImg={caret_down}
                      bgColor={theme.colors.bg}
                      border={"1px solid #fff"}
                      borderRadius={"4px"}
                      color={"#fff"}
                      p={"5px 15px"}
                      data={links1}
                      defaultValue={"-- Select --"}
                      properyToShow={"text"}
                      hoverBg={"#FFF"}
                      hoverColor={"#112E46"}
                      fontSize={"16px"}
                      callback={(option, idx, arr) => {
                        console.log(option, idx, arr);
                        handleActiveLink1(idx);
                      }}
                    />
                  </div>
                </div>
              </TabHeader>

              {verificationsList ? (
                <div>
                  <VerificationsListTable
                    users={filterVerificationUsers(verificationsList, keyword)}
                  />
                </div>
              ) : null}
              <div>
                {!loading && (
                  <div className="flex justify-between gap-2 items-center py-4 w-full">
                    <p className=""></p>
                    <div className="flex items-center">
                      <DefaultButton
                        bg={theme.colors.bg}
                        color={theme.colors.color}
                        p={"8px 16px"}
                        onClick={handlePrevPage}
                        disabled={prevButtonDisabled()}
                      >
                        Prev
                      </DefaultButton>
                      <div className="px-4">
                        <P color={"#646A86"} fontSize="16px" fontWeight="700">
                          Page {page + 1} of {totalPage !== 0 ? totalPage : 1}
                        </P>
                      </div>
                      <DefaultButton
                        bg={theme.colors.bg}
                        color={theme.colors.color}
                        p={"8px 16px"}
                        onClick={handleNextPage}
                        disabled={nextButtonDisabled()}
                      >
                        Next
                      </DefaultButton>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    verificationsList: state.verification.verificationsList,
    page: state.verification.page,
    totalPage: state.verification.totalPage,
    loading: state.verification.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    VerificationListAction: () => dispatch(VerificationListAction()),
    ApproveUserAction: (id) => dispatch(ApproveUserAction(id)),
    updatePage: (page) => dispatch(updateVerificationPage(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Verification));
