import styled from "styled-components";

export const LoginBase = styled.div`
  // background-color: #112E46;
  // color: white;

  background: ${(props) => props.theme.colors.bg || "#112E46"};
  color: ${(props) => props.theme.colors.color || "#FFFFFF"};

  width: 100%;
  height: 100vh;
  padding: 64px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 376px) {
    > .holder {
      padding-top: 12rem;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 53.81px;
    // padding-top: 119.35px;
  }

  .logo .skye_logo {
    width: 133px;
    height: 39px;
  }

  .login-container {
  }

  .login-form {
    background: ${(props) => (props.bg ? props.bg : "#FDFDFD")};

    border-radius: 4px;
    border: 1px solid #e7eaf4;
    box-shadow: 0px 1px 5px #3f3f4419;
    opacity: 1;
    width: 452px;

    @media (max-width: 500px) {
      max-width: 80vw;
    }
  }

  .flitbase_logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
