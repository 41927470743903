import axios from "axios";
import {
  GET_ADMIN,
  CREATE_ADMIN,
  GET_ADMIN_SETTINGS,
  LOADING_ADMIN,
  ERROR_ADMIN,
  GET_COINS,
  GET_DEPOSIT_TYPES,
} from "../Types/Types";

import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";
import Api from "./api";

export const adminSettingsAction = (successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.adminSettings();
      console.log(response);
      dispatch({ type: GET_ADMIN_SETTINGS, admin_settings: response.data });
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const changeAdminSettingsAction = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.changeAdminSettings(state);
      console.log(response);
      customToast.success(`admin settings changed`);
      dispatch(adminSettingsAction());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const createAdminAction = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.createAdmin(state);
      console.log(response);
      customToast.success(`admin created`);
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getAdminsAction = (successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.getAdmins();
      console.log(response.data);
      dispatch({ type: GET_ADMIN, adminList: response.data });
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateAdminAction = (id, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.updateAdmin(id, state);
      console.log(response);
      customToast.success(`admin updated`);
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const changeAdminPasswordAction = (id, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.changeAdminPassword(id, state);
      console.log(response);
      customToast.success(`admin password changed`);
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const removeAdminAction = (adminId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.removeAdmin(adminId);
      console.log(response);
      customToast.success(`admin removed`);
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getCoinsAction = (successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.getCoins();
      console.log(response.data);
      dispatch({ type: GET_COINS, coins: response.data });
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const changeCoinSettingsAction = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.changeCoinSettings(state);
      console.log(response);
      customToast.success(`Coin setting changed`);
      dispatch(getCoinsAction());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getDepositTypesAction = (successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.getDepositTypes();
      console.log(response.data);
      dispatch({ type: GET_DEPOSIT_TYPES, depositTypes: response.data });
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const changeDepositPlatformSettingsAction = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ADMIN });
    dispatch(showLoading());
    try {
      const response = await Api.changeDepositPlatformSettings(state);
      console.log(response);
      customToast.success(`Deposit Platform setting changed`);
      dispatch(getDepositTypesAction());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ADMIN });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};
