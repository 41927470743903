import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import {
  TransactionsTableStyle,
  TransactionsTableHeaderStyle,
} from "./Transactions.styled";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { connect } from "react-redux";

import { capitalizeFirstLetter } from "../../../../../utils/specialFunctions";

import ic_sent_icon from "../../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../../assets/images/ic-received-coin.svg";

import ic_swapped_coin from "../../../../../assets/images/ic-swapped-coin.svg";

import FixedSavingsModal from "./FixedSavingsModal";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

// Redux store mapstatetoprops and mapdispatchtoprops

const FlexibleSavingsTable = ({ links1 }) => {
  const [openModal, setopenModal] = useState(false);

  const [saving, setSaving] = useState(null);

  const statusKeys = {
    stopped: "broken",
    active: "ongoing",
    completed: "completed",
    pending: "unclaimed",
    paid: "claimed",
  };

  const bgColor = (status) => {
    return status === "active" || status === "pending"
      ? "#FFEAB6"
      : status === "completed" || status === "paid"
      ? "#D1F7C4"
      : status === "stopped"
      ? "#FFDCE5"
      : "";
  };

  const color = (status) => {
    return status === "active" || status === "pending"
      ? "#606060"
      : status === "completed" || status === "paid"
      ? "#606060"
      : status === "stopped"
      ? "#531423"
      : "";
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <TransactionsTableHeaderStyle className="w-full">
        <tbody className="w-full">
          <tr className="Table-heading -px-1.5">
            <td width="20%" className="pt-4 pb-4"></td>

            <td width="15%" className="pt-4 pb-4">
              USER
            </td>

            <td width="15%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  INVESTED
                </SubHeader>
              </div>
            </td>
            <td width="10%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  INTEREST
                </SubHeader>
              </div>
            </td>
            <td width="15%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  PAYOUT
                </SubHeader>
              </div>
            </td>

            <td width="25%" className="pt-4 pb-4"></td>
          </tr>
        </tbody>
      </TransactionsTableHeaderStyle>
      {links1 &&
        links1.map((transactionGroup, idx, arr) => {
          return (
            <TransactionsTableStyle className="w-full" key={idx}>
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div className="pt-4 pb-4 px-1.5">
                      <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                        {`${timeAgo.format(
                          new Date(transactionGroup.group)
                        )} — ${new Date(
                          transactionGroup.group
                        ).toDateString()}`}
                      </P>
                    </div>
                  </td>
                </tr>

                {transactionGroup.data.map((curr, idx, arr) => {
                  return (
                    <tr
                      className="px-1.5"
                      style={{ borderBottom: "1px solid #EFF0F5" }}
                      key={idx}
                    >
                      <td
                        width="20%"
                        className="pt-4 pb-4 col-start-1 col-end-2"
                      >
                        <div className="flex">
                          <div className="pr-2">
                            <img
                              src={
                                curr.savings_type.type === "fixed"
                                  ? ic_sent_icon
                                  : curr.savings_type.type === "flexible"
                                  ? ic_receive_icon
                                  : curr.savings_type.type === "swap"
                                  ? ic_swapped_coin
                                  : ""
                              }
                              className="h-auto w-auto"
                            />
                          </div>
                          <div>
                            <P
                              fontSize="13px"
                              color={"#374072"}
                              fontWeight="500"
                            >{`${capitalizeFirstLetter(
                              curr.savings_type.type
                            )}`}</P>
                            <P fontSize="10px" color={"#7A809B"}>
                              {
                                new Date(curr.created_at)
                                  .toLocaleString()
                                  .split(",")[1]
                              }
                            </P>
                          </div>
                        </div>
                      </td>
                      <td width="15%" className="col-start-2 col-end-4">
                        <div>
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr && curr.account && curr.account.email
                              ? curr.account.email
                              : "no email"}
                          </P>
                        </div>
                      </td>
                      <td width="15%">
                        <div className="flex justify-center">
                          <div>
                            <P
                              fontSize="13px"
                              color={"#374072"}
                              fontWeight="500"
                            >
                              {`${
                                curr.currency === "USDT"
                                  ? "$"
                                  : curr.currrency === "NGN"
                                  ? "₦"
                                  : ""
                              } ${Number(curr.amount).toFixed(2)}`}
                            </P>
                            <P
                              fontSize="10px"
                              color={"#7A809B"}
                              fontWeight="400"
                            >
                              {`${
                                curr &&
                                curr.savings_type &&
                                curr.savings_type.duration
                                  ? curr.savings_type.duration
                                  : ""
                              } months at ${
                                curr &&
                                curr.savings_type &&
                                curr.savings_type.interest
                                  ? curr.savings_type.interest
                                  : ""
                              } %`}
                            </P>
                          </div>
                        </div>
                      </td>
                      <td width="10%">
                        <div className="flex justify-center items-start">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {`${
                              curr.currency === "USDT"
                                ? "$"
                                : curr.currrency === "NGN"
                                ? "₦"
                                : ""
                            } ${Number(curr.interest).toFixed(2)}`}
                          </P>
                        </div>
                        <div className="flex justify-center">
                          <Badge
                            color={color(curr.interest_status)}
                            bg={bgColor(curr.interest_status)}
                            style={{ fontSize: "8px", padding: "0.3em 1em" }}
                          >
                            {statusKeys[curr.interest_status]}
                          </Badge>
                        </div>
                      </td>
                      <td width="15%">
                        <div className="flex justify-center">
                          <div>
                            <P
                              fontSize="13px"
                              color={"#374072"}
                              fontWeight="500"
                            >
                              {`${
                                curr.currency === "USDT"
                                  ? "$"
                                  : curr.currrency === "NGN"
                                  ? "₦"
                                  : ""
                              } ${Number(curr.payout).toFixed(2)}`}
                            </P>
                            {/* <P
                              fontSize="10px"
                              color={"#7A809B"}
                              fontWeight="400"
                            >
                              {`Exp ${
                                curr && curr.savings_type && curr.end_date
                                  ? moment(curr.end_date).format("L")
                                  : ""
                              } `}
                            </P> */}
                          </div>
                        </div>
                      </td>
                      <td width="10%" className="pt-4 pb-4 px-1.5">
                        <div className="flex">
                          <div className="">
                            <P
                              fontSize="13px"
                              color={"#0087B5"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSaving(curr);
                                setopenModal(true);
                              }}
                            >
                              View
                            </P>
                          </div>
                        </div>
                      </td>
                      <td width="15%" className="pt-4 pb-4 px-1.5">
                        <Badge
                          color={color(curr.status)}
                          bg={bgColor(curr.status)}
                        >
                          {statusKeys[curr.status]}
                        </Badge>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TransactionsTableStyle>
          );
        })}

      <div>
        {openModal ? (
          <FixedSavingsModal
            width={"30%"}
            border={"none"}
            closeModalCallback={() => {
              setopenModal(false);
            }}
            saving={saving}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlexibleSavingsTable);
