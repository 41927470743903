import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { Input } from "../../../../../UI toolkit/Input/Input";
import { P } from "../../../../../UI toolkit/Text/Text";
import { Card } from "../../../../Units/Card.styled";
import { CardHolder } from "../../../../Units/CardTitle.styled";
import {
  adminSettingsAction,
  changeAdminSettingsAction,
} from "../../../../../store/Actions/SettingsActions";
import { customToast } from "../../../../../utils/customToast";

const BVNVerification = ({
  match,
  changeAdminSettingsAction,
  admin_settings,
}) => {
  const [state, setstate] = useState({
    key: null,
    value: null,
  });

  const [state1, setstate1] = useState({
    key: null,
    value: null,
  });

  const handleSubmit = () => {
    if (
      state.key !== null &&
      state.value !== null &&
      state1.key !== null &&
      state1.value !== null
    ) {
      changeAdminSettingsAction(state, (response) => {});
      changeAdminSettingsAction(state1, (response) => {});
    } else {
      customToast.error(
        "unable to implement action...provide a value for the input provided"
      );
      return;
    }
  };

  useEffect(() => {
    setstate({
      ...state,
      key: "FLUTTERWAVE_PUBLIC_KEY",
      value: admin_settings.FLUTTERWAVE_PUBLIC_KEY,
    });
    setstate1({
      ...state1,
      key: "FLUTTERWAVE_SECRET_KEY",
      value: admin_settings.FLUTTERWAVE_SECRET_KEY,
    });
  }, []);

  return (
    <div className="pt-6">
      <Card>
        <CardHolder>
          <P fontSize="14px" color={"#FFFFFF"} fontWeight="700">
            BVN Verification External API [Flutterwave]
          </P>
        </CardHolder>
        <div className="pt-6">
          <div className="w-7/12">
            <Card>
              <div>
                <P fontSize="12px" color={"#7A809B"}>
                  Public Key
                </P>
                <Input
                  type="text"
                  name="value"
                  color={"#646A86"}
                  fontWeight="500"
                  border="0px"
                  boxShadow="0px"
                  borderRadius="0px"
                  outline="none"
                  onChange={(e) => {
                    setstate({ ...state, value: e.target.value });
                  }}
                  value={state.value}
                />
              </div>
            </Card>
          </div>

          <div className="flex pt-6">
            <div className="w-7/12">
              <Card>
                <div>
                  <P fontSize="12px" color={"#7A809B"}>
                    Secret Key
                  </P>
                  <Input
                    type="text"
                    name="value"
                    color={"#646A86"}
                    fontWeight="500"
                    border="0px"
                    boxShadow="0px"
                    onChange={(e) => {
                      setstate1({ ...state1, value: e.target.value });
                    }}
                    value={state1.value}
                  />
                </div>
              </Card>
            </div>
          </div>

          <div className="py-6">
            <DefaultButton
              bg={"#E5EEF1"}
              color={"#005674"}
              p={"7px 19px"}
              onClick={handleSubmit}
            >
              Update BVN key
            </DefaultButton>
          </div>
        </div>
      </Card>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    admin_settings: state.settings.admin_settings,
    loading: state.settings.loading,
    error: state.settings.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BVNVerification));
