import React, { useState } from "react";
import { H5, SubHeader } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder, TabNav } from "../../../Units/CardTitle.styled";

const TransactionsCard = ({
  Titlecolor,
  transaction,
  pending,
  completed,
  type,
  headerCb,
  index,
  handleIndex
}) => {
  const TABS = [
    { label: "Today", queryParam: "today", index: 0 },
    { label: "7 days", queryParam: "7d", index: 1 },
    { label: "30 days", queryParam: "30d", index: 2 },
    { label: "All time", queryParam: "all", index: 3 },
  ];

  return (
    <div className="w-full overflow-hidden lg:my-5 lg:px-5 lg:w-1/2 xl:w-1/2">
      <Card>
        <CardHolder bg={Titlecolor} className="flex items-center justify-end">
          <div className="w-4/12">{type}</div>
          <div className="w-8/12 flex justify-end">
            <TabNav
              tabs={TABS}
              activeTab={index}
              handleTab={handleIndex}
              p={"4px 8px 3px 8px"}
              Keys={["label", "queryParam"]}
              // borderRadius={"0px"}
              boxShadow={"0px"}
              callback={(curr) => {
                headerCb(curr["queryParam"], type.toLowerCase(), curr["index"]);
              }}
            />
          </div>
        </CardHolder>

        <div className=" flex px-4">
          <div className="w-6/12 py-4">
          <SubHeader fontSize="13px" color={"#7C8191"}>
              COMPLETED
            </SubHeader>
            <H5 color={"#112E46"} fontSize="20px" fontWeight="700">
              ₦ {transaction ? transaction.toLocaleString() : 0}
            </H5>
          </div>

          <div className="w-12/12 py-4">
            <SubHeader fontSize="13px" color={"#7C8191"}>
              PENDING
            </SubHeader>
            <H5 color={"#112E46"} fontSize="20px" fontWeight="700">
              ₦ {pending ? pending.toLocaleString() : 0}
            </H5>
          </div>

          {/* <div className="w-4/12 py-4">
            <SubHeader fontSize="13px" color={"#7C8191"}>
              COMPLETED
            </SubHeader>
            <H5 color={"#112E46"} fontSize="20px" fontWeight="700">
              ₦ {completed ? completed.toLocaleString() : 0}
            </H5>
          </div> */}
        </div>
      </Card>
    </div>
  );
};

export default TransactionsCard;
