import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter, useLocation } from "react-router-dom";
import { Select } from "../../../../../UI toolkit/Dropdown/Dropdown";
import { Card } from "../../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../../Units/TabsAndLinkStyled";
import DetailsCard from "../DetailsCard";
import caret_down from "../../../../../assets/images/caret-down1.svg";
import { withTheme } from "styled-components";
import { groupActivitiesByDaysOrMonth } from "../../../../../utils/specialFunctions";
import { connect } from "react-redux";

import {
  getSavings,
  updateSavingsActiveStatus,
  updateSavingsPage,
} from "../../../../../store/Actions/SavingsActions";
import FlexibleSavingsTable from "./FlexibleSavingsTable";
import { DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P } from "../../../../../UI toolkit/Text/Text";

const Flexible = ({
  location,
  theme,
  getSavings,
  savings,
  filters,
  activeTab,
  updateStatus,
  activeStatus,
  loading,
  page,
  totalPages,
  updatePage,
}) => {
  const tabs = [
    { text: "All", value: "all" },
    //active completed stopped
    { text: "Active", value: "active" },
    { text: "Completed", value: "completed" },
    { text: "Broken", value: "stopped" },
  ];

  const tabKeys = {
    all: 0,
    active: 1,
    completed: 2,
    stopped: 3,
  };

  const currentStatus = activeStatus[activeTab];
  const currentPage = page[activeTab][currentStatus];
  const totalPage = totalPages[activeTab][currentStatus];

  const handleTabChange = (status) => {
    updateStatus({
      tab: activeTab,
      value: status,
    });
  };

  const handleNextPage = () => {
    console.log(currentPage, totalPage);
    if (currentPage < totalPage - 1) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage + 1,
      });
    }
  };

  const prevButtonDisabled = () => {
    return !(currentPage > 0);
  };

  const nextButtonDisabled = () => {
    return !(currentPage < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage - 1,
      });
    }
  };

  useEffect(() => {
    getSavings();
  }, [getSavings, activeStatus, page]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Flexible</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div className="flex flex-wrap overflow-hidden lg:-mx-5">
        <DetailsCard Titlecolor="#24B57A" type="flexible" />
      </div>
      <div className="pt-6">
        <Card>
          <TabHeader>
            <div className="lg:flex justify-between">
              <div className="lg:w-8/12">
                <TableTab
                  margin
                  tabs={tabs}
                  activeTab={currentStatus}
                  handleDispatch={handleTabChange}
                  callback={(active) => {
                    console.log(active);
                  }}
                />
              </div>
              <div className="lg:w-4/12 flex justify-end my-3 mx-3 lg:my-1.5 lg:mx-1.5 py-5 lg:py-0">
                <div className="w-6/12">
                  <Select
                    bgImg={caret_down}
                    // bgColor={"#112E46"}
                    // color={"#fff"}
                    bgColor={theme.colors.bg}
                    color={theme.colors.color}
                    border={"1px solid #fff"}
                    borderRadius={"4px"}
                    p={"5px 15px"}
                    data={tabs}
                    defaultValue={"-- Status --"}
                    properyToShow={"text"}
                    hoverBg={"#FFF"}
                    hoverColor={"#112E46"}
                    fontSize={"16px"}
                    callback={(option, idx, arr) => {
                      handleTabChange(option.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </TabHeader>
          <div className="px-0">
            <FlexibleSavingsTable
              links1={groupActivitiesByDaysOrMonth(
                savings ? savings : null,
                "days"
              )}
            />
          </div>
          <div>
            {!loading && (
              <div className="flex justify-between gap-2 items-center py-4 w-full">
                <p className="">
                  {/* Page no */}
                  {/* {pagination.per_page}  */}
                </p>
                <div className="flex items-center">
                  <DefaultButton
                    bg={theme.colors.bg}
                    color={theme.colors.color}
                    p={"8px 16px"}
                    onClick={handlePrevPage}
                    disabled={prevButtonDisabled()}
                  >
                    Prev
                  </DefaultButton>
                  <div className="px-4">
                    <P color={"#646A86"} fontSize="16px" fontWeight="700">
                      Page {currentPage + 1} of{" "}
                      {totalPage !== 0 ? totalPage : 1}
                    </P>
                  </div>
                  <DefaultButton
                    bg={theme.colors.bg}
                    color={theme.colors.color}
                    p={"8px 16px"}
                    onClick={handleNextPage}
                    disabled={nextButtonDisabled()}
                  >
                    Next
                  </DefaultButton>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.savings.loading,
    error: state.savings.error,
    savings: state.savings.savings,
    activeStatus: state.savings.savingsActiveStatus,
    page: state.savings.savingsPage,
    activeTab: state.savings.savingsActiveTab,
    totalPages: state.savings.savingsTotalPages,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getSavings: (query) => dispatch(getSavings(query)),
    updateStatus: (status) => dispatch(updateSavingsActiveStatus(status)),
    updatePage: (page) => dispatch(updateSavingsPage(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(Flexible)));
