import React, { useEffect } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { H5, P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import {
  CardHolder,
  CardTitle,
  CardTitleIcons
} from "../../../Units/CardTitle.styled";
import ic_send_icon from "../../../../assets/images/ic-send-coin.svg";
import ic_receive_icon from "../../../../assets/images/ic-receive-coin.svg";
import { connect } from "react-redux";
import {
  overviewAction,
  pricesAction,
  walletsAction
} from "../../../../store/Actions/OverviewActions";
import DashboardHeader from "../DashboardHeader";
import {
  OrderCoins,
  OrderCurrencies
} from "../../../../utils/specialFunctions";
import config from "../../../../config";

const Wallets = ({
  overviewAction,
  pricesAction,
  transactions,
  user,
  overview,
  total_users,
  deposits,
  withdrawals,
  currencies,
  prices,
  wallets,
  walletsAction,
  loading
}) => {
  const location = useLocation();
  const skyWalletBalance = curr => {
    return (wallets
      ? wallets[curr.symbol].external - wallets[curr.symbol].users
      : 0
    ).toFixed(6);
  };

  useEffect(() => {
    // overviewAction();
    // pricesAction();
    walletsAction();
  }, [
    // overviewAction,
    // pricesAction,
    walletsAction
  ]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wallets</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Wallet</DashboardHeader>
        {loading ? (
          <>
            <div
              className="flex items-center justify-center"
              style={{ height: "100vh" }}
            >
              <h1>Loading.....</h1>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <div className="">
                <div className="flex flex-wrap overflow-hidden lg:-mx-2 h-full">
                  {currencies &&
                    OrderCurrencies(currencies).map((curr, idx, arr) => {
                      return (
                        <div
                          className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/4 xl:w-1/4"
                          key={idx}
                        >
                          <Card className="h-full">
                            <CardHolder bg={curr.color} p={"7px 8px"}>
                              <div className="flex items-center">
                                <div
                                  className={
                                    curr.symbol !== "NGN" ? "w-8/12" : "w-12/12"
                                  }
                                >
                                  <CardTitle
                                    img={curr.image}
                                    text={curr.name}
                                    color={
                                      curr.symbol === "LTC" ||
                                      curr.symbol === "UNI"
                                        ? "#000"
                                        : "#fff"
                                    }
                                  />
                                </div>
                                <div
                                  className={
                                    curr.symbol !== "NGN" ? "w-4/12" : "w-0/12"
                                  }
                                >
                                  {curr.symbol !== "NGN" &&
                                  user.role === "super-admin" ? (
                                    <CardTitleIcons
                                      img1={ic_send_icon}
                                      img2={ic_receive_icon}
                                      link1={"/dashboard/send-and-recieve"}
                                      link2={"/dashboard/send-and-recieve"}
                                      crypto={curr.symbol}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </CardHolder>

                            <div className="px-4">
                              <div
                                className="py-6"
                                style={{ borderBottom: "1px solid #EFF0F5" }}
                              >
                                <H5
                                  color={"#112E46"}
                                  fontSize="20px"
                                  fontWeight="700"
                                >
                                  {curr.symbol === "NGN" ? "₦" : ""} &nbsp;
                                  {wallets && curr.symbol !== "NGN"
                                    ? Number(
                                        wallets[curr.symbol].external
                                      ).toFixed(6)
                                    : 0}
                                </H5>
                                {curr.symbol && curr.symbol !== "NGN" ? (
                                  <P
                                    fontSize="14px"
                                    color={"#7C8191"}
                                    fontWeight="500"
                                  >
                                    ~ USD{" "}
                                    {(prices &&
                                    prices[curr.symbol] &&
                                    prices[curr.symbol].usd &&
                                    curr.symbol &&
                                    curr.symbol !== "NGN" &&
                                    wallets
                                      ? prices[curr.symbol].usd *
                                        wallets[curr.symbol].external
                                      : 0
                                    ).toFixed(2)}
                                  </P>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="pt-6">
                                <SubHeader color={"#7C8191"} fontSize="12px">
                                  USERS CASH
                                </SubHeader>
                                <div className="pb-4">
                                  <H5
                                    color={"#112E46"}
                                    fontSize="16px"
                                    fontWeight="700"
                                  >
                                    {wallets &&
                                    curr.symbol !== "NGN" &&
                                    wallets[curr.symbol].users !== null
                                      ? wallets[curr.symbol].users
                                      : wallets &&
                                        wallets[curr.symbol].users !== null
                                      ? wallets[curr.symbol].users.toFixed(6)
                                      : 0}
                                  </H5>
                                </div>
                              </div>

                              <SubHeader color={"#7C8191"} fontSize="12px">
                                {config.appName}
                              </SubHeader>
                              <div className="pb-4">
                                <H5
                                  color={
                                    skyWalletBalance(curr) > 0
                                      ? "#24B57A"
                                      : skyWalletBalance(curr) < 0
                                      ? "#D60000"
                                      : "#112E46"
                                  }
                                  fontSize="16px"
                                  fontWeight="700"
                                >
                                  {skyWalletBalance(curr)}
                                </H5>

                                {curr.symbol !== "NGN" ? (
                                  <div>
                                    <SubHeader
                                      fontSize="10px"
                                      color={"#7C8191"}
                                    >
                                      ~ USD{" "}
                                      {(prices &&
                                      prices[curr.symbol] &&
                                      prices[curr.symbol].usd &&
                                      wallets
                                        ? prices[curr.symbol].usd *
                                          skyWalletBalance(curr)
                                        : 0
                                      ).toFixed(2)}
                                    </SubHeader>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              {curr.symbol === "NGN" ? (
                                <div className="pb-4">
                                  <Link
                                    to="/dashboard/transactions"
                                    className="transaction-button"
                                  >
                                    View transactions
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </Card>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    wallets: state.overview.wallets,
    overview: state.overview.overview,
    transactions: state.overview.transactions,
    total_users: state.overview.total_users,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.overview.loading,
    error: state.overview.error
  };
};

// Link Actions to component
const mapDispatchToProps = dispatch => {
  return {
    overviewAction: () => dispatch(overviewAction()),
    pricesAction: () => dispatch(pricesAction()),
    walletsAction: () => dispatch(walletsAction())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Wallets));
