import { Link } from "react-router-dom";
import styled from "styled-components";
import { TabButton } from "../../UI toolkit/Button/Button";
import { P } from "../../UI toolkit/Text/Text";

export const CardHolder = styled.div`
  box-shadow: 0px 1px 5px #3f3f4419;

  // background: ${(props) => props.bg || "#112E46"};
  // color: ${(props) => props.color || "#FFFFFF"};

  border-radius: ${(props) => props.borderRadius || "4px"};
  opacity: ${(props) => props.opacity || "1"};
  padding: ${(props) => props.p || "10px 24px"};
  font-size: ${(props) => props.fontSize || "14px"};

  // background: ${(props) => props.theme.colors.bg || "#112E46"};
  // color: ${(props) => props.theme.colors.color || "#FFFFFF"};

  background: ${(props) => props.bg || props.theme.colors.bg};
  color: ${(props) => props.color || props.theme.colors.color};
`;

export const CardTitleBase = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & .icon {
    width: 24px;
    height: 24px;
    opacity: 1;
    border-radius: 50%;
    margin-right: 8px;
    background: #fff;
  }
`;

export const CardTitleEllipse = styled.div`
  background: #24b57a;
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export const CardTitle = (props) => {
  return (
    <CardTitleBase>
      <div>
        <img className="icon" src={props.img} alt="img" />
      </div>
      <P color={props.color || "#FFF"} fontSize={"14px"} fontWeight="500">
        {props.text}
      </P>
    </CardTitleBase>
  );
};

export const CardTitleIconBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div img {
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
`;

export const CardTitleIcons = (props) => {
  return (
    <CardTitleIconBase>
      <div className="pr-1.5">
        <Link
          to={{
            pathname: props.link1 || "",
            state: { Tab: 0, crypto: props.crypto ? props.crypto : null },
          }}
        >
          <img src={props.img1} alt="i" />
        </Link>
      </div>
      <div className="">
        <Link
          to={{
            pathname: props.link2 || "",
            state: { Tab: 1, crypto: props.crypto ? props.crypto : null },
          }}
        >
          <img src={props.img2} alt="i" />
        </Link>
      </div>
    </CardTitleIconBase>
  );
};

export const TabNav = (props) => {
  const {
    activeTab,
    handleTab,
    tabs,
    p,
    bg,
    activeBg,
    fontSize,
    Keys,
    boxShadow,
    borderRadius,
  } = props;

  return (
    <div>
      {tabs &&
        tabs.map((curr, idx, arr) => (
          <TabButton
            key={idx}
            className={`${idx < arr.length - 1 ? "mr-2" : ""} ${
              idx === activeTab ? "active" : ""
            }`}
            activeOutline="1px solid #fff"
            fontSize={fontSize}
            bg={bg ? bg : "transparent"}
            activeBg={activeBg ? activeBg : "transparent"}
            color={"#FFF"}
            p={p ? p : "4px 8px 3px 8px"}
            borderRadius={borderRadius}
            boxShadow={boxShadow}
            onClick={(e) => {
              handleTab(idx);
              props.callback && props.callback(curr);
            }}
          >
            {curr[Keys[0]]}
          </TabButton>
        ))}
    </div>
  );
};
