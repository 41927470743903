import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  resetReferralEarnings,
  restoreUser,
} from "../../../../store/Actions/UsersAction";
import { Badge, DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import {
  compareBy,
  getLevel,
  toPlainString,
} from "../../../../utils/specialFunctions";
import { CreditPromoBalanceModal, DebitPromoBalanceModal } from "./UsersModal";

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    resetReferralEarnings: (userId) => dispatch(resetReferralEarnings(userId)),
    restoreUser: (id) => dispatch(restoreUser(id)),
  };
};

export const UsersTable =
  // connect(
  //   mapStateToProps,
  //   mapDispatchToProps
  // )(
  //   withRouter
  ({ users, restoreUser }) => {
    const [usersData, setusersData] = useState(null);
    const [tableKey, settableKey] = useState(null);
    const [tableKeyToggle, settableKeyToggle] = useState(false);

    const sortByJoinTime = (tableKey) => {
      settableKey(tableKey);
      settableKeyToggle(!tableKeyToggle);
    };

    useEffect(() => {
      console.log(usersData);
    }, [usersData]);

    useEffect(() => {
      setusersData(users);
    }, [users]);

    return (
      <div style={{ overflowX: "auto" }}>
        <table className="w-full">
          <thead style={{ background: "#E7EAF4" }}>
            <tr className="Table-heading -px-1.5">
              <td
                width="15%"
                className="pt-4 pb-4 px-4"
                onClick={() => {
                  sortByJoinTime("kyc_level");
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    LEVEL
                  </SubHeader>
                </div>
              </td>
              <td
                width="20%"
                className="pt-4 pb-4 px-4"
                onClick={() => {
                  sortByJoinTime("first_name");
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    USERNAME
                  </SubHeader>
                </div>
              </td>
              <td
                width="25%"
                className="pt-4 pb-4 px-4"
                onClick={() => {
                  sortByJoinTime("email");
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    EMAIL
                  </SubHeader>
                </div>
              </td>
              <td
                width="20%"
                className="pt-4 pb-4 px-4"
                onClick={() => {
                  sortByJoinTime("created_at");
                }}
                style={{ cursor: "pointer" }}
              >
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  NGN Balance
                </SubHeader>
              </td>

              <td width="20%" className="pt-4 pb-4 px-4"></td>
            </tr>
          </thead>

          <tbody>
            {users
              ? usersData &&
                usersData
                  .sort(compareBy(tableKey, tableKeyToggle))
                  .map((user, idx, arr) => {
                    const level = user.maxLevel ? user.maxLevel : 0;
                    return (
                      <tr
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #EFF0F5",
                        }}
                        key={user.id}
                      >
                        <td width="15%" className="px-4 pt-4 pb-4">
                          <Badge
                            color={
                              level === 0
                                ? "#337138"
                                : level === 1
                                ? "#606060"
                                : level === 2
                                ? "#335F71"
                                : level === 3
                                ? "#531423"
                                : ""
                            }
                            bg={
                              level === 0
                                ? "#FFDCE5"
                                : level === 1
                                ? "#FFEAB6"
                                : level === 2
                                ? "#D0F0FD"
                                : level === 3
                                ? "#D1F7C4"
                                : "#337138"
                            }
                          >
                            {level}
                          </Badge>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {user.first_name}
                          </P>
                          <P fontSize="10px" color={"#9599AC"}>
                            {new Date(user.created_at).toLocaleString()}
                          </P>
                        </td>

                        <td width="25%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#005674"} fontWeight="500">
                            {user.email}
                          </P>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#005674"} fontWeight="500">
                            {/* {user.wallets.wallets.NGN.coin === "NGN" && " ₦"}
                            {user.wallets.wallets.NGN.available_balance.toLocaleString()} */}
                            N/A
                          </P>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <div className="flex justify-end">
                            <div className="pr-6">
                              <P fontSize="13px" color={"#0087B5"}>
                                <Link to={"/dashboard/users/" + user.id}>
                                  View
                                </Link>
                              </P>
                            </div>

                            {user.status === "deleted" ? (
                              <div className="cursor-pointer">
                                <P
                                  fontSize="13px"
                                  color={"#24B57A"}
                                  onClick={() => restoreUser(user.id)}
                                >
                                  Undelete
                                </P>
                              </div>
                            ) : (
                              <div className="">
                                <P fontSize="13px" color={"#24B57A"}>
                                  <Link
                                    to={
                                      "/dashboard/users/" + user.id + "/balance"
                                    }
                                  >
                                    +/- Balance
                                  </Link>
                                </P>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
              : null}
          </tbody>
        </table>
      </div>
    );
  };
export const UserWalletTable = ({ user }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="w-full">
        <tbody style={{ background: "#E7EAF4" }}>
          <tr>
            <td width="25%" className="pt-4 pb-4 px-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  COIN
                </SubHeader>
              </div>
            </td>

            <td width="25%" className="pt-4 pb-4 px-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  AVALIABLE BALANCE
                </SubHeader>
              </div>
            </td>

            <td width="25%" className="pt-4 pb-4 px-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  LEDGER BALANCE
                </SubHeader>
              </div>
            </td>
            <td width="25%" className="pt-4 pb-4 px-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  WALLET ADDRESS
                </SubHeader>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody>
          {user &&
            Object.values(user.wallets.wallets).map((curr, idx, arr) => {
              return (
                <tr
                  style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}
                  key={idx}
                >
                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {curr.coin}
                    </P>
                  </td>

                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {toPlainString(curr.available_balance)}
                    </P>
                  </td>

                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#005674"} fontWeight="500">
                      {toPlainString(curr.ledger_balance)}
                    </P>
                  </td>

                  <td width="25%" align="left" className="px-4 pt-4 pb-4">
                    {user.wallet_addresses
                      .filter((address) => address.currency === curr.coin)
                      .map((wallet) => {
                        return (
                          <P fontSize="13px" color={"#005674"} fontWeight="500">
                            {wallet.address}
                          </P>
                        );
                      })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export const UserBalanceTable = ({ user }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="w-full">
        <thead style={{ background: "#E7EAF4" }}>
          <tr className="Table-heading">
            <td width="25%" className="px-4 pt-4 pb-4">
              <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                Coin / currency
              </SubHeader>
            </td>

            <td width="25%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Available balance
                </SubHeader>
              </div>
            </td>

            <td width="25%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Ledger Balance
                </SubHeader>
              </div>
            </td>
            <td width="25%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Total balance
                </SubHeader>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {user &&
            Object.values(user.wallets.wallets).map((curr, idx, arr) => {
              return (
                <tr
                  style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}
                  key={curr.id}
                >
                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {curr.coin}
                    </P>
                  </td>

                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {curr.available_balance}
                    </P>
                  </td>

                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#005674"} fontWeight="500">
                      {toPlainString(curr.ledger_balance)}
                    </P>
                  </td>

                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#005674"} fontWeight="500">
                      {toPlainString(curr.ledger_balance)}
                    </P>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export const UserReferralsTable = ({ user }) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="w-full" style={{ background: "#E7EAF4" }}>
        <thead style={{ background: "#E7EAF4" }}>
          <tr className="Table-heading">
            <td width="33.3%" className="px-4 pt-4 pb-4">
              <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                Email
              </SubHeader>
            </td>

            <td width="33.3%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  First name
                </SubHeader>
              </div>
            </td>

            <td width="33.3%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Last name
                </SubHeader>
              </div>
            </td>
          </tr>
        </thead>
      </table>

      {user &&
        user.map((referral, idx, arr) => {
          return (
            <table className="w-full" key={idx}>
              <tbody>
                {referral &&
                  referral.data.map((curr, curridx, currArr) => {
                    return (
                      <tr
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #EFF0F5",
                        }}
                        key={curridx}
                      >
                        <td width="33.3%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr.email}
                          </P>
                        </td>

                        <td width="33.3%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr.first_name}
                          </P>
                        </td>

                        <td width="33.3%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#005674"} fontWeight="500">
                            {curr.last_name}
                          </P>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          );
        })}
    </div>
  );
};

export const UserPromoTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(({ user, match, resetReferralEarnings }) => {
    const [openCreditBalanceModal, setopenCreditBalanceModal] = useState(false);
    const [openDebitBalanceModal, setopenDebitBalanceModal] = useState(false);

    return (
      <div>
        <div className="overflow-auto">
          <table className="w-full">
            <thead style={{ background: "#E7EAF4" }}>
              <tr className="Table-heading">
                <td className="px-4 pt-4 pb-4">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    No of Referrals
                  </SubHeader>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Referred by
                    </SubHeader>
                  </div>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Total Referral Earning
                    </SubHeader>
                  </div>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Promo Balance
                    </SubHeader>
                  </div>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Promo balance Left
                    </SubHeader>
                  </div>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Action
                    </SubHeader>
                  </div>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr
                style={{
                  width: "100%",
                  borderBottom: "1px solid #EFF0F5",
                }}
              >
                <td className="px-4 pt-4 pb-4">
                  <P fontSize="13px" color={"#374072"} fontWeight="500">
                    {user && user.referrals ? user.referrals.length : 0}
                  </P>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <P fontSize="13px" color={"#374072"} fontWeight="500">
                    {user &&
                    user.profile &&
                    user.profile.referrer &&
                    user.profile.referrer.first_name
                      ? user.profile.referrer.first_name
                      : null}
                    {user &&
                    user.profile &&
                    user.profile.referrer &&
                    user.profile.referrer.last_name
                      ? user.profile.referrer.last_name
                      : null}
                  </P>
                  <P fontSize="10px" color={"#374072"} fontWeight="500">
                    {user && user.referrer && user.referrer.email
                      ? user.referrer.email
                      : null}
                  </P>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <P fontSize="13px" color={"#374072"} fontWeight="500">
                    {user && user.profile && user.profile.referral_balance
                      ? user.profile.referral_balance
                      : null}
                  </P>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <P fontSize="13px" color={"#374072"} fontWeight="500">
                    {user && user.profile && user.profile.promo
                      ? user.profile.promo.amount
                      : null}
                  </P>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <P fontSize="13px" color={"#374072"} fontWeight="500">
                    {user && user.profile && user.profile.promo
                      ? user.profile.promo.amount_left
                      : null}
                  </P>
                </td>

                <td className="px-4 pt-4 pb-4">
                  <div className="flex -mx-1">
                    <div className="px-1">
                      <DefaultButton
                        small
                        p={"5px 16px 5px 16px"}
                        bg={"#24B57A"}
                        color={"#FFF"}
                        onClick={() => {
                          setopenCreditBalanceModal(true);
                        }}
                      >
                        Credit Balance
                      </DefaultButton>
                    </div>

                    <div className="px-1">
                      <DefaultButton
                        small
                        p={"5px 16px 5px 16px"}
                        bg={"#FBE5E5"}
                        color={"#D60000"}
                        onClick={() => {
                          setopenDebitBalanceModal(true);
                        }}
                      >
                        Debit Balance
                      </DefaultButton>
                    </div>

                    <div className="px-1">
                      <DefaultButton
                        small
                        p={"5px 16px 5px 16px"}
                        bg={"#FBE5E5"}
                        color={"#D60000"}
                        onClick={() => {
                          resetReferralEarnings(match.params.id);
                        }}
                      >
                        Reset Referral Balance
                      </DefaultButton>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {openDebitBalanceModal ? (
          <div className="">
            <DebitPromoBalanceModal
              width={"30%"}
              border={"none"}
              closeModalCallback={() => {
                setopenDebitBalanceModal(false);
              }}
            />
          </div>
        ) : null}

        {openCreditBalanceModal ? (
          <div className="">
            <CreditPromoBalanceModal
              width={"30%"}
              border={"none"}
              closeModalCallback={() => {
                setopenCreditBalanceModal(false);
              }}
            />
          </div>
        ) : null}
      </div>
    );
  })
);
