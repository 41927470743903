import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeAdminSettingsAction } from "../../../../../store/Actions/SettingsActions";
import { P } from "../../../../../UI toolkit/Text/Text";

const PlatformSettingRow = ({ item, value, changeAdminSettingsAction }) => {
  const [state, setstate] = useState({
    key: null,
    value: null,
    type: null,
  });

  const handleUpdate = () => {
    if (state.value !== null) {
      changeAdminSettingsAction(state, (response) => {});
    }
  };
  const isVersioning =
    item.key === "IOS_APP_VERSION" || item.key === "ANDROID_APP_VERSION";

  const [versionArray, setVersionArray] = useState([]);

  useEffect(() => {
    setstate({
      key: item.key,
      value: versionArray.join("."),
      type: "string",
    });
  }, [versionArray]);

  return (
    <tr style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}>
      <td width="33.333%" className="px-4 py-4">
        <div className="flex items-center">
          <div className="w-10/12">
            <P color={"#374072"} fontSize={"13px"}>
              {item.title}
            </P>
          </div>
        </div>
      </td>
      <td width="33.333%" className="px-4 py-4">
        {isVersioning ? (
          <div className="relative flex justify-center">
            {/* <pre>{JSON.stringify(versionArray, null, 2)}</pre> */}
            <div className="flex items-center">
              {value && (
                <VersioningInputField
                  value={value}
                  index={0}
                  setVersionArray={setVersionArray}
                />
              )}
              <div className="bg-black w-1.5 h-1.5 rounded-full mx-4"></div>
            </div>
            <div className="flex items-center ">
              {value && (
                <VersioningInputField
                  value={value}
                  index={1}
                  setVersionArray={setVersionArray}
                />
              )}
              <div className="bg-black w-1.5 h-1.5 rounded-full mx-4"></div>
            </div>
            <div>
              {value && (
                <VersioningInputField
                  value={value}
                  index={2}
                  setVersionArray={setVersionArray}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="relative grid justify-items-center">
            <input
              style={{
                width: "101px",
                height: "41px",
              }}
              min="0"
              step="1"
              className={`border border-gray-300 rounded-md pl-2 placeholder:text-xxs ${
                item.currency ? "pt-5" : ""
              }`}
              type={item.currency ? "number" : "text"}
              placeholder={value}
              onChange={(e) => {
                setstate({
                  key: item.key,
                  value: item.currency
                    ? Number(e.target.value)
                    : e.target.value,
                  type: "number",
                });
              }}
            />
            {item.currency && (
              <label className="absolute top-0 text-xsm text-primaryMedium mr-14 pt-1">
                {item.currency}
              </label>
            )}
          </div>
        )}
      </td>
      <td width="33.333%" className="px-4 py-4  m-0">
        <div className="flex justify-end">
          <button
            style={{ width: "100px", height: "33px" }}
            type="button"
            className="text-xxs text-primary bg-primaryLight"
            onClick={handleUpdate}
          >
            Update
          </button>
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformSettingRow);

const VersioningInputField = ({ value, index, setVersionArray }) => {
  const [digit, setDigit] = useState(value.split(".")[index]);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input value is a digit and its length is less than or equal to 1
    if (/^\d{1,2}$/.test(inputValue) && inputValue.length <= 2) {
      setDigit(inputValue);
      setVersionArray((prev) => {
        const newArr = [...prev];
        newArr[index] = inputValue;
        return newArr;
      });
    }
  };

  useEffect(() => {
    setVersionArray((prev) => {
      const newArr = [...prev];
      newArr[index] = digit;
      return newArr;
    });
  }, []);

  return (
    <input
      style={{
        width: "80px",
        height: "41px",
      }}
      type="number"
      min="0"
      step="1"
      placeholder={value.split(".")[index]}
      onChange={handleChange}
      value={digit}
      className="border border-gray-300 rounded-md pl-3"
    />
  );
};
