import { combineReducers } from "redux";
import AccountReducer from "./AccountReducer";
import UserReducer from "./UsersReducer";
import { loadingBarReducer } from "react-redux-loading-bar";
import overviewReducer from "./OverviewReducer";
import transactionReducer from "./TransactionReducer";
import MerchantReducer from "./MerchantReducer";
import VerificationReducer from "./VerificationReducer";
import uiReducer from "./UIReducer";
import SettingsReducer from "./SettingsReducer";
import AnnouncementReducer from "./AnnouncementReducer";
import SavingsReducer from "./SavingsReducer";
import CardsReducer from "./CardsReducer";

export default combineReducers({
  loadingBar: loadingBarReducer,
  auth: AccountReducer,
  users: UserReducer,
  overview: overviewReducer,
  transactions: transactionReducer,
  merchants: MerchantReducer,
  verification: VerificationReducer,
  settings: SettingsReducer,
  UI: uiReducer,
  announcement: AnnouncementReducer,
  savings: SavingsReducer,
  cards: CardsReducer,
});
