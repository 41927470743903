import React, { useState } from "react";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import {
  DropdownStyle,
  OptionStyle,
} from "../../../../UI toolkit/Dropdown/Dropdown";
import { Input, ToggleButton } from "../../../../UI toolkit/Input/Input";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { P } from "../../../../UI toolkit/Text/Text";
import caret_down from "../../../../assets/images/caret-down.svg";
import { connect } from "react-redux";
import { createMerchant } from "../../../../store/Actions/MerchantsAction";
import { customToast } from "../../../../utils/customToast";
import times_solid from "../../../../assets/images/times-solid.svg";
import { getBanksAction } from "../../../../store/Actions/TransactionActions";
import {
  addBankAccountsAction,
  creditPromoBalance,
  debitPromoBalance,
  deleteBankAccountAction,
} from "../../../../store/Actions/UsersAction";
import { withRouter } from "react-router";
import { ViewActivityModalStyle } from "../Activities/Activities.styled";
import cancelIcon from "../../../../assets/cancel-icon.svg";

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    banks: state.transactions.banks,
    loading: state.merchants.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    addBankAccountsAction: (userId, successCallback) =>
      dispatch(addBankAccountsAction(userId, successCallback)),
    getBanksAction: () => dispatch(getBanksAction()),
    deleteBankAccountAction: (userId, payload, successCallback) =>
      dispatch(deleteBankAccountAction(userId, payload, successCallback)),
    createMerchant: (state, successCallback) =>
      dispatch(createMerchant(state, successCallback)),
    creditPromoBalance: (id, state, successCallback) =>
      dispatch(creditPromoBalance(id, state, successCallback)),
    debitPromoBalance: (id, state, successCallback) =>
      dispatch(debitPromoBalance(id, state, successCallback)),
  };
};

export const AddBankModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    ({
      match,
      width,
      border,
      closeModalCallback,
      addBankAccountsAction,
      banks,
    }) => {
      const [state, setstate] = useState({
        bank_id: "",
        account_number: "",
        account_name: "",
      });

      const handleChange = (e) => {
        switch (e.target.type) {
          case "number":
            setstate({
              ...state,
              [e.target.name]: parseFloat(e.target.value),
            });
            break;
          case "checkbox":
            setstate({
              ...state,
              [e.target.name]: e.target.checked,
            });
            break;
          default:
            setstate({
              ...state,
              [e.target.name]: e.target.value,
            });
        }
      };

      const handleSubmit = (e) => {
        console.log(state);
        e.preventDefault();
        if (
          state.bank_id.length === 0 ||
          state.account_number.length === 0 ||
          state.account_name.length === 0
        ) {
          customToast.error(
            "account number or account name not present.. input all the required fields"
          );
          return;
        }
        addBankAccountsAction(match.params.id, state, (response) => {});
      };

      return (
        <div>
          <Modal width={width} border={border}>
            <div className="-mx-8 px-6 py-4">
              <div className=" flex justify-between">
                <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
                  Confirm Account
                </P>
                <div className="flex">
                  <div>
                    <img
                      src={times_solid}
                      alt="cancel"
                      style={{
                        height: "15px",
                        width: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        closeModalCallback();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Account Number
                  </P>
                </div>
                <Input
                  placeholder="Account number"
                  type="text"
                  name="account_number"
                  required
                  value={state.account_number}
                  onChange={handleChange}
                />
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Account Name
                  </P>
                </div>
                <Input
                  placeholder="Account name"
                  type="text"
                  name="account_name"
                  required
                  value={state.account_name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <div className="mt-6 mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Bank
                  </P>
                </div>

                <DropdownStyle
                  bgImg={caret_down}
                  bgColor={"#fff"}
                  color={"#374072"}
                  p={"11px 16px 10px 16px"}
                  border={"1px solid #fff"}
                  borderRadius={"4px"}
                  onChange={(e) => {
                    setstate({ ...state, bank_id: e.target.value });
                  }}
                >
                  <OptionStyle
                    bgImg={caret_down}
                    bgColor={"fff"}
                    color={"#374072"}
                    name="bank_account_id"
                    value={null}
                    selected={true}
                    disabled="disabled"
                    boxShadow={"0px 1px 4px #3F3F4419"}
                  >
                    --Select Your Bank
                  </OptionStyle>
                  {banks &&
                    banks.map((curr, idx, arr) => {
                      return (
                        <OptionStyle
                          key={idx}
                          bgImg={caret_down}
                          bgColor={"fff"}
                          color={"#374072"}
                          name="bank_account_id"
                          value={curr.id}
                          boxShadow={"0px 1px 4px #3F3F4419"}
                        >
                          {curr.name}
                        </OptionStyle>
                      );
                    })}
                </DropdownStyle>
              </div>

              <div className="mt-6 mb-4 flex justify-center">
                <DefaultButton
                  small
                  bg={"#005674"}
                  color={"#fff"}
                  border={"1px solid #005674"}
                  p={"7px 16px"}
                  required
                  onClick={handleSubmit}
                >
                  Add
                </DefaultButton>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  )
);

export const ConfirmBankAccountDeletionModal = ({
  width,
  border,
  closeModalCallback,
  yesAction,
  selectedBankAccount,
}) => {
  return (
    <div>
      <Modal width={width} border={border}>
        <div className="-mx-8  py-4 ">
          <div className=" flex justify-between border-b border-[#E7EAF4] pb-4 px-6">
            <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
              Delete account
            </P>

            <div className="flex">
              <div>
                <img
                  src={times_solid}
                  alt="cancel"
                  style={{
                    height: "15px",
                    width: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    closeModalCallback();
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="mb-1.5">
              <P
                color={"#7A809B"}
                fontSize={"13px"}
                fontWeight={700}
                className="text-center"
              >
                Are you sure you want to delete this account?
              </P>
              <div>
                <div className="py-6 flex justify-center">
                  <DefaultButton
                    bg={"#112E46"}
                    color={"#fff"}
                    p={"8px 16px"}
                    onClick={() => {
                      yesAction();
                    }}
                    className="mr-8"
                  >
                    Yes
                  </DefaultButton>
                  <DefaultButton
                    bg={"#D60000"}
                    color={"#fff"}
                    p={"8px 16px"}
                    onClick={() => {
                      closeModalCallback();
                    }}
                  >
                    No
                  </DefaultButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const DeleteBankAccountDeletionModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    ({
      width,
      match,
      border,
      closeModalCallback,
      bank_account_id,
      deleteBankAccountAction,
      selectedBankAccount,
    }) => {
      const [password, setPassword] = useState("");
      const [error, setError] = useState(null);

      const handleSubmit = (e) => {
        e.preventDefault();
        console.log(password);

        if (password.length === 0 || password === null) {
          setError("Password is required");
          return customToast.error("Password is required");
        }

        const payload = {
          password,
          bank_account_id: selectedBankAccount.bank_account_id,
          platform: selectedBankAccount.platform,
          account_id: match.params.id,
        };

        deleteBankAccountAction(match.params.id, payload, (response) => {
          console.log("response", response);
        });
      };
      return (
        <div>
          <Modal width={width} border={border}>
            <div className="-mx-8 px-6 py-4">
              <div className=" flex justify-between">
                <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
                  Delete account
                </P>
                <div className="flex">
                  <div>
                    <img
                      src={times_solid}
                      alt="cancel"
                      style={{
                        height: "15px",
                        width: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        closeModalCallback();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-4">
                <P fontSize="12px" color={"#7A809B"}>
                  Input Password
                </P>
              </div>

              <div className="pt-4">
                <Input
                  type="password"
                  name="password"
                  color={"#646A86"}
                  fontWeight="500"
                  border="none"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <h3 className="text-red-400">{error}</h3>

              <div>
                <div className="py-6 flex justify-between">
                  <DefaultButton
                    border={"1px solid #112E46"}
                    bg={"#fff"}
                    color={"#112E46"}
                    p={"8px 16px"}
                    onClick={() => {
                      closeModalCallback();
                    }}
                    className="mr-8"
                  >
                    Cancel
                  </DefaultButton>
                  <DefaultButton
                    bg={"#112E46"}
                    color={"#fff"}
                    p={"8px 16px"}
                    onClick={handleSubmit}
                  >
                    Continue
                  </DefaultButton>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  )
);

export const ExportUsersModal = ({
  width,
  border,
  closeModalCallback,
  yesAction,
  setFieldsToExport,
  fieldsToExport,
}) => {
  return (
    <div>
      <Modal width={width} border={border}>
        <div>
          <div className=" flex justify-between items-center border-b border-[#E7EAF4] py-4 ">
            <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
              Export user details
            </P>
            <div className="flex">
              <div>
                <img
                  src={cancelIcon}
                  alt="cancel"
                  style={{
                    height: "15px",
                    width: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    closeModalCallback();
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-between py-5 border-b">
              <h2 className="text-xxs text-primarySecondary">First name</h2>
              <ToggleButton
                toggleId={"first_name"}
                checked={fieldsToExport.includes("first_name")}
                onChange={(e) => {
                  //if the field is not in the array, add it
                  if (!fieldsToExport.includes("first_name")) {
                    setFieldsToExport([...fieldsToExport, "first_name"]);
                  } else {
                    //if the field is in the array, remove it
                    setFieldsToExport(
                      fieldsToExport.filter((field) => field !== "first_name")
                    );
                  }
                }}
              />
            </div>
            <div className="flex justify-between py-5 border-b">
              <h2 className="text-xxs text-primarySecondary">Last name</h2>
              <ToggleButton
                toggleId={"last_name"}
                checked={fieldsToExport.includes("last_name")}
                onChange={(e) => {
                  //if the field is not in the array, add it
                  if (!fieldsToExport.includes("last_name")) {
                    setFieldsToExport([...fieldsToExport, "last_name"]);
                  } else {
                    //if the field is in the array, remove it
                    setFieldsToExport(
                      fieldsToExport.filter((field) => field !== "last_name")
                    );
                  }
                }}
              />
            </div>
            <div className="flex justify-between py-5 border-b">
              <h2 className="text-xxs text-primarySecondary">Email address</h2>
              <ToggleButton
                toggleId={"email"}
                checked={fieldsToExport.includes("email")}
                onChange={(e) => {
                  //if the field is not in the array, add it
                  if (!fieldsToExport.includes("email")) {
                    setFieldsToExport([...fieldsToExport, "email"]);
                  } else {
                    //if the field is in the array, remove it
                    setFieldsToExport(
                      fieldsToExport.filter((field) => field !== "email")
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className="flex justify-between py-4">
            <button
              className="text-primaryBg text-xs py-3 border-primaryBg border px-4 rounded-primary"
              onClick={() => closeModalCallback()}
            >
              Cancel
            </button>
            <button
              className="text-white bg-primaryBg py-3 text-xs rounded-primary px-4"
              onClick={() => yesAction()}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export const ViewUserActivityModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ width, border, closeModalCallback, transaction }) => {
  return (
    <div>
      <Modal width={width} border={border} callback={closeModalCallback}>
        {transaction ? (
          <ViewActivityModalStyle>
            <div className="-mx-8">
              <div
                className="px-6"
                style={{ maxHeight: "80vh", overflow: "auto" }}
              >
                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Account Email
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction["account.email"]}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Account Balance
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.amount}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Coin
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.coin}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Opening Balance
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.opening_balance}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Closing Balance
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.closing_balance}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      Description
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.description}`}
                  </P>
                </div>

                <div className="pt-4 pb-4 border-bottom">
                  <div className="mb-1">
                    <P fontSize="13px" color={"#646A86"} fontWeight="500">
                      id
                    </P>
                  </div>
                  <P fontSize="16px" color={"#4B4F52"} fontWeight="500">
                    {`${transaction.id}`}
                  </P>
                </div>
              </div>
            </div>
          </ViewActivityModalStyle>
        ) : (
          <P fontSize="13px" color={"#374072"} fontWeight="500">
            Loading.....
          </P>
        )}
      </Modal>
    </div>
  );
});

export const DebitPromoBalanceModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    ({ match, width, border, closeModalCallback, debitPromoBalance }) => {
      const [state, setstate] = useState({
        amount: "",
      });

      const handleChange = (e) => {
        switch (e.target.type) {
          case "number":
            setstate({
              ...state,
              [e.target.name]: parseFloat(e.target.value),
            });
            break;
          case "checkbox":
            setstate({
              ...state,
              [e.target.name]: e.target.checked,
            });
            break;
          default:
            setstate({
              ...state,
              [e.target.name]: e.target.value,
            });
        }
      };

      const handleSubmit = (e) => {
        console.log(state);
        e.preventDefault();
        if (state.amount.length === 0 || state.amount === null) {
          return customToast.error("fill the amount field");
        }
        if (state.amount > 50000) {
          customToast.error("amount is beyond the promo balance limit");
          return;
        }
        debitPromoBalance(match.params.id, state);
      };

      return (
        <div>
          <Modal width={width} border={border}>
            <div className="-mx-8 px-6 py-4">
              <div className=" flex justify-between">
                <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
                  Debit Promo Balance
                </P>
                <div className="flex">
                  <div>
                    <img
                      src={times_solid}
                      alt="cancel"
                      style={{
                        height: "15px",
                        width: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        closeModalCallback();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Amount to Debit
                  </P>
                </div>
                <Input
                  placeholder="Amount"
                  type="number"
                  name="amount"
                  required
                  value={state.amount}
                  onChange={handleChange}
                  maxLength="50000"
                />
              </div>

              <div className="mt-6 mb-4 flex justify-center">
                <DefaultButton
                  small
                  bg={"#005674"}
                  color={"#fff"}
                  border={"1px solid #005674"}
                  p={"7px 16px"}
                  onClick={handleSubmit}
                >
                  Debit
                </DefaultButton>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  )
);

export const CreditPromoBalanceModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    ({ match, width, border, closeModalCallback, creditPromoBalance }) => {
      const [state, setstate] = useState({
        amount: "",
      });

      const handleChange = (e) => {
        switch (e.target.type) {
          case "number":
            setstate({
              ...state,
              [e.target.name]: parseFloat(e.target.value),
            });
            break;
          case "checkbox":
            setstate({
              ...state,
              [e.target.name]: e.target.checked,
            });
            break;
          default:
            setstate({
              ...state,
              [e.target.name]: e.target.value,
            });
        }
      };

      const handleSubmit = (e) => {
        console.log(state);
        e.preventDefault();
        if (state.amount.length === 0 || state.amount === null) {
          return customToast.error("fill the amount field");
        }
        if (state.amount > 50000) {
          customToast.error("amount is beyond the promo balance limit");
          return;
        }
        creditPromoBalance(match.params.id, state);
      };

      return (
        <div>
          <Modal width={width} border={border}>
            <div className="-mx-8 px-6 py-4">
              <div className=" flex justify-between">
                <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
                  Credit Promo Balance
                </P>
                <div className="flex">
                  <div>
                    <img
                      src={times_solid}
                      alt="cancel"
                      style={{
                        height: "15px",
                        width: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        closeModalCallback();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Amount to Credit
                  </P>
                </div>
                <Input
                  placeholder="Amount"
                  type="number"
                  name="amount"
                  required
                  value={state.amount}
                  onChange={handleChange}
                  maxLength="50000"
                />
              </div>

              <div className="mt-6 mb-4 flex justify-center">
                <DefaultButton
                  small
                  bg={"#005674"}
                  color={"#fff"}
                  border={"1px solid #005674"}
                  p={"7px 16px"}
                  onClick={handleSubmit}
                >
                  Credit
                </DefaultButton>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  )
);
