import React, { useState } from "react";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { Modal } from "../../../../../UI toolkit/Modal/Modal";
import { H1, H5, H6, P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import { TransactionsModalStyle } from "./UserCards.styled";
import ic_sent_icon from "../../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../../assets/images/ic-received-coin.svg";
import ic_appr from "../../../../../assets/images/ic-appr.svg";
import { connect } from "react-redux";
import moment from "moment";

import { capitalizeFirstLetter } from "../../../../../utils/specialFunctions";

const CardTransactionsModal = ({
  width,
  border,
  closeModalCallback,
  activity,
}) => {
  const status =
    activity.type === "inactive" ||
    activity.type === "active" ||
    activity.type === "canceled"
      ? activity.status
      : activity.type === "withdrawal" ||
        activity.type === "fund" ||
        activity.type === "create"
      ? activity.transactions.status
      : "";
  const typeKeys = {
    active: "Unfreeze",
    inactive: "Freeze",
    cancel: "Cancel",
    create: "Create",
    fund: "Fund",
    withdrawal: "Withdrawal",
  };
  const amount =
    activity.type === "inactive" ||
    activity.type === "active" ||
    activity.type === "canceled"
      ? ""
      : activity.type === "withdrawal" ||
        activity.type === "fund" ||
        activity.type === "create"
      ? `${
          activity?.transactions?.currency === "USD"
            ? "$"
            : activity.transactions?.currency === "NGN"
            ? "₦"
            : ""
        } ${Number(activity.transactions?.source_amount).toFixed(2)}`
      : "";
  const isTransaction =
    activity.type === "withdrawal" ||
    activity.type === "fund" ||
    activity.type === "create";

  return (
    <div>
      <Modal width={width} border={border} callback={closeModalCallback}>
        {activity ? (
          <TransactionsModalStyle>
            <div className="-mx-8">
              <div className="flex items-center justify-between px-6 py-3 border-bottom bg-white">
                <div className="flex items-center">
                  &nbsp;
                  <div>
                    <H6
                      color={"#112E46"}
                      className="text-center"
                      fontWeight="700"
                    >
                      {activity.type ? typeKeys[activity.type] : null}
                    </H6>
                  </div>
                </div>

                <div className="">
                  <Badge
                    color={
                      activity.status === "cancelled"
                        ? "#337138"
                        : activity.status === "stopped"
                        ? "#337138"
                        : activity.status === "pending"
                        ? "#606060"
                        : activity.status === "active"
                        ? "#335F71"
                        : activity.status === "completed"
                        ? "#531423"
                        : ""
                    }
                    bg={
                      activity.status === "cancelled"
                        ? "#FFDCE5"
                        : activity.status === "stopped"
                        ? "#FFDCE5"
                        : activity.status === "pending"
                        ? "#FFEAB6"
                        : activity.status === "active"
                        ? "#D0F0FD"
                        : activity.status === "completed"
                        ? "#D1F7C4"
                        : "#337138"
                    }
                  >
                    {status}
                  </Badge>
                </div>
              </div>

              {isTransaction && (
                <div className="px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        Amount
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    <div className="mb-2">
                      <div className="">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {amount}
                        </P>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      User
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="mb-2">
                    <div className="">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {activity?.account?.email}
                      </P>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Date
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="mb-2">
                    <div className="">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {moment(activity?.created_at).format("MMM Do YY")}
                      </P>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Time
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="mb-2">
                    <div className="">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {moment(activity?.created_at).format("LT")}
                      </P>
                    </div>
                  </div>
                </div>
              </div>
              {isTransaction ? (
                <div className="px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        Transaction
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    <div className="mb-2">
                      <div className="">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {activity?.transactions.id}
                        </P>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/*
              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      title
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <P fontSize="14px" color={"#374072"} fontWeight="300">
                    {saving.title}
                  </P>
                </div>
              </div>
              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      amount
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <P fontSize="16px" color={"#00AAAF"} fontWeight="500">
                    {saving.currency}
                    {Number(saving.amount).toFixed(2).toLocaleString()}
                  </P>
                </div>
              </div>
              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Duration
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="flex items-center justify-between">
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      Start date
                    </H5>
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      {moment(saving.start_date).format("L")}
                    </H5>
                  </div>
                  <div className="flex items-center justify-between">
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      End date
                    </H5>
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      {moment(saving.end_date).format("L")}
                    </H5>
                  </div>
                  <div className="flex items-center justify-between">
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      Range
                    </H5>
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      {`${saving?.savings_type?.duration} months`}
                    </H5>
                  </div>
                </div>
              </div> */}

              {/* <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      id
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="mb-1">
                    <P fontSize="16px" color={"#374072"} fontWeight="700">
                      
                      {saving.id}
                    </P>
                  </div>

                  <div className="my-1">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {new Date(saving.created_at).toDateString()}
                    </P>
                  </div>

                  <div className="mt-1">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {new Date(saving.created_at).toLocaleTimeString()}
                    </P>
                  </div>
                </div>
              </div> */}
            </div>
          </TransactionsModalStyle>
        ) : (
          <P fontSize="13px" color={"#374072"} fontWeight="500">
            Loading.....
          </P>
        )}
      </Modal>
    </div>
  );
};

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardTransactionsModal);
