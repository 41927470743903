import {
  GET_VERIFICATION_LIST,
  LOAD_VERIFICATION_DOC,
  VERIFICATION_DOC,
  VERIFICATION_LOADING,
  VERIFICATION_ERROR,
  UPDATE_TOTAL_VERIFICATIONS_PAGES,
  UPDATE_VERIFICATION_PAGE,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  verificationsList: null,
  loadVerificationDoc: null,
  verificationDoc: null,
  page: 0,
  totalPage: 0,
};

const VerificationReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_VERIFICATION_LIST:
      return {
        ...state,
        verificationsList: action.verificationsList,
        loading: false,
      };
    case VERIFICATION_DOC:
      return {
        ...state,
        verificationDoc: action.verificationDoc,
        loadVerificationDoc: false,
      };
    case LOAD_VERIFICATION_DOC:
      return {
        ...state,
        loadVerificationDoc: true,
      };
    case "STOP_VERIFICATION_LOADING":
      return {
        ...state,
        loading: false,
      };
    case VERIFICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case VERIFICATION_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case UPDATE_TOTAL_VERIFICATIONS_PAGES:
      return {
        ...state,
        totalPage: action.payload,
      };
    case UPDATE_VERIFICATION_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};

export default VerificationReducer;
