import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { P } from "../../UI toolkit/Text/Text";

export const TabHeader = styled.div`
  // background: ${(props) => props.bg || "#112E46"};
  // color: ${(props) => props.color || "#FFF"};
  box-shadow: 0px 1px 5px #3f3f4419;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;

  background: ${(props) => props.theme.colors.bg || "#112E46"};
  color: ${(props) => props.theme.colors.color || "#FFFFFF"};
`;

// "19px 10px 17px 10px"

export const TableTabStyle = styled.button`
  background: ${(props) => props.bg || "transparent"};
  color: ${(props) => props.color || "#FFF"};
  padding: ${(props) => props.p || "11px 24px"};
  border: ${(props) => props.border || "none"} !important;
  font-size: ${(props) => props.fontSize || "14px"};
  border-radius: ${(props) => props.borderRadius || "none"};
  width: ${(props) => props.width};
  display: inline-block;
  box-shadow: 0px !important;
  outline: none !important;

  &.active {
    background: ${(props) => props.activeBg || props.theme.colors.activeBg};
    color: ${(props) => props.activeColor || props.theme.colors.activeColor};
    border: ${(props) => props.activeOutline || "none"} !important;
    border-bottom: ${(props) => props.activeOutlineBottom} !important;
    box-shadow: ${(props) => props.boxShadow || "0px 1px 5px #3F3F4419"};
    border-radius: ${(props) => props.borderRadius || "4px 0px 0px 0px"};
    opacity: 1;
    outline: none;
  }

  @media (max-width: 768px) {
    // width: ${(props) => props.mobileWidth || "25%"};
    width: auto;
  }
`;

export const TableTab = ({
  activeTab,
  handleTab,
  handleDispatch,
  tabs,
  callback,
  p,
  bg,
  color,
  width,
  mobileWidth,
  activeBg,
  activeColor,
  activeOutline,
  boxShadow,
  borderRadius,
  activeOutlineBottom,
  tailwindpad,
  border,
  margin,
}) => {
  useEffect(() => {
    callback && callback(activeTab);
  }, [activeTab, callback]);

  return (
    <div className="flex flex-wrap overflow-hidden w-full">
      {tabs &&
        tabs.map((curr, idx, arr) => (
          <TableTabStyle
            key={idx}
            className={`${idx < arr.length - 1 && margin ? "mr-2" : ""} ${
              tailwindpad ? "mr-8" : ""
            } ${idx === activeTab ? "active" : ""}`}
            bg={bg}
            color={color}
            p={p}
            activeBg={activeBg}
            activeColor={activeColor}
            activeOutline={activeOutline}
            border={border}
            boxShadow={boxShadow}
            borderRadius={borderRadius}
            activeOutlineBottom={activeOutlineBottom}
            width={width}
            mobileWidth={mobileWidth}
            onClick={() => {
              // console.log(idx, curr.text);
              handleTab && handleTab(idx);
              handleDispatch && handleDispatch(curr.value);
            }}
          >
            {curr.text}
          </TableTabStyle>
        ))}
    </div>
  );
};
export const SavingsTableTab = ({
  activeTab,
  handleTab,
  tabs,
  callback,
  p,
  bg,
  color,
  width,
  mobileWidth,
  activeBg,
  activeColor,
  activeOutline,
  boxShadow,
  borderRadius,
  activeOutlineBottom,
  tailwindpad,
  border,
  margin,
}) => {
  useEffect(() => {
    callback && callback(activeTab);
  }, [activeTab]);

  return (
    <div className="flex flex-wrap overflow-hidden w-full">
      {tabs &&
        tabs.map((curr, idx, arr) => (
          <TableTabStyle
            key={idx}
            className={`${idx < arr.length - 1 && margin ? "mr-2" : ""} ${
              tailwindpad ? "mr-8" : ""
            } ${idx === activeTab ? "active" : ""}`}
            bg={bg}
            color={color}
            p={p}
            activeBg={activeBg}
            activeColor={activeColor}
            activeOutline={activeOutline}
            border={border}
            boxShadow={boxShadow}
            borderRadius={borderRadius}
            activeOutlineBottom={activeOutlineBottom}
            width={width}
            mobileWidth={mobileWidth}
            onClick={() => {
              handleTab(idx);
            }}
          >
            {curr.text}
          </TableTabStyle>
        ))}
    </div>
  );
};

/* <TableTab
        margin
        tabs={links} 
        activeTab={activeLink} 
        handleTab={handleActiveLink}
        p={"4px 8px 3px 8px"} 
        bg={"transparent"} 
        activeOutline="1px solid #fff" 
        activeBg={"transparent"}
        callback={(active)=>{
            console.log(active)
        }}
    /> */

const SelectStyle = styled.div`
  display: inline-block;
  width: ${(props) => props.width || "100%"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  padding: ${(props) => props.p || "10px 16px"};
  padding-right: ${(props) => props.p || "30px"};
  background: ${(props) => `url(${props.bgImg})`};
  background-position: right;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  -webkit-appearance: none;
  opacity: 1;
  outline: none;
  color: ${(props) => props.color || "#4a4a4a"};
  background-color: ${(props) => props.bgColor || "#f2f2f2"};

  & li {
    list-style-type: none;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const SelectDropDownStyle = styled.ul`
  position: absolute;
  left: 0%;
  top: 100%;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  box-shadow: 0px 1px 4px #3f3f4419;
  border-radius: "0px";
  opacity: 1;
  list-style-type: none;
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  // border-radius: ${(props) => props.borderRadius || "4px"};
  width: ${(props) => props.width || "100%"};
  font-size: ${(props) => props.fontSize || "16px"};
  color: ${(props) => props.color || "#4a4a4a"};
  background-color: ${(props) => props.bgColor || "#f2f2f2"};
  max-height: 80vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 0px;
  }

  & li {
    list-style-type: none;
    padding: ${(props) => props.p || "10px 10px"};
    padding-right: ${(props) => props.pr || "10px"};

    img {
      width: 16px;
      height: 16px;
    }
    background: ${(props) => props.hoverBg || "transparent"};
    color: ${(props) => props.hoverColor};
  }
`;

export const SelectInput = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(null);

  const selectOption = (option) => {
    setValue(option);
    setExpanded(!expanded);
    props.callback &&
      props.callback(option, option.image, props.prices[option.symbol]);
  };
  const expandOption = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div
      className="relative"
      onBlur={() => {
        setExpanded(false);
      }}
    >
      <SelectStyle
        className="flex items-center justify-between p-2 py-1 cursor-pointer"
        onClick={expandOption}
        onBlur={() => {
          setExpanded(false);
        }}
        fontSize={props.fontSize}
        ref={props.ref}
        {...props}
        color={props.color}
        border={props.border}
        boxShadow={props.boxShadow}
        p={props.p}
        pr={props.pr}
        borderRadius={props.borderRadius}
        bgImg={props.bgImg}
        bgColor={props.bgColor}
        width={props.width}
      >
        {value ? (
          <li>
            <div className="flex content-center p1-2">
              <div className="pr-2">
                <img src={value.image} alt="icon" />
              </div>

              <div className="pr-2">
                <P color="#fff" fontSize="13px">
                  {value.name} price:
                </P>
              </div>

              <div className="px-0">
                <P color="#fff" fontSize="13px">
                  ₦{" "}
                  {(
                    props.prices && props.prices[value.symbol].ngn
                  ).toLocaleString()}
                </P>
              </div>
            </div>
          </li>
        ) : (
          <P color="#fff" fontSize="13px">
            loading.....
          </P>
        )}
      </SelectStyle>

      {expanded && (
        <SelectDropDownStyle
          color={props.color}
          border={props.border}
          boxShadow={props.boxShadow}
          fontSize={props.fontSize}
          p={props.p}
          pr={props.pr}
          borderRadius={props.borderRadius}
          bgImg={props.bgImg}
          bgColor={props.bgColor}
          width={props.width}
          hoverBg={props.hoverBg}
          hoverColor={props.hoverColor}
        >
          {props.currencies &&
            props.currencies
              .filter((curr) => curr !== value)
              .map((curr, idx, arr) => {
                if (curr.symbol !== "NGN") {
                  return (
                    <li
                      key={idx}
                      value={curr}
                      onClick={() => selectOption(curr)}
                      className=""
                      style={{ cursor: "pointer" }}
                    >
                      <div className="flex content-center p1-2 py-2">
                        <div className="pr-2">
                          <img src={curr.image} alt="icon" />
                        </div>

                        <div className="">
                          <P color="#fff" fontSize="13px">
                            {curr.name} price:
                          </P>
                        </div>

                        <div className="px-0">
                          <P color="#fff" fontSize="13px">
                            ₦{" "}
                            {(
                              props.prices && props.prices[curr.symbol].ngn
                            ).toLocaleString()}
                          </P>
                        </div>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
        </SelectDropDownStyle>
      )}
    </div>
  );
};
