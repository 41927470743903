import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import {
  overviewAction,
  pricesAction,
  walletsAction,
} from "../../../../store/Actions/OverviewActions";
import {
  getSendAndRecieveAction,
  getTradesAction,
  transactionAction,
} from "../../../../store/Actions/TransactionActions";
import ActivitiesCardTable from "../Activities/ActivitiesCardTable";
import DashboardHeader from "../DashboardHeader";
import Overview from "./Overview";
import TotalCash from "./TotalCash";
import TotalCrypto from "./TotalCrypto";

const DashboardIndex = ({
  walletsAction,
  getSendAndRecieveAction,
  getTradesAction,
  user,
  overview,
  transactions,
  total_users,
  deposits,
  withdrawals,
  currencies,
  transactionsList,
  wallets,
  trades,
  transfers,
}) => {
  const location = useLocation();
  useEffect(() => {
    // getTradesAction();
    // getSendAndRecieveAction({
    //   type: "all",
    //   page: 1,
    //   limit: 10,
    //   status: "all",
    // });
    walletsAction();
  }, [getTradesAction, getSendAndRecieveAction, walletsAction]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Dashboard</DashboardHeader>

        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden lg:w-8/12 xl:w-8/12">
            <Overview />
            <ActivitiesCardTable data={transfers ? transfers : null} />
          </div>

          <div className="w-full overflow-hidden lg:px-6 xl:px-6 lg:w-4/12 xl:w-4/12">
            <TotalCash
              overview={overview ? overview : 0}
              transactions={wallets ? wallets : 0}
              withdrawals={withdrawals ? withdrawals : 0}
              users={total_users ? total_users : 0}
              deposits={deposits ? deposits : 0}
            />
            <TotalCrypto
              overview={overview ? overview : 0}
              transactions={wallets ? wallets : 0}
              withdrawals={withdrawals ? withdrawals : 0}
              users={total_users ? total_users : 0}
              deposits={deposits ? deposits : 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    trades: state.transactions.trades,
    transfers: state.transactions.transfers,
    overview: state.overview.overview,
    wallets: state.overview.wallets,
    transactions: state.overview.transactions,
    transactionsList: state.transactions.transactionsList,
    total_users: state.overview.total_users,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.overview.loading,
    error: state.overview.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: () => dispatch(overviewAction()),
    pricesAction: () => dispatch(pricesAction()),
    transactionAction: () => dispatch(transactionAction()),
    walletsAction: () => dispatch(walletsAction()),
    getSendAndRecieveAction: (query) =>
      dispatch(getSendAndRecieveAction(query)),
    getTradesAction: () => dispatch(getTradesAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardIndex));
