import React, { useState } from "react";
import { P } from "../../../../UI toolkit/Text/Text";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Dropdown, Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { Input } from "../../../../UI toolkit/Input/Input";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import caret_down from "../../../../assets/images/caret-down.svg";
import { connect } from "react-redux";
import { changeAdminPasswordAction, getAdminsAction, removeAdminAction, updateAdminAction, createAdminAction } from "../../../../store/Actions/SettingsActions";
import times_solid from "../../../../assets/images/times-solid.svg";

const AddAdminModal = ({ setModal, createAdminAction, modal, getAdmins, updateAdmin, changePassword }) => {
  const dropdownTexts = [
    { text: "viewer" },
    { text: "super-admin" },
    { text: "manager" },
  ];

  const [state, setstate] = useState({
    email: null,
    password: null,
    role: null,
  });

  const onSubmit = (e) => {
    console.log(state);
    switch(modal.action) {
      case 'create':
        createAdminAction(state, (response) => {
          getAdmins()
          setstate({
            email: null,
            password: null,
            role: null,
          })
          setModal({
            isOpen: false,
            action: "create",
            id: null
          });
        });
        break
      case 'update':
        updateAdmin(modal.id, state, (response) => {
          getAdmins()
          setstate({
            email: null,
            password: null,
            role: null,
          })
          setModal({
            isOpen: false,
            action: "create",
            id: null
          });
        });
        break
      case 'password':
        changePassword(modal.id, state, (response) => {
          getAdmins()
          setstate({
            email: null,
            password: null,
            role: null,
          })
          setModal({
            isOpen: false,
            action: "create",
            id: null
          });
        });
        break
      default:
        return
    }
  };

  const handleChange = (e) => {
    switch (e.target.type) {
      case "number":
        setstate({
          ...state,
          [e.target.name]: parseFloat(e.target.value),
        });
        break;
      case "checkbox":
        setstate({
          ...state,
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        setstate({
          ...state,
          [e.target.name]: e.target.value,
        });
    }
  };

  return (
    <div>
      <Modal width={"35%"}>
        <div className="-mx-8 px-6 py-4">
          <div className=" flex justify-between">
            <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
              Add Admin
            </P>
            <div className="flex">
              <div>
                <img
                  src={times_solid}
                  alt="cancel"
                  style={{ height: "15px", width: "15px", cursor: "pointer" }}
                  onClick={() => {
                    setModal({
                      isOpen: false,
                      option: "create",
                      id: null
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {modal.action === 'create' && (
            <div className="mt-6">
            <div className="mb-1.5">
              <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                Email
              </P>
            </div>
            <Input
              placeholder="Placeholder"
              type="text"
              name="email"
              required
              onChange={handleChange}
              
            />
          </div>
          )}

          {
            (modal.action === 'create' || modal.action === 'update') && (
              <div className="mt-6">
            <div className="mb-1.5">
              <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                Role
              </P>
            </div>
            <Dropdown
              items={dropdownTexts}
              bgImg={caret_down}
              bgColor={"transparent"}
              color={"#374072"}
              p={"11px 16px 10px 16px"}
              callback={(e) => {
                console.log(e);
                setstate({ ...state, role: e.target.value });
              }}
            />
            </div>
            )
          }

            {/* <Select
                            bgImg={caret_down} 
                            bgColor={"transparent"}
                            border={"1px solid #fff"}
                            borderRadius={"4px"}
                            color={"#112E46"}
                            boxShadow={"0px 1px 4px #3F3F4419"}
                            p={"11px 16px 10px 16px"}
                            data={dropdownTexts}
                            defaultValue={"-- Select --"}
                            properyToShow={"text"}
                            hoverBg={"#FFF"}
                            hoverColor={"#112E46"}
                            fontSize={"16px"}
                            callback={(option, idx, arr)=>{
                                console.log(option, idx, arr);
                            }}
                        /> */}
          

          {(modal.action === 'create' || modal.action === "password") && (
            <div className="mt-6">
            <div className="mb-1.5">
              <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                Password
              </P>
            </div>
            <Input
              placeholder="Placeholder"
              type="password"
              name="password"
              required
              onChange={handleChange}
            />
          </div>
          )}

          <div className="mt-6 mb-4 flex justify-center">
            <DefaultButton
              small
              bg={"#005674"}
              color={"#fff"}
              border={"1px solid #005674"}
              p={"7px 16px"}
              required
              onClick={onSubmit}
            >
              {modal.action === 'create' ? 'Invite' : 'Update'}
            </DefaultButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    verificationsList: state.verification.verificationsList,
    verificationDoc: state.verification.verificationDoc,
    loadVerificationDoc: state.verification.loadVerificationDoc,
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    createAdminAction: (state, successCallback) =>
      dispatch(createAdminAction(state, successCallback)),
      updateAdmin: (id, state, successCallback) =>
      dispatch(updateAdminAction(id, state, successCallback)),
      changePassword: (id, state, successCallback) =>
      dispatch(changeAdminPasswordAction(id, state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminModal);
