import styled from "styled-components";
import React from "react";
import { P } from "../../../UI toolkit/Text/Text";
import { Link, NavLink } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

export const SideBarStyle = styled.div`
    background: ${(props) => props.theme.colors.bg || "#112E46"};
    color: ${(props) => props.theme.colors.color || "#fff"};
    position: fixed;
    width: 251px;
    height: 100%;
    overflow: hidden;
    transition: width 0.5s ease-in-out;

    .skye_logo{
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 26.35px;
        padding-bottom: 49.99px;
    }

    .skye_logo img{
        width: 115px;
        height: 34px;
    }

    .menu{
        height: 65vh;
        overflow-y: auto;
    }

    .menu li{
        font-size: 14px;
    }

    .menu::-webkit-scrollbar {
        width: 0.5em;
      }
       
    .menu::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    .menu::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
    }

    .menu li a{
        display: block;
        width: 100%;
        padding-left: 50px;
        padding-rignt: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
    }

    .menu li.logout{
        display: block;
        width: 100%;
        padding-left: 50px;
        padding-rignt: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .menu li a.active{
        background-color: ${(props) =>
          props.theme.colors.activeBg || "#0087B5"};
        color: ${(props) =>
          props.activeColor || props.theme.colors.activeColor};
    }



    @media (max-width: 992px) {
        width: ${(props) => (props.mobileWidth === false ? "0px" : "300px")};
        position: fixed;
        z-index: 1;
        transition: 0.5s width;
    }
}
`;

export const Main = styled.div`
  display: block;
  width: calc(100% - 251px);
  height: 100%;
  min-height: 100vh;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 251px;
  background: #fafbfd;

  @media (max-width: 992px) {
    position: static;
    width: 100%;
  }
`;

export const UserAvatar = styled.div`
    width: 32px;
    height: 32px;
    background: ${(props) => props.bg || "#BFC4D5"};
    color: ${(props) => props.color || "#fff"};
    border-radius: 3px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
`;

export const NavLinks = (props) => {
  const { activeLink, handleActiveLink, links } = props;

  return (
    <ul className="menu">
      {links &&
        links.map((curr, idx, arr) => (
          <li className="mb-2" key={idx}>
            {idx === activeLink ? (
              <NavLink
                to={curr.route}
                onClick={() => {
                  handleActiveLink(idx);
                }}
              >
                {curr.text}
              </NavLink>
            ) : (
              <Link
                to={curr.route}
                onClick={() => {
                  handleActiveLink(idx);
                }}
              >
                {curr.text}
              </Link>
            )}
          </li>
        ))}
    </ul>
  );
};

export const UserDetails = (props) => {
  return (
    <div className="flex items-center">
      <P
        fontSize="14px"
        color={"#7C8191"}
        fontWeight="500"
        className="mr-4 hidden lg:block"
      >
        {props.name}
      </P>
      <UserAvatar>{props.initials}</UserAvatar>
    </div>
  );
};
