import styled from "styled-components";

export const ToastContentWrapper = styled.div`
  display: inline-flex;
  img {
    width: 24px;
    height: 24px;
    margin: auto 20px;
  }
  div {
    p {
      margin: 0;
      :first-child {
        font-weight: bold;
      }
    }
  }
`;
