import { LOGIN_TO_ACCT, LOGOUT_TO_ACCT, LOADING, ERROR } from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  token: localStorage.getItem("s-k-account")
    ? JSON.parse(localStorage.getItem("s-k-account")).token
    : null,
  user: localStorage.getItem("s-k-account")
    ? JSON.parse(localStorage.getItem("s-k-account")).user
    : null,
};

const accountReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case LOGIN_TO_ACCT:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case LOGOUT_TO_ACCT:
      return {
        ...state,
        token: null,
        user: null,
        loading: null,
        error: null,
      };
    default:
      return state;
  }
};

export default accountReducer;
