import React from "react";
import { Badge, DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  activateUser,
  deleteUser,
  restoreUser,
  suspendUser,
} from "../../../../store/Actions/UsersAction";

const UserProfile = ({
  user,
  activateUser,
  suspendUser,
  restoreUser,
  loading,
  error,
  match,
}) => {
  const mapStatusToButton = {
    suspended: {
      text: "Activate user",
      callback: activateUser,
    },
    active: {
      text: "Suspend user",
      callback: suspendUser,
    },
    deleted: {
      text: "Undelete user",
      callback: restoreUser,
    },
  }
  return (
    <div>
      <div className="pt-6">
        <div className="lg:w-7/12">
          <Card>
            <div>
              <P fontSize="12px" color={"#7A809B"}>
                User Name
              </P>
              <P fontSize="16px" color={"#374072"} fontWeight="500">
                {user && user.profile && user.profile.first_name
                  ? user.profile.first_name
                  : ""}
              </P>
            </div>
          </Card>
        </div>
      </div>

      <div className="pt-6">
        <div className="lg:w-7/12">
          <Card>
            <div>
              <P fontSize="12px" color={"#7A809B"}>
                Full Name
              </P>
              <P fontSize="16px" color={"#374072"} fontWeight="500">
                {user && user.profile && user.profile.first_name
                  ? user.profile.first_name + "\n" + user.profile.last_name
                  : ""}
              </P>
            </div>
          </Card>
        </div>
      </div>

      <div className="pt-6">
        <div className="lg:w-7/12">
          <Card>
            <div>
              <P fontSize="12px" color={"#7A809B"}>
                Email
              </P>
              <P fontSize="16px" color={"#374072"} fontWeight="500">
                {user && user.profile && user.profile.email
                  ? user.profile.email
                  : ""}
              </P>
            </div>
          </Card>
        </div>

        {/* <div className="lg:w-5/12">
                    
                </div> */}
      </div>

      <div className="py-6">
        <div className="lg:w-7/12">
          <Card>
            <div>
              <P fontSize="12px" color={"#7A809B"}>
                Phone Number
              </P>
              <P fontSize="16px" color={"#374072"} fontWeight="500">
                {user && user.profile && user.profile.phone_number
                  ? user.profile.phone_number
                  : ""}
              </P>
            </div>
          </Card>
        </div>

        {/* <div className="lg:w-5/12">
                    
                </div> */}
      </div>

      <div className="pb-10">
        <Badge
          color={
            user && user.profile && user.profile.kyc_level === 0
              ? "#337138"
              : user && user.profile && user.profile.kyc_level === 1
              ? "#606060"
              : user && user.profile && user.profile.kyc_level === 2
              ? "#335F71"
              : user && user.profile && user.profile.kyc_level === 3
              ? "#531423"
              : ""
          }
          bg={
            user && user.profile && user.profile.kyc_level === 0
              ? "#FFDCE5"
              : user && user.profile && user.profile.kyc_level === 1
              ? "#FFEAB6"
              : user && user.profile && user.profile.kyc_level === 2
              ? "#D0F0FD"
              : user && user.profile && user.profile.kyc_level === 3
              ? "#D1F7C4"
              : "#337138"
          }
        >
          level{" "}
          {user && user.profile && user.profile.kyc_level
            ? user.profile.kyc_level
            : ""}
        </Badge>
      </div>

      <div>
        <DefaultButton
          bg={
            user && user.profile && user.profile.status === "suspended"
              ? "#112E46"
              : "#D60000"
          }
          color={"#fff"}
          p={"8px 16px"}
          onClick={() => {
            if (user && user.profile && user.profile.status) {
              mapStatusToButton[user.profile.status].callback(match.params.id)
            } else {
              suspendUser(match.params.id)
            }
          }}
        >
          {user && user.profile && user.profile.status ? mapStatusToButton[user.profile.status].text : 'Suspend user'}
        </DefaultButton>
      </div>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    prices: state.overview.prices,
    loading: state.users.loading,
    error: state.users.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    activateUser: (id) => dispatch(activateUser(id)),
    suspendUser: (id) => dispatch(suspendUser(id)),
    deleteUser: (id) => dispatch(deleteUser(id)),
    restoreUser: (id) => dispatch(restoreUser(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserProfile));
