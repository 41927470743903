import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Input } from "../../../../UI toolkit/Input/Input";
import { P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder } from "../../../Units/CardTitle.styled";
import PriceAPISourceList from "./PriceAPISourceList";
import { PricesTable } from "./SettingsTable/SettingsTables";
import {
  adminSettingsAction,
  changeAdminSettingsAction,
} from "../../../../store/Actions/SettingsActions";
import { customToast } from "../../../../utils/customToast";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";

const Price = ({
  prices,
  currencies,
  images,
  admin_settings,
  changeAdminSettingsAction,
}) => {
  const [state, setstate] = useState({
    key: "GLOBAL_SELL_MARKUP_PERCENTAGE",
    value: admin_settings.GLOBAL_SELL_MARKUP_PERCENTAGE,
  });
  const [state2, setstate2] = useState({
    key: "GLOBAL_BUY_MARKUP_PERCENTAGE",
    value: admin_settings.GLOBAL_BUY_MARKUP_PERCENTAGE,
  });
  const [state3, setstate3] = useState({
    key: "GLOBAL_SWAP_MARKUP_PERCENTAGE",
    value: admin_settings.GLOBAL_SWAP_MARKUP_PERCENTAGE,
  });

  const handleSubmit = () => {
    if (
      state.value.length !== 0 &&
      state2.value.length !== 0 &&
      state3.value.length !== 0
    ) {
      changeAdminSettingsAction(state, (response) => {});
      changeAdminSettingsAction(state2, (response) => {});
      changeAdminSettingsAction(state3, (response) => {});
    } else {
      customToast.error(
        "unable to implement action...provide a value for the input provided"
      );
      return;
    }
  };

  return (
    <div>
      <div className="mb-6">
        <Card>
          <CardHolder>
            <div className="">
              <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                Select price API source:
              </P>
            </div>
          </CardHolder>

          <div className="p-4">
            <PriceAPISourceList images={images} />
          </div>
        </Card>
      </div>

      <div className="mb-6">
        <Card>
          <CardHolder>
            <div className="">
              <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                Set Buy Sell and Swap Percentages
              </P>
            </div>
          </CardHolder>

          <div>
            <div className="mt-6 md:w-4/12">
              <P fontSize="12px" color={"#7A809B"}>
                Buy Percentage
              </P>
              <Input
                type="number"
                name="value"
                color={"#646A86"}
                fontWeight="500"
                // border="0px"
                // boxShadow="0px"
                // borderRadius="0px"
                outline="none"
                onChange={(e) => {
                  setstate2({
                    ...state2,
                    value:
                      e.target.value.length !== 0
                        ? parseFloat(e.target.value)
                        : e.target.value,
                  });
                }}
                value={state2.value}
              />
            </div>
            <div className="my-6 md:w-4/12">
              <P fontSize="12px" color={"#7A809B"}>
                Sell Percentage
              </P>
              <Input
                type="number"
                name="value"
                color={"#646A86"}
                fontWeight="500"
                // border="0px"
                // boxShadow="0px"
                // borderRadius="0px"
                // outline="none"
                onChange={(e) => {
                  setstate({
                    ...state,
                    value:
                      e.target.value.length !== 0
                        ? parseFloat(e.target.value)
                        : e.target.value,
                  });
                }}
                value={state.value}
              />
            </div>

            <div className="mt-6 md:w-4/12">
              <P fontSize="12px" color={"#7A809B"}>
                Swap Percentage
              </P>
              <Input
                type="number"
                name="value"
                color={"#646A86"}
                fontWeight="500"
                // border="0px"
                // boxShadow="0px"
                // borderRadius="0px"
                // outline="none"
                onChange={(e) => {
                  setstate3({
                    ...state3,
                    value:
                      e.target.value.length !== 0
                        ? parseFloat(e.target.value)
                        : e.target.value,
                  });
                }}
                value={state3.value}
              />
            </div>

            <div className="py-6">
              <DefaultButton
                bg={"#E5EEF1"}
                color={"#005674"}
                p={"7px 19px"}
                onClick={handleSubmit}
              >
                Update Prices
              </DefaultButton>
            </div>
          </div>
        </Card>
      </div>

      <div>
        <Card>
          <CardHolder>
            <div className="">
              <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                Set price
              </P>
            </div>
          </CardHolder>

          <div className="">
            <PricesTable
              prices={prices ? prices : null}
              currencies={currencies ? currencies : null}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currencies: state.overview.currencies,
    admin_settings: state.settings.admin_settings,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Price));
