import React from "react";
import { UserWalletTable } from "./UsersTables";

const UserWallets = ({ user }) => {
  return (
    <div>
      <UserWalletTable user={user} />
    </div>
  );
};

export default UserWallets;
