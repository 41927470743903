import React, { useEffect, useState } from "react";
import QrReader from "react-qr-reader";

const QRComponent = ({ callback }) => {
  const [result, setresult] = useState(null);

  const handleScan = (data) => {
    if (data) {
      setresult(data);
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const PreviewStyle = {
    height: "100%",
    width: "80%",
    display: "flex",
    "justify-content": "center",
  };

  const CamStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const textStyle = {
    fontSize: "16px",
    "text-align": "center",
  };

  useEffect(() => {
    callback && callback(result);
  }, [result, callback]);

  return (
    <div>
      <div style={CamStyle}>
        <QrReader
          delay={100}
          style={PreviewStyle}
          onError={handleError}
          onScan={handleScan}
        />
      </div>
      <h1 style={textStyle}>hold QR Code Steady and Clear to Scan</h1>
      {result ? <p style={textStyle}>wallet Address: {result}</p> : null}
    </div>
  );
};

export default QRComponent;
