import styled from "styled-components";
import arrow from "../../assets/images/ic-right-arrow.svg";

export const Card = styled.div`
    border: 1px solid #E7EAF4;
    box-shadow: 0px 1px 5px #3F3F4419;
    padding: ${(props) => props.p || "8px"};
    background: ${(props) => props.bg || "#FFFFFF"};
    // color: ${(props) => props.color || "#FFFFFF"};
    border-radius: ${(props) => props.borderRadius || "4px"};
    opacity: ${(props) => props.opacity || "1"};
   
    & .viewAll{
        text-align: left;
        font-size: 14px;
        font-weight: 500 !important;
        letter-spacing: 0px;
        color: #0087B5;
        opacity: 1;
        position: relative;

        &::after {
            content: url(${arrow});
            width: 11px;
            height: 8px;
            padding-left: 8px;
        }

        &:hover::after{
            width: 300px;
            height: 300px;
            transform: rotate(180deg);
        }
        
        &:hover{
            text-decoration: underline;
        }
    }

    .transaction-button{
        color: #0087B5;
        padding: 7px 19px;
        background: #E5F3F8;
        border-radius: 4px;
        opacity: 1;
        outline: none;
    }
}
`;
