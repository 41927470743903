import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { LoginBase } from "../Login/Login.styled";
import flitbase_logo_center from "../../../assets/images/flitbase-alt-center.svg";
import { H6, P } from "../../../UI toolkit/Text/Text";
import { Input } from "../../../UI toolkit/Input/Input";
import { DefaultButton } from "../../../UI toolkit/Button/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTheme } from "styled-components";

const ResetPassword = ({ logo, theme }) => {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Password</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <LoginBase>
        <div className="login-container">
          <div className="logo">
            <img src={theme.colors.logo} alt="skye_logo" />
          </div>

          <div className="login-form px-6 py-10">
            <H6 color={"#112E46"} className="text-center" fontWeight="700">
              Reset Password
            </H6>

            <P color={"#646A86"} fontSize="16px" className="mt-8">
              Enter your email and the FLITBASE team will reach out to you for a
              password reset.
            </P>

            <div className="w-full mt-6 mb-2">
              <Input type="text" placeholder="Email address" required />
            </div>

            <P
              color={"#005674"}
              className="text-center"
              fontSize="13px"
              fontWeight="500"
            >
              <Link to="/">Login?</Link>
            </P>

            <div className="w-full mt-8">
              <DefaultButton
                small
                p={"7px 19px 7px 19px"}
                bg={"#005674"}
                // bg={theme.colors.bg}
                color={"#FFF"}
                width={"100%"}
              >
                Request Password Reset
              </DefaultButton>
            </div>
          </div>

          <div className="flitbase_logo pt-10">
            <img src={flitbase_logo_center} alt="skye_logo" className="" />
          </div>
        </div>
      </LoginBase>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    logo: state.UI.logo,
  };
};

export default connect(mapStateToProps, null)(withTheme(ResetPassword));
