import axios from "axios";
import Api from "./api";
import {
  GET_VERIFICATION_LIST,
  LOAD_VERIFICATION_DOC,
  VERIFICATION_DOC,
  VERIFICATION_LOADING,
  VERIFICATION_ERROR,
  UPDATE_TOTAL_VERIFICATIONS_PAGES,
  UPDATE_VERIFICATION_PAGE,
} from "../Types/Types";

import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";

export const ApproveUserAction = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: VERIFICATION_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.ApproveUser(id);
      console.log(response);
      customToast.success(`User Verified`);
      dispatch(VerificationListAction());
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: VERIFICATION_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const RejectUserAction = (id, state) => {
  return async (dispatch, getState) => {
    dispatch({ type: VERIFICATION_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.RejectUser(id);
      console.log(response);
      customToast.success(`User verification rejected`);
      dispatch(VerificationListAction());
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: VERIFICATION_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const VerificationListAction = (page) => {
  return async (dispatch, getState) => {
    dispatch({ type: VERIFICATION_LOADING });
    dispatch(showLoading());
    try {
      const { page } = getState().verification;

      const response = await Api.VerificationList(page);
      dispatch({
        type: GET_VERIFICATION_LIST,
        verificationsList: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_VERIFICATIONS_PAGES,
        payload: response.data.totalPages,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: VERIFICATION_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const viewVerificationDocumentsAction = (documentKey) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_VERIFICATION_DOC });
    dispatch(showLoading());
    try {
      const response = await Api.viewVerificationDocuments(documentKey);
      console.log(Buffer.from(response.data, "binary").toString("base64"));
      dispatch({
        type: VERIFICATION_DOC,
        verificationDoc: Buffer.from(response.data, "binary").toString(
          "base64"
        ),
      });
      customToast.success(
        Buffer.from(response.data, "binary").toString("base64")
      );
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: VERIFICATION_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateVerificationPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_VERIFICATION_PAGE, payload });
  };
};
