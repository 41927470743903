import {
  GET_MERCHANTS,
  LOADING_MERCHANTS,
  ERROR_MERCHANTS,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  merchantsList: null,
};

const MerchantReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MERCHANTS:
      return {
        ...state,
        merchantsList: action.merchantsList,
        loading: false,
      };
    case LOADING_MERCHANTS:
      return {
        ...state,
        loading: true,
      };
    case ERROR_MERCHANTS:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default MerchantReducer;
