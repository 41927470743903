import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { compareBy, getLevel } from "../../../../utils/specialFunctions";

export const MessagesTable = ({ users }) => {
  const [usersData, setusersData] = useState(null);
  const [tableKey, settableKey] = useState(null);
  const [tableKeyToggle, settableKeyToggle] = useState(false);

  const sortByJoinTime = (tableKey) => {
    settableKey(tableKey);
    settableKeyToggle(!tableKeyToggle);
  };

  useEffect(() => {
    console.log(usersData);
  }, [usersData]);

  useEffect(() => {
    setusersData(users);
  }, [users]);

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="w-full">
        <thead style={{ background: "#E7EAF4" }}>
          <tr className="Table-heading -px-1.5">
            <td
              width="50%"
              className="pt-4 pb-4 px-4"
              onClick={() => {
                sortByJoinTime("kyc_level");
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Message
                </SubHeader>
              </div>
            </td>
            <td
              width="16.66%"
              className="pt-4 pb-4 px-4"
              onClick={() => {
                sortByJoinTime("first_name");
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Delivered
                </SubHeader>
              </div>
            </td>
            <td
              width="16.66%"
              className="pt-4 pb-4 px-4"
              onClick={() => {
                sortByJoinTime("email");
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Unique Opens
                </SubHeader>
              </div>
            </td>
            <td
              width="16.66%"
              className="pt-4 pb-4 px-4 text-right"
              onClick={() => {
                sortByJoinTime("created_at");
              }}
              style={{ cursor: "pointer" }}
            >
              <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                Action
              </SubHeader>
            </td>
          </tr>
        </thead>

        <tbody>
          {usersData &&
            usersData
              .sort(compareBy(tableKey, tableKeyToggle))
              .map((user, idx, arr) => {
                return (
                  <tr
                    style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}
                    key={user.id}
                  >
                    <td width="50%" className="px-4 pt-4 pb-4 flex">
                      <div className="mr-3.5 w-12/12">
                        <Badge
                          color={
                            getLevel(user) === 0
                              ? "#337138"
                              : getLevel(user) === 1
                              ? "#606060"
                              : getLevel(user) === 2
                              ? "#335F71"
                              : getLevel(user) === 3
                              ? "#531423"
                              : ""
                          }
                          bg={
                            getLevel(user) === 0
                              ? "#FFDCE5"
                              : getLevel(user) === 1
                              ? "#FFEAB6"
                              : getLevel(user) === 2
                              ? "#D0F0FD"
                              : getLevel(user) === 3
                              ? "#D1F7C4"
                              : "#337138"
                          }
                        >
                          {getLevel(user)}
                        </Badge>
                      </div>

                      <div>
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {user.first_name}
                        </P>
                        <P fontSize="10px" color={"#9599AC"}>
                          {new Date(user.created_at).toLocaleString()}
                        </P>
                      </div>
                    </td>

                    <td width="16.6%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#005674"} fontWeight="500">
                        {user.wallets.wallets.NGN.coin === "NGN" && " ₦ "}
                        {user.wallets.wallets.NGN.available_balance.toLocaleString()}
                      </P>
                    </td>

                    <td width="16.66%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#005674"} fontWeight="500">
                        {user.wallets.wallets.NGN.coin === "NGN" && " ₦ "}
                        {user.wallets.wallets.NGN.available_balance.toLocaleString()}
                      </P>
                    </td>

                    <td width="16.66%" className="px-4 pt-4 pb-4">
                      {/* <P fontSize="13px" color={"#005674"} fontWeight="500">
                                        {user.wallets.wallets.NGN.coin==="NGN" && " ₦ "} 
                                        {user.wallets.wallets.NGN.available_balance.toLocaleString()}
                                    </P> */}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};
