import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import axios from "axios";
import RootReducer from "./store/Reducers/RootReducer";
import { loadState, saveState } from "./store/LocalStorage";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import thunkMiddleware from "redux-thunk-recursion-detect";
import config from "./config";

axios.defaults.baseURL = config.apiBaseUrl;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const persistedState = loadState();

export const store = createStore(
  RootReducer,
  persistedState,
  compose(
    // promise middleware for loader
    applyMiddleware(
      loadingBarMiddleware({
        promiseTypeSuffixes: ["REQUEST", "SUCCESS", "FAILURE"],
        scope: "sectionBar",
      }),
      thunk,
      thunkMiddleware
    ),
    window.navigator.userAgent.includes("Chrome") &&
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : compose
  )
);

store.subscribe(() => {
  saveState({
    user: store.getState().auth.user,
    token: store.getState().auth.token,
  });
});

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
