import axios from "axios";
import Api from "./api";
import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";
import {
  CARDS_ERROR,
  CARDS_LOADING,
  CARD_TRANSACTIONS_ERROR,
  CARD_TRANSACTIONS_LOADING,
  GET_CARD_ACTIVITIES,
  GET_CARD_STATS,
  GET_USER_CARD_ACTIVITIES,
  LOAD_CARD_TRANSACTIONS,
  UPDATE_CARD_ACTIVITIES_ACTIVE_STATUS,
  UPDATE_CARD_ACTIVITIES_ACTIVE_TAB,
  UPDATE_CARD_ACTIVITIES_NAVIGATOR,
  UPDATE_CARD_ACTIVITIES_PAGE,
  UPDATE_TOTAL_CARD_ACTIVITIES,
  UPDATE_TOTAL_USER_CARD_ACTIVITIES,
  UPDATE_USER_CARD_ACTIVITIES_ACTIVE_STATUS,
  UPDATE_USER_CARD_ACTIVITIES_ACTIVE_TAB,
  UPDATE_USER_CARD_ACTIVITIES_PAGE,
} from "../Types/Types";
const FileDownload = require("js-file-download");

export const getCardStats = (query) => {
  return async (dispatch, getState) => {
    dispatch({ type: CARDS_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.getCardStats(query);
      console.log(response);
      dispatch({
        type: GET_CARD_STATS,
        cardStats: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: CARDS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getCardActivities = (payload) => {
  // const status = payload.status ? payload.status : "all";
  return async (dispatch, getState) => {
    dispatch({ type: CARDS_LOADING });
    dispatch(showLoading());

    try {
      const {
        cardActivitiesActiveStatus,
        cardActivitiesPage,
        cardActivitiesActiveTab,
      } = getState().cards;
      console.log(cardActivitiesActiveStatus);
      console.log(cardActivitiesPage);
      console.log(cardActivitiesActiveTab);
      const currentStatus = cardActivitiesActiveStatus[cardActivitiesActiveTab];
      const currentPage =
        cardActivitiesPage[cardActivitiesActiveTab][currentStatus];
      const query = {
        type: cardActivitiesActiveTab,
        page: currentPage,
        status: currentStatus,
      };
      const response = await Api.getCardActivities(query);

      //      console.log(response);
      dispatch({
        type: GET_CARD_ACTIVITIES,
        cardActivities: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_CARD_ACTIVITIES,
        payload: {
          tab: query.type,
          status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: CARDS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getUserCardActivities = (id) => {
  // const status = payload.status ? payload.status : "all";
  return async (dispatch, getState) => {
    dispatch({ type: CARDS_LOADING });
    dispatch(showLoading());

    try {
      const {
        userCardActivitiesActiveStatus,
        userCardActivitiesPage,
        userCardActivitiesActiveTab,
      } = getState().cards;
      // console.log(userCardActivitiesActiveStatus);
      // console.log(userCardActivitiesPage);
      // console.log(userCardActivitiesActiveTab);
      const currentStatus =
        userCardActivitiesActiveStatus[userCardActivitiesActiveTab];
      const currentPage =
        userCardActivitiesPage[userCardActivitiesActiveTab][currentStatus];
      const query = {
        id,
        type: userCardActivitiesActiveTab,
        page: currentPage,
        status: currentStatus,
      };
      const response = await Api.getUserCardActivities(query);
      dispatch({
        type: GET_USER_CARD_ACTIVITIES,
        user_card_activities: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_USER_CARD_ACTIVITIES,
        payload: {
          tab: query.type,
          status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: CARDS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const loadCardTransactions = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: CARD_TRANSACTIONS_LOADING });
    dispatch(showLoading());
    try {
      console.log("hello");
      console.log("hello");
      console.log("hello");
      const response = await Api.getUserCardTransactions(id);
      console.log(response);
      console.log("hello");
      console.log("hello");
      console.log("hello");
      console.log("resps", response);
      console.log("resps data", response.data);
      dispatch({
        type: LOAD_CARD_TRANSACTIONS,
        cardTransactions: response.data.data,
      });
      //      console.log(response);
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: CARD_TRANSACTIONS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateCardActivitiesNavigator = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_CARD_ACTIVITIES_NAVIGATOR, payload });
  };
};

export const updateCardActivitiesActiveTab = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_CARD_ACTIVITIES_ACTIVE_TAB, payload });
  };
};

export const updateCardActivitiesActiveStatus = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_CARD_ACTIVITIES_ACTIVE_STATUS, payload });
  };
};

export const updateCardActivitiesPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_CARD_ACTIVITIES_PAGE, payload });
  };
};

export const updateUserCardActivitiesActiveTab = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_CARD_ACTIVITIES_ACTIVE_TAB, payload });
  };
};

export const updateUserCardActivitiesActiveStatus = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_CARD_ACTIVITIES_ACTIVE_STATUS, payload });
  };
};

export const updateUserCardActivitiesPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_CARD_ACTIVITIES_PAGE, payload });
  };
};
