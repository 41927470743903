import React from "react";
import { H5, P } from "../../../../UI toolkit/Text/Text";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { connect } from "react-redux";
import parse from "html-react-parser";
import {
  getAnnouncementAction,
  postAnnouncementAction,
} from "../../../../store/Actions/Messages_AnnouncementsAction";

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getAnnouncementAction: () => dispatch(getAnnouncementAction()),
    postAnnouncementAction: (state, successCallback) =>
      dispatch(postAnnouncementAction(state, successCallback)),
  };
};

export const PreviewModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ closeModal, Announcementstate, postAnnouncementAction }) => {

  const handleSubmit = ()=>{
    postAnnouncementAction({
      title: Announcementstate.title,
      body: Announcementstate.content
    }, ()=>{
      closeModal();
    })
  }


  return (
    <div>
      <Modal width={"50%"} bg={"#EFF0F3"}>
        <div className="-mx-8 px-6 py-4">
          <div className="flex items-center justify-center mb-12">
            <div className="mx-3">
              <DefaultButton
                small
                p={"8px 16px"}
                color={"#FF6A6A"}
                bg={"#F0E1E4"}
                required
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </DefaultButton>
            </div>

            <div className="mx-3">
              <DefaultButton
                small
                p={"8px 16px"}
                color={"#FFFFFF"}
                bg={"#005674"}
                required
                onClick={handleSubmit}
              >
                Send message
              </DefaultButton>
            </div>
          </div>

          {Announcementstate.content &&
            parse(
              `<div style="background: #fff; max-width: 640px; margin: auto; border-top: 5px solid #2FB47C; padding: 48px;"> 
                <span>
                  Title :<h1 style="font-size: 38px; text-transform: capitalize">${Announcementstate.title}</h1>
                </span>
                &nbsp;
                <p>${Announcementstate.content} </p>
              </div>`
            )}
        </div>
      </Modal>
    </div>
  );
});