import axios from "axios";
import Api from "./api";
import {
  GET_LIST_OF_BANKS,
  GET_LIST_OF_TRANSACTIONS,
  GET_LIST_OF_WITHDRAWALS,
  GET_LIST_OF_DEPOSITS,
  GET_TRADES,
  GET_TRANSFERS,
  TRANSACTIONS_LOADING,
  TRANSACTIONS_ERROR,
  GET_WALLET_ADDRESS,
  POST_WALLET_ADDRESS,
  GET_LIST_OF_DEPOSITS_AND_WITHDRAWALS,
  UPDATE_TRADE_NAVIGATOR,
  UPDATE_WITHDRAWALS_DEPOSIT_ACTIVE_TAB,
  UPDATE_WITHDRAWALS_DEPOSIT_NAVIGATOR,
  UPDATE_TRANSACTIONS_ACTIVE_TAB,
  UPDATE_TRANSACTIONS_PAGE,
  UPDATE_TOTAL_TRANSACTIONS,
  UPDATE_TRANSACTIONS_ACTIVE_STATUS,
  UPDATE_TRANSACTIONS_ACTIVE_MERCHANT,
  GET_COMPLETED_DEPOSITS,
  GET_COMPLETED_WITHDRAWALS,
  GET_PENDING_DEPOSITS,
  GET_PENDING_WITHDRAWALS,
  UPDATE_TRADES_ACTIVE_TAB,
  TRADES_LOADING,
  TRADES_ERROR,
  UPDATE_TRADES_PAGE,
  UPDATE_TOTAL_TRADES,
  UPDATE_TRADES_ACTIVE_CURRENCY,
  UPDATE_TRANSFERS_ACTIVE_TAB,
  UPDATE_TRANSFERS_ACTIVE_CURRENCY,
  TRANSFERS_LOADING,
  UPDATE_TOTAL_TRANSFERS,
  UPDATE_TRANSFERS_PAGE,
  PENDING_RECEIVED_TRANSFERS_LOADING,
  UPDATE_TOTAL_INCOMING_TRANSFERS,
  SENT_TRANSFERS_LOADING,
  UPDATE_TOTAL_SENT_TRANSFERS,
  INCOMING_TRANSFERS_LOADING,
  GET_INCOMING_TRANSFERS,
  GET_SENT_TRANSFERS,
  UPDATE_INCOMING_TRANSFERS_PAGE,
  UPDATE_INCOMING_TRANSFERS_ACTIVE_CURRENCY,
  UPDATE_SENT_TRANSFERS_ACTIVE_CURRENCY,
  UPDATE_SENT_TRANSFERS_PAGE,
  GET_TRANSFERS_COUNT,
  TRANSFERS_COUNT_LOADING,
} from "../Types/Types";
import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";
const FileDownload = require("js-file-download");

export const getBanksAction = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.GetBanks();
      console.log(response);
      dispatch({
        type: GET_LIST_OF_BANKS,
        banks: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const transactionAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
        transactionsActiveMerchant,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const query = {
        page: currentPage,
        type: transactionsActiveTab,
        status: currentStatus,
        merchant: transactionsActiveMerchant,
      };
      const response = await Api.Transactions(query);
      console.log(response.data.rows);
      dispatch({
        type: GET_LIST_OF_TRANSACTIONS,
        transactions: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_TRANSACTIONS,
        payload: {
          tab: query.type,
          status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getDepositsAndWithdrawalsAction = (query) => {
  const cursor = query?.cursor ? query?.cursor : "";
  const limit = query?.limit ? query?.limit : "";
  const status = query?.status ? query?.status : "all";
  const type = query?.type ? query?.type : "all";

  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.getDepositWithdrawals({
        cursor,
        limit,
        status,
        type,
      });
      console.log(response);
      dispatch({
        type: GET_LIST_OF_DEPOSITS_AND_WITHDRAWALS,
        data: response.data.results,
        filters: response.data.filters,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getDepositsAction = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.getDeposits();
      console.log(response);
      dispatch({
        type: GET_LIST_OF_DEPOSITS,
        deposits: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getWithdrawalsAction = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.getWithdrawals();
      console.log(response);
      dispatch({
        type: GET_LIST_OF_WITHDRAWALS,
        withdrawals: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};
export const getOtpAction = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.getOtp();
      console.log(response);
      customToast.success("OTP SENT");
      // dispatch({ type: GET_LIST_OF_WITHDRAWALS, withdrawals: response.data });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const sendCryptoAction = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.sendCrypto(state);
      console.log(response);
      customToast.success(
        `${state.currency ? state.currency : "coin"} sent successfully`
      );
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const retrieveCryptoWalletsAction = (state) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.retrieveCryptoWallets(state);
      console.log(response);
      if (response && response.data && response.data.length === 0) {
        dispatch(fetchCryptoWalletsAddressbyPostAction(state));
      } else {
        dispatch({
          type: GET_WALLET_ADDRESS,
          wallet_address: response.data[0],
        });
        customToast.success(
          `fetched Wallet Address ${
            response && response.data && response.data[0]
              ? "for " + response.data[0].coin
              : null
          } `
        );
      }
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const fetchCryptoWalletsAddressbyPostAction = (state) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.fetchCryptoWalletsAddressbyPost(state);
      console.log(response);
      dispatch({
        type: POST_WALLET_ADDRESS,
        wallet_address: response.data,
      });
      customToast.success(
        `fetched new Address ${
          response && response.data[0] ? "for " + response.data[0].coin : null
        } `
      );
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getTradesAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRADES_LOADING,
    });
    dispatch(showLoading());
    try {
      const { tradesPage, tradesActiveTab, tradesActiveCurrency } =
        getState().transactions;
      // console.log(currencies);
      // console.log(tradesPage);
      // console.log(tradesActiveTab);
      const currentPage = tradesPage[tradesActiveTab];
      const query = {
        page: currentPage,
        type: tradesActiveTab,
        currency: tradesActiveCurrency,
      };
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      const response = await Api.getTrades(query);
      console.log(response.data);
      dispatch({
        type: GET_TRADES,
        trades: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_TRADES,
        payload: {
          tab: query.type,
          // status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRADES_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getSendAndRecieveAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSFERS_LOADING,
    });
    dispatch(showLoading());
    try {
      const { transfersPage, transfersActiveTab, transfersActiveCurrency } =
        getState().transactions;
      const currentPage = transfersPage[transfersActiveTab];
      const query = {
        page: currentPage,
        type: transfersActiveTab,
        currency: transfersActiveCurrency,
      };
      const response = await Api.getSendAndRecieve(query);
      console.log(response);
      dispatch({
        type: GET_TRANSFERS,
        transfers: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_TRANSFERS,
        payload: {
          tab: query.type,
          // status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getReceivedTransfersAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({
      type: INCOMING_TRANSFERS_LOADING,
    });
    dispatch(showLoading());
    try {
      const { incomingTransfersPage, incomingTransfersActiveCurrency } =
        getState().transactions;
      const currentPage = incomingTransfersPage;
      const query = {
        page: currentPage,
        type: "receive",
        status: "pending",
        currency: incomingTransfersActiveCurrency,
      };
      const response = await Api.getSendAndRecieve(query);
      console.log(response);
      dispatch({
        type: GET_INCOMING_TRANSFERS,
        data: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_INCOMING_TRANSFERS,
        payload: {
          tab: query.type,
          // status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getSentTransfersAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SENT_TRANSFERS_LOADING,
    });
    dispatch(showLoading());
    try {
      const { sentTransfersPage, sentTransfersActiveCurrency } =
        getState().transactions;
      const currentPage = sentTransfersPage;
      const query = {
        page: currentPage,
        type: "send",
        status: "pending",
        currency: sentTransfersActiveCurrency,
      };
      const response = await Api.getSendAndRecieve(query);
      console.log(response);
      dispatch({
        type: GET_SENT_TRANSFERS,
        data: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_SENT_TRANSFERS,
        payload: {
          tab: query.type,
          // status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const approveDepositsAction = (depositId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const response = await Api.approveDeposits(depositId);
      console.log(response);
      customToast.success(`deposit approved`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const retryDepositsAction = (depositId, successCallback) => {
  console.log("retrying");
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const response = await Api.retryDeposit(depositId);
      console.log(response);
      customToast.success(`deposit approved`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const rejectDepositsAction = (depositId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const response = await Api.rejectDeposits(depositId);
      console.log(response);
      customToast.success(`deposit rejected`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const canelDepositsAction = (depositId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const response = await Api.cancelDeposits(depositId);
      console.log(response);
      customToast.success(`deposit rejected`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const approveWithdrawalAction = (
  withdrawalId,
  state,
  successCallback
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const response = await Api.approveWithdrawal(withdrawalId, state);
      console.log(response);
      customToast.success(`withdrawal approved`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const rejectWithdrawalAction = (withdrawalId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      const response = await Api.rejectWithdrawal(withdrawalId);
      console.log(response);
      customToast.success(`withdrawal rejected`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const retryWithdrawalAction = (withdrawalId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      console.log(currentPage, currentStatus);
      const response = await Api.retryWithdrawal(withdrawalId);
      console.log(response);
      customToast.success(`withdrawal approved`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const resolveWithdrawalAction = (withdrawalId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const {
        transactionsActiveTab,
        transactionsPage,
        transactionsActiveStatus,
      } = getState().transactions;
      const currentStatus = transactionsActiveStatus[transactionsActiveTab];
      const currentPage =
        transactionsPage[transactionsActiveTab][currentStatus];
      console.log(withdrawalId);
      const response = await Api.resolveWithdrawal(withdrawalId);
      console.log(response);
      customToast.success(`withdrawal approved`);
      dispatch(hideLoading());
      dispatch(
        transactionAction({
          page: currentPage,
          type: transactionsActiveTab,
          status: currentStatus,
        })
      );

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const retryTransferAction = (transferId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.retryTransfer(transferId);
      console.log(response);
      customToast.success(`transfer approved`);
      dispatch(hideLoading());
      dispatch(getSendAndRecieveAction());
      dispatch(getTradesAction());

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const rejectTransferAction = (transferId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.rejectTransfer(transferId);
      console.log(response);
      customToast.success(`transfer rejected`);
      dispatch(hideLoading());
      dispatch(getSendAndRecieveAction());
      dispatch(getTradesAction());

      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const downloadTransactionsAsCSVAction = (module, start, end) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.downloadTransactionsAsCSV(module, start, end);
      console.log(response);
      FileDownload(response.data, "report.csv");
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const transactionStatsAction = (query) => {
  return async (dispatch, getState) => {
    dispatch(showLoading());
    try {
      const response = await Api.TransactionStats(query);
      console.log(response);
      dispatch({
        type: GET_COMPLETED_DEPOSITS,
        deposits: response.data.total_deposits,
      });
      dispatch({
        type: GET_COMPLETED_WITHDRAWALS,
        withdrawals: response.data.total_withdrawals,
      });
      dispatch({
        type: GET_PENDING_DEPOSITS,
        pending_deposits: response.data.total_pending_deposits,
      });
      dispatch({
        type: GET_PENDING_WITHDRAWALS,
        pending_withdrawals: response.data.total_pending_withdrawals,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getPendingTransfersCountAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({
      type: TRANSFERS_COUNT_LOADING,
    });
    dispatch(showLoading());
    try {
      const response = await Api.getPendingTransfersCount();
      dispatch({
        type: GET_TRANSFERS_COUNT,
        transfersCount: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({
        type: TRANSACTIONS_ERROR,
      });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateTradesActiveTab = (tab) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRADES_ACTIVE_TAB,
      payload: tab,
    });
  };
};

export const updateTradesActiveCurrency = (currency) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRADES_ACTIVE_CURRENCY,
      payload: currency,
    });
  };
};

export const updateTradesPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRADES_PAGE,
      payload,
    });
  };
};

export const updateTradeNavigator = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRADE_NAVIGATOR,
      payload,
    });
  };
};

export const updateTransfersActiveTab = (tab) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSFERS_ACTIVE_TAB,
      payload: tab,
    });
  };
};

export const updateTransfersPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSFERS_PAGE,
      payload,
    });
  };
};

export const updateTransfersActiveCurrency = (currency) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSFERS_ACTIVE_CURRENCY,
      payload: currency,
    });
  };
};

export const updateIncomingTransfersPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_INCOMING_TRANSFERS_PAGE,
      payload,
    });
  };
};

export const updateIncomingTransfersActiveCurrency = (currency) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_INCOMING_TRANSFERS_ACTIVE_CURRENCY,
      payload: currency,
    });
  };
};

export const updateSentTransfersPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SENT_TRANSFERS_PAGE,
      payload,
    });
  };
};

export const updateSentTransfersActiveCurrency = (currency) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SENT_TRANSFERS_ACTIVE_CURRENCY,
      payload: currency,
    });
  };
};

export const updateWithDrawalsDepositActiveTab = (tab) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_WITHDRAWALS_DEPOSIT_ACTIVE_TAB,
      payload: tab,
    });
  };
};

export const updateWithDrawalsDepositNavigator = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_WITHDRAWALS_DEPOSIT_NAVIGATOR,
      payload,
    });
  };
};

export const updateTransactionsPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSACTIONS_PAGE,
      payload,
    });
  };
};

export const updateTotalTransactions = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TOTAL_TRANSACTIONS,
      payload,
    });
  };
};

export const updateTransactionsActiveTab = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSACTIONS_ACTIVE_TAB,
      payload,
    });
  };
};

export const updateTransactionsActiveStatus = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSACTIONS_ACTIVE_STATUS,
      payload,
    });
  };
};

export const updateTransactionActiveMerchant = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_TRANSACTIONS_ACTIVE_MERCHANT,
      payload,
    });
  };
};
