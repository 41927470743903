import {
  GET_OVERVIEW_DATA,
  GET_TRANSACTIONS,
  GET_DEPOSITS,
  GET_WITHDRAWALS,
  GET_CURRENCIES,
  GET_TOTAL_USERS,
  GET_PRICES,
  GET_MATCHED_DEPOSITS,
  GET_MATCHED_WITHDRAWALS,
  GET_WALLETS_DATA,
  OVERVIEW_LOADING,
  OVERVIEW_ERROR,
  GET_FEES,
  FEES_LOADING,
  FEES_ERROR,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  overview: null,
  transactions: null,
  deposits: null,
  withdrawals: null,
  total_users: null,
  currencies: null,
  prices: null,
  wallets: null,
  fees: null,
  feesLoading: null,
};

const overviewReducer = (state = initState, action) => {
  switch (action.type) {
    case OVERVIEW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OVERVIEW_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_OVERVIEW_DATA:
      return {
        ...state,
        overview: action.overview,
        loading: false,
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
        loading: false,
      };
    case GET_DEPOSITS:
      return {
        ...state,
        deposits: action.deposits,
        loading: false,
      };
    case GET_WITHDRAWALS:
      return {
        ...state,
        withdrawals: action.withdrawals,
        loading: false,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
        loading: false,
      };
    case GET_TOTAL_USERS:
      return {
        ...state,
        total_users: action.total_users,
        loading: false,
      };
    case GET_PRICES:
      return {
        ...state,
        prices: action.prices,
        loading: false,
      };
    case GET_MATCHED_DEPOSITS:
      return {
        ...state,
        total_matched_deposits: action.total_matched_deposits,
        loading: false,
      };
    case GET_MATCHED_WITHDRAWALS:
      return {
        ...state,
        total_matched_withdrawals: action.total_matched_withdrawals,
        loading: false,
      };

    case GET_WALLETS_DATA:
      return {
        ...state,
        wallets: action.wallets,
        loading: false,
      };
    case GET_FEES:
      return {
        ...state,
        fee: action.fee,
        feesLoading: false,
      };
    case FEES_LOADING:
      return {
        ...state,
        feesLoading: true,
      };
    case FEES_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default overviewReducer;
