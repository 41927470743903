import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ApproveUserAction,
  viewVerificationDocumentsAction,
} from "../../../../store/Actions/VerificationActions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { compareBy } from "../../../../utils/specialFunctions";
import {
  ApproveModal,
  RejectModal,
  RejectReasonModal,
  ViewDocumentModal,
} from "./VerificationModals";

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    verificationsList: state.verification.verificationsList,
    verificationDoc: state.verification.verificationDoc,
    loadVerificationDoc: state.verification.loadVerificationDoc,
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    viewVerificationDocumentsAction: (key) =>
      dispatch(viewVerificationDocumentsAction(key)),
    ApproveUserAction: (id) => dispatch(ApproveUserAction(id)),
  };
};

// named exported tables

export const VerificationsListTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ users, viewVerificationDocumentsAction, user }) => {
  const [openModal, setopenModal] = useState(false);
  const [verificationDetails, setverificationDetails] = useState(null);
  const [approveModal, setapproveModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);
  const [userId, setuserId] = useState(null);
  const [reasonModal, setreasonModal] = useState(false);

  const [usersData, setusersData] = useState(null);
  const [tableKey, settableKey] = useState(null);
  const [tableKeyToggle, settableKeyToggle] = useState(false);

  const sortByJoinTime = (tableKey) => {
    settableKey(tableKey);
    settableKeyToggle(!tableKeyToggle);
  };

  useEffect(() => {
    setusersData(users);
  }, [users]);

  useEffect(() => {
    console.log(verificationDetails);
  }, [verificationDetails]);

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="w-full">
        <thead style={{ background: "#E7EAF4" }}>
          <tr className="Table-heading -px-1.5">
            <td
              width="25%"
              className="pt-4 pb-4 px-4"
              onClick={() => {
                sortByJoinTime("account.first_name");
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  USERNAME
                </SubHeader>
              </div>
            </td>
            <td
              width="25%"
              className="pt-4 pb-4 px-4"
              onClick={() => {
                sortByJoinTime("account.email");
              }}
              style={{ cursor: "pointer" }}
            >
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  EMAIL
                </SubHeader>
              </div>
            </td>
            <td
              width="25%"
              className="pt-4 pb-4 px-4"
              onClick={() => {
                sortByJoinTime("id");
              }}
              style={{ cursor: "pointer" }}
            >
              <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                NGN BALANCE
              </SubHeader>
            </td>

            <td width="25%" className="pt-4 pb-4 px-4"></td>
          </tr>
        </thead>

        <tbody>
          {usersData &&
            usersData
              .sort(compareBy(tableKey, tableKeyToggle))
              .map((curr, idx, arr) => {
                return (
                  <tr
                    style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}
                    key={curr.id}
                  >
                    <td width="25%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {curr.account.first_name}
                      </P>
                      <P fontSize="10px" color={"#9599AC"}>
                        {new Date(curr.created_at).toLocaleString()}
                      </P>
                    </td>

                    <td width="25%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#005674"} fontWeight="500">
                        {curr.account.email}
                      </P>
                    </td>

                    <td width="25%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#005674"} fontWeight="500">
                        {null}
                      </P>
                    </td>

                    <td width="25%" className="px-4 pt-4 pb-4">
                      <div
                        className={`flex ${
                          user.role === "super-admin" ? "" : "justify-end"
                        }`}
                      >
                        {user.role === "super-admin" ||
                        user.role === "manager" ? (
                          <div className="flex justify-between pr-6">
                            <DefaultButton
                              small
                              p={"7px 19px 7px 19px"}
                              bg={"#24B57A"}
                              color={"#FFF"}
                              onClick={() => {
                                setuserId(curr.id);
                                setapproveModal(true);
                              }}
                            >
                              Approve
                            </DefaultButton>

                            <DefaultButton
                              small
                              p={"7px 19px 7px 19px"}
                              bg={"#FBE5E5"}
                              color={"#D60000"}
                              onClick={() => {
                                setuserId(curr.id);
                                // setrejectModal(true);
                                setreasonModal(true);
                              }}
                            >
                              Reject
                            </DefaultButton>
                          </div>
                        ) : null}

                        <div className="flex justify-end">
                          <P
                            fontSize="13px"
                            color={"#0087B5"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setverificationDetails(curr);
                              setopenModal(true);
                            }}
                          >
                            View
                          </P>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>

      {approveModal ? (
        <ApproveModal
          width={"30%"}
          closeModal={() => {
            setapproveModal(false);
          }}
          id={userId}
        />
      ) : null}

      {rejectModal ? (
        <RejectModal
          width={"30%"}
          closeModal={() => {
            setrejectModal(false);
          }}
          id={userId}
        />
      ) : null}

      {reasonModal ? (
        <RejectReasonModal
          width={"30%"}
          closeModal={() => {
            setreasonModal(false);
          }}
          id={userId}
        />
      ) : null}

      {openModal ? (
        <ViewDocumentModal
          width={"30%"}
          closeModal={() => {
            setopenModal(false);
          }}
          user={verificationDetails}
        />
      ) : null}
    </div>
  );
});
