import { Link } from "react-router-dom";
import { Input } from "../../../../../UI toolkit/Input/Input";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import { AdminTableStyle } from "../Settings.Styled";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  adminSettingsAction,
  changeAdminSettingsAction,
} from "../../../../../store/Actions/SettingsActions";
import RateSelector from "./RateSelector";

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    admin_settings: state.settings.admin_settings,
    //   admin_settings: state.settings.admin_settings ? Object.keys(state.settings.admin_settings).map(key => ({key, value: state.settings.admin_settings[key]})): null,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export const PricesTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(({ prices, currencies, admin_settings }) => {
    return (
      <div style={{ overflowX: "auto" }}>
        <table className="w-full">
          <thead style={{ background: "#E7EAF4" }}>
            <tr className="Table-heading">
              <td width="20%" className="px-4 pt-4 pb-4">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Coin
                </SubHeader>
              </td>

              <td width="20%" className="px-4 pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    API PRICE
                  </SubHeader>
                </div>
              </td>

              <td width="20%" className="px-4 pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    SET FEE
                  </SubHeader>
                </div>
              </td>
              <td width="20%" className="px-4 pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    LIVE BUY PRICE
                  </SubHeader>
                </div>
              </td>
              <td width="20%" className="px-4 pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    LIVE Sell PRICE
                  </SubHeader>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            {currencies &&
              currencies.map((curr, idx, arr) => {
                if (curr.symbol !== "NGN") {
                  return (
                    <tr
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EFF0F5",
                      }}
                      key={idx}
                    >
                      <td width="20%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {curr.symbol}
                        </P>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {prices[curr.symbol].ngn.toLocaleString()}
                        </P>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <form>
                          <Input
                            w={"50%"}
                            placeholder="Set Fee"
                            type="number"
                            name="setfee"
                            value={admin_settings.GLOBAL_SELL_MARKUP_PERCENTAGE}
                            required
                          />
                        </form>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#005674"} fontWeight="500">
                          {(
                            prices[curr.symbol].ngn *
                              (admin_settings.GLOBAL_BUY_MARKUP_PERCENTAGE /
                                100) +
                            prices[curr.symbol].ngn
                          ).toLocaleString()}
                        </P>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#005674"} fontWeight="500">
                          {(
                            prices[curr.symbol].ngn -
                            prices[curr.symbol].ngn *
                              (admin_settings.GLOBAL_SELL_MARKUP_PERCENTAGE /
                                100)
                          ).toLocaleString()}
                        </P>
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
          </tbody>
        </table>
      </div>
    );
  })
);

export const AdminsTable = ({
  admins,
  removeAdmin,
  getAdmins,
  setModal = { setModal },
}) => {
  return (
    <div style={{ overflowX: "auto" }}>
      <AdminTableStyle className="w-full">
        <thead style={{ background: "#E7EAF4" }}>
          <tr className="Table-heading">
            <td width="20%" className="px-4 pt-4 pb-4">
              <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                Name
              </SubHeader>
            </td>

            <td width="20%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Email
                </SubHeader>
              </div>
            </td>

            <td width="50%" className="px-4 pt-4 pb-4">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Role
                </SubHeader>
              </div>
            </td>
          </tr>
        </thead>

        <tbody>
          {admins &&
            admins.map((admin) => {
              return (
                <tr
                  style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}
                  key={admin.id}
                >
                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {`${admin.first_name} ${admin.last_name}`}
                    </P>
                  </td>

                  <td width="25%" className="px-4 pt-4 pb-4">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {admin.email}
                    </P>
                  </td>

                  <td width="50%" className="px-4 pt-4 pb-4">
                    <div className="flex Admin">
                      <div className="flex mr-12">
                        <P fontSize="13px" color={"#005674"} fontWeight="500">
                          {admin.role}
                        </P>
                      </div>

                      <div className="flex admin-actions">
                        <div
                          className="mr-6"
                          onClick={() =>
                            setModal({
                              isOpen: true,
                              action: "update",
                              id: admin.id,
                            })
                          }
                        >
                          <P fontSize="13px" color={"#0087B5"}>
                            Change role
                          </P>
                        </div>

                        <div
                          className="mr-6"
                          onClick={() =>
                            setModal({
                              isOpen: true,
                              action: "password",
                              id: admin.id,
                            })
                          }
                        >
                          <P fontSize="13px" color={"#0087B5"}>
                            Change password
                          </P>
                        </div>

                        <div
                          className=""
                          onClick={() =>
                            removeAdmin(admin.id, () => getAdmins())
                          }
                        >
                          <P fontSize="13px" color={"#D60000"}>
                            Remove
                          </P>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </AdminTableStyle>
    </div>
  );
};
