import {
  CARDS_ERROR,
  CARDS_LOADING,
  CARD_TRANSACTIONS_ERROR,
  CARD_TRANSACTIONS_LOADING,
  GET_CARD_ACTIVITIES,
  GET_CARD_STATS,
  GET_USER_CARD_ACTIVITIES,
  LOAD_CARD_TRANSACTIONS,
  UPDATE_CARD_ACTIVITIES_ACTIVE_STATUS,
  UPDATE_CARD_ACTIVITIES_ACTIVE_TAB,
  UPDATE_CARD_ACTIVITIES_NAVIGATOR,
  UPDATE_CARD_ACTIVITIES_PAGE,
  UPDATE_TOTAL_CARD_ACTIVITIES,
  UPDATE_TOTAL_USER_CARD_ACTIVITIES,
  UPDATE_USER_CARD_ACTIVITIES_ACTIVE_STATUS,
  UPDATE_USER_CARD_ACTIVITIES_ACTIVE_TAB,
  UPDATE_USER_CARD_ACTIVITIES_PAGE,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  savings: null,
  cardStats: null,
  cardActivities: null,
  cardActivitiesNavigator: {
    startIndex: 0,
    endIndex: 6,
    currentIndex: 0,
  },
  cardActivitiesPage: {
    all: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    fund: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    withdrawal: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    create: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    active: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    inactive: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
  },
  cardActivitiesTotalPages: {
    all: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    fund: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    withdrawal: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    create: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    active: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    inactive: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
  },
  user_card_activities: null,
  cardActivitiesActiveTab: "all",
  cardActivitiesActiveStatus: {
    fund: "all",
    withdrawal: "all",
    create: "all",
    active: "all",
    inactive: "all",
    all: "all",
  },
  cardActivitiesFilters: null,

  userCardActivitiesPage: {
    all: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    fund: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    withdrawal: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    create: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    active: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    inactive: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
  },
  userCardActivitiesTotalPages: {
    all: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    fund: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    withdrawal: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    create: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    active: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
    inactive: {
      pending: 0,
      completed: 0,
      failed: 0,
      all: 0,
    },
  },
  userCardActivitiesActiveTab: "all",
  userCardActivitiesActiveStatus: {
    fund: "all",
    withdrawal: "all",
    create: "all",
    active: "all",
    inactive: "all",
    all: "all",
  },
  userCardTransactions: null,
  cardTransactionsLoading: false,
};

const cardsReducer = (state = initState, action) => {
  switch (action.type) {
    case CARDS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CARDS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_CARD_STATS:
      return {
        ...state,
        cardStats: action.cardStats,
        loading: false,
      };
    case GET_CARD_ACTIVITIES:
      return {
        ...state,
        cardActivities: action.cardActivities,
        cardActivitiesFilters: action.filters,
        loading: false,
      };
    case GET_USER_CARD_ACTIVITIES:
      return {
        ...state,
        user_card_activities: action.user_card_activities,
        cardActivitiesFilters: action.filters,
        loading: false,
      };

    case UPDATE_CARD_ACTIVITIES_ACTIVE_TAB:
      return {
        ...state,
        cardActivitiesActiveTab: action.payload,
        loading: false,
      };

    case UPDATE_CARD_ACTIVITIES_ACTIVE_STATUS:
      return {
        ...state,
        cardActivitiesActiveStatus: {
          ...state.cardActivitiesActiveStatus,
          [action.payload.tab]: action.payload.value,
        },
        loading: false,
      };
    case UPDATE_TOTAL_CARD_ACTIVITIES:
      return {
        ...state,
        cardActivitiesTotalPages: {
          ...state.cardActivitiesTotalPages,
          [action.payload.tab]: {
            [action.payload.status]: action.payload.value,
          },
        },
      };
    case UPDATE_CARD_ACTIVITIES_PAGE:
      return {
        ...state,
        cardActivitiesPage: {
          ...state.cardActivitiesPage,
          [action.payload.tab]: {
            ...state.cardActivitiesPage[action.payload.tab],
            [action.payload.status]: action.payload.value,
          },
        },
      };
    case UPDATE_USER_CARD_ACTIVITIES_ACTIVE_TAB:
      return {
        ...state,
        userCardActivitiesActiveTab: action.payload,
        loading: false,
      };

    case UPDATE_USER_CARD_ACTIVITIES_ACTIVE_STATUS:
      return {
        ...state,
        userCardActivitiesActiveStatus: {
          ...state.userCardActivitiesActiveStatus,
          [action.payload.tab]: action.payload.value,
        },
        loading: false,
      };
    case UPDATE_TOTAL_USER_CARD_ACTIVITIES:
      return {
        ...state,
        userCardActivitiesTotalPages: {
          ...state.userCardActivitiesTotalPages,
          [action.payload.tab]: {
            [action.payload.status]: action.payload.value,
          },
        },
      };
    case UPDATE_USER_CARD_ACTIVITIES_PAGE:
      return {
        ...state,
        userCardActivitiesPage: {
          ...state.userCardActivitiesPage,
          [action.payload.tab]: {
            ...state.userCardActivitiesPage[action.payload.tab],
            [action.payload.status]: action.payload.value,
          },
        },
      };
    case LOAD_CARD_TRANSACTIONS:
      return {
        ...state,
        userCardTransactions: action.cardTransactions,
        cardTransactionsLoading: false,
      };
    case CARD_TRANSACTIONS_LOADING:
      return {
        ...state,
        cardTransactionsLoading: true,
      };
    case CARD_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        cardTransactionsLoading: false,
      };
    default:
      return state;
  }
};

export default cardsReducer;
