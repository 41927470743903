import React, { useState } from "react";
import { Badge, DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { H1, H6, P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { TransactionsModalStyle } from "./Transactions.styled";
import ic_sent_icon from "../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../assets/images/ic-received-coin.svg";
import ic_appr from "../../../../assets/images/ic-appr.svg";
import { connect } from "react-redux";
import {
  approveDepositsAction,
  approveWithdrawalAction,
  rejectDepositsAction,
  rejectWithdrawalAction,
  retryWithdrawalAction,
  resolveWithdrawalAction,
  retryDepositsAction,
  canelDepositsAction,
} from "../../../../store/Actions/TransactionActions";
import { capitalizeFirstLetter } from "../../../../utils/specialFunctions";
import {
  Dropdown,
  DropdownStyle,
  OptionStyle,
} from "../../../../UI toolkit/Dropdown/Dropdown";
import caret_down from "../../../../assets/images/caret-down.svg";

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    merchantsList: state.merchants.merchantsList,
    verificationsList: state.verification.verificationsList,
    verificationDoc: state.verification.verificationDoc,
    loadVerificationDoc: state.verification.loadVerificationDoc,
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    approveDepositsAction: (id, successCallback) =>
      dispatch(approveDepositsAction(id, successCallback)),
    retryDepositAction: (id, successCallback) =>
      dispatch(retryDepositsAction(id, successCallback)),
    approveWithdrawalAction: (id, state, successCallback) =>
      dispatch(approveWithdrawalAction(id, state, successCallback)),
    rejectDepositsAction: (id, successCallback) =>
      dispatch(rejectDepositsAction(id, successCallback)),
    cancelDepositsAction: (id, successCallback) =>
      dispatch(canelDepositsAction(id, successCallback)),
    rejectWithdrawalAction: (id, successCallback) =>
      dispatch(rejectWithdrawalAction(id, successCallback)),
    retryWithdrawalAction: (id, successCallback) =>
      dispatch(retryWithdrawalAction(id, successCallback)),
    resolveWithdrawalAction: (id, successCallback) =>
      dispatch(resolveWithdrawalAction(id, successCallback)),
  };
};

export const TransactionsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    border,
    setselectMerchantWithdrawalModal,
    closeModalCallback,
    transaction,
    approveDepositsAction,
    approveWithdrawalAction,
    rejectDepositsAction,
    rejectWithdrawalAction,
    retryWithdrawalAction,
    resolveWithdrawalAction,
    retryDepositAction,
    cancelDepositsAction,
    user,
  }) => {

    const ApproveTransaction = (transaction) => {
      if (transaction.type === "deposit") {
        if (transaction.status === "review") {
          retryDepositAction(transaction.id, (response) => {
            closeModalCallback();
          });
          return;
        }
        approveDepositsAction(transaction.id, (response) => {
          closeModalCallback();
        });
      } else if (transaction.type === "withdrawal") {
        closeModalCallback();
        if (
          transaction.status === "pending" ||
          transaction.status === "refunded"
        ) {
          setselectMerchantWithdrawalModal(true);
          return;
        }
        retryWithdrawalAction(transaction.id, (response) => {
          closeModalCallback();
        });
      }
    };

    const RejectTransaction = (transaction) => {
      if (transaction.type === "deposit") {
        if (transaction.status === "review") {
          cancelDepositsAction(transaction.id, (response) => {
            closeModalCallback();
          });
          return;
        }
        rejectDepositsAction(transaction.id, (response) => {
          closeModalCallback();
        });
      } else if (transaction.type === "withdrawal") {
        if (
          transaction.status === "failed" ||
          transaction.status === "refunded"
        ) {
          resolveWithdrawalAction(transaction.id, (response) => {
            closeModalCallback();
          });
          return;
        }
        rejectWithdrawalAction(transaction.id, (response) => {
          closeModalCallback();
        });
      }
    };

    return (
      <div>
        <Modal width={width} border={border} callback={closeModalCallback}>
          {transaction ? (
            <TransactionsModalStyle>
              <div className="-mx-8">
                <div className="flex items-center justify-between px-6 py-3 border-bottom bg-white">
                  <div className="flex items-center">
                    <div>
                      <img
                        src={
                          transaction.type.toLowerCase() === "withdrawal"
                            ? ic_sent_icon
                            : transaction.type.toLowerCase() === "deposit"
                            ? ic_receive_icon
                            : ""
                        }
                        alt="img"
                      />
                    </div>
                    &nbsp;
                    <div>
                      <H6
                        color={"#112E46"}
                        className="text-center"
                        fontWeight="700"
                      >
                        {transaction.type === "deposit"
                          ? "Deposit"
                          : transaction.type === "withdrawal"
                          ? "Withdraw"
                          : null}
                      </H6>
                    </div>
                  </div>

                  <div className="">
                    <Badge
                      color={
                        transaction.status === "cancelled"
                          ? "#337138"
                          : transaction.status === "failed"
                          ? "#337138"
                          : transaction.status === "refunded"
                          ? "#337138"
                          : transaction.status === "rejected"
                          ? "#337138"
                          : transaction.status === "pending"
                          ? "#606060"
                          : transaction.status === "review"
                          ? "#606060"
                          : transaction.status === "active"
                          ? "#335F71"
                          : transaction.status === "processing"
                          ? "#335F71"
                          : transaction.status === "completed"
                          ? "#531423"
                          : ""
                      }
                      bg={
                        transaction.status === "cancelled"
                          ? "#FFDCE5"
                          : transaction.status === "failed"
                          ? "#FFDCE5"
                          : transaction.status === "refunded"
                          ? "#FFDCE5"
                          : transaction.status === "rejected"
                          ? "#FFDCE5"
                          : transaction.status === "pending"
                          ? "#FFEAB6"
                          : transaction.status === "review"
                          ? "#FFEAB6"
                          : transaction.status === "active"
                          ? "#D0F0FD"
                          : transaction.status === "processing"
                          ? "#D0F0FD"
                          : transaction.status === "completed"
                          ? "#D1F7C4"
                          : "#337138"
                      }
                    >
                      {transaction.status}
                    </Badge>
                  </div>
                </div>

                <div className="flex px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        user
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    <div className="mb-2">
                      <P fontSize="16px" color={"#374072"} fontWeight="500">
                        {`${transaction.user_first_name} ${transaction.user_last_name}`}
                      </P>
                    </div>

                    <div className="">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {transaction.user_email}
                      </P>
                    </div>

                    <div className="">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {transaction.user_phone_number}
                      </P>
                    </div>
                  </div>
                </div>

                <div className="flex px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        {transaction.type === "deposit"
                          ? "merchant"
                          : transaction.type === "withdrawal"
                          ? "user"
                          : transaction.user_account_name || ""}
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    {transaction.merchant_username ? (
                      <div className="mb-1">
                        <P
                          fontSize="13px"
                          color={"#374072"}
                          fontWeight="500"
                          className="italic"
                        >
                          {`${capitalizeFirstLetter(
                            transaction.merchant_username
                          )}`}
                        </P>
                      </div>
                    ) : null}

                    <div
                      className={
                        transaction && transaction.merchant_username
                          ? `my-1`
                          : `mb-1`
                      }
                    >
                      <P fontSize="16px" color={"#374072"} fontWeight="500">
                        {transaction && transaction.merchant_username
                          ? `${capitalizeFirstLetter(
                              transaction.merchant_first_name
                            )} ${capitalizeFirstLetter(
                              transaction.merchant_last_name
                            )}`
                          : capitalizeFirstLetter(
                              transaction.user_account_name || ""
                            )}
                      </P>
                    </div>
                    <div className="mt-1">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {transaction && transaction.merchant_bank_name
                          ? transaction.merchant_bank_name
                          : transaction && transaction.user_bank_name
                          ? transaction.user_bank_name
                          : null}
                      </P>
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {transaction && transaction.merchant_account_number
                          ? transaction.merchant_account_number
                          : transaction && transaction.user_account_number
                          ? transaction.user_account_number
                          : null}
                      </P>
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {transaction && transaction.merchant_account_name
                          ? transaction.merchant_account_name
                          : transaction && transaction.user_account_name
                          ? transaction.user_account_name
                          : null}
                      </P>
                    </div>
                  </div>
                </div>

                <div className="flex px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        amount
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    <P fontSize="16px" color={"#00AAAF"} fontWeight="500">
                      {transaction.coin} {transaction.amount.toLocaleString()}
                    </P>
                  </div>
                </div>

                {transaction.type === "withdrawal" && (
                  <div className="flex px-6 pt-6 pb-4 border-bottom">
                    <div className="w-4/12">
                      <div className="">
                        <SubHeader
                          fontSize="12px"
                          color={"#7C8191"}
                          fontWeight="500"
                        >
                          fee
                        </SubHeader>
                      </div>
                    </div>

                    <div className="w-8/12">
                      <P fontSize="16px" color={"#00AAAF"} fontWeight="500">
                        {transaction.coin}{" "}
                        {transaction.fee?.toLocaleString() || 0}
                      </P>
                    </div>
                  </div>
                )}

                <div className="flex px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        METHOD
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    <P fontSize="16px" color={"#00AAAF"} fontWeight="500">
                      {transaction.coin} {transaction.transaction_method}
                    </P>
                  </div>
                </div>

                <div className="flex px-6 pt-6 pb-4 border-bottom">
                  <div className="w-4/12">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        id
                      </SubHeader>
                    </div>
                  </div>

                  <div className="w-8/12">
                    <div className="mb-1">
                      <P fontSize="16px" color={"#374072"} fontWeight="700">
                        {/* {transaction.account_id} */}
                        {transaction.reference}
                      </P>
                    </div>

                    <div className="my-1">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {new Date(transaction.created_at).toDateString()}
                      </P>
                    </div>

                    <div className="mt-1">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {new Date(transaction.created_at).toLocaleTimeString()}
                      </P>
                    </div>
                  </div>
                </div>

                {user.role === "super-admin" ? (
                  <div className="mx-0.5 my-0.5 pr-6 Actions">
                    <div className="flex">
                      <div className="mr-2">
                        <DefaultButton
                          small
                          p={"7px 19px 7px 19px"}
                          bg={"#24B57A"}
                          color={"#FFF"}
                          border={"none"}
                          opacity={
                            transaction.status === "completed" ||
                            transaction.status === "cancelled" ||
                            transaction.status === "rejected" ||
                            transaction.status === "processing"
                              ? "0.1"
                              : "1"
                          }
                          disabled={
                            transaction.status === "completed" ||
                            transaction.status === "cancelled" ||
                            transaction.status === "rejected" ||
                            transaction.status === "processing"
                              ? true
                              : false
                          }
                          onClick={() => ApproveTransaction(transaction)}
                        >
                          {transaction.type === "deposit"
                            ? "Approve"
                            : transaction.type === "withdrawal"
                            ? transaction.status === "review"
                              ? "Approve"
                              : transaction.status === "failed"
                              ? "Retry"
                              : "Process"
                            : null}
                        </DefaultButton>
                      </div>

                      <div className="ml-1">
                        <DefaultButton
                          small
                          p={"7px 19px 7px 19px"}
                          bg={"#FBE5E5"}
                          color={"#D60000"}
                          border={"none"}
                          opacity={
                            transaction.status === "completed" ||
                            transaction.status === "cancelled" ||
                            transaction.status === "rejected" ||
                            transaction.status === "processing"
                              ? "0.1"
                              : "1"
                          }
                          disabled={
                            transaction.status === "completed" ||
                            transaction.status === "cancelled" ||
                            transaction.status === "rejected" ||
                            transaction.status === "processing"
                              ? true
                              : false
                          }
                          onClick={() => {
                            RejectTransaction(transaction);
                          }}
                        >
                          {transaction.type === "withdrawal" &&
                          (transaction.status === "failed" ||
                            transaction.status === "refunded")
                            ? "Resolve"
                            : "Reject"}
                        </DefaultButton>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </TransactionsModalStyle>
          ) : (
            <P fontSize="13px" color={"#374072"} fontWeight="500">
              Loading.....
            </P>
          )}
        </Modal>
      </div>
    );
  }
);

export const TransactionApprovedModal = ({ width, border, callback }) => {
  return (
    <div>
      <Modal width={width} bg={"#005674"} border={border} callback={callback}>
        <TransactionsModalStyle h={"441px"}>
          <div className="Transaction-Approved flex items-center justify-center">
            <div>
              <div className="my-12">
                <img src={ic_appr} alt="text" width="93" height="93" />
              </div>

              <div className="">
                <H6 color={"#FFFFFF"} className="text-center" fontWeight="700">
                  Approved!
                </H6>
              </div>
            </div>
          </div>
        </TransactionsModalStyle>
      </Modal>
    </div>
  );
};

export const TransactionRejectedModal = ({ width, border, callback }) => {
  return (
    <div>
      <Modal width={width} bg={"#005674"} border={border} callback={callback}>
        <TransactionsModalStyle h={"441px"}>
          <div className="Transaction-Approved flex items-center justify-center">
            <div>
              <div className="my-12">
                <img src={ic_appr} alt="text" width="93" height="93" />
              </div>

              <div className="">
                <H6 color={"#FFFFFF"} className="text-center" fontWeight="700">
                  Rejected!
                </H6>
              </div>
            </div>
          </div>
        </TransactionsModalStyle>
      </Modal>
    </div>
  );
};

export const SelectMerchantWithdrawalModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    setopenApprovedModal,
    closeModal,
    transaction,
    approveWithdrawalAction,
    merchantsList,
  }) => {
    const [state, setstate] = useState({
      merchantId: "",
    });

    return (
      <>
        <Modal width={width} callback={closeModal}>
          <TransactionsModalStyle>
            <div className="-mx-8">
              <div className="flex items-center px-6 py-3">
                <div>
                  <img
                    src={
                      transaction.type.toLowerCase() === "withdrawal"
                        ? ic_sent_icon
                        : transaction.type.toLowerCase() === "deposit"
                        ? ic_receive_icon
                        : ""
                    }
                    alt="img"
                  />
                </div>
                &nbsp;
                <div>
                  <H6
                    color={"#112E46"}
                    className="text-center"
                    fontWeight="700"
                  >
                    {transaction.type === "deposit"
                      ? "Deposit"
                      : transaction.type === "withdrawal"
                      ? "Withdraw"
                      : null}
                  </H6>
                </div>
              </div>

              <div className="px-6 pt-6 pb-4 border-bottom">
                <DropdownStyle
                  bgImg={caret_down}
                  bgColor={"#fff"}
                  color={"#374072"}
                  p={"11px 16px 10px 16px"}
                  border={"1px solid #fff"}
                  borderRadius={"4px"}
                  onChange={(e) => {
                    setstate({ ...state, merchantId: e.target.value });
                  }}
                >
                  <OptionStyle
                    bgImg={caret_down}
                    bgColor={"fff"}
                    color={"#374072"}
                    name="bank_account_id"
                    value={null}
                    selected={true}
                    disabled="disabled"
                    boxShadow={"0px 1px 4px #3F3F4419"}
                  >
                    --Select Merchant For Withdrawal
                  </OptionStyle>
                  {merchantsList &&
                    merchantsList.map((curr, idx, arr) => {
                      return (
                        <OptionStyle
                          key={idx}
                          bgImg={caret_down}
                          bgColor={"fff"}
                          color={"#374072"}
                          name="bank_account_id"
                          value={curr.account_id}
                          boxShadow={"0px 1px 4px #3F3F4419"}
                        >
                          {curr.username}
                        </OptionStyle>
                      );
                    })}
                </DropdownStyle>
              </div>

              <div className="mx-0.5 my-0.5 pr-6 Actions">
                <div className="flex">
                  <div className="py-4 pr-6">
                    <DefaultButton
                      p={"5px 16px 5px 16px"}
                      bg={"#24B57A"}
                      color={"#FFF"}
                      onClick={() => {
                        approveWithdrawalAction &&
                          approveWithdrawalAction(
                            transaction.id,
                            state,
                            (response) => {
                              closeModal();
                              setopenApprovedModal(true);
                            }
                          );
                      }}
                    >
                      Approve
                    </DefaultButton>
                  </div>

                  <div className="py-4">
                    <DefaultButton
                      p={"5px 16px 5px 16px"}
                      bg={"#FBE5E5"}
                      color={"#D60000"}
                      onClick={closeModal}
                    >
                      Cancel
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </div>
          </TransactionsModalStyle>
        </Modal>
      </>
    );
  }
);

export const ConfirmTransactionModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    border,
    closeModalCallback,
    transaction,
    approveDepositsAction,
    approveWithdrawalAction,
    rejectDepositsAction,
    rejectWithdrawalAction,
    retryDepositAction,
  }) => {
    const approvedTransaction = (curr) => {
      if (curr.transactionType === "Deposit") {
        if (curr.status === "revie") {
          retryDepositAction(curr.id, (response) => {
            // setopenApprovedModal(true);
          });
          return;
        }
        approveDepositsAction(curr.id, (response) => {
          // setopenApprovedModal(true);
        });
      } else if (curr.transactionType === "Withdrawal") {
        approveWithdrawalAction(curr.id, null, (response) => {
          // setopenApprovedModal(true);
        });
      }
    };
    return (
      <Modal width={width} callback={closeModalCallback}>
        <div className="flex items-center justify-center">
          <div>
            <div className="pt-4">
              <H1 fontSize="16px" fontWeight="700" color={"#7A809B"}>
                Do you want to Accept this transaction ?
                {/* {curr.transactionType} */}
              </H1>
            </div>

            <div className="flex items-center justify-center">
              <div className="py-4 pr-6">
                <DefaultButton
                  bg={"#112E46"}
                  color={"#fff"}
                  p={"8px 16px"}
                  onClick={() => {
                    approvedTransaction(null);
                  }}
                >
                  Yes
                </DefaultButton>
              </div>

              <div className="py-4">
                <DefaultButton
                  bg={"#FF6A6A"}
                  color={"#fff"}
                  p={"8px 16px"}
                  onClick={closeModalCallback}
                >
                  No
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
