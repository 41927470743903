import styled from "styled-components";
import arrow from "../../../../assets/images/ic-right-arrow.svg";

export const Div = styled.div`

    .backTo{
        text-align: left;
        font-size: 14px;
        font-weight: 500 !important;
        letter-spacing: 0px;
        color: #0087B5;
        opacity: 1;
        position: relative;
        display: flex;

        &::before{
            content: url(${arrow});
            width: 11px;
            height: 8px;
            padding-right: 8px;
            color: #2C3149 !important;
            display: block;
            transform: rotateY(-180deg);
        }

        &:hover{
            text-decoration: underline;
        }
    }
}

`;
