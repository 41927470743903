import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { LoginBase } from "./Login/Login.styled";
import skye_logo from "../../assets/skye-logo-white.svg";
import flitbase_logo_center from "../../assets/images/flitbase-alt-center.svg";
import { H6, P } from "../../UI toolkit/Text/Text";

const PasswordRecieved = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Password Recieved</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <LoginBase>
          <div className="login-container">
            <div className="logo">
              <img src={skye_logo} alt="skye_logo" />
            </div>

            <div className="login-form px-6 pt-10 pb-11">
              <H6
                color={"#112E46"}
                className="px-6 mb-8 text-center"
                fontWeight="700"
              >
                {" "}
                Request Recieved!
              </H6>

              <P color={"#646A86"} fontSize="16px" fontWeight="700">
                Your request has been received successfully and will be
                initiated!
              </P>
            </div>

            <div className="flitbase_logo pt-20">
              <img src={flitbase_logo_center} alt="skye_logo" className="" />
            </div>
          </div>
        </LoginBase>
      </div>
    </>
  );
};

export default PasswordRecieved;
