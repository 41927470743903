import React, { useEffect, useState } from "react";
import { Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { ToggleButton } from "../../../../UI toolkit/Input/Input";
import { P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder } from "../../../Units/CardTitle.styled";
import caret_down from "../../../../assets/images/caret-down1.svg";
import { connect } from "react-redux";
import {
  changeAdminSettingsAction,
  changeDepositPlatformSettingsAction,
  getDepositTypesAction,
} from "../../../../store/Actions/SettingsActions";
import { withTheme } from "styled-components";

const Transaction = ({
  changeAdminSettingsAction,
  changeDepositPlatformSettingsAction,
  deposit_types,
  getDepositTypesAction,
  admin_settings,
  theme,
}) => {
  const depositTypesKey = {
    "bank-trasfer": "Bank Transfer",
    voucher: "Voucher",
    p2p: "P2P",
  };

  const tabs = [
    { text: "Coin profile", value: "coinprofile" },
    //active completed stopped
    { text: "Bani", value: "bani" },
  ];

  const tabsKey = {
    coinprofile: "Coin profile",
    bani: "Bani",
  };

  const [state, setstate] = useState({
    key: null,
    value: null,
  });
  const [activeWithdrawalService, setActiveWithdrawalService] = useState(null);

  const withdrawalServiceKey = "WITHDRAWAL_SERVICE";
  const handleUpdate = () => {
    changeAdminSettingsAction(
      { key: withdrawalServiceKey, value: activeWithdrawalService },
      (response) => {}
    );
  };

  useEffect(() => {
    if (deposit_types === null) {
      getDepositTypesAction();
    }
  }, []);

  useEffect(() => {
    console.log(state);
    if (state.key !== null && state.value !== null) {
      changeDepositPlatformSettingsAction(state, (response) => {});
    }
  }, [state, changeDepositPlatformSettingsAction]);
  return (
    <div>
      <Card>
        <CardHolder>
          <div className="flex items-center">
            <div className="w-10/12">
              <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                Deposit
              </P>
            </div>
          </div>
        </CardHolder>

        <div className="flex py-8 justify-between">
          {deposit_types?.map((item, index) => {
            return (
              <div className="w-full mr-3">
                <Card>
                  <div className="flex py-8 justify-between px-3">
                    <h2 classNamme="text-xll text-primaryBlack">
                      {depositTypesKey[item?.name]}
                    </h2>
                    <ToggleButton
                      toggleId={item.name}
                      onChange={(e) => {
                        console.log(e.target.checked);
                        setstate({
                          ...state,
                          key: item?.name,
                          value: e.target.checked,
                        });
                      }}
                      checked={
                        item?.is_active === true
                          ? true
                          : item?.is_active === false
                          ? false
                          : null
                      }
                    />
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </Card>
      <Card>
        <CardHolder>
          <div className="flex items-center">
            <div className="w-10/12">
              <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                Withdrawal
              </P>
            </div>
          </div>
        </CardHolder>

        <div className="mt-10">
          <div className="flex">
            <div className="mr-3 w-40">
              <Select
                bgImg={caret_down}
                bgColor={theme.colors.bg}
                color={theme.colors.color}
                border={"1px solid #fff"}
                borderRadius={"4px"}
                p={"5px 15px"}
                data={tabs}
                defaultValue={`-- ${
                  tabsKey[admin_settings[withdrawalServiceKey]]
                } --`}
                properyToShow={"text"}
                hoverBg={"#FFF"}
                hoverColor={"#112E46"}
                fontSize={"16px"}
                callback={(option, idx, arr) => {
                  console.log(option);
                  setActiveWithdrawalService(option.value);
                }}
              />
            </div>
            <button
              style={{ width: "100px", height: "33px" }}
              type="button"
              className="text-xxs text-primary bg-primaryLight"
              onClick={handleUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deposit_types: state.settings.deposit_types,
    admin_settings: state.settings.admin_settings,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeDepositPlatformSettingsAction: (state, successCallback) =>
      dispatch(changeDepositPlatformSettingsAction(state, successCallback)),
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
    getDepositTypesAction: () => dispatch(getDepositTypesAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Transaction));
