/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  transactionAction,
  getSendAndRecieveAction,
  getTradesAction,
  getSentTransfersAction,
} from "../../../../../store/Actions/TransactionActions";
import { TableTab, TableTabStyle } from "../../../../Units/TabsAndLinkStyled";
import DashboardHeader from "../../DashboardHeader";
import { withTheme } from "styled-components";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import ActivitiesTableTab from "../ActivitiesTableTab";
const SendAndRecieve = lazy(() => import("./SendAndRecieve"));

const Activities = ({
  transactionsList,
  trades,
  transfers,
  loading,
  theme,
  currencies,
  transfer_filters,
  prices,
  activeTab,
  getTradesAction,
  overviewAction,
  page,
  activeCurrency,
  sentTransfersAction,
}) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState(3);

  const links = [
    { text: "Trades", link: "trades" },
    {
      text: "Send/Receive",
      link: "transfers",
      type: "all",
      status: "not_pending",
    },
    { text: "Incoming", link: "receive", type: "receive", status: "pending" },
    {
      text: "Pending Transfers",
      link: "send",
      type: "send",
      status: "pending",
    },
  ];

  useEffect(() => {
    sentTransfersAction();
  }, [sentTransfersAction, page, activeCurrency]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Send</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <Suspense
        fallback={
          <div
            className="flex items-center justify-center"
            style={{ height: "100vh" }}
          >
            <h1>Loading...</h1>
          </div>
        }
      >
        <div>
          <DashboardHeader>Activities</DashboardHeader>

          {loading ? (
            <div
              className="flex items-center justify-center"
              style={{ height: "100vh" }}
            >
              <h1>Loading...</h1>
            </div>
          ) : (
            <div>
              <div className="pt-0">
                <div className="flex flex-wrap justify-between overflow-hidden w-full">
                  <div>
                    <ActivitiesTableTab
                      links={links}
                      activeLink1={activeLink1}
                    />
                  </div>

                  <div className="flex justify-end">
                    <DefaultButton
                      small
                      p={"8px 16px"}
                      color={theme.colors.color}
                      bg={theme.colors.bg}
                    >
                      <Link to={"/dashboard/download-activities"}>
                        Download Activities
                      </Link>
                    </DefaultButton>
                  </div>
                </div>

                <div className="">
                  <SendAndRecieve
                    theme={theme}
                    activities={transfers ? transfers : null}
                    currencies={currencies ? currencies : null}
                    prices={prices ? prices : null}
                    getSendAndRecieveAction={sentTransfersAction}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    transfers: state.transactions.sentTransfers,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    page: state.transactions.sentTransfersPage,
    activeCurrency: state.transactions.sentTransfersActiveCurrency,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    sentTransfersAction: (query) => dispatch(getSentTransfersAction(query)),
    // overviewAction: () => dispatch(overviewAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(Activities)));

// eslint-disable-next-line no-lone-blocks
{
  /* <TableTab
mobileWidth={"25%"}
margin
tabs={links} 
activeTab={activeLink1} 
handleTab={handleActiveLink1}
p={"16px 0px"}
boxShadow={"0px"}
borderRadius={"0px"}
activeBg={"#FAFBFD"}
activeColor={"#005674"}
color={"#646A86"}
activeOutline={"none"}
activeOutlineBottom={"2px solid #005674"}
tailwindpad
callback={(active)=>{
    console.log(active)
}}
/> */
}
