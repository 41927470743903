import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Activities from "../components/pages/Dashboard/Activities/TradeActivities/Activities";
import DashboardIndex from "../components/pages/Dashboard/DashboardIndex/DashboardIndex";
import AddMerchant from "../components/pages/Dashboard/Merchants/AddMerchant";
import Merchants from "../components/pages/Dashboard/Merchants/Merchants";
import Messages from "../components/pages/Dashboard/Messages/Messages";
import NewMessage from "../components/pages/Dashboard/Messages/NewMessage";
import SendRecieveindex from "../components/pages/Dashboard/Send-RecieveCrypto/SendRecieveindex";
import SuccessfullySent from "../components/pages/Dashboard/Send-RecieveCrypto/SuccessfullySent";
import Settings from "../components/pages/Dashboard/Settings/Settings";
import Transactions from "../components/pages/Dashboard/Transactions/Transactions";
import Balance from "../components/pages/Dashboard/Users/Balance";
import SingleUser from "../components/pages/Dashboard/Users/SingleUser";
import Users from "../components/pages/Dashboard/Users/Users";
import Verification from "../components/pages/Dashboard/Verification/Verification";
import Wallets from "../components/pages/Dashboard/Wallet/Wallet";
import NotFound from "../components/pages/ErrorPage/NotFound";
import Login from "../components/pages/Login/Login";
import PasswordRecieved from "../components/pages/passwordRecieved";
import ResetPassword from "../components/pages/ResetPassword/ResetPassword";
import ProtectedRoute from "./ProtectedRoute";
import OverviewReportForAudit from "../components/pages/Dashboard/OverviewReportForAudit";
import NewAnnouncement from "../components/pages/Dashboard/Announcements/NewAnnouncement";
import Announcements from "../components/pages/Dashboard/Announcements";
import Savings from "../components/pages/Dashboard/Savings";
import VirtualCards from "../components/pages/Dashboard/VirtualCards";
import TransferActivities from "../components/pages/Dashboard/Activities/TransferActivities/index";
import TransferReceiveActivities from "../components/pages/Dashboard/Activities/PendingReceivedTransfers/index";
import TransferSentActivities from "../components/pages/Dashboard/Activities/PendingSentTransfers/index";
import CardTransactions from "../components/pages/Dashboard/Users/UsersCards/CardTransactions";

const AllRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/resetpassword" render={() => <ResetPassword />} />
      <Route
        exact
        path="/requestProcessed"
        render={() => <PasswordRecieved />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/overview"
        component={<DashboardIndex />}
      />
      <ProtectedRoute exact path="/dashboard/wallet" component={<Wallets />} />
      <ProtectedRoute
        exact
        path="/dashboard/transactions"
        component={<Transactions />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/activities/trades"
        component={<Activities />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/activities/transfers"
        component={<TransferActivities />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/activities/receive"
        component={<TransferReceiveActivities />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/activities/send"
        component={<TransferSentActivities />}
      />
      <ProtectedRoute exact path="/dashboard/users" component={<Users />} />
      <ProtectedRoute
        exact
        path="/dashboard/users/:id"
        component={<SingleUser />}
      />

      <ProtectedRoute
        exact
        path="/dashboard/users/:id/balance"
        component={<Balance />}
      />

      <ProtectedRoute
        exact
        path="/dashboard/settings"
        component={<Settings />}
      />

      <ProtectedRoute
        exact
        path="/dashboard/crypto-Successfully-sent"
        component={<SuccessfullySent />}
      />

      <ProtectedRoute
        exact
        path="/dashboard/send-and-recieve"
        component={<SendRecieveindex />}
      />

      <ProtectedRoute
        exact
        path="/dashboard/verifications"
        component={<Verification />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/merchants"
        component={<Merchants />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/merchants/+"
        component={<AddMerchant />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/messages"
        component={<Messages />}
      />
      <Route exact path="/dashboard/messages/+" render={() => <NewMessage />} />
      <ProtectedRoute
        exact
        path="/dashboard/announcements"
        component={<Announcements />}
      />
      <Route
        exact
        path="/dashboard/announcements/+"
        render={() => <NewAnnouncement />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/download-activities"
        component={<OverviewReportForAudit />}
      />
      <ProtectedRoute exact path="/dashboard/savings" component={<Savings />} />
      <ProtectedRoute
        exact
        path="/dashboard/cards"
        component={<VirtualCards />}
      />
      <ProtectedRoute
        exact
        path="/dashboard/users/:id/card_transactions"
        component={<CardTransactions />}
      />
      <Route render={() => <NotFound />} status={404} />
    </Switch>
  );
};

export default AllRoutes;
