import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { H5, SubHeader } from "../../../../../UI toolkit/Text/Text";
import { Card } from "../../../../Units/Card.styled";
import {
  CardHolder,
  CardTitle,
  CardTitleEllipse,
} from "../../../../Units/CardTitle.styled";
import ic_usd from "../../../../../assets/images/ic-usd.svg";
import { connect } from "react-redux";
import config from "../../../../../config";
import { getUsersSavingsWalletStats } from "../../../../../store/Actions/SavingsActions";

const TotalCash = ({ value, walletStats, getWalletsStatsAction }) => {
  useEffect(() => {
    getWalletsStatsAction();
  }, [ getWalletsStatsAction]);

  return (
    <div className="">
      <div className="mb-4">
        <Card>
          <CardHolder>
            <div className="flex items-center">
              <div className="w-10/12">
                <CardTitle img={ic_usd} text={"Cash"} />
              </div>
              <div className="w-2/12 flex justify-end">
                <CardTitleEllipse />
              </div>
            </div>
          </CardHolder>
          <div className="px-4">
            <div className="py-6">
              <SubHeader color={"#7C8191"} fontWeight="500">
                USERS SAVINGS WALLET BALANCE
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  USDT{" "}
                  {walletStats
                    ? walletStats.totalUsersSavingsWalletsBalance.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card>
          <CardHolder
            theme={{
              colors: {
                bg: "#2FB47C",
              },
            }}
          >
            <div className="flex items-center">
              <div className="w-10/12">
                <h1 className="text-[14px] text-white"> Flexible Savings</h1>
              </div>
              <div className="w-2/12 flex justify-end">
                <CardTitleEllipse />
              </div>
            </div>
          </CardHolder>
          <div className="px-4">
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                TOTAL FLEXIBLE COUNT
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalFlexibleSavingsCount.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                ONGOING
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalOngoingFlexibleCount.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                BROKEN
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalBrokenFlexibleSavingsCount.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                COMPLETED
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalCompletedFlexibleSavings.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card>
          <CardHolder
            theme={{
              colors: {
                bg: "#FFBE40",
              },
            }}
          >
            <div className="flex items-center">
              <div className="w-10/12">
                <h1 className="text-[14px] text-white"> Fixed Savings</h1>
              </div>
              <div className="w-2/12 flex justify-end">
                <CardTitleEllipse />
              </div>
            </div>
          </CardHolder>
          <div className="px-4">
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                TOTAL FIXED COUNT
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalFixedSavingsCount.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                ONGOING
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalOngoingFixedSavingsCount.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                CLAIMED INTEREST
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalClaimedFixedInterest.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                UNCLAIMED INTEREST
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalUnclaimedFixedInterest.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
            <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
              <SubHeader color={"#7C8191"} fontWeight="500">
                COMPLETED
              </SubHeader>
              <div className="flex pt-4">
                <H5 color={"#112E46"} fontWeight="700" className="pr-4">
                  {walletStats
                    ? walletStats.totalCompletedFixedSavingsCount.toLocaleString()
                    : 0}
                </H5>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    walletStats: state.savings.walletStats,
    loading: state.savings.loading,
    error: state.savings.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getWalletsStatsAction: () => dispatch(getUsersSavingsWalletStats()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalCash);
