import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, withRouter, useLocation } from "react-router-dom";
import { LoginBase } from "./Login.styled";
import flitbase_logo_center from "../../../assets/images/flitbase-alt-center.svg";
import { H6, P } from "../../../UI toolkit/Text/Text";
import { Input } from "../../../UI toolkit/Input/Input";
import { DefaultButton } from "../../../UI toolkit/Button/Button";
import { connect } from "react-redux";
import { LoginAuthAction } from "../../../store/Actions/AuthActions";
import { withTheme } from "styled-components";

const Login = ({ history, LoginAdmin, user, token, logo, theme }) => {
  const location = useLocation();

  const [loading, setloading] = useState(false);
  const [state, setstate] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    console.log("hello");
    e.preventDefault();
    setloading(true);
    !token &&
      LoginAdmin(state, () => {
        setloading(false);
      });
  };

  if (token) {
    return <Redirect to="/dashboard/overview" />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <LoginBase>
        <div className="login-container">
          <div className="logo">
            <img
              src={theme.colors.logo}
              alt="app_logo"
              className="h-10 w-auto"
            />
          </div>

          <form className="login-form px-6 py-10" onSubmit={handleSubmit}>
            <H6 color={"#112E46"} className="mb-8 text-center" fontWeight="700">
              Login
            </H6>

            <div className="w-full mb-6">
              <Input
                placeholder="Email address"
                type="text"
                name="email"
                onChange={handleChange}
                value={state.email}
                required
              />
            </div>

            <div className="w-full mb-2">
              <Input
                placeholder="password"
                type="password"
                name="password"
                onChange={handleChange}
                value={state.password}
                required
              />
            </div>

            <P
              color={"#005674"}
              className="text-center"
              fontSize="13px"
              fontWeight="500"
            >
              <Link to="/forgotPassword">Forgot password?</Link>
            </P>

            <div className="w-full pt-10">
              <DefaultButton
                small
                p={"7px 19px 7px 19px"}
                // bg={"#005674"}
                bg={theme.colors.bg}
                color={"#FFF"}
                width={"100%"}
              >
                {loading ? "loading..." : "Log in"}
              </DefaultButton>
            </div>
          </form>

          <div className="flitbase_logo pt-10">
            <img src={flitbase_logo_center} alt="flitbase_logo" className="" />
          </div>
        </div>
      </LoginBase>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    logo: state.UI.logo,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    LoginAdmin: (state, callback) => dispatch(LoginAuthAction(state, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(Login)));
