import React, { useState, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import {
  DefaultButton,
  OutlineButton,
} from "../../../../UI toolkit/Button/Button";
import { SearchInput, ToggleButton } from "../../../../UI toolkit/Input/Input";
import { Card } from "../../../Units/Card.styled";
import {
  TabHeader,
  TableTab,
  TableTabStyle,
} from "../../../Units/TabsAndLinkStyled";
import { UsersTable } from "./UsersTables";
import search_icon from "../../../../assets/images/search.svg";
import caret_down from "../../../../assets/images/caret-down1.svg";
import { Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { connect } from "react-redux";
import {
  exportUsersDataAction,
  getUsers,
  restoreUser,
  updateUsersActiveTab,
  updateUsersPage,
  updateUsersSearch,
} from "../../../../store/Actions/UsersAction";
import DashboardHeader from "../DashboardHeader";
import { getLevel } from "../../../../utils/specialFunctions";
import { withTheme } from "styled-components";
import lodash from "lodash";
import { P } from "../../../../UI toolkit/Text/Text";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import cancelIcon from "../../../../assets/cancel-icon.svg";
import { ExportUsersModal } from "./UsersModal";

const Users = ({
  getUsers,
  user,
  totalUsers,
  page,
  listOfUsers,
  loading,
  theme,
  restoreUser,
  updateUsersSearch,
  activeTab,
  totalPages,
  keyword,
  updateActiveTab,
  updatePage,
  exportUsers,
  downloadUrl,
}) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState();
  const [openExportModal, setOpenExportModal] = useState(false);
  const [fieldsToExport, setFieldsToExport] = useState([
    "first_name",
    "last_name",
    "email",
  ]);
  // const [keyword, setkeyword] = useState(null);
  // const [page, setpage] = useState(1);
  const hasPrevious = page > 1;
  const hasNext = true;

  const currentPage = page[activeTab];
  const totalPage = totalPages[activeTab];
  const links1 = [
    { text: "All", value: "all" },
    { text: "Level 0", value: "0" },
    { text: "Level 1", value: "1" },
    { text: "Level 2", value: "2" },
    { text: "Level 3", value: "3" },
  ];

  const handleTabChange = (level) => {
    updateActiveTab(level);
  };

  const handleNextPage = () => {
    // console.log(currentPage, totalPage);
    if (currentPage < totalPage - 1) {
      updatePage({
        level: activeTab,
        value: currentPage + 1,
      });
    }
  };

  const prevButtonDisabled = () => {
    return !(currentPage > 0);
  };

  const nextButtonDisabled = () => {
    return !(currentPage < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      updatePage({
        level: activeTab,
        value: currentPage - 1,
      });
    }
  };

  useLayoutEffect(() => {
    getUsers();
  }, [page, keyword, restoreUser, activeTab]);

  const downloadAsCsv = () => {
    //extract data from list of users with only the fields in the fieldsToExport state
    const dataToExport = listOfUsers.map((user) => {
      const userObj = {};
      fieldsToExport.forEach((field) => {
        userObj[field] = user[field];
      });
      return userObj;
    });

    console.log(dataToExport);
    const csv = dataToExport.map((row) => {
      return Object.values(row).join(",");
    });
    const csvData = csv.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "users.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Users</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <div>
          <DashboardHeader>
            {/* Users ({totalUsers ? totalUsers : 0}) */}
          </DashboardHeader>

          <div className="lg:flex lg:justify-between">
            {/* <pre>{JSON.stringify(fieldsToExport, null, 2)} hhh</pre> */}
            {/* <h1>dhdhdh {downloadUrl}</h1> */}
            <div className="flex pb-6 w-full justify-between">
              <form className="lg:w-6/12 w-9/12 pr-5 ">
                <SearchInput
                  placeholder="Search users"
                  search_icon={search_icon}
                  bg={"#FFFFFF"}
                  color={"#646A86"}
                  p={"8px 16px"}
                  w={"100%"}
                  callback={(e) => {
                    lodash.debounce(function () {
                      updateUsersSearch(e.target.value);
                      updatePage({
                        level: activeTab,
                        value: 0,
                      });
                      // setkeyword(e.target.value);
                      // setpage(1);
                    }, 1500)();
                    // setTimeout(() => {
                    //   setkeyword(e.target.value);
                    //   setpage(1);
                    // }, 1000);
                  }}
                />
              </form>
              <button
                className="bg-primaryBg text-white px-8"
                onClick={() => {
                  console.log("opening modal");
                  setOpenExportModal(true);
                }}
              >
                EXPORT
              </button>
              {/* <div className="lg:w-4/12 w-3/12">
                <DefaultButton
                  bg={theme.colors.bg}
                  color={theme.colors.color}
                  p={"8px 16px"}
                >
                  <CSVLink data={users ? users: null}>
                  Download Users
                  </CSVLink>
                </DefaultButton>
              </div> */}
            </div>

            {/* <div className="py-5 lg:py-0 flex justify-end">
              <Link to="/dashboard/messages">
                <OutlineButton
                  bg={"#FFFFFF"}
                  border={"1px solid #005674"}
                  color={"#005674"}
                  p={"8px 16px"}
                  boxShadow={"0px 1px 3px #00000019"}
                >
                  Messages
                </OutlineButton>
              </Link>
            </div> */}
          </div>

          <Card>
            <TabHeader>
              <div className="lg:flex">
                <div className="lg:flex lg:w-10/12 lg:items-center">
                  <div className="lg:flex">
                    <TableTab
                      tabs={links1}
                      activeTab={activeLink1}
                      handleTab={handleActiveLink1}
                      handleDispatch={handleTabChange}
                      callback={(active) => {
                        // console.log(active)
                      }}
                    />
                    <TableTabStyle color={"#FBB56C"}>
                      <Link to="/dashboard/merchants">Merchants</Link>
                    </TableTabStyle>
                    <TableTabStyle
                      color={"#EB2929"}
                      className={activeLink1 === 5 ? "active" : ""}
                      onClick={() => {
                        handleActiveLink1(5);
                        handleTabChange("deleted");
                      }}
                    >
                      Deleted
                    </TableTabStyle>
                  </div>
                </div>
                <div className="lg:w-2/12 my-3 mx-3 lg:my-1.5 lg:mx-1.5 py-5 lg:py-0">
                  <Select
                    bgImg={caret_down}
                    // bgColor={"#112E46"}
                    //color={"#fff"}
                    bgColor={theme.colors.bg}
                    color={theme.colors.color}
                    border={"1px solid #fff"}
                    borderRadius={"4px"}
                    p={"5px 15px"}
                    data={links1}
                    defaultValue={"-- Select --"}
                    properyToShow={"text"}
                    hoverBg={"#FFF"}
                    hoverColor={"#112E46"}
                    fontSize={"16px"}
                    callback={(option, idx, arr) => {
                      console.log(option, idx, arr);
                      handleActiveLink1(idx);
                      handleTabChange(option.value);
                    }}
                  />
                </div>
              </div>
            </TabHeader>

            <div>
              {loading ? (
                <div
                  className="flex items-center justify-center h-100"
                  style={{ height: "50vh" }}
                >
                  <h1>Loading........</h1>
                </div>
              ) : (
                <div>
                  <UsersTable
                    restoreUser={restoreUser}
                    // users={filterUsersonChange(users, keyword)}
                    users={listOfUsers ? listOfUsers : null}
                  />
                  <div>
                    {/* {!loading && ( */}
                    <div className="flex justify-between gap-2 items-center py-4 w-full">
                      <p className="">
                        {/* Page no */}
                        {/* {pagination.per_page}  */}
                      </p>
                      <div className="flex items-center">
                        <DefaultButton
                          bg={theme.colors.bg}
                          color={theme.colors.color}
                          p={"8px 16px"}
                          onClick={handlePrevPage}
                          disabled={prevButtonDisabled()}
                        >
                          Prev
                        </DefaultButton>
                        <div className="px-4">
                          <P color={"#646A86"} fontSize="16px" fontWeight="700">
                            Page {currentPage + 1} of{" "}
                            {totalPage !== 0 ? totalPage : 1}
                          </P>
                        </div>
                        <DefaultButton
                          bg={theme.colors.bg}
                          color={theme.colors.color}
                          p={"8px 16px"}
                          onClick={handleNextPage}
                          disabled={nextButtonDisabled()}
                        >
                          Next
                        </DefaultButton>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
      {openExportModal ? (
        <ExportUsersModal
          width={"30%"}
          border={"none"}
          closeModalCallback={() => {
            setOpenExportModal(false);
          }}
          yesAction={() => {
            console.log("Exporting users");
            // downloadAsCsv();
            exportUsers({
              required_fields: fieldsToExport,
            });
            setOpenExportModal(false);
          }}
          setFieldsToExport={setFieldsToExport}
          fieldsToExport={fieldsToExport}
        />
      ) : null}
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    listOfUsers: state.users.listOfUsers,
    totalUsers: state.users.totalUsers,
    page: state.users.usersPage,
    loading: state.users.loading,
    activeTab: state.users.activeTab,
    totalPages: state.users.totalUsersPage,
    keyword: state.users.search,
    downloadUrl: state.users.downloadUrl,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (page, keyword) => dispatch(getUsers(page, keyword)),
    updateUsersSearch: (keyword) => dispatch(updateUsersSearch(keyword)),
    restoreUser: (id) => dispatch(restoreUser(id)),
    updateActiveTab: (tab) => dispatch(updateUsersActiveTab(tab)),
    updatePage: (page) => dispatch(updateUsersPage(page)),
    exportUsers: (fields) => dispatch(exportUsersDataAction(fields)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Users));
