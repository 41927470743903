import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AllRoutes from "./Routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBar from "react-redux-loading-bar";
import { ThemeProvider, withTheme } from "styled-components";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Flitaa, SkyeWallet, Fimbre } from "./theme";
import { selectTheme, selectShowChat } from "./store/Selectors/ui-selector";
import config from "./config";

function App({ page_theme, show_chat }) {
  const theme = SkyeWallet;

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
        <LoadingBar className="loading" />
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  page_theme: selectTheme,
  show_chat: selectShowChat,
});

export default connect(mapStateToProps)(withTheme(App));
