import React, { useState } from "react";
import DashboardHeader from "../DashboardHeader";
import { Input } from "../../../../UI toolkit/Input/Input";
import caret_down from "../../../../assets/images/caret-down1.svg";
import { Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { downloadTransactionsAsCSVAction } from "../../../../store/Actions/TransactionActions";
import { customToast } from "../../../../utils/customToast";

const ReportForAudit = ({ theme, downloadTransactionsAsCSVAction }) => {
  const [state, setstate] = useState({
    start: "",
    end: "",
    module: "",
  });

  const tabs = [{ text: "All" }, { text: "Deposits" }, { text: "Withdrawals" }];

  const handleChange = (e) => {
    switch (e.target.type) {
      case "number":
        setstate({
          ...state,
          [e.target.name]: parseFloat(e.target.value),
        });
        break;
      case "checkbox":
        setstate({
          ...state,
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        setstate({
          ...state,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      new Date(state.start) === new Date(state.end) ||
      new Date(state.start) > new Date(state.end)
    )
      customToast.error(
        "start date must not be equal to or greater than end date"
      );
    downloadTransactionsAsCSVAction(state.module, state.start, state.end);
  };

  return (
    <div>
      <DashboardHeader>Download Activities</DashboardHeader>

      <form onSubmit={handleSubmit}>
        <div className="flex">
          <div className="w-6/12 p-3">
            <Input
              placeholder="Start Date"
              type="date"
              name="start"
              required
              // value={state.start}
              onChange={handleChange}
            />
          </div>

          <div className="w-6/12 p-3">
            <Input
              placeholder="End Date"
              type="date"
              name="end"
              required
              // value={state.end}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="md:w-6/12 p-3">
          <Select
            bgImg={caret_down}
            bgColor={theme.colors.bg}
            color={theme.colors.color}
            border={"1px solid #fff"}
            borderRadius={"4px"}
            p={"5px 15px"}
            data={tabs}
            defaultValue={"-- Select --"}
            properyToShow={"text"}
            hoverBg={"#FFF"}
            hoverColor={"#112E46"}
            fontSize={"16px"}
            callback={(option, idx, arr) => {
              console.log(option, idx, arr);
              setstate({
                ...state,
                module: option.text,
              });
            }}
          />
        </div>

        <div className="flex justify-end p-3">
          <DefaultButton
            bg={theme.colors.bg}
            color={theme.colors.color}
            p={"8px 16px"}
            onClick={() => {}}
          >
            submit
          </DefaultButton>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loading: state.transactions.loading,
    error: state.transactions.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    downloadTransactionsAsCSVAction: (module, start, end) =>
      dispatch(downloadTransactionsAsCSVAction(module, start, end)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(ReportForAudit)));
