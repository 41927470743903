import React, { useEffect, useState } from "react";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import {
  DropdownStyle,
  OptionStyle,
} from "../../../../UI toolkit/Dropdown/Dropdown";
import { Input } from "../../../../UI toolkit/Input/Input";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { P } from "../../../../UI toolkit/Text/Text";
import caret_down from "../../../../assets/images/caret-down.svg";
import { connect } from "react-redux";
import {
  createMerchant,
  updateMerchant,
} from "../../../../store/Actions/MerchantsAction";
import { customToast } from "../../../../utils/customToast";
import times_solid from "../../../../assets/images/times-solid.svg";
import { getUser } from "../../../../store/Actions/UsersAction";

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    loading: state.merchants.loading,
    singleUser: state.users.singleUser,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id, successCallback) => dispatch(getUser(id, successCallback)),
    updateMerchant: (merchantId, state, successCallback) =>
      dispatch(updateMerchant(merchantId, state, successCallback)),
    createMerchant: (state, successCallback) =>
      dispatch(createMerchant(state, successCallback)),
  };
};

export const AddMerchantModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    border,
    closeModalCallback,
    merchant,
    createMerchant,
    updateMerchant,
  }) => {
    const [state, setstate] = useState({
      account_id: merchant && merchant.account_id,
      username: "",
      min_amount: "",
      max_amount: "",
      bank_account_id: "",
    });

    const [errors, seterrors] = useState({
      account_id: merchant && merchant.account_id,
      username: "",
      min_amount: "",
      max_amount: "",
    });

    const handleChange = (e) => {
      switch (e.target.type) {
        case "number":
          setstate({
            ...state,
            [e.target.name]: parseFloat(e.target.value),
          });
          break;
        case "checkbox":
          setstate({
            ...state,
            [e.target.name]: e.target.checked,
          });
          break;
        default:
          setstate({
            ...state,
            [e.target.name]: e.target.value,
          });
      }
    };

    const handleSubmit = (e) => {
      console.log(state);
      e.preventDefault();
      if (state.min_amount > state.max_amount) {
        customToast.error(
          "minimum amount is higher than maximum amount. cannot perform this action"
        );
        return;
      }
      createMerchant(state, (response) => {
        console.log("response from api", response);
        closeModalCallback();
      });
    };

    useEffect(() => {
      if (merchant && merchant.id) {
        setstate({
          ...state,
          username:
            merchant && merchant.account && merchant.account.username
              ? merchant.account.username
              : "",
          account_id: merchant.id,
        });
      }
    }, []);

    return (
      <div>
        <Modal width={width} border={border}>
          <div className="-mx-8 px-6 py-4">
            <div className=" flex justify-between">
              <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
                Confirm Merchant
              </P>
              <div className="flex">
                <div>
                  <img
                    src={times_solid}
                    alt="cancel"
                    style={{ height: "15px", width: "15px", cursor: "pointer" }}
                    onClick={() => {
                      closeModalCallback();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div className="mb-1.5">
                <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                  Username
                </P>
              </div>
              <Input
                placeholder="Username"
                type="text"
                name="username"
                required
                value={state.username}
                onChange={handleChange}
              />
            </div>

            <div>
              <div className="mt-6 mb-1.5">
                <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                  Min Amount
                </P>
              </div>
              <Input
                placeholder="Minimum Amount"
                type="number"
                name="min_amount"
                required
                value={state.min_amount}
                onChange={handleChange}
              />
              <div className="mt-2">
                <P color={"#FFDCE5"} fontSize={"12px"}>
                  {errors.min_amount}
                </P>
              </div>
            </div>

            <div>
              <div className="mt-6 mb-1.5">
                <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                  Max Amount
                </P>
              </div>
              <Input
                placeholder="Maximum Amount"
                type="number"
                name="max_amount"
                required
                value={state.max_amount}
                onChange={handleChange}
              />
              <div className="mt-2">
                <P color={"#FFDCE5"} fontSize={"12px"}>
                  {errors.max_amount}
                </P>
              </div>
            </div>

            <div>
              <div className="mt-6 mb-1.5">
                <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                  Bank
                </P>
              </div>

              <DropdownStyle
                bgImg={caret_down}
                bgColor={"#fff"}
                color={"#374072"}
                p={"11px 16px 10px 16px"}
                border={"1px solid #fff"}
                borderRadius={"4px"}
                onChange={(e) => {
                  console.log(e.target.value);
                  setstate({ ...state, bank_account_id: e.target.value });
                }}
              >
                <OptionStyle
                  bgImg={caret_down}
                  bgColor={"fff"}
                  color={"#374072"}
                  name="bank_account_id"
                  value={null}
                  selected={true}
                  disabled="disabled"
                  boxShadow={"0px 1px 4px #3F3F4419"}
                >
                  --Select Your Bank
                </OptionStyle>
                {merchant.bank_accounts &&
                  merchant.bank_accounts.map((curr, idx, arr) => {
                    return (
                      <OptionStyle
                        key={idx}
                        bgImg={caret_down}
                        bgColor={"fff"}
                        color={"#374072"}
                        name="bank_account_id"
                        value={curr.id}
                        boxShadow={"0px 1px 4px #3F3F4419"}
                      >
                        {curr.bank.name}
                      </OptionStyle>
                    );
                  })}
              </DropdownStyle>

              <div className="mt-2">
                <P color={"#FFDCE5"} fontSize={"12px"}>
                  {errors.max_amount}
                </P>
              </div>
            </div>

            <div className="mt-6 mb-4 flex justify-center">
              <DefaultButton
                small
                bg={"#005674"}
                color={"#fff"}
                border={"1px solid #005674"}
                p={"7px 16px"}
                required
                onClick={handleSubmit}
              >
                Add
              </DefaultButton>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export const UpdateMerchantModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    border,
    closeModalCallback,
    merchant,
    updateMerchant,
    singleUser,
    getUser,
  }) => {
    const [show, setshow] = useState(false);

    const [state, setstate] = useState({
      account_id: merchant && merchant.account_id,
      username: "",
      min_amount: "",
      max_amount: "",
      bank_account_id: "",
    });

    const [errors, seterrors] = useState({
      account_id: merchant && merchant.account_id,
      username: "",
      min_amount: "",
      max_amount: "",
    });

    const handleChange = (e) => {
      switch (e.target.type) {
        case "number":
          setstate({
            ...state,
            [e.target.name]: parseFloat(e.target.value),
          });
          break;
        case "checkbox":
          setstate({
            ...state,
            [e.target.name]: e.target.checked,
          });
          break;
        default:
          setstate({
            ...state,
            [e.target.name]: e.target.value,
          });
      }
    };

    const handleSubmit = (e) => {
      console.log(state);
      e.preventDefault();
      if (state.min_amount > state.max_amount) {
        customToast.error(
          "minimum amount is higher than maximum amount. cannot perform this action"
        );
        return;
      }
      updateMerchant(merchant.id, state, (response) => {
        closeModalCallback();
      });
    };

    useEffect(() => {
      console.log(merchant);
      getUser(merchant.account_id, () => {
        setshow(true);
      });
      if (merchant && merchant.id) {
        setstate({
          ...state,
          username: merchant.username,
          account_id: merchant.id,
          min_amount: merchant.min_amount,
          max_amount: merchant.max_amount,
          bank_account_id: merchant.bank_account_id,
        });
      }
    }, []);

    return (
      <div>
        {show === true ? (
          <Modal width={width} border={border}>
            <div className="-mx-8 px-6 py-4">
              <div className=" flex justify-between">
                <P color={"#2E2E2E"} fontSize={"16px"} fontWeight={700}>
                  Confirm Merchant
                </P>
                <div className="flex">
                  <div>
                    <img
                      src={times_solid}
                      alt="cancel"
                      style={{
                        height: "15px",
                        width: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        closeModalCallback();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Username
                  </P>
                </div>
                <Input
                  placeholder="Username"
                  type="text"
                  name="username"
                  required
                  value={state.username}
                  onChange={handleChange}
                />
              </div>

              <div>
                <div className="mt-6 mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Bank
                  </P>
                </div>

                <DropdownStyle
                  bgImg={caret_down}
                  bgColor={"#fff"}
                  color={"#374072"}
                  p={"11px 16px 10px 16px"}
                  border={"1px solid #fff"}
                  borderRadius={"4px"}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setstate({ ...state, bank_account_id: e.target.value });
                  }}
                >
                  <OptionStyle
                    bgImg={caret_down}
                    bgColor={"fff"}
                    color={"#374072"}
                    name="bank_account_id"
                    value={""}
                    selected={true}
                    disabled="disabled"
                    boxShadow={"0px 1px 4px #3F3F4419"}
                  >
                    --Select Your Bank
                  </OptionStyle>
                  {singleUser &&
                    singleUser.bank_accounts.map((curr, idx, arr) => {
                      return (
                        <OptionStyle
                          key={idx}
                          bgImg={caret_down}
                          bgColor={"fff"}
                          color={"#374072"}
                          name="bank_account_id"
                          value={curr.id}
                          boxShadow={"0px 1px 4px #3F3F4419"}
                        >
                          {curr.bank.name}
                        </OptionStyle>
                      );
                    })}
                </DropdownStyle>

                <div className="mt-2">
                  <P color={"#FFDCE5"} fontSize={"12px"}>
                    {errors.max_amount}
                  </P>
                </div>
              </div>

              <div>
                <div className="mt-6 mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Min Amount
                  </P>
                </div>
                <Input
                  placeholder="Minimum Amount"
                  type="number"
                  name="min_amount"
                  required
                  value={state.min_amount}
                  onChange={handleChange}
                />
                <div className="mt-2">
                  <P color={"#FFDCE5"} fontSize={"12px"}>
                    {errors.min_amount}
                  </P>
                </div>
              </div>

              <div>
                <div className="mt-6 mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Max Amount
                  </P>
                </div>
                <Input
                  placeholder="Maximum Amount"
                  type="number"
                  name="max_amount"
                  required
                  value={state.max_amount}
                  onChange={handleChange}
                />
                <div className="mt-2">
                  <P color={"#FFDCE5"} fontSize={"12px"}>
                    {errors.max_amount}
                  </P>
                </div>
              </div>

              <div className="mt-6 mb-4 flex justify-center">
                <DefaultButton
                  small
                  bg={"#005674"}
                  color={"#fff"}
                  border={"1px solid #005674"}
                  p={"7px 16px"}
                  required
                  onClick={handleSubmit}
                >
                  Add
                </DefaultButton>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
);
