import React from "react";
import { Link } from "react-router-dom";
import { H5, SubHeader } from "../../../../UI toolkit/Text/Text";

const DepositsOverview = ({ deposits }) => {
  return (
    <div className="w-6/12">
      <SubHeader color={"#7C8191"} fontWeight="500">
        DEPOSITS
      </SubHeader>
      <div className="flex pt-4">
        <H5 color={"#112E46"} fontWeight="700" className="pr-4">
          ₦ {deposits ? deposits.toLocaleString() : 0}
        </H5>
        <div>{/* <img src="" alt="graph"/> */}</div>
      </div>
      {/* <div className="flex pt-1 pb-4">
                <P fontSize="14px" color={"#7C8191"} fontWeight="500">₦783,670</P>
            </div> */}

      <div className="">
        <Link
          to={{ pathname: "/dashboard/transactions", state: { activeNo: 1 } }}
          className="viewAll"
        >
          View all
        </Link>
      </div>
    </div>
  );
};

export default DepositsOverview;
