import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeAdminSettingsAction } from "../../../../../store/Actions/SettingsActions";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import { Card } from "../../../../Units/Card.styled";
import { CardHolder } from "../../../../Units/CardTitle.styled";
import { AdminTableStyle } from "./../Settings.Styled";
import PlatformSettingRow from "./PlatformSettingRow";

const PlatformControl = ({ changeAdminSettingsAction, admin_settings }) => {
  const platformContent = [
    {
      title: "Withdrawal Fee",
      key: "WITHDRAWAL_FEE",
      currency: "NGN",
    },
    {
      title: "Withdrawal Review Limit",
      key: "WITHDRAWAL_REVIEW_LIMIT",
      currency: "NGN",
    },
    {
      title: "Minimum Buy Amount",
      key: "MINIMUM_BUY_AMOUNT_NGN",
      currency: "NGN",
    },
    {
      title: "Minimum Sell Amount",
      key: "MINIMUM_SELL_AMOUNT_NGN",
      currency: "NGN",
    },
    {
      title: "Minimum Save Amount",
      key: "MIN_SAVE_AMOUNT_USDT",
      currency: "USDT",
    },
    {
      title: "Transfer Review Limit",
      key: "REVIEW_OUT_BOUND_TRANSFERS_MIN_AMOUNT_USD",
      currency: "USDT",
    },
    {
      title: "USD - NGN Buy",
      key: "NGN_USD_BUY_RATE",
      currency: "NGN",
    },
    {
      title: "USD - NGN Sell",
      key: "NGN_USD_SELL_RATE",
      currency: "NGN",
    },
    {
      title: "IOS VERSION NUMBER",
      key: "IOS_APP_VERSION",
    },
    {
      title: "ANDROID VERSION NUMBER",
      key: "ANDROID_APP_VERSION",
    },
  ];

  const [state, setstate] = useState({
    key: null,
    value: null,
  });
  useEffect(() => {
    console.log(state);
    if (state.key !== null && state.value !== null) {
      changeAdminSettingsAction(state, (response) => {});
    }
  }, [state, changeAdminSettingsAction]);

  return (
    <div>
      <Card>
        <CardHolder>
          <div className="flex items-center">
            <div className="w-10/12">
              <div className="grid grid-cols-2">
                <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                  Set control
                </P>
                <P color={"#FFF"} fontSize={"14px"} fontWeight="500" className="ml-14">
                  Units
                </P>
              </div>
            </div>
          </div>
        </CardHolder>

        <div className="">
          <div style={{ overflowX: "auto" }}>
            <AdminTableStyle className="w-full">
              <thead style={{ background: "#E7EAF4" }}>
                <tr className="Table-heading">
                  <td width="33.333%" className="px-4 pt-4 pb-4">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      {" "}
                    </SubHeader>
                  </td>
                  <td width="33.333%" className="px-4 pt-4 pb-4">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        {" "}
                      </SubHeader>
                    </div>
                  </td>
                  <td width="33.333%" className="px-4 pt-4 pb-4">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        {" "}
                      </SubHeader>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {platformContent.map((item, index) => {
                  return (
                    <PlatformSettingRow
                      key={index}
                      item={item}
                      value={admin_settings[item.key]}
                    />
                  );
                })}
              </tbody>
            </AdminTableStyle>
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admin_settings: state.settings.admin_settings,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformControl);
