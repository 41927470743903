import {
  LOADING_UI,
  LOADING_UI_DONE,
  BLUE_WHITE_THEME,
  BLACK_WHITE_THEME,
  SHOW_CHAT,
} from "../Types/Types";

const initState = {
  loading: false,
  theme: "blue-white",
  mobileNav: false,
  show_chat: false,
};

// Reducer handling all accounts
const uiReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_UI:
      return {
        ...state,
        loading: action.loading === true ? true : false,
      };
    case LOADING_UI_DONE:
      return {
        ...state,
        loading: false,
      };
    case "TOGGLE_MOBILE_NAV":
      return {
        ...state,
        mobileNav: action.mobileNav,
      };
    case BLUE_WHITE_THEME:
      return {
        ...state,
        theme: "blue-white",
      };

    case BLACK_WHITE_THEME:
      return {
        ...state,
        theme: "black-white",
      };

    case SHOW_CHAT:
      return {
        ...state,
        show_chat: action.payload,
      };

    default:
      return state;
  }
};

export default uiReducer;
