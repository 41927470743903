import React from "react";
import Overview from "../VirtualCardDashboard/Overview";
import ActivitiesCardTable from "./ActivitiesCardTable";
import TotalCash from "./TotalCash";

const VirtualCardDashBoard = ({ handleActiveTab }) => {
  return (
    <div>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-full overflow-hidden lg:w-8/12 xl:w-8/12">
          <Overview />
          <ActivitiesCardTable handleActiveTab={handleActiveTab} />
        </div>

        <div className="w-full overflow-hidden lg:px-6 xl:px-6 lg:w-4/12 xl:w-4/12">
          <TotalCash />
        </div>
      </div>
    </div>
  );
};

export default VirtualCardDashBoard;
