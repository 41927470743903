import React, { useState, useEffect } from "react";
import Overview from "./Overview";
import TotalCash from "./TotalCash";
import ActivitiesCardTable from "./ActivitiesCardTable";

const OverviewSection = () => {
  const [state, setstate] = useState({
    key: null,
    value: null,
  });
  return (
    <div>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-full overflow-hidden lg:w-8/12 xl:w-8/12">
          <Overview />
          <ActivitiesCardTable />
        </div>

        <div className="w-full overflow-hidden lg:px-6 xl:px-6 lg:w-4/12 xl:w-4/12">
          <TotalCash />
        </div>
      </div>
    </div>
  );
};

export default OverviewSection;
