import React from "react";
import { H5, P } from "../../../../UI toolkit/Text/Text";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { connect } from "react-redux";
import parse from "html-react-parser";

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    trades: state.transactions.trades,
    transfers: state.transactions.transfers,
    transactionsList: state.transactions.transactionsList,
    loading: state.transactions.loading,
    error: state.overview.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    // transactionAction: () => dispatch(transactionAction()),
    // getSendAndRecieveAction: () => dispatch(getSendAndRecieveAction()),
    // getTradesAction: () => dispatch(getTradesAction())
  };
};

export const PreviewModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ closeModal, content }) => {
  return (
    <div>
      <Modal width={"50%"} bg={"#EFF0F3"}>
        <div className="-mx-8 px-6 py-4">
          <div className="flex items-center justify-center mb-12">
            <div className="mx-3">
              <DefaultButton
                small
                p={"8px 16px"}
                color={"#FF6A6A"}
                bg={"#F0E1E4"}
                required
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </DefaultButton>
            </div>

            <div className="mx-3">
              <DefaultButton
                small
                p={"8px 16px"}
                color={"#FFFFFF"}
                bg={"#005674"}
                required
                onClick={() => {
                  closeModal();
                }}
              >
                Send message
              </DefaultButton>
            </div>
          </div>

          {content &&
            parse(
              `<div style="background: #fff; max-width: 640px; margin: auto; border-top: 5px solid #2FB47C; padding: 48px;"> ${content} </div>`
            )}
        </div>
      </Modal>
    </div>
  );
});

// eslint-disable-next-line no-lone-blocks
{
  /* <table width="100%">
    <thead>
        <tr>
            <td style={{paddingBottom: "56px"}}>
                <img src="" alt="[LOGO]"/>
            </td>
        </tr>
        <tr>
            <td style={{paddingBottom: '24px', fontSize: '28px', color: '#374072', fontWeight: "bold"}}>
                Welcome to flitaa!
            </td>      
        </tr>  
        <tr>
            <td style={{paddingBottom: '24px', paddingTop:'24px', fontSize: '20px', color: '#374072', fontWeight: "bold"}}>
                Hi Precious,
            </td>
        </tr>
    </thead>

    <tbody>
        <tr>
        <td style={{paddingBottom: '24px', fontSize: '18px', color: '#646A86'}}>
                <p>
                    We are elated to become part of your cryptocurrency journey at this time. 
                    It is so good of you to join the simplest wallet where you can buy, sell, swap and <span style={{fontWeight: 'bold'}}>earn</span> cryptocurrency.
                </p>
                <p> 
                    Our support team is one click away should you need any help as you journey through #SatoshiLand.
                </p>
            </td>
        </tr>
    </tbody>
</table> */
}
