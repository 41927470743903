import Api from "./api";
import {
  GET_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENTS,
  LOADING_ANNOUNCEMENTS,
  ERROR_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENTS_NAVIGATOR,
} from "../Types/Types";
import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const getAnnouncementAction = (query) => {
  console.log(query);
  const page = query ? query.page : 1;
  const limit = query ? query.limit : 10;
  console.log(page, limit);
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ANNOUNCEMENTS });
    dispatch(showLoading());
    try {
      const response = await Api.getAnnouncements({ page, limit });
      console.log(response, "getAnnouncements");
      dispatch({
        type: GET_ANNOUNCEMENTS,
        announcementsList: response.data.results.rows,
        filters: response.data.filters,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ANNOUNCEMENTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const postAnnouncementAction = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ANNOUNCEMENTS });
    dispatch(showLoading());
    try {
      const response = await Api.postAnnouncement(state);
      console.log(response);
      customToast.success(`Announcement posted successfully`);
      dispatch(getAnnouncementAction());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ANNOUNCEMENTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const publishAnnouncementAction = (announcementId, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ANNOUNCEMENTS });
    dispatch(showLoading());
    try {
      const response = await Api.publishAnnouncement(announcementId);
      console.log(response);
      customToast.success(`Announcement published successfully`);
      dispatch(getAnnouncementAction());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ANNOUNCEMENTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const unpublishAnnouncementAction = (
  announcementId,
  successCallback
) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_ANNOUNCEMENTS });
    dispatch(showLoading());
    try {
      const response = await Api.unpublishAnnouncement(announcementId);
      console.log(response);
      customToast.success(`Announcement unpublished successfully`);
      dispatch(getAnnouncementAction());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_ANNOUNCEMENTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateAnnouncementsNavigator = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ANNOUNCEMENTS_NAVIGATOR, payload });
  };
};
