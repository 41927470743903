import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Card } from "../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../Units/TabsAndLinkStyled";
import DashboardHeader from "../DashboardHeader";
import { Div } from "../Users/Users.styled";
import { MessagesTable } from "./MessagesTable";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { getUsers } from "../../../../store/Actions/UsersAction";
import {
  filterUsersonChange,
  getLevel,
} from "../../../../utils/specialFunctions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";

const Messages = ({ getUsers, user, listOfUsers, loading, theme }) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState(0);

  const links1 = [
    { text: "All Messages" },
    { text: "Sent" },
    { text: "Draft" },
    { text: "Failed" },
  ];

  useEffect(() => {
    !listOfUsers && getUsers();
  }, [listOfUsers, getUsers]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Messages</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Messages</DashboardHeader>

        <Div className="pb-6 flex justify-between items-center">
          <Link to={"/dashboard/users/"} className="backTo">
            &nbsp; Back to Users
          </Link>

          <div>
            <DefaultButton
              small
              p={"8px 16px"}
              color={"#FFFFFF"}
              bg={"#005674"}
              type="number"
              name="setfee"
              value={2}
              required
            >
              <Link to={"/dashboard/messages/+"}>New message</Link>
            </DefaultButton>
          </div>
        </Div>

        <Card>
          <TabHeader>
            <div className="flex">
              <div className="w-12/12">
                <TableTab
                  tabs={links1}
                  activeTab={activeLink1}
                  handleTab={handleActiveLink1}
                  callback={(active) => {
                    // console.log(active)
                  }}
                />
              </div>
            </div>
          </TabHeader>

          <div>
            {activeLink1 === 0 ? (
              <div className="">
                <MessagesTable users={filterUsersonChange(listOfUsers, null)} />
              </div>
            ) : activeLink1 === 1 ? (
              <div className=""></div>
            ) : activeLink1 === 2 ? (
              <div className="">
                <Card></Card>
              </div>
            ) : null}
          </div>
        </Card>
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    listOfUsers: state.users.listOfUsers,
    loading: state.users.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (page, keyword) => dispatch(getUsers(page, keyword)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Messages));
