import React, { lazy, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "../../../Units/Card.styled";
import { CardHolder, CardTitle } from "../../../Units/CardTitle.styled";
import { ActivitiesTable } from "./ActivitiesTable";
import ic_activities from "../../../../assets/images/ic-activities-db.svg";
import { groupActivitiesByDaysOrMonth } from "../../../../utils/specialFunctions";
import { Link } from "react-router-dom";
import { ActivitiesTableCardStyle } from "./Activities.styled";
import {
  transactionAction,
  getSendAndRecieveAction,
  getTradesAction,
} from "../../../../store/Actions/TransactionActions";
import { overviewAction } from "../../../../store/Actions/OverviewActions";
import { TradeActivitiesTable } from "./ActivitiesTable";
import { TableTab } from "../../../Units/TabsAndLinkStyled";
import { withTheme } from "styled-components";

const ActivitiesCardTable = ({
  data,
  transactionsList,
  trades,
  transfers,
  loading,
  theme,
  currencies,
  prices,
  getSendAndRecieveAction,
  getTradesAction,
  overviewAction,
}) => {
  useEffect(() => {
    getSendAndRecieveAction();
    getTradesAction();
    overviewAction();
  }, [getSendAndRecieveAction, getTradesAction, overviewAction]);

  return (
    <ActivitiesTableCardStyle className="pt-6">
      <Card>
        <CardHolder>
          <div className="flex">
            <div className="w-12/12">
              <CardTitle img={ic_activities} text={"Activities"} />
            </div>
          </div>
        </CardHolder>

        <div className="lg:px-4">
          <ActivitiesTable
            activities={groupActivitiesByDaysOrMonth(
              data ? data : null,
              "days"
            ).slice(0, 5)}
          />
          {data && data.length !== 0 ? (
            <div className="text-center pt-5">
              <Link className="viewAll" to="/dashboard/activities">
                See more
              </Link>
            </div>
          ) : null}
        </div>
      </Card>
    </ActivitiesTableCardStyle>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    trades: state.transactions.trades,
    transfers: state.transactions.transfers,
    loading: state.transactions.loading,
    error: state.overview.error,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    transactionAction: () => dispatch(transactionAction()),
    getSendAndRecieveAction: () => dispatch(getSendAndRecieveAction()),
    getTradesAction: (query) => dispatch(getTradesAction()),
    overviewAction: () => dispatch(overviewAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(ActivitiesCardTable)));
