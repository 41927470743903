import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { SearchInput } from "../../../../UI toolkit/Input/Input";
import DashboardHeader from "../DashboardHeader";
import { Div } from "../Users/Users.styled";
import search_icon from "../../../../assets/images/search.svg";
import { connect } from "react-redux";
import { getUsers } from "../../../../store/Actions/UsersAction";
import { AddUserToMerchantTable } from "./MerchantTable";
import {
  filterUsersBasedOnLevel,
  filterUsersOnLevel,
} from "../../../../utils/specialFunctions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { withTheme } from "styled-components";

const AddMerchant = ({ listOfUsers, user, loading, getUsers, theme }) => {
  const location = useLocation();

  const [keyword, setkeyword] = useState(null);
  const [page, setpage] = useState(1);
  const hasPrevious = page > 1;
  const hasNext = true;

  useEffect(() => {
    getUsers(page, keyword);
  }, [page, keyword]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Merchant</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Add Merchant</DashboardHeader>

        <Div className="pb-6">
          <Link to={"/dashboard/merchants/"} className="backTo">
            &nbsp; Back to merchants
          </Link>
        </Div>

        <div className="">
          <div className="pb-6">
            <div className="">
              <SearchInput
                placeholder="Select user to add as merchant"
                search_icon={search_icon}
                bg={"#FFFFFF"}
                color={"#646A86"}
                p={"8px 16px"}
                callback={(e) => {
                  setkeyword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div
            className="flex items-center justify-center h-100"
            style={{ height: "50vh" }}
          >
            <h1>Loading........</h1>
          </div>
        ) : (
          <div>
            <AddUserToMerchantTable
              // users={filterUsersBasedOnLevel(listOfUsers, keyword, 3)}
              users={filterUsersOnLevel(listOfUsers, 3)}
            />

            <div className=" mt-2 flex justify-end">
              {listOfUsers && hasPrevious ? (
                <div className="flex justify-start">
                  <DefaultButton
                    bg={theme.colors.bg}
                    color={theme.colors.color}
                    p={"8px 16px"}
                    onClick={() => {
                      setpage(page - 1);
                    }}
                  >
                    Previous
                  </DefaultButton>
                </div>
              ) : null}

              {listOfUsers && listOfUsers.length !== 0 && hasNext ? (
                <div className="flex justify-end">
                  <DefaultButton
                    bg={theme.colors.bg}
                    color={theme.colors.color}
                    p={"8px 16px"}
                    onClick={() => {
                      setpage(page + 1);
                    }}
                  >
                    Next
                  </DefaultButton>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    listOfUsers: state.users.listOfUsers,
    loading: state.users.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (page, keyword) => dispatch(getUsers(page, keyword)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(AddMerchant));
