import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { H1, P } from "../../../../UI toolkit/Text/Text";
import { SendRecieveStyle } from "./Send-Recieve.Styled";
import QRCode from "react-qr-code";
import {
  fetchCryptoWalletsAddressbyPostAction,
  retrieveCryptoWalletsAction,
} from "../../../../store/Actions/TransactionActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { customToast } from "../../../../utils/customToast";
import { withTheme } from "styled-components";

const RecieveCrypto = ({
  crypto,
  retrieveCryptoWalletsAction,
  fetchCryptoWalletsAddressbyPostAction,
  wallet_address,
  loading,
  error,
  theme,
}) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (crypto) {
      retrieveCryptoWalletsAction({ coin: crypto });
    }
  }, [crypto, retrieveCryptoWalletsAction]);

  console.log(crypto);

  return (
    <div className="text-center flex justify-center items-center content-center">
      {loading ? (
        <div>
          <H1 color={"#BFC4D5"}>Loading...</H1>
        </div>
      ) : (
        <SendRecieveStyle>
          <div className="p-3">
            <div className=" text-center px-16">
              <div className="recieve-img-div my-10">
                {crypto && wallet_address ? (
                  <QRCode value={wallet_address.address} />
                ) : (
                  <div className="mt-6">
                    <P color={"#005674"} fontSize={"16px"} fontWeight={600}>
                      No QR Code.. please choose a crypto type
                    </P>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-10 text-center">
              {crypto && wallet_address ? (
                <div>
                  <div className="mt-12 mb-2 text-center text-uppercase">
                    <P fontSize="10px" color={"#BFC4D5"}>
                      Wallet address
                    </P>
                  </div>
                  <div className="mb-4" style={{ cursor: "pointer" }}>
                    <CopyToClipboard
                      text={wallet_address ? wallet_address.address : null}
                      onCopy={() => customToast.success(`copied to clipboard`)}
                    >
                      <P fontSize="16px" color={"#374072"} ref={textAreaRef}>
                        {wallet_address ? wallet_address.address : "--"}
                      </P>
                    </CopyToClipboard>
                  </div>

                  {/* <Link onClick={()=>{fetchCryptoWalletsAddressbyPostAction({coin: crypto ? crypto: null})}}>
                                                <P fontSize="13px" color={"#006F95"}>Generate new address</P>
                                            </Link> */}
                </div>
              ) : (
                <div className="mt-12 mb-2 text-center text-uppercase">
                  <P fontSize="16px" color={"#BFC4D5"}>
                    No wallet address avaliable..please choose a crypto type
                  </P>
                </div>
              )}
            </div>

            {crypto && wallet_address ? (
              <div>
                <div className="px-5 mb-6">
                  <CopyToClipboard
                    text={wallet_address ? wallet_address.address : null}
                    onCopy={() => customToast.success(`copied to clipboard`)}
                  >
                    <DefaultButton
                      small
                      // bg={"#112E46"}
                      bg={theme.colors.bg}
                      color={"#FFFFFF"}
                      p={"12px 12px 12px 12px"}
                      width={"100%"}
                      border={"1px solid #00BFFF"}
                      // onClick={copyToClipboard}
                    >
                      Copy address
                    </DefaultButton>
                  </CopyToClipboard>
                </div>

                <div className="text-center mb-7">
                  <Link to="/">
                    <P fontSize="13px" color={"#006F95"}>
                      Share
                    </P>
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </SendRecieveStyle>
      )}
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    wallet_address: state.transactions.wallet_address,
    loading: state.transactions.loading,
    error: state.transactions.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    retrieveCryptoWalletsAction: (state) =>
      dispatch(retrieveCryptoWalletsAction(state)),
    fetchCryptoWalletsAddressbyPostAction: (state) =>
      dispatch(fetchCryptoWalletsAddressbyPostAction(state)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(RecieveCrypto));
