import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = () => {
  const rows = Array(20).fill(null);
  return (
    <SkeletonTheme baseColor="rgba(184, 193, 213, 0.19)" highlightColor="white">
      <div className="mt-8">
        <div className="grid grid-cols-2 gap-x-10 mb-6">
          <Skeleton height={100} />
          <Skeleton height={100} />
        </div>
        <Skeleton height={60} />
        {rows.map((row, index) => (
          <div key={index + 1} className="grid grid-cols-6 gap-x-2 gap-y-6">
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ))}
      </div>
    </SkeletonTheme>
  );
};

export default TableSkeleton;
