import React from "react";
import { Link } from "react-router-dom";
import { H5, SubHeader } from "../../../../UI toolkit/Text/Text";

const UsersOverview = ({ users }) => {
  return (
    <div className="w-6/12 pb-6">
      <SubHeader color={"#7C8191"} fontWeight="500">
        USERS
      </SubHeader>
      <div className="flex py-4">
        <H5 color={"#112E46"} fontWeight="700" className="pr-4">
          {users ? users.toLocaleString() : 0}
        </H5>
        <div>{/* <img src="" alt="graph"/> */}</div>
      </div>

      <div className="">
        <Link to="/dashboard/users" className="viewAll">
          View all
        </Link>
      </div>
    </div>
  );
};

export default UsersOverview;
