import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ToastContent from "../components/Toast/ToastContent";

export const customToast = {
  success: (text) => {
    toast.success(<ToastContent type={"success"} text={text} />);
  },
  warning: (text) => {
    toast.warning(<ToastContent type={"warning"} text={text} />);
  },
  error: (text) => {
    toast.error(<ToastContent type={"error"} text={text} />);
  },
  info: (text) => {
    toast.info(<ToastContent type={"info"} text={text} />);
  },
  message: (text) => {
    toast(<ToastContent type={"message"} text={text} />);
  },
};
