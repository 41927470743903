import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import {
  getUserCardActivities,
  loadCardTransactions,
  updateUserCardActivitiesActiveTab,
  updateUserCardActivitiesPage,
} from "../../../../../store/Actions/CardsAction";
import { withTheme } from "styled-components";
import { ArrowLeftIcon } from "../../../../../UI toolkit/icons";
import { Card } from "../../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../../Units/TabsAndLinkStyled";
import { Select } from "../../../../../UI toolkit/Dropdown/Dropdown";
import UserCardTransactionsTable from "./UsersCardsTable";
import caret_down from "../../../../../assets/images/caret-down1.svg";
import {
  customGroupActivitiesByDaysOrMonth,
  groupActivitiesByDaysOrMonth,
} from "../../../../../utils/specialFunctions";
import { DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P } from "../../../../../UI toolkit/Text/Text";
import LoadCardTransactionsTable from "./LoadCardTransactionsTable";

const UserCards = ({
  theme,
  activeTab,
  updateActiveTab,
  card_activities,
  getUserCardActivities,
  match,
  loading,
  updatePage,
  page,
  totalPages,
  activeStatus,
  loadCardTransactions,
  cardTransactions,
}) => {
  const currentStatus = activeStatus[activeTab];
  const currentPage = page[activeTab][currentStatus];
  const totalPage = totalPages[activeTab][currentStatus];

  const [active, setActive] = useState(0);
  const setActiveOptimized = useCallback((value) => {
    setActive(value);
  }, []);

  const tabs = [
    { text: "All", value: "all" },
    { text: "Funding", value: "fund" },
    { text: "Withdrawals", value: "withdrawal" },
    { text: "Created", value: "create" },
    { text: "Freeze", value: "inactive" },
    { text: "Unfreeze", value: "active" },
  ];

  const loadTransactionsTabs = [{ text: "All", value: "all" }];

  const handleTabChange = (type) => {
    type = type.toLowerCase();

    updateActiveTab(type);
    console.log(type);
  };

  const handleNextPage = () => {
    console.log(currentPage, totalPage);
    if (currentPage < totalPage - 1) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage + 1,
      });
    }
  };

  const prevButtonDisabled = () => {
    return !(currentPage > 0);
  };

  const nextButtonDisabled = () => {
    return !(currentPage < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage - 1,
      });
    }
  };

  useEffect(() => {
    getUserCardActivities(match.params.id);
    //    console.log(match.params.id);
  }, [getUserCardActivities, activeTab, page, activeStatus]);

  useEffect(() => {
    loadCardTransactions(match.params.id);
  }, [loadCardTransactions]);

  return (
    <div>
      {/* <div
        className="flex items-center cursor-pointer py-4"
        onClick={() => handleActiveTab(0)}
      >
        <ArrowLeftIcon />
        <h3 className="text-primary underline ml-2">Back</h3>
      </div> */}
      <div className="">
        <Card>
          <TabHeader>
            <div className="lg:flex justify-between">
              <div className="lg:w-8/12">
                <TableTab
                  margin
                  tabs={tabs}
                  activeTab={activeTab}
                  handleDispatch={handleTabChange}
                  callback={(active) => {
                    console.log(active);
                  }}
                />
              </div>
              {/* <div className="lg:w-4/12 flex justify-end my-3 mx-3 lg:my-1.5 lg:mx-1.5 py-5 lg:py-0">
                <div className="w-6/12">
                  <Select
                    bgImg={caret_down}
                    bgColor={theme.colors.bg}
                    color={theme.colors.color}
                    border={"1px solid #fff"}
                    borderRadius={"4px"}
                    p={"5px 15px"}
                    data={statusTabs}
                    defaultValue={"-- Status --"}
                    properyToShow={"text"}
                    hoverBg={"#FFF"}
                    hoverColor={"#112E46"}
                    fontSize={"16px"}
                    callback={(option, idx, arr) => {
                      handleStatusChange(option.value);
                    }}
                  />
                </div>
              </div> */}
            </div>
          </TabHeader>
          <div className="px-0">
            <UserCardTransactionsTable
              match={match}
              setActiveOptimized={setActiveOptimized}
              links1={groupActivitiesByDaysOrMonth(
                card_activities ? card_activities : null,
                "days"
              )}
            />
          </div>
          {!loading && (
            <div className="flex justify-between gap-2 items-center py-4 w-full">
              <p className="">
                {/* Page no */}
                {/* {pagination.per_page}  */}
              </p>
              <div className="flex items-center">
                <DefaultButton
                  bg={theme.colors.bg}
                  color={theme.colors.color}
                  p={"8px 16px"}
                  onClick={handlePrevPage}
                  disabled={prevButtonDisabled()}
                >
                  Prev
                </DefaultButton>
                <div className="px-4">
                  <P color={"#646A86"} fontSize="16px" fontWeight="700">
                    Page {currentPage + 1} of {totalPage !== 0 ? totalPage : 1}
                  </P>
                </div>
                <DefaultButton
                  bg={theme.colors.bg}
                  color={theme.colors.color}
                  p={"8px 16px"}
                  onClick={handleNextPage}
                  disabled={nextButtonDisabled()}
                >
                  Next
                </DefaultButton>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.cards.loading,
    error: state.cards.error,
    card_activities: state.cards.user_card_activities,
    cardTransactions: state.cards.userCardTransactions,
    activeTab: state.cards.userCardActivitiesActiveTab,
    activeStatus: state.cards.userCardActivitiesActiveStatus,
    page: state.cards.userCardActivitiesPage,
    totalPages: state.cards.userCardActivitiesTotalPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserCardActivities: (payload) =>
      dispatch(getUserCardActivities(payload)),
    loadCardTransactions: (payload) => dispatch(loadCardTransactions(payload)),
    updateActiveTab: (tab) => dispatch(updateUserCardActivitiesActiveTab(tab)),
    updatePage: (page) => dispatch(updateUserCardActivitiesPage(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(UserCards)));
