import {
  GET_LIST_OF_BANKS,
  GET_LIST_OF_TRANSACTIONS,
  GET_LIST_OF_WITHDRAWALS,
  GET_LIST_OF_DEPOSITS,
  GET_TRADES,
  GET_TRANSFERS,
  TRANSACTIONS_LOADING,
  TRANSACTIONS_ERROR,
  GET_WALLET_ADDRESS,
  POST_WALLET_ADDRESS,
  GET_LIST_OF_DEPOSITS_AND_WITHDRAWALS,
  UPDATE_TRADES_ACTIVE_TAB,
  UPDATE_TRADE_NAVIGATOR,
  UPDATE_TRANSACTIONS_PAGE,
  UPDATE_TRANSACTIONS_ACTIVE_TAB,
  UPDATE_TOTAL_TRANSACTIONS,
  UPDATE_TRANSACTIONS_ACTIVE_STATUS,
  UPDATE_TRANSACTIONS_ACTIVE_MERCHANT,
  GET_COMPLETED_DEPOSITS,
  GET_COMPLETED_WITHDRAWALS,
  GET_PENDING_DEPOSITS,
  GET_PENDING_WITHDRAWALS,
  TRADES_LOADING,
  TRADES_ERROR,
  UPDATE_TRADES_PAGE,
  UPDATE_TOTAL_TRADES,
  UPDATE_TRADES_ACTIVE_CURRENCY,
  UPDATE_TRANSFERS_ACTIVE_TAB,
  UPDATE_TRANSFERS_ACTIVE_CURRENCY,
  UPDATE_TRANSFERS_PAGE,
  UPDATE_TOTAL_TRANSFERS,
  TRANSFERS_LOADING,
  GET_INCOMING_TRANSFERS,
  UPDATE_INCOMING_TRANSFERS_ACTIVE_TAB,
  UPDATE_INCOMING_TRANSFERS_ACTIVE_CURRENCY,
  UPDATE_INCOMING_TRANSFERS_PAGE,
  UPDATE_TOTAL_INCOMING_TRANSFERS,
  UPDATE_SENT_TRANSFERS_ACTIVE_CURRENCY,
  UPDATE_SENT_TRANSFERS_PAGE,
  UPDATE_TOTAL_SENT_TRANSFERS,
  GET_SENT_TRANSFERS,
  INCOMING_TRANSFERS_LOADING,
  SENT_TRANSFERS_LOADING,
  GET_TRANSFERS_COUNT,
  TRANSFERS_COUNT_LOADING,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  transactionsList: null,
  depositsAndWithdrawalsList: null,
  depositsAndWithdrawalsFilters: null,
  depositsList: null,
  withdrawalsList: null,
  wallet_address: null,
  trades: null,
  transfers: null,
  banks: null,
  trade_filters: null,
  transfer_filters: null,
  tradeNavigator: {
    startIndex: 0,
    endIndex: 6,
    currentIndex: 0,
  },
  transferActiveTab: 0,
  transferNavigator: {
    startIndex: 0,
    endIndex: 6,
    currentIndex: 0,
  },
  transactionsActiveTab: "all",

  transactionsPage: {
    all: {
      pending: 0,
      completed: 0,
      rejected: 0,
      failed: 0,
      all: 0,
      refunded: 0,
    },
    deposit: {
      pending: 0,
      completed: 0,
      rejected: 0,
      failed: 0,
      all: 0,
      refunded: 0,
    },
    withdrawal: {
      pending: 0,
      completed: 0,
      rejected: 0,
      failed: 0,
      all: 0,
      refunded: 0,
    },
  },
  totalTransactions: {
    all: {
      pending: 0,
      completed: 0,
      rejected: 0,
      failed: 0,
      all: 0,
      refunded: 0,
    },
    deposit: {
      pending: 0,
      completed: 0,
      rejected: 0,
      failed: 0,
      all: 0,
      refunded: 0,
    },
    withdrawal: {
      pending: 0,
      completed: 0,
      rejected: 0,
      failed: 0,
      all: 0,
      refunded: 0,
    },
  },
  transactionsActiveStatus: {
    all: "all",
    deposit: "all",
    withdrawal: "all",
  },
  // activitiesPage
  transactionsActiveMerchant: null,
  totalWithdrawals: 0,
  totalDeposits: 0,
  totalPendingWithdrawals: 0,
  totalPendingDeposits: 0,
  tradesPage: {
    all: 0,
    sell: 0,
    buy: 0,
    swap: 0,
  },
  totalTrades: {
    all: 0,
    sell: 0,
    buy: 0,
    swap: 0,
  },
  tradesActiveTab: "all",
  tradesActiveCurrency: "",
  tradesLoading: false,
  transfersPage: {
    all: 0,
    send: 0,
    receive: 0,
  },
  totalTransfers: {
    all: 0,
    send: 0,
    receive: 0,
  },
  transfersActiveTab: "all",
  transfersActiveCurrency: "",
  transfersStatus: "",
  transfersLoading: false,
  incomingTransfers: null,
  incomingTransfersPage: 0,
  incomingTotalTransfers: 0,
  incomingTransfersStatus: "",
  incomingTransfersActiveCurrency: "",
  incomingTransfersLoading: false,
  sentTransfers: null,
  sentTransfersPage: 0,
  sentTotalTransfers: 0,
  sentTransfersStatus: "",
  sentTransfersActiveCurrency: "",
  sentTransfersLoading: false,
  transfersCount: null,
  transfersCountloading: false,
};

const transactionReducer = (state = initState, action) => {
  switch (action.type) {
    case TRANSACTIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TRADES_LOADING:
      return {
        ...state,
        tradesLoading: true,
      };
    case TRADES_ERROR:
      return {
        ...state,
        error: action.error,
        tradesLoading: false,
      };
    case GET_LIST_OF_BANKS:
      return {
        ...state,
        banks: action.banks,
        loading: false,
      };
    case GET_LIST_OF_TRANSACTIONS:
      return {
        ...state,
        transactionsList: action.transactions,
        loading: false,
      };
    case GET_LIST_OF_DEPOSITS_AND_WITHDRAWALS:
      return {
        ...state,
        depositsAndWithdrawalsList: action.data,
        depositsAndWithdrawalsFilters: action.filters,
        loading: false,
      };
    case GET_LIST_OF_DEPOSITS:
      return {
        ...state,
        depositsList: action.deposits,
        loading: false,
      };
    case GET_LIST_OF_WITHDRAWALS:
      return {
        ...state,
        withdrawalsList: action.withdrawals,
        loading: false,
      };
    case GET_TRADES:
      return {
        ...state,
        trades: action.trades,
        tradesLoading: false,
      };
    case GET_TRANSFERS:
      return {
        ...state,
        transfers: action.transfers,
        transfersLoading: false,
      };
    case GET_WALLET_ADDRESS:
      return {
        ...state,
        wallet_address: action.wallet_address,
        loading: false,
      };
    case POST_WALLET_ADDRESS:
      return {
        ...state,
        wallet_address: action.wallet_address,
        loading: false,
      };
    case UPDATE_TRADES_ACTIVE_TAB:
      return {
        ...state,
        tradesActiveTab: action.payload,
        loading: false,
      };
    case UPDATE_TRADES_ACTIVE_CURRENCY:
      return {
        ...state,
        tradesActiveCurrency: action.payload,
        loading: false,
      };
    case UPDATE_TRADES_PAGE:
      //console.log(action.payload);
      return {
        ...state,
        tradesPage: {
          ...state.tradesPage,
          [action.payload.tab]: action.payload.value,
        },
        loading: false,
      };
    case UPDATE_TRADE_NAVIGATOR:
      return {
        ...state,
        //update field in the object tradeNavigator
        tradeNavigator: {
          ...state.tradeNavigator,
          [action.payload.field]: action.payload.value,
        },
        loading: false,
      };
    case UPDATE_TOTAL_TRADES:
      return {
        ...state,
        totalTrades: {
          ...state.totalTrades,
          [action.payload.tab]: action.payload.value,
        },
      };
    case TRANSFERS_LOADING:
      return {
        ...state,
        transfersLoading: true,
      };

    case UPDATE_TRANSFERS_ACTIVE_TAB:
      return {
        ...state,
        transfersActiveTab: action.payload,
        transfersLoading: false,
      };
    case UPDATE_TRANSFERS_ACTIVE_CURRENCY:
      return {
        ...state,
        transfersActiveCurrency: action.payload,
        transfersLoading: false,
      };
    case UPDATE_TRANSFERS_PAGE:
      //console.log(action.payload);
      return {
        ...state,
        transfersPage: {
          ...state.transfersPage,
          [action.payload.tab]: action.payload.value,
        },
        transfersLoading: false,
      };
    case UPDATE_TOTAL_TRANSFERS:
      return {
        ...state,
        totalTransfers: {
          ...state.totalTransfers,
          [action.payload.tab]: action.payload.value,
        },
      };

    case INCOMING_TRANSFERS_LOADING:
      return {
        ...state,
        incomingTransfersLoading: true,
      };

    case GET_INCOMING_TRANSFERS:
      return {
        ...state,
        incomingTransfers: action.data,
        incomingTransfersLoading: false,
      };
    case UPDATE_INCOMING_TRANSFERS_ACTIVE_CURRENCY:
      return {
        ...state,
        incomingTransfersActiveCurrency: action.payload,
        incomingTransfersLoading: false,
      };
    case UPDATE_INCOMING_TRANSFERS_PAGE:
      //console.log(action.payload);
      return {
        ...state,
        incomingTransfersPage: action.payload.value,
        incomingTransfersLoading: false,
      };
    case UPDATE_TOTAL_INCOMING_TRANSFERS:
      return {
        ...state,
        incomingTotalTransfers: action.payload.value,
      };
    case SENT_TRANSFERS_LOADING:
      return {
        ...state,
        sentTransfersLoading: true,
      };
    case GET_SENT_TRANSFERS:
      return {
        ...state,
        sentTransfers: action.data,
        sentTransfersLoading: false,
      };

    case UPDATE_SENT_TRANSFERS_ACTIVE_CURRENCY:
      return {
        ...state,
        sentTransfersActiveCurrency: action.payload,
        sentTransfersLoading: false,
      };
    case UPDATE_SENT_TRANSFERS_PAGE:
      //console.log(action.payload);
      return {
        ...state,
        sentTransfersPage: action.payload.value,
        sentTransfersLoading: false,
      };
    case UPDATE_TOTAL_SENT_TRANSFERS:
      return {
        ...state,
        sentTotalTransfers: action.payload.value,
        sentTransfersLoading: false,
      };

    case UPDATE_TRANSACTIONS_PAGE:
      // console.log(action.payload);
      return {
        ...state,
        transactionsPage: {
          ...state.transactionsPage,
          [action.payload.tab]: {
            ...state.transactionsPage[action.payload.tab],
            [action.payload.status]: action.payload.value,
          },
        },
        loading: false,
      };

    case UPDATE_TRANSACTIONS_ACTIVE_STATUS:
      console.log(action.payload);
      return {
        ...state,
        transactionsActiveStatus: {
          ...state.transactionsActiveStatus,
          [action.payload.tab]: action.payload.value,
        },
        loading: false,
      };

    case UPDATE_TOTAL_TRANSACTIONS:
      //      console.log(action.payload);
      return {
        ...state,
        totalTransactions: {
          ...state.totalTransactions,
          [action.payload.tab]: {
            ...state.totalTransactions[action.payload.tab],
            [action.payload.status]: action.payload.value,
          },
        },
        loading: false,
      };

    case UPDATE_TRANSACTIONS_ACTIVE_TAB:
      return {
        ...state,
        transactionsActiveTab: action.payload,
        loading: false,
      };

    case UPDATE_TRANSACTIONS_ACTIVE_MERCHANT:
      return {
        ...state,
        transactionsActiveMerchant: action.payload,
        loading: false,
      };
    case GET_COMPLETED_DEPOSITS:
      console.log("cd", action.deposits);
      return {
        ...state,
        totalDeposits: action.deposits,
        loading: false,
      };
    case GET_COMPLETED_WITHDRAWALS:
      console.log("cw", action.withdrawals);
      return {
        ...state,
        totalWithdrawals: action.withdrawals,
        loading: false,
      };
    case GET_PENDING_DEPOSITS:
      console.log("pd", action.pending_deposits);
      return {
        ...state,
        totalPendingDeposits: action.pending_deposits,
        loading: false,
      };
    case GET_PENDING_WITHDRAWALS:
      console.log("pw", action.pending_withdrawals);
      return {
        ...state,
        totalPendingWithdrawals: action.pending_withdrawals,
        loading: false,
      };

    case GET_TRANSFERS_COUNT:
      return {
        ...state,
        transfersCount: action.transfersCount,
        transfersCountloading: false,
      };
    case TRANSFERS_COUNT_LOADING:
      return {
        ...state,
        transfersCountloading: true,
      };
    default:
      return state;
  }
};

export default transactionReducer;
