import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import {
  TransactionsTableStyle,
  TransactionsTableHeaderStyle,
} from "./Transactions.styled";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import {
  TransactionApprovedModal,
  TransactionsModal,
  TransactionRejectedModal,
  SelectMerchantWithdrawalModal,
} from "./TransactionsModal";
import { connect } from "react-redux";
import {
  approveDepositsAction,
  approveWithdrawalAction,
  rejectDepositsAction,
  rejectWithdrawalAction,
  retryWithdrawalAction,
  resolveWithdrawalAction,
  retryDepositsAction,
  canelDepositsAction,
} from "../../../../store/Actions/TransactionActions";
import { capitalizeFirstLetter } from "../../../../utils/specialFunctions";
import { ViewUserActivityModal } from "../Users/UsersModal";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    verificationsList: state.verification.verificationsList,
    verificationDoc: state.verification.verificationDoc,
    loadVerificationDoc: state.verification.loadVerificationDoc,
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    approveDepositsAction: (id, successCallback) =>
      dispatch(approveDepositsAction(id, successCallback)),
    retryDepositsAction: (id, successCallback) =>
      dispatch(retryDepositsAction(id, successCallback)),
    approveWithdrawalAction: (id, state, successCallback) =>
      dispatch(approveWithdrawalAction(id, state, successCallback)),
    rejectDepositsAction: (id, successCallback) =>
      dispatch(rejectDepositsAction(id, successCallback)),
    cancelDepositsAction: (id, successCallback) =>
      dispatch(canelDepositsAction(id, successCallback)),
    rejectWithdrawalAction: (id, successCallback) =>
      dispatch(rejectWithdrawalAction(id, successCallback)),
    retryWithdrawalAction: (id, successCallback) =>
      dispatch(retryWithdrawalAction(id, successCallback)),
    resolveWithdrawalAction: (id, successCallback) =>
      dispatch(resolveWithdrawalAction(id, successCallback)),
  };
};

export const UserTransactionsTable = ({ links1 }) => {
  const [openModal, setopenModal] = useState(false);
  const [transaction, settransaction] = useState(null);

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <TransactionsTableHeaderStyle className="w-full">
          <tbody className="w-full">
            <tr className="Table-heading -px-1.5">
              <td width="20%" className="pt-4 pb-4"></td>

              <td width="20%" className="pt-4 pb-4"></td>

              <td width="20%" className="pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    AMOUNT
                  </SubHeader>
                </div>
              </td>
              <td width="20%" className="pt-4 pb-4">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  EMAIL
                </SubHeader>
              </td>

              <td width="20%" className="pt-4 pb-4"></td>
            </tr>
          </tbody>
        </TransactionsTableHeaderStyle>
        {links1 &&
          links1.map((transactionGroup, idx, arr) => {
            return (
              <TransactionsTableStyle className="w-full" key={idx}>
                <thead>
                  <div className="pt-4 pb-4 px-1.5">
                    <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                      {`${timeAgo.format(
                        new Date(transactionGroup.group)
                      )} — ${new Date(transactionGroup.group).toDateString()}`}
                    </P>
                  </div>
                </thead>

                <tbody>
                  {transactionGroup.data.map((curr, idx, arr) => {
                    return (
                      <tr
                        className="px-1.5"
                        style={{ borderBottom: "1px solid #EFF0F5" }}
                        key={curr.id}
                      >
                        <td width="20%" className="pt-4 pb-4 px-1.5">
                          <Badge
                            bg={
                              curr.status === "complete"
                                ? "#D1F7C4"
                                : curr.status === "pending"
                                ? "#FFEAB6"
                                : curr.status === "matched"
                                ? "#D0F0FD"
                                : curr.status === "failed"
                                ? "#FFDCE5"
                                : "#D1F7C4"
                            }
                            color={
                              curr.status === "complete"
                                ? "#337138"
                                : curr.status === "pending"
                                ? "#606060"
                                : curr.status === "matched"
                                ? "#335F71"
                                : curr.status === "failed"
                                ? "#531423"
                                : ""
                            }
                          >
                            {curr.status}
                          </Badge>
                        </td>

                        <td width="20%" className="pt-4 pb-4 px-1.5">
                          <div className="flex items-center">
                            <div>
                              <P
                                fontSize="13px"
                                color={"#374072"}
                                fontWeight="500"
                              >
                                {curr.description}
                              </P>
                            </div>
                          </div>
                        </td>

                        <td width="20%" className="pt-4 pb-4 px-1.5">
                          <P fontSize="14px" color={"#005674"} fontWeight="600">
                            {curr.coin} &nbsp; {curr.amount}
                          </P>
                        </td>

                        <td width="20%" className="pt-4 pb-4 px-1.5">
                          <P fontSize="14px" color={"#374072"} fontWeight="600">
                            {curr["account.email"]
                              ? curr["account.email"]
                              : null}
                          </P>
                        </td>

                        <td width="20%" className="pt-4 pb-4 px-1.5">
                          <div className="flex justify-end">
                            <div className="pr-6">
                              <P fontSize="10px" color={"#7A809B"}>
                                {new Date(curr.created_at).toDateString()}
                              </P>
                            </div>

                            <div className="">
                              <P
                                fontSize="13px"
                                color={"#0087B5"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  settransaction(curr);
                                  setopenModal(true);
                                }}
                              >
                                View
                              </P>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </TransactionsTableStyle>
            );
          })}
      </div>

      <div>
        {openModal ? (
          <ViewUserActivityModal
            width={"30%"}
            border={"none"}
            closeModalCallback={() => {
              setopenModal(false);
            }}
            transaction={transaction}
          />
        ) : null}
      </div>
    </>
  );
};

export const TransactionsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    links1,
    user,
    approveDepositsAction,
    rejectDepositsAction,
    approveWithdrawalAction,
    rejectWithdrawalAction,
    retryWithdrawalAction,
    resolveWithdrawalAction,
    retryDepositsAction,
    cancelDepositsAction
  }) => {
    const [openModal, setopenModal] = useState(false);

    const [openApprovedModal, setopenApprovedModal] = useState(false);

    const [openRejectedModal, setopenRejectedModal] = useState(false);

    const [selectMerchantWithdrawal, setselectMerchantWithdrawal] =
      useState(false);

    const [transaction, settransaction] = useState(null);

    const RejectTransaction = (curr) => {
      settransaction(curr);
      if (curr.type === "deposit") {
        if (curr.status=== "review") {
          cancelDepositsAction(curr.id, (response) => {
            setopenRejectedModal(true);
          });
          return
        }
        rejectDepositsAction(curr.id, (response) => {
          setopenRejectedModal(true);
        });
      } else if (curr.type === "withdrawal") {
        if(curr.status === 'failed' || curr.status === 'refunded') {
          resolveWithdrawalAction(curr.id, (response) => {
            setopenApprovedModal(true);
          })
          return;
        }
        rejectWithdrawalAction(curr.id, (response) => {
          setopenRejectedModal(true);
        });
      }
    };

    const ApproveTransaction = (curr) => {
      settransaction(curr);
      if (curr.type === "deposit") {
        if (curr.status === 'review') {
          retryDepositsAction(curr.id, (response) => {
            setopenApprovedModal(true);
          });
          return;
        }
        approveDepositsAction(curr.id, (response) => {
          setopenApprovedModal(true);
        });
      } else if (curr.type === "withdrawal") {
        if(curr.status === "pending" || curr.status === "refunded") {
          setselectMerchantWithdrawal(true);
          return
        }
        retryWithdrawalAction(curr.id, (response) => {
          setopenApprovedModal(true);
        });
      }
    };

    const bgColor = (curr) => {
      return curr.status === "cancelled"
        ? "#FFDCE5"
        : curr.status === "failed"
        ? "#FFDCE5"
        : curr.status === "refunded"
        ? "#FFDCE5"
        : curr.status === "rejected"
        ? "#FFDCE5"
        : curr.status === "pending"
        ? "#FFEAB6"
        : curr.status === "review"
        ? "#FFEAB6"
        : curr.status === "active"
        ? "#D0F0FD"
        : curr.status === "processing"
        ? "#D0F0FD"
        : curr.status === "completed"
        ? "#D1F7C4"
        : "#337138";
    };

    const color = (curr) => {
      return curr.status === "cancelled"
        ? "#337138"
        : curr.status === "failed"
        ? "#337138"
        : curr.status === "refunded"
        ? "#337138"
        : curr.status === "rejected"
        ? "#337138"
        : curr.status === "pending"
        ? "#606060"
        : curr.status === "review"
        ? "#606060"
        : curr.status === "active"
        ? "#335F71"
        : curr.status === "processing"
        ? "#335F71"
        : curr.status === "completed"
        ? "#531423"
        : "";
    };

    const opacity = (curr) => {
      return curr.status === "completed" ||
        curr.status === "cancelled" ||
        curr.status === "rejected" ||
        curr.status === "processing"
        ? "0.1"
        : "1";
    };

    const disableButton = (curr) => {
      return curr.status === "completed" ||
        curr.status === "cancelled" ||
        curr.status === "rejected" ||
        curr.status === "processing"
        ? true
        : false;
    };

    return (
      <div style={{ overflowX: "auto" }}>
        <TransactionsTableHeaderStyle className="w-full">
          <tbody className="w-full">
            <tr className="Table-heading -px-1.5">
              <td width="15%" className="pt-4 pb-4"></td>

              <td width="15%" className="pt-4 pb-4"></td>

              <td width="12%" className="pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    AMOUNT
                  </SubHeader>
                </div>
              </td>
              <td width="10%" className="pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    MERCHANT
                  </SubHeader>
                </div>
              </td>
              <td width="10%" className="pt-4 pb-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    METHOD
                  </SubHeader>
                </div>
              </td>
              <td width="15%" className="pt-4 pb-4">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  USER
                </SubHeader>
              </td>

              <td width="25%" className="pt-4 pb-4"></td>
            </tr>
          </tbody>
        </TransactionsTableHeaderStyle>
        {links1 &&
          links1.map((transactionGroup, idx, arr) => {
            return (
              <TransactionsTableStyle className="w-full" key={idx}>
                <tbody>
                  <tr>
                    <td colSpan="5">
                      <div className="pt-4 pb-4 px-1.5">
                        <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                          {`${timeAgo.format(
                            new Date(transactionGroup.group)
                          )} — ${new Date(
                            transactionGroup.group
                          ).toDateString()}`}
                        </P>
                      </div>
                    </td>
                  </tr>

                  {transactionGroup.data.map((curr, idx, arr) => {
                    return (
                      <tr
                        className="px-1.5"
                        style={{ borderBottom: "1px solid #EFF0F5" }}
                        key={idx}
                      >
                        <td width="15%" className="pt-4 pb-4 px-1.5">
                          <Badge color={color(curr)} bg={bgColor(curr)}>
                            {curr.status}
                          </Badge>
                        </td>

                        <td width="15%" className="pt-4 pb-4 px-1.5">
                          <div className="flex items-center">
                            {/* <div>
                                                        <img src={ic_send} alt="icon"/>
                                                    </div> */}
                            <div>
                              <P
                                fontSize="13px"
                                color={"#374072"}
                                fontWeight="500"
                              >
                                {curr.type
                                  ? curr.type
                                  : ""}
                              </P>
                            </div>
                          </div>
                        </td>

                        <td width="12%" className="pt-4 pb-4 px-1.5">
                          <P fontSize="14px" color={"#005674"} fontWeight="600">
                            ₦{curr.amount.toLocaleString()}
                          </P>
                        </td>

                        <td width="10%" className="pt-4 pb-4 px-1.5">
                          <P fontSize="14px" color={"#374072"} fontWeight="600">
                            {curr && curr.merchant_username
                              ? capitalizeFirstLetter(
                                  curr.merchant_username
                                )
                              : null}
                          </P>
                        </td>
                        <td width="10%" className="pt-4 pb-4 px-1.5">
                          <P fontSize="14px" color={"#374072"} fontWeight="600">
                            {curr && curr.transaction_method
                              ? capitalizeFirstLetter(
                                  curr.transaction_method
                                )
                              : null}
                          </P>
                        </td>

                        <td width="15%" className="pt-4 pb-4 px-1.5">
                          <P fontSize="14px" color={"#374072"} fontWeight="600">
                            {curr.user_email}
                          </P>
                        </td>

                        <td
                          width="25%"
                          className={`pt-4 pb-4 px-2 ${
                            user.role === "super-admin"
                              ? ""
                              : "w-full  flex justify-end"
                          }`}
                        >
                          <div
                            className={`flex ${
                              user.role === "super-admin" ? "" : "justify-start"
                            }`}
                          >
                            {user.role === "super-admin" ? (
                              <div className="flex justify-between pr-6">
                                <>
                                  <DefaultButton
                                    small
                                    p={"5px 16px 5px 16px"}
                                    bg={"#24B57A"}
                                    color={"#FFF"}
                                    opacity={opacity(curr)}
                                    disabled={disableButton(curr)}
                                    onClick={() => ApproveTransaction(curr)}
                                  >
                                    {curr.type === "deposit"
                                      ? "Approve"
                                      : curr.type === "withdrawal"
                                      ? curr.status === "review" ? "Approve"
                                      : curr.status === "failed" ? "Retry"
                                      : "Process"
                                      : "None"}
                                  </DefaultButton>

                                  <DefaultButton
                                    small
                                    p={"5px 16px 5px 16px"}
                                    bg={"#FBE5E5"}
                                    color={"#D60000"}
                                    opacity={opacity(curr)}
                                    disabled={disableButton(curr)}
                                    onClick={() => {
                                      RejectTransaction(curr);
                                    }}
                                  >
                                    {(curr.type === 'withdrawal' && (curr.status === "failed" || curr.status === "refunded")) ? 'Resolve' : 'Reject'}
                                  </DefaultButton>
                                </>
                              </div>
                            ) : null}

                            <div className={"flex justify-end"}>
                              <P
                                fontSize="13px"
                                color={"#0087B5"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  settransaction(curr);
                                  setopenModal(true);
                                }}
                              >
                                View
                              </P>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </TransactionsTableStyle>
            );
          })}

        <div>
          {openModal ? (
            <TransactionsModal
              width={"30%"}
              border={"none"}
              openApprovedModal={openApprovedModal}
              setopenApprovedModal={setopenApprovedModal}
              closeModalCallback={() => {
                setopenModal(false);
              }}
              transaction={transaction}
              setselectMerchantWithdrawalModal={setselectMerchantWithdrawal}
            />
          ) : null}
        </div>

        <div>
          {openApprovedModal ? (
            <TransactionApprovedModal
              width={"30%"}
              border={"none"}
              callback={setopenApprovedModal}
            />
          ) : null}
        </div>

        <div>
          {openRejectedModal ? (
            <TransactionRejectedModal
              width={"30%"}
              border={"none"}
              callback={setopenRejectedModal}
            />
          ) : null}
        </div>

        <div>
          {selectMerchantWithdrawal ? (
            <SelectMerchantWithdrawalModal
              width={"30%"}
              border={"none"}
              closeModal={() => {
                setselectMerchantWithdrawal(false);
              }}
              transaction={transaction}
              setopenApprovedModal={setopenApprovedModal}
            />
          ) : null}
        </div>
      </div>
    );
  }
);
