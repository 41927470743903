import React, { useCallback, useEffect, useState } from "react";
import { Badge, DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import {
  ActivitiesTableStyle,
  SendReceiveTableStyle,
  ActivitiesTablesHeaderStyle,
} from "./Activities.styled";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ic_sent_icon from "../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../assets/images/ic-received-coin.svg";
import ic_bought_coin from "../../../../assets/images/ic-bought-coin.svg";
import ic_sold_coin from "../../../../assets/images/ic-sold-coin.svg";
import ic_swapped_coin from "../../../../assets/images/ic-swapped-coin.svg";
import {
  capitalizeFirstLetter,
  groupActivitiesByDaysOrMonth,
} from "../../../../utils/specialFunctions";
import { connect, useDispatch } from "react-redux";
import {
  getSendAndRecieveAction,
  retryTransferAction,
} from "../../../../store/Actions/TransactionActions";
import { ViewActivityModal } from "./ViewActivitymodal";
import { ConfirmTransferModal, RetryApprovedModal } from "./ActivitiesModal";
import { range } from "lodash";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import ReactSelect from "react-select";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    transfers: state.transactions.transfers,
    filters: state.transactions.transfer_filters,
    navigator: state.transactions.transferNavigator,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    retryTransferAction: (id, successCallback) =>
      dispatch(retryTransferAction(id, successCallback)),
    getSendAndRecieveAction: (query) =>
      dispatch(getSendAndRecieveAction(query)),
  };
};

export const ActivitiesTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    transfers,
    type,
    getSendAndRecieveAction,
    retryTransferAction,
    currencies,
    prices,
    filters,
    navigator,
    updateNavigator,
  }) => {
    const [openviewModal, setopenviewModal] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [transaction, settransaction] = useState(null);
    const [confirmModal, setconfirmModal] = useState(null);
    const [typeOfTransfer, settypeOfTransfer] = useState(null);
    const rowsOptions = [
      { value: 10, label: "10" },
      { value: 20, label: "20" },
      { value: 50, label: "50" },
      { value: 100, label: "100" },
    ];
    const pageCount = Math.ceil(
      Number(filters?.count) / Number(filters?.limit)
    );
    let pages = range(1, pageCount + 1);

    const bg = (curr) => {
      return curr.status === "completed"
        ? "#D1F7C4"
        : curr.status === "pending"
        ? "#FFEAB6"
        : curr.status === "matched"
        ? "#D0F0FD"
        : curr.status === "failed"
        ? "#FFDCE5"
        : curr.status === "cancelled"
        ? "#FFDCE5"
        : "#D1F7C4";
    };

    const color = (curr) => {
      return curr.status === "completed"
        ? "#337138"
        : curr.status === "pending"
        ? "#606060"
        : curr.status === "matched"
        ? "#335F71"
        : curr.status === "failed"
        ? "#531423"
        : curr.status === "cancelled"
        ? "#531423"
        : "";
    };

    var groupedTransfers = transfers && groupActivitiesByDaysOrMonth(transfers);

    // useEffect(() => {
    //   handleFetchTransfers();
    // }, [state]);

    return (
      <div>
        <div style={{ overflowX: "auto" }}>
          {groupedTransfers &&
            groupedTransfers.map((activityGroup, idx, arr) => {
              return (
                <SendReceiveTableStyle
                  className={idx % 2 === 0 ? "w-full my-10" : "w-full"}
                  key={idx}
                >
                  <tbody>
                    <tr>
                      <td colSpan="5">
                        <div className="pt-4 pb-4 px-1.5">
                          <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                            {`${timeAgo.format(
                              new Date(activityGroup.group)
                            )} — ${new Date(
                              activityGroup.group
                            ).toDateString()}`}
                          </P>
                        </div>
                      </td>
                    </tr>
                    {activityGroup.data &&
                      activityGroup.data.map((curr, idx, arr) => {
                        return (
                          <tr
                            style={{
                              borderBottom: "1px solid #EFF0F5",
                              width: "100%",
                            }}
                            key={curr.id}
                          >
                            <td className="pt-4 pb-4">
                              <div className="flex">
                                <div className="pr-2">
                                  <img
                                    src={
                                      curr.type === "send"
                                        ? ic_sent_icon
                                        : curr.type === "receive"
                                        ? ic_receive_icon
                                        : curr.type === "swap"
                                        ? ic_swapped_coin
                                        : ""
                                    }
                                    alt="send"
                                    className="h-auto w-auto"
                                  />
                                </div>
                                <div>
                                  <P
                                    fontSize="13px"
                                    color={"#374072"}
                                    fontWeight="500"
                                  >{`${capitalizeFirstLetter(curr.type)} ${
                                    curr.currency
                                  }`}</P>
                                  <P fontSize="10px" color={"#7A809B"}>
                                    {
                                      new Date(curr.created_at)
                                        .toLocaleString()
                                        .split(",")[1]
                                    }
                                  </P>
                                </div>
                              </div>
                            </td>
                            <td className="pt-4 pb-4">
                              <div className="flex">
                                <div>
                                  <P
                                    fontSize="16px"
                                    color={
                                      parseFloat(curr.amount) > 0
                                        ? "#005674"
                                        : parseFloat(curr.amount) < 0
                                        ? "#D60000"
                                        : "#005674"
                                    }
                                    fontWeight="600"
                                    className="mr-1.5 tooltip"
                                  >
                                    {curr.currency}{" "}
                                    {Number(curr.amount).toFixed(8)}
                                    <div className="tooltiptext">
                                      <div>
                                        <P
                                          fontSize="16px"
                                          color={"#fff"}
                                          fontWeight="500"
                                        >
                                          USD{" "}
                                          {curr && curr.amount_usd_equivalent
                                            ? curr.amount_usd_equivalent
                                            : null}
                                        </P>
                                      </div>
                                      <span>
                                        Platform Fee
                                        <span>
                                          {/* <P fontSize="13px" color={"#fff"} fontWeight="500" >{ prices && prices[curr.currency] && prices[curr.currency].usd ? prices[curr.currency].usd * curr.fee: 0} USD</P> */}
                                          <P
                                            fontSize="13px"
                                            color={"#fff"}
                                            fontWeight="500"
                                          >
                                            {curr && curr.fee
                                              ? Number(curr.fee).toFixed(8)
                                              : 0.0}{" "}
                                            {curr.currency}
                                          </P>
                                        </span>
                                      </span>
                                    </div>
                                  </P>
                                  <P
                                    fontSize="13px"
                                    color={"#646A86"}
                                    fontWeight="500"
                                  >
                                    {curr && curr.blockchain_fee
                                      ? curr.blockchain_fee
                                      : null}
                                  </P>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${
                                  curr.status === "pending" &&
                                  curr.type === "send"
                                    ? "w-3/12"
                                    : "w-6/12"
                                }`}
                              >
                                <P
                                  fontSize="13px"
                                  color={"#374072"}
                                  fontWeight="500"
                                >
                                  {curr && curr.account && curr.account.email
                                    ? curr.account.email
                                    : "no email"}
                                </P>
                              </div>
                            </td>
                            <td>
                              {curr.status ? (
                                <div className={"w-3/12 flex justify-end"}>
                                  <P
                                    fontSize="13px"
                                    color={"#0087B5"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      settransaction(curr);
                                      setopenviewModal(true);
                                    }}
                                  >
                                    View
                                  </P>
                                </div>
                              ) : null}
                            </td>
                            <td className="text-right pt-4 pb-4">
                              <div
                                className={`flex ${
                                  curr.status === "pending" &&
                                  curr.type === "send"
                                    ? "justify-between"
                                    : "justify-end"
                                }`}
                              >
                                {curr.status === "pending" &&
                                curr.type === "send" ? (
                                  <div className="w-6/12 flex justify-between">
                                    <DefaultButton
                                      small
                                      className={"mr-3"}
                                      p={"5px 16px 5px 16px"}
                                      bg={"#24B57A"}
                                      color={"#FFF"}
                                      opacity={
                                        curr.status !== "pending" &&
                                        curr.type === "send"
                                          ? "0.1"
                                          : "1"
                                      }
                                      disabled={
                                        curr.status !== "pending" &&
                                        curr.type === "send"
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        settransaction(curr);
                                        settypeOfTransfer("retry");
                                        setconfirmModal(true);
                                      }}
                                    >
                                      {curr.status === "pending" &&
                                      curr.type === "send"
                                        ? "Approve"
                                        : ""}
                                    </DefaultButton>
                                    <DefaultButton
                                      small
                                      p={"5px 16px 5px 16px"}
                                      bg={"#FF6A6A"}
                                      color={"#FFF"}
                                      opacity={
                                        curr.status !== "pending" &&
                                        curr.type === "send"
                                          ? "0.1"
                                          : "1"
                                      }
                                      disabled={
                                        curr.status !== "pending" &&
                                        curr.type === "send"
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        settransaction(curr);
                                        settypeOfTransfer("reject");
                                        setconfirmModal(true);
                                      }}
                                    >
                                      {curr.status === "pending" &&
                                      curr.type === "send"
                                        ? "Reject"
                                        : ""}
                                    </DefaultButton>
                                  </div>
                                ) : null}
                                <div
                                  className={`${
                                    curr.status === "pending" &&
                                    curr.type === "send"
                                      ? "w-3/12 text-right"
                                      : "w-6/12 flex justify-end"
                                  }`}
                                >
                                  <Badge bg={bg(curr)} color={color(curr)}>
                                    {curr.status}
                                  </Badge>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </SendReceiveTableStyle>
              );
            })}
          <>
            {confirmModal ? (
              <ConfirmTransferModal
                width={"30%"}
                border={"none"}
                typeOfTransfer={typeOfTransfer}
                transaction={transaction}
                closeModalCallbackstate={confirmModal}
                approveCallback={() => {
                  setopenModal(true);
                }}
                closeModalCallback={() => {
                  setconfirmModal(false);
                }}
              />
            ) : null}
          </>
          <>
            {openModal ? (
              <div>
                <RetryApprovedModal
                  width={"30%"}
                  border={"none"}
                  bg={"#005674"}
                  closeModal={() => {
                    setopenModal(false);
                  }}
                />
              </div>
            ) : null}
          </>
          <>
            {openviewModal ? (
              <ViewActivityModal
                width={"30%"}
                border={"none"}
                closeModalCallback={() => {
                  setopenviewModal(false);
                }}
                transaction={transaction}
              />
            ) : null}
          </>
        </div>
      </div>
    );
  }
);

export const SendAndReceive = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ activities, retryTransferAction, currencies, prices }) => {
  const [openviewModal, setopenviewModal] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [transaction, settransaction] = useState(null);
  const [confirmModal, setconfirmModal] = useState(null);
  const [typeOfTransfer, settypeOfTransfer] = useState(null);

  const bg = (curr) => {
    return curr.status === "completed"
      ? "#D1F7C4"
      : curr.status === "pending"
      ? "#FFEAB6"
      : curr.status === "matched"
      ? "#D0F0FD"
      : curr.status === "failed"
      ? "#FFDCE5"
      : curr.status === "cancelled"
      ? "#FFDCE5"
      : "#D1F7C4";
  };

  const color = (curr) => {
    return curr.status === "completed"
      ? "#337138"
      : curr.status === "pending"
      ? "#606060"
      : curr.status === "matched"
      ? "#335F71"
      : curr.status === "failed"
      ? "#531423"
      : curr.status === "cancelled"
      ? "#531423"
      : "";
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {activities &&
        activities.map((activityGroup, idx, arr) => {
          return (
            <SendReceiveTableStyle
              className={idx % 2 === 0 ? "w-full my-10" : "w-full"}
              key={idx}
            >
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div className="pt-4 pb-4 px-1.5">
                      <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                        {`${timeAgo.format(
                          new Date(activityGroup.group)
                        )} — ${new Date(activityGroup.group).toDateString()}`}
                      </P>
                    </div>
                  </td>
                </tr>
                {activityGroup.data &&
                  activityGroup.data.map((curr, idx, arr) => {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid #EFF0F5",
                          width: "100%",
                        }}
                        key={curr.id}
                      >
                        <td className="pt-4 pb-4">
                          <div className="flex">
                            <div className="pr-2">
                              <img
                                src={
                                  curr.type === "send"
                                    ? ic_sent_icon
                                    : curr.type === "receive"
                                    ? ic_receive_icon
                                    : curr.type === "swap"
                                    ? ic_swapped_coin
                                    : ""
                                }
                                alt="send"
                                className="h-auto w-auto"
                              />
                            </div>
                            <div>
                              <P
                                fontSize="13px"
                                color={"#374072"}
                                fontWeight="500"
                              >{`${capitalizeFirstLetter(curr.type)} ${
                                curr.currency
                              }`}</P>
                              <P fontSize="10px" color={"#7A809B"}>
                                {
                                  new Date(curr.created_at)
                                    .toLocaleString()
                                    .split(",")[1]
                                }
                              </P>
                            </div>
                          </div>
                        </td>
                        <td className="pt-4 pb-4">
                          <div className="flex">
                            <div>
                              <P
                                fontSize="16px"
                                color={
                                  parseFloat(curr.amount) > 0
                                    ? "#005674"
                                    : parseFloat(curr.amount) < 0
                                    ? "#D60000"
                                    : "#005674"
                                }
                                fontWeight="600"
                                className="mr-1.5 tooltip"
                              >
                                {curr.currency} {Number(curr.amount).toFixed(8)}
                                <div className="tooltiptext">
                                  <div>
                                    <P
                                      fontSize="16px"
                                      color={"#fff"}
                                      fontWeight="500"
                                    >
                                      USD{" "}
                                      {curr && curr.amount_usd_equivalent
                                        ? curr.amount_usd_equivalent
                                        : null}
                                    </P>
                                  </div>
                                  <span>
                                    Platform Fee
                                    <span>
                                      {/* <P fontSize="13px" color={"#fff"} fontWeight="500" >{ prices && prices[curr.currency] && prices[curr.currency].usd ? prices[curr.currency].usd * curr.fee: 0} USD</P> */}
                                      <P
                                        fontSize="13px"
                                        color={"#fff"}
                                        fontWeight="500"
                                      >
                                        {curr && curr.fee
                                          ? Number(curr.fee).toFixed(8)
                                          : 0.0}{" "}
                                        {curr.currency}
                                      </P>
                                    </span>
                                  </span>
                                </div>
                              </P>
                              <P
                                fontSize="13px"
                                color={"#646A86"}
                                fontWeight="500"
                              >
                                {curr && curr.blockchain_fee
                                  ? curr.blockchain_fee
                                  : null}
                              </P>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div
                            className={`${
                              curr.status === "pending" && curr.type === "send"
                                ? "w-3/12"
                                : "w-6/12"
                            }`}
                          >
                            <P
                              fontSize="13px"
                              color={"#374072"}
                              fontWeight="500"
                            >
                              {curr.account_id !== "platform"
                                ? curr && curr.account && curr.account.email
                                  ? curr.account.email
                                  : "no email"
                                : "Platform"}
                            </P>
                          </div>
                        </td>

                        <td>
                          {curr.status ? (
                            <div className={"w-3/12 flex justify-end"}>
                              <P
                                fontSize="13px"
                                color={"#0087B5"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  settransaction(curr);
                                  setopenviewModal(true);
                                }}
                              >
                                View
                              </P>
                            </div>
                          ) : null}
                        </td>

                        <td className="text-right pt-4 pb-4">
                          <div
                            className={`flex ${
                              curr.status === "pending" && curr.type === "send"
                                ? "justify-between"
                                : "justify-end"
                            }`}
                          >
                            {curr.status === "pending" &&
                            curr.type === "send" ? (
                              <div className="w-6/12 flex justify-between">
                                <DefaultButton
                                  small
                                  className={"mr-3"}
                                  p={"5px 16px 5px 16px"}
                                  bg={"#24B57A"}
                                  color={"#FFF"}
                                  opacity={
                                    curr.status !== "pending" &&
                                    curr.type === "send"
                                      ? "0.1"
                                      : "1"
                                  }
                                  disabled={
                                    curr.status !== "pending" &&
                                    curr.type === "send"
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    settransaction(curr);
                                    settypeOfTransfer("retry");
                                    setconfirmModal(true);
                                  }}
                                >
                                  {curr.status === "pending" &&
                                  curr.type === "send"
                                    ? "Approve"
                                    : ""}
                                </DefaultButton>

                                <DefaultButton
                                  small
                                  p={"5px 16px 5px 16px"}
                                  bg={"#FF6A6A"}
                                  color={"#FFF"}
                                  opacity={
                                    curr.status !== "pending" &&
                                    curr.type === "send"
                                      ? "0.1"
                                      : "1"
                                  }
                                  disabled={
                                    curr.status !== "pending" &&
                                    curr.type === "send"
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    settransaction(curr);
                                    settypeOfTransfer("reject");
                                    setconfirmModal(true);
                                  }}
                                >
                                  {curr.status === "pending" &&
                                  curr.type === "send"
                                    ? "Reject"
                                    : ""}
                                </DefaultButton>
                              </div>
                            ) : null}

                            <div
                              className={`${
                                curr.status === "pending" &&
                                curr.type === "send"
                                  ? "w-3/12 text-right"
                                  : "w-6/12 flex justify-end"
                              }`}
                            >
                              <Badge bg={bg(curr)} color={color(curr)}>
                                {curr.status}
                              </Badge>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </SendReceiveTableStyle>
          );
        })}

      <>
        {confirmModal ? (
          <ConfirmTransferModal
            width={"30%"}
            border={"none"}
            typeOfTransfer={typeOfTransfer}
            transaction={transaction}
            closeModalCallbackstate={confirmModal}
            approveCallback={() => {
              setopenModal(true);
            }}
            closeModalCallback={() => {
              setconfirmModal(false);
            }}
          />
        ) : null}
      </>

      <>
        {openModal ? (
          <div>
            <RetryApprovedModal
              width={"30%"}
              border={"none"}
              bg={"#005674"}
              closeModal={() => {
                setopenModal(false);
              }}
            />
          </div>
        ) : null}
      </>

      <>
        {openviewModal ? (
          <ViewActivityModal
            width={"30%"}
            border={"none"}
            closeModalCallback={() => {
              setopenviewModal(false);
            }}
            transaction={transaction}
          />
        ) : null}
      </>
    </div>
  );
});

export const TradeActivitiesTable = ({ activities }) => {
  const bgColor = (curr) => {
    return curr.status === "complete"
      ? "#D1F7C4"
      : curr.status === "pending"
      ? "#FFEAB6"
      : curr.status === "matched"
      ? "#D0F0FD"
      : curr.status === "failed"
      ? "#FFDCE5"
      : "#D1F7C4";
  };

  const color = (curr) => {
    return curr.status === "complete"
      ? "#337138"
      : curr.status === "pending"
      ? "#606060"
      : curr.status === "matched"
      ? "#335F71"
      : curr.status === "failed"
      ? "#531423"
      : "";
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <ActivitiesTablesHeaderStyle className="w-full">
        <tbody>
          <tr className="Table-heading">
            <td width="15%" className="pt-4 pb-4 px-1.5"></td>

            <td width="20%" className="pt-4 pb-4 px-1.5">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  USER
                </SubHeader>
              </div>
            </td>
            <td width="20%" className=" pt-4 pb-4 px-1.5">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  COIN PRICE
                </SubHeader>
              </div>
            </td>
            <td width="20%" className="pt-4 pb-4 px-1.5">
              <div className="">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  PAID
                </SubHeader>
              </div>
            </td>
            <td width="20%" className="pt-4 pb-4 px-1.5">
              <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                RECIEVED
              </SubHeader>
            </td>

            <td width="5%" className="pt-4 pb-4 px-1.5"></td>
          </tr>
        </tbody>
      </ActivitiesTablesHeaderStyle>
      {activities &&
        activities.map((activityGroup, idx, arr) => {
          return (
            <ActivitiesTableStyle
              className={idx % 2 === 0 ? "w-full my-10" : "w-full"}
              key={idx}
            >
              <tbody>
                <tr>
                  <td colSpan="6">
                    <div className="pt-4 pb-4">
                      <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                        {`${timeAgo.format(
                          new Date(activityGroup.group)
                        )} — ${new Date(activityGroup.group).toDateString()}`}
                      </P>
                    </div>
                  </td>
                </tr>
                {activityGroup.data.map((curr, idx, arr) => {
                  return (
                    <tr
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EFF0F5",
                      }}
                      key={curr.id}
                    >
                      <td width="15%" className="pt-4 pb-4 px-1.5">
                        <div className="flex">
                          <div>
                            <img
                              src={
                                curr.destination_currency === "NGN" &&
                                curr.source_currency !== "NGN"
                                  ? ic_sold_coin
                                  : curr.destination_currency !== "NGN" &&
                                    curr.source_currency === "NGN"
                                  ? ic_bought_coin
                                  : curr.destination_currency !== "NGN" &&
                                    curr.source_currency !== "NGN"
                                  ? ic_swapped_coin
                                  : ""
                              }
                              alt="icon_swap"
                            />
                          </div>
                          <div>
                            <P
                              fontSize="14px"
                              color={"#005674"}
                              fontWeight="500"
                            >
                              {curr.destination_currency === "NGN" &&
                              curr.source_currency !== "NGN"
                                ? `Sell ${curr.source_currency}`
                                : curr.destination_currency !== "NGN" &&
                                  curr.source_currency === "NGN"
                                ? `Buy ${curr.destination_currency}`
                                : curr.destination_currency !== "NGN" &&
                                  curr.source_currency !== "NGN"
                                ? `Swap ${curr.source_currency}`
                                : null}
                            </P>
                            <P fontSize="10px" color={"#7A809B"}>
                              {
                                new Date(curr.created_at)
                                  .toLocaleString()
                                  .split(",")[1]
                              }
                            </P>
                          </div>
                        </div>
                      </td>

                      <td width="20%" className="pt-4 pb-4 px-1.5">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {curr && curr.account && curr.account.email
                            ? curr.account.email
                            : "no email"}
                        </P>
                      </td>

                      <td width="20%" className="pt-4 pb-4 px-1.5">
                        <P fontSize="14px" color={"#005674"} fontWeight="500">
                          {curr.destination_currency === "NGN" &&
                          curr.source_currency !== "NGN"
                            ? `${curr.destination_currency} ${parseFloat(
                                curr.exchange_rate_multiplier
                              ).toLocaleString()} / ${curr.source_currency}`
                            : curr.destination_currency !== "NGN" &&
                              curr.source_currency === "NGN"
                            ? `${curr.source_currency} ${parseFloat(
                                curr.exchange_rate_divisor
                              ).toLocaleString()} / ${
                                curr.destination_currency
                              }`
                            : curr.destination_currency !== "NGN" &&
                              curr.source_currency !== "NGN"
                            ? `${curr.source_currency} ${parseFloat(
                                curr.exchange_rate_divisor
                              ).toFixed(6)} / ${curr.destination_currency}`
                            : null}
                        </P>
                        <P fontSize="10px" color={"#7A809B"}>
                          USD{" "}
                          {curr.destination_currency === "NGN" &&
                          curr.source_currency !== "NGN"
                            ? `${(
                                curr.source_amount_usd_equivalent /
                                curr.source_amount
                              ).toFixed(8)}`
                            : curr.destination_currency !== "NGN" &&
                              curr.source_currency === "NGN"
                            ? `${(
                                curr.destination_amount_usd_equivalent /
                                curr.destination_amount
                              ).toFixed(8)}`
                            : curr.destination_currency !== "NGN" &&
                              curr.source_currency !== "NGN"
                            ? `${(
                                curr.destination_amount_usd_equivalent /
                                curr.destination_amount
                              ).toFixed(8)}`
                            : null}
                        </P>
                      </td>

                      <td width="20%" className="pt-4 pb-4 px-1.5">
                        <P fontSize="14px" color={"#005674"} fontWeight="500">
                          {curr.source_currency === "NGN"
                            ? ` ${curr.source_currency} ${parseFloat(
                                curr.source_amount
                              ).toLocaleString()}`
                            : curr.source_currency !== "NGN"
                            ? `${curr.source_currency} ${parseFloat(
                                curr.source_amount
                              ).toFixed(6)}`
                            : `${curr.source_currency} ${curr.source_amount}`}
                        </P>

                        <P fontSize="10px" color={"#7A809B"}>
                          USD{" "}
                          {curr && curr.source_amount_usd_equivalent
                            ? Number(curr.source_amount_usd_equivalent).toFixed(
                                2
                              )
                            : null}
                        </P>
                      </td>

                      <td width="20%" className="pt-4 pb-4 px-1.5">
                        <P fontSize="14px" color={"#005674"} fontWeight="500">
                          {curr.destination_currency === "NGN"
                            ? ` ${curr.destination_currency} ${parseFloat(
                                curr.destination_amount
                              ).toLocaleString()}`
                            : curr.destination_currency !== "NGN"
                            ? `${curr.destination_currency} ${parseFloat(
                                curr.destination_amount
                              ).toFixed(6)}`
                            : `${curr.destination_currency} ${curr.destination_currency}`}
                        </P>
                        <P fontSize="10px" color={"#7A809B"}>
                          USD{" "}
                          {curr && curr.destination_amount_usd_equivalent
                            ? Number(
                                curr.destination_amount_usd_equivalent
                              ).toFixed(2)
                            : null}
                        </P>
                      </td>

                      <td width="5%" align="right" className="pt-4 pb-4 px-1.5">
                        <Badge bg={bgColor(curr)} color={color(curr)}>
                          {curr.status}
                        </Badge>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ActivitiesTableStyle>
          );
        })}
    </div>
  );
};
