import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import {
  overviewAction,
  pricesAction,
} from "../../../store/Actions/OverviewActions";
import {
  getBanksAction,
  transactionAction,
} from "../../../store/Actions/TransactionActions";
import { Container, Main } from "./Dashboard.styled";
import Sidebar from "./Sidebar";

const Dashboard = ({ children, pricesAction, currencies, prices, theme }) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <Container>
        <Sidebar />

        <Main className="px-5 pt-3 md:px-10 md:pt-6">{children}</Main>
      </Container>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    overview: state.overview.overview,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.overview.loading,
    error: state.overview.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getBanksAction: () => dispatch(getBanksAction()),
    overviewAction: () => dispatch(overviewAction()),
    pricesAction: () => dispatch(pricesAction()),
    transactionAction: () => dispatch(transactionAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
