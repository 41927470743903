import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSavingsOverviewStats } from "../../../../store/Actions/SavingsActions";
import { H5, SubHeader } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder, TabNav } from "../../../Units/CardTitle.styled";

const DetailsCard = ({
  Titlecolor,
  overview,
  overviewAction,
  type,
  subStats
}) => {
  const TABS = [
    { label: "Today", queryParam: "today" },
    { label: "7 days", queryParam: "7d" },
    { label: "30 days", queryParam: "30d" },
    { label: "All time", queryParam: "all" },
  ];

  const [activeTab, handleTab] = useState(3);
  const [queryParam, setqueryParam] = useState("all");

  useEffect(() => {
    console.log(queryParam);
    overviewAction(queryParam);
  }, [queryParam, overviewAction]);

  return (
    <div className="w-full overflow-hidden lg:mb-5 lg:px-5 lg:w-1/2 xl:w-1/2">
      <Card>
        <CardHolder bg={Titlecolor} className="flex items-center justify-end">
          <div className="w-4/12">
            {type === "flexible"
              ? "Flexible Savings"
              : type === "fixed"
              ? "Fixed Savings"
              : ""}
          </div>
          <div className="w-8/12 flex justify-end">
            <TabNav
              tabs={TABS}
              activeTab={activeTab}
              handleTab={handleTab}
              p={"4px 8px 3px 8px"}
              Keys={["label", "queryParam"]}
              // borderRadius={"0px"}
              boxShadow={"0px"}
              callback={(curr) => {
                setqueryParam(curr["queryParam"]);
              }}
            />
          </div>
        </CardHolder>

        <div className=" flex justify-between px-4">
          <div className="w-3/12 py-4">
            <SubHeader fontSize="13px" color={"#7C8191"}>
              TOTAL SAVED
            </SubHeader>
            <H5 color={"#112E46"} fontSize="20px" fontWeight="700">
              USDT{" "}
              {overview
                ? type === "flexible"
                  ? overview.totalFlexibleSaved.toLocaleString()
                  : type === "fixed"
                  ? overview.totalFixedSaved.toLocaleString()
                  : 0
                : 0}
            </H5>
          </div>

          {/* <div className="w-4/12 py-4">
            <SubHeader fontSize="13px" color={"#7C8191"}>
              PENDING
            </SubHeader>
            <H5 color={"#112E46"} fontSize="20px" fontWeight="700">
              ₦ {pending ? pending.toLocaleString() : 0}
            </H5>
          </div> */}

          <div className="w-5/12 py-4">
            <SubHeader fontSize="13px" color={"#7C8191"}>
              {`TOTAL ${
                type === "flexible"
                  ? "FLEXIBLE"
                  : type === "fixed"
                  ? "FIXED"
                  : ""
              } PAYABLE`}
            </SubHeader>
            <H5 color={"#112E46"} fontSize="20px" fontWeight="700">
              USDT{" "}
              {overview
                ? type === "flexible"
                  ? overview.totalFlexiblePayable.toLocaleString()
                  : type === "fixed"
                  ? overview.totalFixedPayable.toLocaleString()
                  : 0
                : 0}
            </H5>
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    overview: state.savings.overviewStats,
    loading: state.savings.loading,
    error: state.savings.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: (query) => dispatch(getSavingsOverviewStats(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsCard);
