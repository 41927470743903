import React, { useEffect } from "react";
import caret_down from "../../../assets/images/caret-down1.svg";
import { SelectInput } from "../../Units/TabsAndLinkStyled";
import { H5, P } from "../../../UI toolkit/Text/Text";
import { UserDetails } from "./Dashboard.styled";
import { connect } from "react-redux";
import {
  overviewAction,
  pricesAction,
} from "../../../store/Actions/OverviewActions";
import { Link, withRouter } from "react-router-dom";
import { Logout } from "../../../store/Actions/AuthActions";
import ic_send_icon from "../../../assets/images/ic-send-coin.svg";
import ic_receive_icon from "../../../assets/images/ic-receive-coin.svg";
import ic_nav from "../../../assets/images/ic-nav.svg";
import { useDispatch } from "react-redux";

const DashboardHeader = ({
  children,
  match,
  history,
  user,
  prices,
  currencies,
  pricesAction,
  overviewAction,
  Logout,
  mobileNav,
}) => {
  const dispatch = useDispatch();

  const toggleNav = () => {
    dispatch({ type: "TOGGLE_MOBILE_NAV", mobileNav: !mobileNav });
  };

  useEffect(() => {
    overviewAction();
    pricesAction();
  }, [overviewAction, pricesAction]);

  var valueOfBtc = currencies
    ? currencies.filter((curr) => curr.symbol === "BTC")
    : 0;

  return (
    <>
      <div className="hidden lg:flex items-center pb-12">
        <div className="w-4/12">
          <H5 color="#262626" fontWeight="700">
            {children}
          </H5>
        </div>

        <div className={`flex justify-between w-8/12 items-center`}>
          <div className="w-4/12">
            <div className="flex">
              {user.role === "super-admin" ? (
                <div className="w-6/12">
                  <Link
                    className="flex items-center"
                    to={{
                      pathname: "/dashboard/send-and-recieve",
                      state: { Tab: 0 },
                    }}
                  >
                    <img src={ic_send_icon} alt="icons" />
                    <P className="ml-2" fontSize="13px" color={"#112E46"}>
                      Send
                    </P>
                  </Link>
                </div>
              ) : null}

              <div className="w-6/12">
                <Link
                  className="flex items-center"
                  to={{
                    pathname: "/dashboard/send-and-recieve",
                    state: { Tab: 1 },
                  }}
                >
                  <img src={ic_receive_icon} alt="icons" />
                  <P className="ml-2" fontSize="13px" color={"#112E46"}>
                    Recieve
                  </P>
                </Link>
              </div>
            </div>
          </div>

          <div className="w-5/12">
            <SelectInput
              bgImg={caret_down}
              bgColor={"#262626"}
              borderRadius={"17px"}
              color={"#fff"}
              p={"4px 6px"}
              hoverBg={""}
              hoverColor={""}
              currencies={currencies ? currencies : null}
              prices={prices ? prices : 0}
              defaultValue={valueOfBtc ? valueOfBtc[0] : 0}
              callback={(currency, coinImage, price) => {
                console.log(currency, coinImage, price);
              }}
            />
          </div>

          <div className="flex justify-end w-3/12">
            <UserDetails
              name={user ? user.first_name + "\n" + user.last_name : ""}
              initials={
                user ? user.first_name.charAt(0) + user.last_name.charAt(0) : ""
              }
            />
          </div>
        </div>
      </div>

      <div className="lg:hidden flex items-center pb-12">
        <div className="w-8/12 flex items-center">
          <div className="lg:hidden mr-6" onClick={toggleNav}>
            <img src={ic_nav} className="w-100 h-auto" alt="nav" />
          </div>
          <H5 color="#262626" fontWeight="700">
            {children}
          </H5>
        </div>

        <div className={`w-4/12 flex justify-end items-center`}>
          <div className="w-7/12 flex justify-center items-center">
            {user.role === "super-admin" ? (
              <div className="w-6/12">
                <Link
                  className="flex items-center"
                  to={{
                    pathname: "/dashboard/send-and-recieve",
                    state: { Tab: 0 },
                  }}
                >
                  <img src={ic_send_icon} alt="icons" />
                </Link>
              </div>
            ) : null}
            <div className="w-6/12">
              <Link
                className="flex items-center"
                to={{
                  pathname: "/dashboard/send-and-recieve",
                  state: { Tab: 1 },
                }}
              >
                <img src={ic_receive_icon} alt="icons" />
              </Link>
            </div>
          </div>
          <div className="w-5/12 flex justify-end items-center">
            <UserDetails
              name={user ? user.first_name + "\n" + user.last_name : ""}
              initials={
                user ? user.first_name.charAt(0) + user.last_name.charAt(0) : ""
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    overview: state.overview.overview,
    transactions: state.overview.transactions,
    total_users: state.overview.total_users,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    mobileNav: state.UI.mobileNav,
    loading: state.overview.loading,
    error: state.overview.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: () => dispatch(overviewAction()),
    pricesAction: () => dispatch(pricesAction()),
    Logout: () => dispatch(Logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardHeader));
