import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeAdminPasswordAction, getAdminsAction, removeAdminAction, updateAdminAction } from "../../../../store/Actions/SettingsActions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder } from "../../../Units/CardTitle.styled";
import AddAdminModal from "./AddAdminModal";
import { AdminsTable } from "./SettingsTable/SettingsTables";

const Admin = ({ admins, getAdmins, removeAdmin, changePassword, updateAdmin }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    action: "create",
    id: null
  });

  useEffect(() => {
    getAdmins()
  }, [getAdmins])

  return (
    <div>
      {modal.isOpen === true ? (
        <AddAdminModal setModal={setModal} modal={modal} getAdmins={getAdmins} />
      ) : null}

      <div>
        <Card>
          <CardHolder>
            <div className="flex items-center">
              <div className="w-10/12">
                <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                  Platform managers
                </P>
              </div>

              <div className="w-2/12">
                <DefaultButton
                  p={"7px 19px 7px 19px"}
                  bg={"#E5EEF1"}
                  color={"#005674"}
                  width={"100%"}
                  onClick={() => {
                    setModal({
                      isOpen: true,
                      action: "create",
                      id: null
                    });
                  }}
                >
                  Add new admin
                </DefaultButton>
              </div>
            </div>
          </CardHolder>

          <div className="">
            <AdminsTable admins={admins} removeAdmin={removeAdmin} getAdmins={getAdmins} modal={modal} setModal={setModal} />
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admins: state.settings.adminList,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getAdmins: () => dispatch(getAdminsAction()),
    removeAdmin: (id, successCallback) =>
      dispatch(removeAdminAction(id, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admin));
