import skye_logo from "../assets/skye-logo-white.svg";
import flitaa_logo from "../assets/flitaa-logo-white.svg";
import ngn from "../assets/images/ngn.svg";
import bitcoin from "../assets/images/Bitcoin.svg";
import bitcoinCash from "../assets/images/Bitcoin Cash.svg";
import EOS from "../assets/images/EOS.svg";
import litecoin from "../assets/images/Litecoin.svg";
import ripple from "../assets/images/Ripple.svg";
import Tron from "../assets/images/Tron.svg";
import USDT from "../assets/images/USDT.svg";
import ETH from "../assets/images/ic_eth.svg";
import DASH from "../assets/images/ic-dash.svg";
import BNB from "../assets/images/ic-bnb-bep20.svg";
import BUSD from "../assets/images/ic-busd-bep20.svg";
import ADA from "../assets/images/ic-ada-bep20.svg";
import BTT from "../assets/images/ic-btt-bep20.svg";
import DOGE from "../assets/images/ic-doge-bep20.svg";
import LINK from "../assets/images/ic-link-bep20.svg";
import SHIB from "../assets/images/ic-shib.png";
import CAKE from "../assets/images/ic-cake-bep20.svg";
import UNI from "../assets/images/ic-uniswap-bep20.svg";
import logo from "../assets/skye-logo-white.svg"
import config from "../config";

export const Flitaa = {
  colors: {
    bg: "#000",
    color: "#fff",
    logo: config.img_url,
    activeBg: "#52DDA2",
    activeColor: "#FFF",
    activeOutline: "",
    activeOutlineBottom: "",
    boxShadow: "",
    borderRadius: "",
    border: "1px solid #0039D6",
    p: "9px 16px 9px 16px",
    linkColor: "#fff",
  },
};

export const SkyeWallet = {
  colors: {
    bg: "#112E46",
    color: "#fff",
    logo: logo,
    activeBg: "#0087B5",
    activeColor: "#FFF",
    activeOutline: "",
    activeOutlineBottom: "",
    boxShadow: "",
    borderRadius: "",
    border: "1px solid #0039D6",
    p: "9px 16px 9px 16px",
    linkColor: "#fff",
  },
};

export const Fimbre = {
  colors: {
    bg: "#1E3471",
    color: "#fff",
    logo: config.img_url,
    activeBg: "#EFF0F5",
    activeColor: "#1E3471",
    activeOutline: "",
    activeOutlineBottom: "",
    boxShadow: "",
    borderRadius: "",
    border: "1px solid #0039D6",
    p: "9px 16px 9px 16px",
    linkColor: "#fff",
  },
};

export const coinImages = {
  BTC: bitcoin,
  BCH: bitcoinCash,
  EOS: EOS,
  LTC: litecoin,
  RPP: ripple,
  TRN: Tron,
  USDT: USDT,
  NGN: ngn,
  ETH: ETH,
  DASH: DASH,
  BNB: BNB,
  BUSD: BUSD,
  ADA: ADA,
  BTT: BTT,
  DOGE: DOGE,
  LINK: LINK,
  SHIB: SHIB,
  CAKE: CAKE,
  UNI: UNI,
};
