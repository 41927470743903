import React, { useState } from "react";
import { connect } from "react-redux";
import {
  ApproveUserAction,
  RejectUserAction,
  viewVerificationDocumentsAction,
} from "../../../../store/Actions/VerificationActions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { H1, H6 } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { Dropdown, Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import caret_down from "../../../../assets/images/caret-down.svg";

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    verificationsList: state.verification.verificationsList,
    verificationDoc: state.verification.verificationDoc,
    loadVerificationDoc: state.verification.loadVerificationDoc,
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    viewVerificationDocumentsAction: (key) =>
      dispatch(viewVerificationDocumentsAction(key)),
    ApproveUserAction: (id) => dispatch(ApproveUserAction(id)),
    RejectUserAction: (id, state) => dispatch(RejectUserAction(id, state)),
  };
};

// named exported components

export const ApproveModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ width, closeModal, approveCallback, id, ApproveUserAction }) => {
  return (
    <Modal width={width} callback={closeModal}>
      <div className="flex items-center justify-center">
        <div>
          <div className="pt-4">
            <H1 fontSize="16px" fontWeight="700" color={"#7A809B"}>
              Do you want to Approve this user as Verified?
            </H1>
          </div>

          <div className="flex items-center justify-center">
            <div className="py-4 pr-6">
              <DefaultButton
                bg={"#112E46"}
                color={"#fff"}
                p={"8px 16px"}
                onClick={() => {
                  ApproveUserAction && ApproveUserAction(id);
                }}
              >
                Yes
              </DefaultButton>
            </div>

            <div className="py-4">
              <DefaultButton
                bg={"#FF6A6A"}
                color={"#fff"}
                p={"8px 16px"}
                onClick={closeModal}
              >
                No
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export const RejectModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ width, closeModal, approveCallback, id, RejectUserAction }) => {
  return (
    <Modal width={width} callback={closeModal}>
      <div className="flex items-center justify-center">
        <div>
          <div className="pt-4">
            <H1 fontSize="16px" fontWeight="700" color={"#7A809B"}>
              Do you want to Reject this user as Verified?
            </H1>
          </div>

          <div className="flex items-center justify-center">
            <div className="py-4 pr-6">
              <DefaultButton
                bg={"#112E46"}
                color={"#fff"}
                p={"8px 16px"}
                onClick={() => {
                  RejectUserAction && RejectUserAction(id, null);
                }}
              >
                Yes
              </DefaultButton>
            </div>

            <div className="py-4">
              <DefaultButton
                bg={"#FF6A6A"}
                color={"#fff"}
                p={"8px 16px"}
                onClick={closeModal}
              >
                No
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export const ViewDocumentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    closeModal,
    user,
    loadVerificationDoc,
    verificationDoc,
    viewVerificationDocumentsAction,
  }) => {
    return (
      <div>
        <Modal width={width} callback={closeModal}>
          <div className="Transaction-Approved flex items-center justify-center">
            <div>
              <div className="flex h-full p-5">
                {user &&
                  user.documents.map((document, idx, arr) => {
                    return (
                      <div className="mr-5" key={idx}>
                        <Card className="p-5">
                          <H6
                            color={"#112E46"}
                            className="text-center"
                            fontWeight="700"
                          >
                            Document {idx + 1}
                          </H6>

                          <a href={document} target="blank">
                            <DefaultButton
                              small
                              p={"7px 19px 7px 19px"}
                              bg={"#24B57A"}
                              color={"#FFF"}
                            >
                              View Document
                            </DefaultButton>
                          </a>
                        </Card>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export const RejectReasonModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ width, closeModal, approveCallback, id, RejectUserAction }) => {
  const [state, setstate] = useState({
    reason: "",
  });

  const reasonTexts = [
    { text: "ID NOT CLEAR" },
    { text: "FULL COPY OF INTERNATIONAL PASSPORT NEEDED" },
    { text: "NAME IRREGULARITIES" },
    { text: "INVALID ID" },
    { text: "PASSPORT PHOTOGRAPH NOT CLEAR" },
    { text: "FORM OF ID CARD NOT ACCEPTED" },
    { text: "NO ID CARD PASSPORT PHOTOGRAPH USED" },
  ];
  return (
    <>
      <Modal width={width} callback={closeModal}>
        <div className="flex items-center justify-center">
          <div>
            <div className="py-2">
              <H1 fontSize="16px" fontWeight="700" color={"#7A809B"}>
                Reason for Rejecting Verification
              </H1>
            </div>

            <div>
              <Dropdown
                items={reasonTexts}
                bgImg={caret_down}
                bgColor={"transparent"}
                color={"#374072"}
                p={"11px 16px 10px 16px"}
                callback={(e) => {
                  console.log(e);
                  setstate({ ...state, reason: e.target.value });
                }}
              />
            </div>

            <div className="flex items-center justify-center">
              <div className="py-4 pr-6">
                <DefaultButton
                  bg={"#112E46"}
                  color={"#fff"}
                  p={"8px 16px"}
                  onClick={() => {
                    RejectUserAction && RejectUserAction(id, state);
                  }}
                >
                  Reject
                </DefaultButton>
              </div>

              <div className="py-4">
                <DefaultButton
                  bg={"#FF6A6A"}
                  color={"#fff"}
                  p={"8px 16px"}
                  onClick={closeModal}
                >
                  Cancel
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});
