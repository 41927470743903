import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { SearchInput } from "../../../../UI toolkit/Input/Input";
import { Card } from "../../../Units/Card.styled";
import DashboardHeader from "../DashboardHeader";
import search_icon from "../../../../assets/images/search.svg";
import { CardHolder } from "../../../Units/CardTitle.styled";
import { P } from "../../../../UI toolkit/Text/Text";
import {
  DefaultButton,
  OutlineButton,
} from "../../../../UI toolkit/Button/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUsers } from "../../../../store/Actions/UsersAction";
import { MerchantsListTable } from "./MerchantTable";
import { filterUsersonChange } from "../../../../utils/specialFunctions";
import { getMerchants } from "../../../../store/Actions/MerchantsAction";
import ic_back_white from "../../../../assets/images/ic-back-white.svg";

const Merchants = ({ merchantsList, user, loading, getMerchants }) => {
  const location = useLocation();
  const [keyword, setkeyword] = useState(null);

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>
          Users ({merchantsList ? merchantsList.length : 0}) - Merchants
        </DashboardHeader>

        <div className="flex justify-between">
          <div className="flex w-8/12 pb-6">
            <div className="w-8/12 pr-5">
              <SearchInput
                placeholder="Search merchants"
                search_icon={search_icon}
                bg={"#FFFFFF"}
                color={"#646A86"}
                p={"8px 16px"}
                callack={(e) => {
                  setkeyword(e.target.value);
                }}
              />
            </div>
          </div>

          <div>
            <Link to="/dashboard/messages">
              <OutlineButton
                bg={"#FFFFFF"}
                border={"1px solid #005674"}
                color={"#005674"}
                p={"8px 16px"}
                boxShadow={"0px 1px 3px #00000019"}
              >
                Messages
              </OutlineButton>
            </Link>
          </div>
        </div>

        <Card>
          <CardHolder>
            <div className="flex items-center">
              <div className="w-9/12">
                <div className="w-2/12 flex items-center justify-between">
                  <Link to="/dashboard/users">
                    <img src={ic_back_white} alt="back" />
                  </Link>
                  <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                    Merchants
                  </P>
                </div>
              </div>

              {user.role === "super-admin" ? (
                <div className="w-3/12">
                  <Link to="/dashboard/merchants/+">
                    <DefaultButton
                      p={"7px 19px 7px 19px"}
                      bg={"#E5EEF1"}
                      color={"#005674"}
                      width={"100%"}
                    >
                      Add new merchant
                    </DefaultButton>
                  </Link>
                </div>
              ) : null}
            </div>
          </CardHolder>

          <div>
            <MerchantsListTable
              users={filterUsersonChange(merchantsList, keyword)}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    merchantsList: state.merchants.merchantsList,
    loading: state.merchants.loading,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    getMerchants: () => dispatch(getMerchants()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchants);
