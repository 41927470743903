import React, { useState } from "react";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { Modal } from "../../../../../UI toolkit/Modal/Modal";
import { H1, H5, H6, P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import ic_sent_icon from "../../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../../assets/images/ic-received-coin.svg";
import ic_appr from "../../../../../assets/images/ic-appr.svg";
import { connect } from "react-redux";
import moment from "moment";

import { capitalizeFirstLetter } from "../../../../../utils/specialFunctions";
import { SavingsModalStyle } from "./UserSavings.styled";

const UserSavingModal = ({ width, border, closeModalCallback, saving }) => {
  return (
    <div>
      <Modal width={width} border={border} callback={closeModalCallback}>
        {saving ? (
          <SavingsModalStyle>
            <div className="-mx-8">
              <div className="flex items-center justify-between px-6 py-3 border-bottom bg-white">
                <div className="flex items-center">
                  <div>
                    <img
                      src={
                        saving.savings_type.type.toLowerCase() === "flexible"
                          ? ic_sent_icon
                          : saving.savings_type.type.toLowerCase() === "fixed"
                          ? ic_receive_icon
                          : ""
                      }
                      alt="img"
                    />
                  </div>
                  &nbsp;
                  <div>
                    <H6
                      color={"#112E46"}
                      className="text-center"
                      fontWeight="700"
                    >
                      {saving.savings_type.type === "flexible"
                        ? "Flexible"
                        : saving.savings_type.type === "fixed"
                        ? "Fixed"
                        : null}
                    </H6>
                  </div>
                </div>

                <div className="">
                  <Badge
                    color={
                      saving.status === "cancelled"
                        ? "#337138"
                        : saving.status === "stopped"
                        ? "#337138"
                        : saving.status === "pending"
                        ? "#606060"
                        : saving.status === "active"
                        ? "#335F71"
                        : saving.status === "completed"
                        ? "#531423"
                        : ""
                    }
                    bg={
                      saving.status === "cancelled"
                        ? "#FFDCE5"
                        : saving.status === "stopped"
                        ? "#FFDCE5"
                        : saving.status === "pending"
                        ? "#FFEAB6"
                        : saving.status === "active"
                        ? "#D0F0FD"
                        : saving.status === "completed"
                        ? "#D1F7C4"
                        : "#337138"
                    }
                  >
                    {saving.status}
                  </Badge>
                </div>
              </div>

              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      user
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="mb-2">
                    <P fontSize="16px" color={"#374072"} fontWeight="500">
                      {`${saving.account.first_name} ${saving.account.last_name}`}
                    </P>
                  </div>

                  <div className="">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {saving.account.email}
                    </P>
                  </div>

                  <div className="">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {saving.account.phone_number}
                    </P>
                  </div>
                </div>
              </div>
              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      title
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <P fontSize="14px" color={"#374072"} fontWeight="300">
                    {saving.title}
                  </P>
                </div>
              </div>
              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      amount
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <P fontSize="16px" color={"#00AAAF"} fontWeight="500">
                    {saving.currency}
                    {Number(saving.amount).toFixed(2).toLocaleString()}
                  </P>
                </div>
              </div>
              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Duration
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="flex items-center justify-between">
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      Start date
                    </H5>
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      {moment(saving.start_date).format("L")}
                    </H5>
                  </div>
                  <div className="flex items-center justify-between">
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      End date
                    </H5>
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      {moment(saving.end_date).format("L")}
                    </H5>
                  </div>
                  <div className="flex items-center justify-between">
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      Range
                    </H5>
                    <H5 fontSize="16px" color={"#374072"} fontWeight="500">
                      {`${saving?.savings_type?.duration} months`}
                    </H5>
                  </div>
                </div>
              </div>

              <div className="flex px-6 pt-6 pb-4 border-bottom">
                <div className="w-4/12">
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      id
                    </SubHeader>
                  </div>
                </div>

                <div className="w-8/12">
                  <div className="mb-1">
                    <P fontSize="16px" color={"#374072"} fontWeight="700">
                      {/* {transaction.account_id} */}
                      {saving.id}
                    </P>
                  </div>

                  <div className="my-1">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {new Date(saving.created_at).toDateString()}
                    </P>
                  </div>

                  <div className="mt-1">
                    <P fontSize="13px" color={"#374072"} fontWeight="500">
                      {new Date(saving.created_at).toLocaleTimeString()}
                    </P>
                  </div>
                </div>
              </div>
            </div>
          </SavingsModalStyle>
        ) : (
          <P fontSize="13px" color={"#374072"} fontWeight="500">
            Loading.....
          </P>
        )}
      </Modal>
    </div>
  );
};

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    loading: state.verification.loading,
    error: state.verification.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSavingModal);
