import React, { useEffect, useState } from "react";
import { Card } from "../../../Units/Card.styled";
import { CardHolder, CardTitle, TabNav } from "../../../Units/CardTitle.styled";
import ic_overview from "../../../../assets/images/ic-overview-db.svg";
import { connect } from "react-redux";
import {
  overviewAction,
  pricesAction,
  walletsAction,
} from "../../../../store/Actions/OverviewActions";
import UsersOverview from "./UsersOverview";
import TransactionsOverview from "./TransactionsOverview";
import DepositsOverview from "./DepositsOverview";
import WithdrawalsOverview from "./WithdrawalsOverview";
import { transactionAction } from "../../../../store/Actions/TransactionActions";
import { addTransactionsCurrencies } from "../../../../utils/specialFunctions";

const Overview = ({
  deposits,
  withdrawals,
  users,
  transactions,
  prices,
  currencies,
  overviewAction,
  transactionAction,
  loading,
  token,
}) => {
  const TABS = [
    { label: "Today", queryParam: "today" },
    { label: "7 days", queryParam: "7d" },
    { label: "30 days", queryParam: "30d" },
    { label: "All time", queryParam: "all" },
  ];

  const [activeTab, handleTab] = useState(3);
  const [queryParam, setqueryParam] = useState("all");

  let totalCash =
    transactions && prices && currencies
      ? addTransactionsCurrencies(transactions, prices, currencies, "ngn")
      : null;
  let totalCashDollarEq =
    transactions && prices && currencies
      ? addTransactionsCurrencies(transactions, prices, currencies, "usd")
      : null;

  // useEffect(() => {
  //   transactionAction();
  // }, [transactionAction, token]);

  useEffect(() => {
    overviewAction(queryParam);
    // transactionAction();
  }, [queryParam, transactionAction, overviewAction, token]);

  return (
    <div className="">
      <Card>
        <CardHolder>
          <div className="flex">
            <div className="w-6/12">
              <CardTitle img={ic_overview} text={"Overview"} />
            </div>
            <div className="w-6/12">
              <TabNav
                tabs={TABS}
                activeTab={activeTab}
                handleTab={handleTab}
                p={"4px 8px 3px 8px"}
                Keys={["label", "queryParam"]}
                // borderRadius={"0px"}
                boxShadow={"0px"}
                callback={(curr) => {
                  setqueryParam(curr["queryParam"]);
                }}
              />
            </div>
          </div>
        </CardHolder>

        <div
          className="flex py-6 pl-4"
          style={{ borderBottom: "1px solid #EFF0F5" }}
        >
          <UsersOverview users={users ? users : null} />

          <TransactionsOverview
            totalCash={totalCash ? totalCash : null}
            totalCashDollarEq={totalCashDollarEq ? totalCashDollarEq : null}
          />
        </div>

        <div className="flex py-6 px-4">
          <DepositsOverview deposits={deposits ? deposits : null} />

          <WithdrawalsOverview withdrawals={withdrawals ? withdrawals : null} />
        </div>
      </Card>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    users: state.overview.total_users,
    overview: state.overview.overview,
    transactions: state.overview.transactions,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.overview.loading,
    error: state.overview.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    pricesAction: () => dispatch(pricesAction()),
    overviewAction: (query) => dispatch(overviewAction(query)),
    transactionAction: () => dispatch(transactionAction()),
    walletsAction: () => dispatch(walletsAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
