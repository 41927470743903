import React from "react";
import { ToastContentWrapper } from "./ToastContentWrapper";
import icon_success from "../../assets/images/icon_success.png";
import icon_warning from "../../assets/images/icon_warning.png";
import icon_error from "../../assets/images/icon_error.png";
import icon_info from "../../assets/images/icon_info.png";
import icon_message from "../../assets/images/icon_message.png";

const ToastContent = ({ type, text }) => {
  let title, icon;
  switch (type) {
    case "success":
      title = "Success";
      icon = icon_success;
      break;
    case "warning":
      title = "Warning";
      icon = icon_warning;
      break;
    case "error":
      title = "Error";
      icon = icon_error;
      break;
    case "info":
      title = "Info";
      icon = icon_info;
      break;
    case "message":
      title = "New message";
      icon = icon_message;
      break;
    default:
      title = "Info";
      icon = icon_info;
      break;
  }
  return (
    <ToastContentWrapper>
      <img src={icon} alt={title} />
      <div>
        <p>{title}</p>
        <p>{text}</p>
      </div>
    </ToastContentWrapper>
  );
};

export default ToastContent;
