import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Card } from "../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../Units/TabsAndLinkStyled";
import TransactionsCard from "./TransactionsCard";
import { TransactionsTable } from "./TransactionsTable";
import caret_down from "../../../../assets/images/caret-down1.svg";
import { Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { connect } from "react-redux";
import {
  getDepositsAction,
  getDepositsAndWithdrawalsAction,
  getWithdrawalsAction,
  transactionAction,
  updateTransactionsActiveStatus,
  updateTransactionsActiveTab,
  updateTransactionsPage,
  updateWithDrawalsDepositActiveTab,
  updateWithDrawalsDepositNavigator,
  updateTransactionActiveMerchant,
  transactionStatsAction,
} from "../../../../store/Actions/TransactionActions";
import { getMerchants } from "../../../../store/Actions/MerchantsAction";
import DashboardHeader from "../DashboardHeader";
import {
  groupActivitiesByDaysOrMonth,
  filterPendingOrCompletedTransactions,
  getMerchantsStrings,
  filterTransactionsBasedOnMerchants,
  filterTransactionsByType,
} from "../../../../utils/specialFunctions";
import { withTheme } from "styled-components";
import ReactSelect from "react-select";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { range } from "lodash";
import TableSkeleton from "./TableSkeleton";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { P } from "../../../../UI toolkit/Text/Text";

const Transactions = ({
  transactionAction,
  transactionsList,
  merchantsList,
  getMerchants,
  total_pending_withdrawals,
  total_pending_deposits,
  deposits,
  withdrawals,
  location,
  loadingOverview,
  loadingTransactions,
  theme,
  prices,
  activeTab,
  updateActiveTab,
  page,
  updatePage,
  totalPages,
  updateActiveStatus,
  activeStatus,
  updateActiveMerchant,
  activeMerchant,
  transactionStatsAction,
}) => {
  const tabs = [
    { text: "All", value: "all" },
    { text: "Deposits", value: "deposit" },
    { text: "Withdrawals", value: "withdrawal" },
  ];

  const links1 = [
    { text: "all" },
    { text: "pending" },
    { text: "completed" },
    { text: "failed" },
    { text: "rejected" },
    { text: "refunded" },
    { text: "review" },
  ];

  const [depositTabIndex, setDepositTabIndex] = useState(3);
  const [withdrawalTabIndex, setWithdrawalTabIndex] = useState(3);

  const currentStatus = activeStatus[activeTab];
  const currentPage = page[activeTab][currentStatus];
  const totalPage = totalPages[activeTab][currentStatus];

  const handleTabChange = (type) => {
    type = type.toLowerCase();
    updateActiveTab(type);
    console.log(type);
  };

  const handleStatusChange = (status) => {
    console.log(status);
    updateActiveStatus({ tab: activeTab, value: status });
  };

  const handleNextPage = () => {
    console.log(currentPage, totalPage);
    if (currentPage < totalPage - 1) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage + 1,
      });
    }
  };

  const prevButtonDisabled = () => {
    return !(currentPage > 0);
  };

  const nextButtonDisabled = () => {
    return !(currentPage < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage - 1,
      });
    }
  };

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  useEffect(() => {
    transactionStatsAction({ query: "all", type: "all" });
  }, [transactionStatsAction]);

  useEffect(() => {
    transactionAction();
  }, [transactionAction, activeTab, page, activeStatus, activeMerchant]);

  const headerCb = (query, type) => {
    transactionStatsAction({ query, type });
  };

  const handleDepositIndex = (index) => {
    setDepositTabIndex(index);
  };

  const handleWithdrawalIndex = (index) => {
    setWithdrawalTabIndex(index);
  };

  const activeTabIdx = {
    all: 0,
    deposit: 1,
    withdrawal: 2,
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Transactions</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Transactions</DashboardHeader>
        {loadingTransactions ? (
          <div
            // className="flex items-center justify-center"
            style={{ height: "100vh" }}
          >
            <TableSkeleton />
          </div>
        ) : (
          <div>
            <div className="flex flex-wrap overflow-hidden lg:-mx-5">
              <TransactionsCard
                Titlecolor="#24B57A"
                pending={total_pending_deposits}
                type="Deposit"
                transaction={deposits}
                headerCb={headerCb}
                index={depositTabIndex}
                handleIndex={handleDepositIndex}
              />
              <TransactionsCard
                Titlecolor="#FF6A6A"
                pending={total_pending_withdrawals}
                type="Withdrawal"
                transaction={withdrawals}
                headerCb={headerCb}
                index={withdrawalTabIndex}
                handleIndex={handleWithdrawalIndex}
              />
            </div>

            <div className="pt-6">
              <Card>
                <TabHeader>
                  <div className="lg:flex">
                    <div className="lg:w-8/12">
                      <TableTab
                        margin
                        tabs={tabs}
                        activeTab={activeTabIdx[activeTab]}
                        // handleTab={handleActiveLink}
                        handleDispatch={handleTabChange}
                        callback={(active) => {
                          // console.log(active)
                        }}
                      />
                    </div>
                    <div className="lg:w-4/12 flex my-3 mx-3 lg:my-1.5 lg:mx-1.5 py-5 lg:py-0">
                      <div className="w-6/12 lg:mr-4">
                        <Select
                          bgImg={caret_down}
                          bgColor={theme.colors.bg}
                          color={theme.colors.color}
                          border={"1px solid #fff"}
                          borderRadius={"4px"}
                          p={"5px 15px"}
                          data={getMerchantsStrings(merchantsList)}
                          defaultValue={"-- Merchants --"}
                          properyToShow={"text"}
                          hoverBg={"#FFF"}
                          hoverColor={"#112E46"}
                          fontSize={"16px"}
                          callback={(option, idx, arr) => {
                            console.log(option, idx, arr);
                            if (option.text.toLowerCase() !== "all") {
                              updateActiveMerchant(option.text);
                            } else {
                              updateActiveMerchant(null);
                            }
                          }}
                        />
                      </div>

                      <div className="w-6/12">
                        <Select
                          bgImg={caret_down}
                          //bgColor={"#112E46"}
                          //color={"#fff"}
                          bgColor={theme.colors.bg}
                          color={theme.colors.color}
                          border={"1px solid #fff"}
                          borderRadius={"4px"}
                          p={"5px 15px"}
                          data={links1}
                          defaultValue={
                            activeStatus[activeTab]
                              ? activeStatus[activeTab]
                              : "-- Status --"
                          }
                          properyToShow={"text"}
                          hoverBg={"#FFF"}
                          hoverColor={"#112E46"}
                          fontSize={"16px"}
                          callback={(option, idx, arr) => {
                            handleStatusChange(option.text);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </TabHeader>

                <div className="px-0">
                  {
                    <TransactionsTable
                      links1={groupActivitiesByDaysOrMonth(
                        transactionsList ? transactionsList : null,
                        "days"
                      )}
                    />
                  }
                </div>
                <div>
                  {!loadingTransactions && (
                    <div className="flex justify-between gap-2 items-center py-4 w-full">
                      <p className="">
                        {/* Page no */}
                        {/* {pagination.per_page}  */}
                      </p>
                      <div className="flex items-center">
                        <DefaultButton
                          bg={theme.colors.bg}
                          color={theme.colors.color}
                          p={"8px 16px"}
                          onClick={handlePrevPage}
                          disabled={prevButtonDisabled()}
                        >
                          Prev
                        </DefaultButton>
                        <div className="px-4">
                          <P color={"#646A86"} fontSize="16px" fontWeight="700">
                            Page {currentPage + 1} of{" "}
                            {totalPage !== 0 ? totalPage : 1}
                          </P>
                        </div>
                        <DefaultButton
                          bg={theme.colors.bg}
                          color={theme.colors.color}
                          p={"8px 16px"}
                          onClick={handleNextPage}
                          disabled={nextButtonDisabled()}
                        >
                          Next
                        </DefaultButton>
                      </div>
                      {/* ReactSelect component without text typing cursor */}
                      {/* <div className="">
                        <ReactSelect
                          isSearchable={false}
                          options={rowsOptions}
                          placeholder="Number of rows"
                          menuPlacement="top"
                          onChange={handleChangeRowsNo}
                        />
                      </div> */}
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    prices: state.overview.prices,
    transactionsList: state.transactions.transactionsList,
    total_pending_deposits: state.transactions.totalPendingDeposits,
    total_pending_withdrawals: state.transactions.totalPendingWithdrawals,
    deposits: state.transactions.totalDeposits,
    withdrawals: state.transactions.totalWithdrawals,
    merchantsList: state.merchants.merchantsList,
    loadingOverview: state.overview.loading,
    loadingTransactions: state.transactions.loading,
    error: state.overview.error,
    page: state.transactions.transactionsPage,
    activeTab: state.transactions.transactionsActiveTab,
    totalPages: state.transactions.totalTransactions,
    activeStatus: state.transactions.transactionsActiveStatus,
    activeMerchant: state.transactions.transactionsActiveMerchant,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveTab: (tab) => dispatch(updateTransactionsActiveTab(tab)),
    updatePage: (page) => dispatch(updateTransactionsPage(page)),
    getMerchants: () => dispatch(getMerchants()),
    transactionAction: (query) => dispatch(transactionAction(query)),
    updateActiveStatus: (payload) =>
      dispatch(updateTransactionsActiveStatus(payload)),
    updateActiveMerchant: (payload) =>
      dispatch(updateTransactionActiveMerchant(payload)),
    transactionStatsAction: (query) => dispatch(transactionStatsAction(query)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(Transactions)));
