import axios from "axios";
import { LOGIN_TO_ACCT, LOGOUT_TO_ACCT, LOADING, ERROR } from "../Types/Types";
import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import Api from "./api";

export const LoginAuthAction = (state, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.Login(state);
      Api.setToken(response.data.token);
      console.log(response, "login response");
      customToast.success(`Admin sucessfully logged in`);
      dispatch({
        type: LOGIN_TO_ACCT,
        payload: {
          token: response.data.token,
          user: response.data.account,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR });
      return console.log(error.message);
    } finally {
      if (callback && typeof callback === "function") {
        callback();
      }
      dispatch(hideLoading());
    }
  };
};
export const messages = {};
export const Logout = (message = "Sucessfully Logged Out") => {
  if (message.includes("Logout Sucessfully")) {
    messages["logout_message"] = message;
  } else {
    messages[`other ${message.substr(0, 2)}`] = message;
  }
  return (dispatch, getState) => {
    localStorage.removeItem("s-k-account");
    dispatch({ type: LOGOUT_TO_ACCT });
    for (const msg in messages) {
      customToast.success(`${messages[msg]}`);
    }
  };
};
