import React, { useState } from "react";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import {
  TransactionsTableStyle,
  TransactionsTableHeaderStyle,
} from "./UserCards.styled";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { connect, useDispatch } from "react-redux";
import UserCardModal from "./CardTransactionsModal";
import { withTheme } from "styled-components";
import { updateUserCardActivitiesActiveStatus } from "../../../../../store/Actions/CardsAction";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

// Redux store mapstatetoprops and mapdispatchtoprops

const LoadCardTransactionsTable = ({
  links1,
  updateActiveStatus,
  activeTab,
  setActiveOptimized,
}) => {
  const [openModal, setopenModal] = useState(false);

  const [activity, setActivity] = useState(null);

  const statusKeys = {
    completed: "completed",
    pending: "pending",
    failed: "failed",
  };

  const typeKeys = {
    active: "Unfreeze",
    inactive: "Freeze",
    cancel: "Cancel",
    create: "Create",
    fund: "Fund",
    withdrawal: "Withdrawal",
  };

  const bgColor = (status) => {
    return status === "approved"
      ? "#D1F7C4"
      : status === "pending"
      ? "#FFEAB6"
      : status === "matched"
      ? "#D0F0FD"
      : status === "closed"
      ? "#FFDCE5"
      : status === "cancelled"
      ? "#FFDCE5"
      : "#D1F7C4";
  };

  const color = (status) => {
    return status === "approved"
      ? "#337138"
      : status === "pending"
      ? "#606060"
      : status === "matched"
      ? "#335F71"
      : status === "closed"
      ? "#531423"
      : status === "cancelled"
      ? "#531423"
      : "";
  };

  const statusTabs = [
    { text: "All", value: "all" },
    { text: "Pending", value: "pending" },
    { text: "Failed", value: "failed" },
    { text: "Completed", value: "completed" },
  ];

  const handleStatusChange = (status) => {
    console.log("status", status);
    updateActiveStatus({
      tab: activeTab,
      value: status,
    });
  };

  console.log(links1);
  return (
    <div style={{ overflowX: "auto" }}>
      <TransactionsTableHeaderStyle className="w-full">
        <tbody className="w-full">
          <tr className="Table-heading -px-1.5">
            <td width="20%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  STATUS
                </SubHeader>
              </div>
            </td>

            <td width="15%" className="pt-4 pb-4"></td>
            <td width="20%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  AMOUNT
                </SubHeader>
              </div>
            </td>
            <td width="20%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  USER
                </SubHeader>
              </div>
            </td>

            <td width="20%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  DESCRIPTION
                </SubHeader>
              </div>
            </td>
          </tr>
        </tbody>
      </TransactionsTableHeaderStyle>
      {links1 &&
        links1.map((transactionGroup, idx, arr) => {
          return (
            <TransactionsTableStyle className="w-full" key={idx}>
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div className="pt-4 pb-4 px-1.5">
                      <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                        {`${timeAgo.format(
                          new Date(transactionGroup.group)
                        )} — ${new Date(
                          transactionGroup.group
                        ).toDateString()}`}
                      </P>
                    </div>
                  </td>
                </tr>

                {transactionGroup.data.map((curr, idx, arr) => {
                  return (
                    <tr
                      className="px-1.5"
                      style={{ borderBottom: "1px solid #EFF0F5" }}
                      key={idx}
                    >
                      <td width="20%" className="pt-4 pb-4 px-1.5">
                        <Badge color={color(curr?.status)} bg={bgColor(curr?.status)}>
                          {curr.status}
                        </Badge>
                      </td>
                      <td width="15%" className="col-start-2 col-end-4">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {curr.transactionMetadata.type}
                        </P>
                      </td>
                      <td width="20%" className="col-start-2 col-end-4">
                        <div className="flex justify-center">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr.card.currency} {curr.amount}
                          </P>
                        </div>
                      </td>
                      <td width="20%" className="col-start-2 col-end-4">
                        <div>
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr && curr.customer && curr.customer.emailAddress
                              ? curr.customer.emailAddress
                              : "no email"}
                          </P>
                        </div>
                      </td>

                      <td width="15%" className="pt-4 pb-4 px-1.5">
                        <div className="flex">
                          <div className="">
                            <P
                              fontSize="13px"
                              color={"#0087B5"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setActivity(curr);
                                setopenModal(true);
                              }}
                            >
                              View
                            </P>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TransactionsTableStyle>
          );
        })}
      <div>
        {openModal ? (
          <UserCardModal
            width={"30%"}
            border={"none"}
            closeModalCallback={() => {
              setopenModal(false);
            }}
            activity={activity}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeStatus: state.cards.userCardActivitiesActiveStatus,
    activeTab: state.cards.userCardActivitiesActiveTab,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveStatus: (status) =>
      dispatch(updateUserCardActivitiesActiveStatus(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(LoadCardTransactionsTable));
