import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import {
  loadCardTransactions,
  updateUserCardActivitiesActiveTab,
  updateUserCardActivitiesPage,
} from "../../../../../store/Actions/CardsAction";
import { withTheme } from "styled-components";
import { ArrowLeftIcon } from "../../../../../UI toolkit/icons";
import { Card } from "../../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../../Units/TabsAndLinkStyled";
import {
  customGroupActivitiesByDaysOrMonth
} from "../../../../../utils/specialFunctions";
import LoadCardTransactionsTable from "./LoadCardTransactionsTable";

const CardTransactions = ({
  theme,
  activeTab,
  updateActiveTab,
  match,
  updatePage,
  page,
  totalPages,
  activeStatus,
  loadCardTransactions,
  cardTransactions,
}) => {
  const currentStatus = activeStatus[activeTab];
  const currentPage = page[activeTab][currentStatus];
  const totalPage = totalPages[activeTab][currentStatus];

  const router = useHistory();
  const [active, setActive] = useState(0);
  const setActiveOptimized = useCallback((value) => {
    setActive(value);
  }, []);

  const tabs = [
    { text: "All", value: "all" },
    { text: "Funding", value: "fund" },
    { text: "Withdrawals", value: "withdrawal" },
    { text: "Created", value: "create" },
    { text: "Freeze", value: "inactive" },
    { text: "Unfreeze", value: "active" },
  ];

  const loadTransactionsTabs = [{ text: "All", value: "all" }];

  const handleTabChange = (type) => {
    type = type.toLowerCase();

    updateActiveTab(type);
    console.log(type);
  };

  const handleNextPage = () => {
    console.log(currentPage, totalPage);
    if (currentPage < totalPage - 1) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage + 1,
      });
    }
  };

  const prevButtonDisabled = () => {
    return !(currentPage > 0);
  };

  const nextButtonDisabled = () => {
    return !(currentPage < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage - 1,
      });
    }
  };

  useEffect(() => {
    loadCardTransactions(match.params.id);
  }, [loadCardTransactions]);

  return (
    <div>
      <div
        className="flex items-center cursor-pointer py-4"
        onClick={() => {
          router.push(`/dashboard/users/${match.params.id}`);
        }}
      >
        <ArrowLeftIcon />
        <h3 className="text-primary underline ml-2">Back</h3>
      </div>
      <div className="">
        <Card>
          <TabHeader>
            <div className="lg:flex justify-between">
              <div className="lg:w-8/12">
                <TableTab
                  margin
                  tabs={loadTransactionsTabs}
                  activeTab={activeTab}
                  handleDispatch={handleTabChange}
                  callback={(active) => {
                    console.log(active);
                  }}
                />
              </div>
            </div>
          </TabHeader>
          <div className="px-0">
            <LoadCardTransactionsTable
              setActiveOptimized={setActiveOptimized}
              links1={customGroupActivitiesByDaysOrMonth(
                cardTransactions ? cardTransactions : null,
                "days",
                "createdAt"
              )}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.cards.loading,
    error: state.cards.error,
    card_activities: state.cards.user_card_activities,
    cardTransactions: state.cards.userCardTransactions,
    activeTab: state.cards.userCardActivitiesActiveTab,
    activeStatus: state.cards.userCardActivitiesActiveStatus,
    page: state.cards.userCardActivitiesPage,
    totalPages: state.cards.userCardActivitiesTotalPages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCardTransactions: (payload) => dispatch(loadCardTransactions(payload)),
    updateActiveTab: (tab) => dispatch(updateUserCardActivitiesActiveTab(tab)),
    updatePage: (page) => dispatch(updateUserCardActivitiesPage(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(CardTransactions)));
