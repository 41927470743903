import React, { useEffect, useState } from "react";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { ToggleButton } from "../../../../UI toolkit/Input/Input";
import { updateUserControls } from "../../../../store/Actions/UsersAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const UserControl = ({ user, updateUserControlsAction, match }) => {
  const [state, setstate] = useState({
    key: null,
    value: null,
  });

  const cryptoKeyPair = {
    buy: "buy_enabled",
    sell: "sell_enabled",
    swap: "swap_enabled",
    send: "send_enabled",
  };

  const cashKeyPair = {
    withdraw: "withdrawal_enabled",
  };

  const mapper = {
    buy_enabled: "Buy",
    sell_enabled: "Sell",
    swap_enabled: "Swap",
    send_enabled: "Send",
    withdrawal_enabled: "Withdraw",
  };

  useEffect(() => {
    console.log(state);
    if (state.key !== null && state.value !== null) {
      updateUserControlsAction(match.params.id, state, (response) => {});
    }
  }, [state, updateUserControlsAction]);
  return (
    <div>
      <div style={{ background: "#E7EAF4" }} className="pt-4 pb-4 px-4">
        <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
          SET SERVICES STATUS
        </SubHeader>
      </div>
      <div className="px-6 py-6">
        <div className="">
          <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
            CRYPTO
          </SubHeader>
          {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(user?.profile, null, 2)}</pre> */}
          <div className="mt-4">
            <div className="flex flex-wrap overflow-hidden h-full">
              {Object.keys(cryptoKeyPair).map((curr, index) => (
                <div className={`w-6/12 flex mt-4`}>
                  <div className="flex w-6/12">
                    <P color={"#374072"} fontSize={"13px"} fontWeight="500">
                      {mapper[cryptoKeyPair[curr]]}
                    </P>
                  </div>
                  <div className="flex w-6/12">
                    <ToggleButton
                      toggleId={curr}
                      checked={user?.profile[cryptoKeyPair[curr]]}
                      onChange={(e) => {
                        setstate({
                          ...state,
                          key: curr,
                          value: e.target.checked,
                        });
                      }}
                    />
                    <P
                      className="ml-2"
                      color={"#374072"}
                      fontSize={"13px"}
                      fontWeight="500"
                    >
                      {true ? "On" : "Off"}
                    </P>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr className="my-6" />
        <div className="mt-4">
          <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
            CASH
          </SubHeader>
          <div className="mt-4">
            <div className="flex flex-wrap overflow-hidden h-full">
              {Object.keys(cashKeyPair).map((curr, index) => (
                <div className={`w-6/12 flex mt-4`}>
                  <div className="flex w-6/12">
                    <P color={"#374072"} fontSize={"13px"} fontWeight="500">
                      {mapper[cashKeyPair[curr]]}
                    </P>
                  </div>
                  <div className="flex w-6/12">
                    <ToggleButton
                      toggleId={curr}
                      checked={user?.profile[cashKeyPair[curr]]}
                      onChange={(e) => {
                        setstate({
                          ...state,
                          key: curr,
                          value: e.target.checked,
                        });
                      }}
                    />
                    <P
                      className="ml-2"
                      color={"#374072"}
                      fontSize={"13px"}
                      fontWeight="500"
                    >
                      {true ? "On" : "Off"}
                    </P>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currencies: state.settings.coins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserControlsAction: (state, successCallback) =>
      dispatch(updateUserControls(state, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserControl));
