//authentication
export const LOGIN_TO_ACCT = "LOGIN_TO_ACCT";
export const SIGNUP_TO_ACCT = "SIGNUP_TO_ACCT";
export const LOGOUT_TO_ACCT = "LOGOUT_TO_ACCT";

// loading stuffs for login and signup on the app
export const LOADING = "LOADING";
export const ERROR = "ERROR";

export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_USERS_RAN_ONCE = "GET_USERS_RAN_ONCE";
export const SEARCH_USERS = "SEARCH_USERS";
export const UPDATE_USERS_ACTIVE_TAB = "UPDATE_USERS_ACTIVE_TAB";
export const UPDATE_USERS_PAGE = "UPDATE_USERS_PAGE";
export const UPDATE_TOTAL_USERS_PAGE = "UPDATE_TOTAL_USERS_PAGE";
export const UPDATE_USERS_SEARCH = "UPDATE_USERS_SEARCH";
export const SET_DOWNLOAD_URL = "SET_DOWNLOAD_URL";
// loading stuffs for users on the app
export const LOADING_USERS = "LOADING_USERS";
export const ERROR_USERS = "ERROR_USERS";

// overview
export const GET_OVERVIEW_DATA = "GET_OVERVIEW_DATA";
// deposits
export const GET_DEPOSITS = "GET_DEPOSITS";
// withdrawals
export const GET_WITHDRAWALS = "GET_WITHDRAWALS";
// total currencies
export const GET_CURRENCIES = "GET_CURRENCIES";
// total currencies
export const GET_TOTAL_USERS = "GET_TOTAL_USERS";
// prices
export const GET_PRICES = "GET_PRICES";
// matched deposits
export const GET_PENDING_DEPOSITS = "GET_PENDING_DEPOSITS";
// matched withdrawals
export const GET_PENDING_WITHDRAWALS = "GET_PENDING_WITHDRAWALS";

export const GET_COMPLETED_DEPOSITS = "GET_COMPLETED_DEPOSITS";
// matched withdrawals
export const GET_COMPLETED_WITHDRAWALS = "GET_COMPLETED_WITHDRAWALS";

export const GET_MATCHED_DEPOSITS = "GET_MATCHED_DEPOSITS";
// matched withdrawals
export const GET_MATCHED_WITHDRAWALS = "GET_MATCHED_WITHDRAWALS";

export const OVERVIEW_LOADING = "OVERVIEW_LOADING";
export const OVERVIEW_ERROR = "OVERVIEW_ERROR";

// transactions
export const GET_TRANSACTIONS = "GET_TRANSACTIONS=";
export const GET_LIST_OF_DEPOSITS_AND_WITHDRAWALS =
  "GET_LIST_OF_DEPOSITS_AND_WITHDRAWALS";
export const GET_LIST_OF_DEPOSITS = "GET_LIST_OF_DEPOSITS";
export const GET_LIST_OF_WITHDRAWALS = "GET_LIST_OF_WITHDRAWALS";
export const GET_TRADES = "GET_TRADES";
export const TRADES_LOADING = "TRADES_LOADING";
export const TRADES_ERROR = "TRADES_ERROR";
export const UPDATE_TRADES_PAGE = "UPDATE_TRADES_PAGE";
export const UPDATE_TRADES_ACTIVE_TAB = "UPDATE_TRADES_ACTIVE_TAB";
export const UPDATE_TRADES_ACTIVE_CURRENCY = "UPDATE_TRADES_ACTIVE_CURRENCY";
export const UPDATE_TRADE_NAVIGATOR = "UPDATE_TRADE_NAVIGATOR";
export const UPDATE_TOTAL_TRADES = "UPDATE_TOTAL_TRADES";
export const GET_TRANSFERS = "GET_TRANSFERS";
export const TRANSFERS_LOADING = "TRANSFERS_LOADING";
export const UPDATE_TRANSFERS_ACTIVE_TAB = "UPDATE_TRANSFERS_ACTIVE_TAB";
export const UPDATE_TRANSFERS_NAVIGATOR = "UPDATE_TRANSFERS_NAVIGATOR";
export const UPDATE_TRANSFERS_PAGE = "UPDATE_TRANSFERS_PAGE";
export const UPDATE_TRANSFERS_ACTIVE_CURRENCY =
  "UPDATE_TRANSFERS_ACTIVE_CURRENCY";
export const UPDATE_TOTAL_TRANSFERS = "UPDATE_TOTAL_TRANSFERS";
export const GET_INCOMING_TRANSFERS = "GET_INCOMING_TRANSFERS";
export const INCOMING_TRANSFERS_LOADING = "INCOMING_TRANSFERS_LOADING";
export const UPDATE_INCOMING_TRANSFERS_NAVIGATOR =
  "UPDATE_INCOMING_TRANSFERS_NAVIGATOR";
export const UPDATE_INCOMING_TRANSFERS_PAGE = "UPDATE_INCOMING_TRANSFERS_PAGE";
export const UPDATE_INCOMING_TRANSFERS_ACTIVE_CURRENCY =
  "UPDATE_INCOMING_TRANSFERS_ACTIVE_CURRENCY";
export const UPDATE_TOTAL_INCOMING_TRANSFERS =
  "UPDATE_TOTAL_INCOMING_TRANSFERS";
export const GET_SENT_TRANSFERS = "GET_SENT_TRANSFERS";
export const SENT_TRANSFERS_LOADING = "SENT_TRANSFERS_LOADING";
export const UPDATE_SENT_TRANSFERS_NAVIGATOR =
  "UPDATE_SENT_TRANSFERS_NAVIGATOR";
export const UPDATE_SENT_TRANSFERS_PAGE = "UPDATE_SENT_TRANSFERS_PAGE";
export const UPDATE_SENT_TRANSFERS_ACTIVE_CURRENCY =
  "UPDATE_SENT_TRANSFERS_ACTIVE_CURRENCY";
export const UPDATE_TOTAL_SENT_TRANSFERS = "UPDATE_TOTAL_SENT_TRANSFERS";

export const UPDATE_WITHDRAWALS_DEPOSIT_ACTIVE_TAB =
  "UPDATE_WITHDRAWALS_DEPOSIT_ACTIVE_TAB";
export const UPDATE_WITHDRAWALS_DEPOSIT_NAVIGATOR =
  "UPDATE_WITHDRAWALS_DEPOSIT_NAVIGATOR";

export const UPDATE_TRANSACTIONS_PAGE = "UPDATE_TRANSACTIONS_PAGE";
export const UPDATE_TRANSACTIONS_ACTIVE_TAB = "UPDATE_TRANSACTIONS_ACTIVE_TAB";
export const UPDATE_TOTAL_TRANSACTIONS = "UPDATE_TOTAL_TRANSACTIONS";
export const UPDATE_TRANSACTIONS_ACTIVE_STATUS =
  "UPDATE_TRANSACTIONS_ACTIVE_STATUS";
//list of transactions
export const GET_LIST_OF_TRANSACTIONS = "GET_LIST_OF_TRANSACTIONS";
export const TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";
export const TRANSACTIONS_ERROR = "TRANSACTIONS_ERROR";

export const UPDATE_TRANSACTIONS_ACTIVE_MERCHANT =
  "UPDATE_TRANSACTIONS_ACTIVE_MERCHANT";

// fetch wallet address and post wallet type to get addresses
export const GET_WALLET_ADDRESS = "GET_WALLET_ADDRESS";
export const POST_WALLET_ADDRESS = "POST_WALLET_ADDRESS";

// wallets values
export const GET_WALLETS_DATA = "GET_WALLETS_DATA";

// get verifications list
export const GET_VERIFICATION_LIST = "GET_VERIFICATION_LIST";
export const LOAD_VERIFICATION_DOC = "LOAD_VERIFICATION_DOC";
export const VERIFICATION_DOC = "VERIFICATION_DOC";
export const VERIFICATION_LOADING = "VERIFICATION_LOADING";
export const VERIFICATION_ERROR = "VERIFICATION_ERROR";
export const UPDATE_TOTAL_VERIFICATIONS_PAGES = "UPDATE_TOTAL_VERIFICATIONS_PAGES";
export const UPDATE_VERIFICATION_PAGE = "UPDATE_VERIFICATION_PAGE";
// merchants
export const GET_MERCHANTS = "GET_MERCHANTS";
export const CREATE_MERCHANT = "CREATE_MERCHANT";
// loading stuffs for users on the app
export const LOADING_MERCHANTS = "LOADING_MERCHANTS";
export const ERROR_MERCHANTS = "ERROR_MERCHANTS";

// UI Reducer Types
export const LOADING_UI = "LOADING_UI";
export const LOADING_UI_DONE = "LOADING_UI_DONE";
export const BLUE_WHITE_THEME = "BLUE_WHITE_THEME";
export const BLACK_WHITE_THEME = "BLACK_WHITE_THEME";

export const SHOW_CHAT = "SHOW_CHAT";

// admin
export const GET_ADMIN = "GET_ADMIN";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const UPDATE_ADMIN = "CREATE_ADMIN";
export const REMOVE_ADMIN = "CREATE_ADMIN";
export const GET_ADMIN_SETTINGS = "GET_ADMIN_SETTINGS";
// loading admin stuffs for users on the app
export const LOADING_ADMIN = "LOADING_ADMIN";
export const ERROR_ADMIN = "ERROR_ADMIN";

//get links of banks
export const GET_LIST_OF_BANKS = "GET_LIST_OF_BANKS";

// messages and announcements
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const CREATE_ANNOUNCEMENTS = "CREATE_ANNOUNCEMENTS";
export const UPDATE_ANNOUNCEMENTS_NAVIGATOR = "UPDATE_ANNOUNCEMENTS_NAVIGATOR";
// loading stuffs for users on the app
export const LOADING_ANNOUNCEMENTS = "LOADING_ANNOUNCEMENTS";
export const ERROR_ANNOUNCEMENTS = "ERROR_ANNOUNCEMENTS";
//Savings
export const GET_SAVINGS_OVERVIEW_STATS = "GET_SAVINGS_OVERVIEW_STATS";
export const GET_SAVINGS = "GET_SAVINGS";
export const SAVINGS_LOADING = "SAVINGS_LOADING";
export const SAVINGS_ERROR = "SAVINGS_ERROR";
export const GET_USER_SAVINGS_STATS = "GET_USER_SAVINGS_STATS";
export const GET_USER_SAVINGS = "GET_USER_SAVINGS";
export const GET_USER_SAVINGS_WALLET = "GET_USER_SAVINGS_WALLET";
export const SET_USER_SAVINGS_FILTER = "SET_USER_SAVINGS_FILTER";
export const GET_COINS = "GET_COINS";
export const UPDATE_FIXED_ACTIVE_TAB = "UPDATE_FIXED_ACTIVE_TAB";
export const UPDATE_FIXED_NAVIGATOR = "UPDATE_FIXED_NAVIGATOR";
export const UPDATE_FLEXIBLE_ACTIVE_TAB = "UPDATE_FLEXIBLE_ACTIVE_TAB";
export const UPDATE_FLEXIBLE_NAVIGATOR = "UPDATE_FLEXIBLE_NAVIGATOR";
export const GET_DEPOSIT_TYPES = "GET_DEPOSIT_TYPES";
export const UPDATE_SAVINGS_ACTIVE_TAB = "UPDATE_SAVINGS_ACTIVE_TAB";
export const UPDATE_SAVINGS_ACTIVE_STATUS = "UPDATE_SAVINGS_ACTIVE_STATUS";
export const UPDATE_TOTAL_SAVINGS = "UPDATE_TOTAL_SAVINGS";
export const UPDATE_SAVINGS_PAGE = "UPDATE_SAVINGS_PAGE";

//CARDS
export const GET_CARDS = "GET_CARDS";
export const GET_CARD_STATS = "GET_CARD_STATS";
export const CARDS_LOADING = "CARDS_LOADING";
export const CARDS_ERROR = "CARDS_ERROR";
export const GET_CARD_ACTIVITIES = "GET_CARD_ACTIVITIES";
export const UPDATE_CARD_ACTIVITIES_NAVIGATOR =
  "UPDATE_CARD_ACTIVITIES_NAVIGATOR";
export const GET_USER_CARD_ACTIVITIES = "GET_USER_CARD_ACTIVITIES";
export const UPDATE_CARD_ACTIVITIES_ACTIVE_TAB =
  "UPDATE_CARD_ACTIVITIES_ACTIVE_TAB";
export const UPDATE_CARD_ACTIVITIES_ACTIVE_STATUS =
  "UPDATE_CARD_ACTIVITIES_ACTIVE_STATUS";
export const UPDATE_TOTAL_CARD_ACTIVITIES = "UPDATE_TOTAL_CARD_ACTIVITIES";
export const UPDATE_CARD_ACTIVITIES_PAGE = "UPDATE_CARD_ACTIVITIES_PAGE";

export const UPDATE_USER_CARD_ACTIVITIES_ACTIVE_TAB =
  "UPDATE_USER_CARD_ACTIVITIES_ACTIVE_TAB";
export const UPDATE_USER_CARD_ACTIVITIES_ACTIVE_STATUS =
  "UPDATE_USER_CARD_ACTIVITIES_ACTIVE_STATUS";
export const UPDATE_TOTAL_USER_CARD_ACTIVITIES =
  "UPDATE_TOTAL_USER_CARD_ACTIVITIES";
export const UPDATE_USER_CARD_ACTIVITIES_PAGE =
  "UPDATE_USER_CARD_ACTIVITIES_PAGE";
export const LOAD_CARD_TRANSACTIONS = "LOAD_CARD_TRANSACTIONS";
export const CARD_TRANSACTIONS_LOADING = "CARD_TRANSACTIONS_LOADING";
export const CARD_TRANSACTIONS_ERROR = "CARD_TRANSACTIONS_ERROR";
export const GET_TRANSFERS_COUNT = "GET_TRANSFERS_COUNT";
export const TRANSFERS_COUNT_LOADING = "TRANSFERS_COUNT_LOADING";
export const GET_FEES = "GET_FEES";
export const FEES_LOADING = "FEES_LOADING";
export const FEES_ERROR = "FEES_ERROR";