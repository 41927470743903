import axios from "axios";
import { customToast } from "../../../utils/customToast";
import config from "../../../config/index";
import { store } from "../../../index";
import { Logout, messages } from "../AuthActions";

const instance = axios.create();

class Api {
  __jsonRequest(method, url, data = {}, params = {}, contentType = undefined) {
    return instance.request({
      url,
      method,
      data,
      params: {
        ...params,
      },
      headers: {
        "Content-Type":
          contentType !== undefined ? contentType : "application/json",
        ...(this.token
          ? {
              Authorization: "Bearer " + this.token,
            }
          : {}),
      },
    });
  }

  async __request(
    method,
    url,
    data = undefined,
    params = undefined,
    contentType = undefined,
    flag = false
  ) {
    // if ('logout_message' in messages) throw Error("end")
    try {
      url = url.includes("http") ? url : `${config.apiBaseUrl}/${url}`;
      const res = await this.__jsonRequest(
        method,
        url,
        data,
        params,
        contentType
      );
      if (flag) return res;
      if (res && res.data.status === "success") {
        return res.data;
      }
      return Promise.reject(res.data);
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          if ("logout_message" in messages) return;
          return store.dispatch(
            Logout(
              `Logout Sucessfully ${
                e && e.response && e.response.data
                  ? e.response.data.message
                  : ""
              }`
            )
          );
        } else {
          customToast.error(
            `unable to perform this action. ${
              e && e.response && e.response.data ? e.response.data.message : ""
            }`
          );
        }
        return Promise.reject(e.response.data);
      }
      return Promise.reject(e);
    }
  }

  setToken(token) {
    this.token = token;
  }

  setBase(token) {
    this.base = token;
  }

  /*
   * Authentication
   * */

  async Login(data) {
    return this.__request("post", "admin/auth/login", data);
  }

  async Overview(query) {
    return this.__request(
      "get",
      `admin/statistics?timespan=${query ? query : "all"}`
    );
  }

  async Prices() {
    return this.__request("get", "admin/prices");
  }

  async Wallets() {
    return this.__request("get", `admin/wallets`);
  }

  async GetBanks() {
    return this.__request("get", "v1/banks");
  }

  async Transactions(query) {
    return this.__request(
      "get",
      `admin/transactions?page=${query.page}&status=${query.status}&type=${
        query.type
      }${query.merchant ? `&merchant=${query.merchant}` : ""}`
    );
  }

  async TransactionStats(query) {
    return this.__request(
      "get",
      `admin/transactions/stats?timespan=${
        query.query ? query.query : "all"
      }&type=${query.type ? query.type : "all"}`
    );
  }

  async getDeposits() {
    return this.__request("get", "admin/deposits");
  }

  async getDepositWithdrawals(query) {
    const { limit, status, type, cursor } = query;
    return this.__request(
      "get",
      // admin/all_transactions is the endpoint, concatenating the query params but check if they exist first
      `admin/all_transactions?cursor_date=${
        cursor?.date ? cursor.date : ""
      }&cursor_id=${cursor?.id ? cursor.id : ""}&limit=${
        limit ? limit : ""
      }&status=${status ? status : ""}&type=${type ? type : ""}`
    );
  }

  async getWithdrawals() {
    return this.__request("get", "admin/withdrawals");
  }

  async sendCrypto(data) {
    return this.__request("post", "admin/send", data);
  }

  async retrieveCryptoWallets(state) {
    return this.__request(
      "get",
      `admin/wallet-addresses?coin=${state && state.coin ? state.coin : ""}`
    );
  }

  async getCoins() {
    return this.__request("get", "admin/coins");
  }
  async changeCoinSettings(data) {
    return this.__request("post", "admin/coin_settings", data);
  }
  async getOtp() {
    return this.__request("post", "admin/get-pin");
  }
  async getDepositTypes() {
    return this.__request("get", "admin/deposits/get_deposite_types");
  }

  async changeDepositPlatformSettings(data) {
    return this.__request("post", "admin/deposits/update_deposite_type", data);
  }

  async fetchCryptoWalletsAddressbyPost(data) {
    return this.__request("post", "admin/wallet-addresses", data);
  }

  async getTrades(query) {
    return this.__request(
      "get",
      `admin/trades?page=${query.page}${
        query.type ? `&type=${query.type}` : ""
      }${query.status ? `&status=${query.status}` : ""}${
        query.currency ? `&currency=${query.currency}` : ""
      }`
    );
  }

  async getSendAndRecieve(query) {
    return this.__request(
      "get",
      `admin/transfers?page=${query.page}${
        query.type ? `&type=${query.type}` : ""
      }${query.status ? `&status=${query.status}` : ""}${
        query.currency ? `&currency=${query.currency}` : ""
      }`
    );
  }
  async getPendingTransfersCount(query) {
    return this.__request("get", `admin/transfers/count`);
  }

  async approveDeposits(depositId) {
    return this.__request("post", `admin/deposits/${depositId}/approve`);
  }

  async retryDeposit(depositId) {
    return this.__request("post", `admin/deposits/${depositId}/retry`);
  }

  async rejectDeposits(depositId) {
    return this.__request("post", `admin/deposits/${depositId}/reject`);
  }

  async cancelDeposits(depositId) {
    return this.__request("post", `admin/deposits/${depositId}/cancel`);
  }

  async approveWithdrawal(withdrawalId, data) {
    return this.__request(
      "post",
      `admin/withdrawals/${withdrawalId}/approve`,
      data
    );
  }

  async rejectWithdrawal(withdrawalId) {
    return this.__request("post", `admin/withdrawals/${withdrawalId}/reject`);
  }

  async retryWithdrawal(withdrawalId) {
    return this.__request("post", `admin/withdrawals/${withdrawalId}/retry`);
  }

  async resolveWithdrawal(withdrawalId) {
    return this.__request("post", `admin/withdrawals/${withdrawalId}/resolve`);
  }

  async retryTransfer(transferId) {
    return this.__request("post", `admin/transfers/${transferId}/retry`);
  }

  async rejectTransfer(transferId) {
    return this.__request("post", `admin/transfers/${transferId}/reject`);
  }

  async downloadTransactionsAsCSV(module, start, end) {
    return this.__request(
      "post",
      `admin/export?module=${module}&start=${start}&end=${end}`,
      null,
      undefined,
      "application/octet-stream",
      true
    );
  }

  async getUsers(query) {
    return this.__request(
      "get",
      `admin/users?page=${query.page}${
        query.keyword ? `&search=${query.keyword}` : ""
      }${query.level ? `&level=${query.level}` : ""}`
    );
  }

  async searchUsers(page, keyword) {
    return this.__request(
      "get",
      `admin/users?page=${page}${keyword ? `&search=${keyword}` : ""}`
    );
  }

  async getUser(id) {
    return this.__request("get", `admin/users/${id}`);
  }

  async creditUser(state) {
    return this.__request("post", `admin/credit-user`, state);
  }

  async debitUser(state) {
    return this.__request("post", `admin/debit-user`, state);
  }

  async suspendUser(id) {
    return this.__request("post", `admin/users/${id}/suspend`);
  }

  async activateUser(id) {
    return this.__request("post", `admin/users/${id}/activate`);
  }

  async deleteUser(id) {
    return this.__request("post", `admin/users/${id}/delete`);
  }

  async restoreUser(id) {
    return this.__request("post", `admin/users/${id}/restore`);
  }

  async updateUserControls(userId, payload) {
    return this.__request(
      "patch",
      `admin/users/${userId}/update-controls`,
      payload
    );
  }

  async addBankAccounts(userId, state) {
    return this.__request("post", `admin/users/${userId}/bank-accounts`, state);
  }

  async deleteBankAccount(userId, payload) {
    return this.__request("delete", `admin/users/bank-account`, payload);
  }

  async ApproveUser(id) {
    return this.__request(
      "post",
      `admin/kyc/level3/verifications/${id}/approve`
    );
  }

  async RejectUser(id) {
    return this.__request(
      "post",
      `admin/kyc/level3/verifications/${id}/reject`
    );
  }

  async VerificationList(page) {
    return this.__request("get", `admin/kyc/level3/verifications?page=${page}`);
  }

  async viewVerificationDocuments(documentKey) {
    return this.__request("get", `admin/files/${documentKey}`);
  }

  async getMerchants() {
    return this.__request("get", `admin/merchants`);
  }

  async createMerchant(data) {
    return this.__request("post", `admin/merchants`, data);
  }

  async updateMerchant(data, merchantId) {
    return this.__request("patch", `admin/merchants/${merchantId}`, data);
  }

  async removeMerchant(merchantId, data) {
    return this.__request("get", `admin/merchants/${merchantId}/remove`, data);
  }

  async toggleMerchantVisibility(merchantId, data) {
    return this.__request(
      "post",
      `admin/merchants/${merchantId}/visibility`,
      data
    );
  }

  async adminSettings() {
    return this.__request("get", `admin/settings`);
  }

  async changeAdminSettings(data) {
    return this.__request("post", `admin/settings`, data);
  }

  async createAdmin(data) {
    return this.__request("post", `admin/admins`, data);
  }

  async updateAdmin(adminId, data) {
    return this.__request("patch", `admin/update/${adminId}`, data);
  }

  async changeAdminPassword(adminId, data) {
    return this.__request("patch", `admin/change-password/${adminId}`, data);
  }

  async removeAdmin(adminId) {
    return this.__request("delete", `admin/remove/${adminId}`);
  }

  async getAdmins() {
    return this.__request("get", `admin/admins`);
  }

  async getAnnouncements({ page, limit }) {
    return this.__request(
      "get",
      `admin/announcements?page=${page}${limit ? `&limit=${limit}` : ""}`
    );
  }

  async postAnnouncement(data) {
    return this.__request("post", "admin/announcements", data);
  }

  async publishAnnouncement(announcementId) {
    return this.__request(
      "post",
      `admin/announcements/${announcementId}/publish`
    );
  }

  async unpublishAnnouncement(announcementId) {
    return this.__request(
      "post",
      `admin/announcements/${announcementId}/unpublish`
    );
  }

  async debitPromoBalance(id, data) {
    return this.__request("post", `admin/debit-promo-balance/${id}`, data);
  }

  async creditPromoBalance(id, data) {
    return this.__request("post", `admin/credit-promo-balance/${id}`, data);
  }

  async resetReferralEarnings(id) {
    return this.__request("post", `admin/reset-referral-balance/${id}`);
  }

  async getSavingStats(query) {
    return this.__request(
      "get",
      `admin/savings/dashboard-stats?timespan=${query ? query : "all"}`
    );
  }

  async getSavingWalletStats(query) {
    return this.__request("get", `admin/savings/wallets-stats`);
  }
  async getSavings(query) {
    const { type, page, status } = query;
    console.log(query);

    const url = `admin/savings?page=${page}${type ? `&type=${type}` : ""}${
      status ? `&status=${status}` : ""
    }`;
    console.log(url);
    return this.__request("get", url);
  }
  async getUserSavingsStats(id) {
    return this.__request("get", `admin/savings/user/dashboard-stats/${id}`);
  }
  async getUserSavings(id) {
    return this.__request("get", `admin/savings/user/${id}`);
  }
  async getUserSavingsWallet(id) {
    return this.__request("get", `admin/savings/user/${id}`);
  }

  async getCardStats(query) {
    return this.__request(
      "get",
      `admin/cards/stats?timespan=${query ? query : "all"}`
    );
  }
  async getCardActivities(query) {
    const { type, page, status } = query;
    const url = `admin/cards/activities?page=${page}${
      type ? `&type=${type}` : ""
    }${status ? `&status=${status}` : ""}`;
    return this.__request("get", url);
  }
  async getUserCardActivities(query) {
    const { id, type, page, limit, status } = query;

    const response = this.__request(
      "get",
      `admin/cards/user/activities/${id}?type=${type ? type : "all"}&status=${
        status ? status : "all"
      }&page=${page}&limit=${limit ? limit : "10"}`
    );
    console.log(response.data);
    return response;
  }
  async getUserCardTransactions(id) {
    const response = this.__request("get", `admin/cards/transactions/${id}`);
    console.log("blah", response.data);
    return response;
  }
  async getFees(payload) {
    const response = this.__request("post", `admin/send/fees`, payload);
    return response;
  }
  async exportUsersData(payload) {
    const response = this.__request("post", `admin/export_users`, payload);
    return response;
  }
}

const ApiClass = new Api();
ApiClass.setToken(
  localStorage.getItem("s-k-account") &&
    JSON.parse(localStorage.getItem("s-k-account")).token
);

export default ApiClass;
