import React from "react";
import { Helmet } from "react-helmet";
import { withRouter, useLocation } from "react-router-dom";
import { H5, P } from "../../../UI toolkit/Text/Text";
import { NotFoundBaseStyle } from "./NotFound.styled";
import { connect } from "react-redux";
import { withTheme } from "styled-components";

const NotFound = ({ history, logo, theme }) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <NotFoundBaseStyle>
        <div>
          <div className="pb-20">
            <img src={theme.colors.logo} alt="skye_logo" className="" />
          </div>
          <P color="#FFF" fontSize="14px">
            Could not find{" "}
            <H5 color="#FFF">
              {window.location.hostname}
              {history.location.pathname}
            </H5>
            on this App
          </P>
        </div>
      </NotFoundBaseStyle>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    logo: state.UI.logo,
  };
};

export default connect(mapStateToProps, null)(withRouter(withTheme(NotFound)));
