import {
  GET_ADMIN,
  CREATE_ADMIN,
  GET_ADMIN_SETTINGS,
  LOADING_ADMIN,
  ERROR_ADMIN,
  GET_COINS,
  GET_DEPOSIT_TYPES,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  adminList: null,
  admin_settings: {
    MIN_SAVE_AMOUNT_USDT: null,
    WITHDRAWAL_REVIEW_LIMIT: null,
    WITHDRAWAL_FEE: null,
    MINIMUM_BUY_AMOUNT_NGN: null,
    MINIMUM_SELL_AMOUNT_NGN: null,
    SUDO_USD_SETTLEMENT_ACCOUNT: null,
    MINIMUM_CARD_CREATION_AMOUNT_USD: null,
    CARD_CREATION_FEE_USD: null,
    MINIMUM_CARD_FUND_USD: null,
    NGN_USD_BUY_RATE: null,
    NGN_USD_SELL_RATE: null,
    VERIFY_DEVICE_ON_LOGIN: null,
    RECEIVE_ENABLED: null,
    REFERRAL_ENABLED: null,
    TWILIO_ACCOUNT_SID: null,
    DEPOSITS_ENABLED: null,
    REVIEW_OUT_BOUND_TRANSFERS: null,
    SEND_ENABLED: null,
    SWAP_ENABLED: null,
    FLUTTERWAVE_PUBLIC_KEY: null,
    BUY_ENABLED: null,
    REFERRAL_LEVEL: null,
    WITHDRAWALS_ENABLED: null,
    TWILIO_AUTH_TOKEN: null,
    SENDGRID_API_KEY: null,
    SELL_ENABLED: null,
    REFERRAL_PERCENTAGE: null,
    FLUTTERWAVE_SECRET_KEY: null,
    GLOBAL_BUY_MARKUP_PERCENTAGE: null,
    GLOBAL_SELL_MARKUP_PERCENTAGE: null,
    GLOBAL_SWAP_MARKUP_PERCENTAGE: null,
    VENTOGRAM_DEPOSIT_ENABLED: null,
    P2P_DEPOSIT_ENABLED: null,
    BANI_DEPOSIT_ENABLED: null,
    FIGURE_DEPOSIT_ENABLED: null,
  },
  deposit_types: null,
  coins: null,
};

const SettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ADMIN:
      return {
        ...state,
        adminList: action.adminList,
        loading: false,
      };
    case GET_ADMIN_SETTINGS:
      return {
        ...state,
        admin_settings: action.admin_settings,
        loading: false,
      };
    case LOADING_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case ERROR_ADMIN:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_COINS:
      return {
        ...state,
        coins: action.coins,
        loading: false,
      };
    case GET_DEPOSIT_TYPES:
      return {
        ...state,
        deposit_types: action.depositTypes,
        loading: false,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
