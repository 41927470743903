import React from "react";
import { connect } from "react-redux";
import { changeCoinSettingsAction } from "../../../../../store/Actions/SettingsActions";
import { DownPointerIcon } from "../../../../../UI toolkit/icons";

const RateSelector = ({ changeCoinSettingsAction }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="flex items-center cursor-pointer" onClick={handleToggle}>
        <h2 className="mr-2 text-primarySecondary text-xxs">2%</h2>
        <DownPointerIcon />
      </div>
      {isOpen && (
        <div className="absolute w-[3em] border border-borderPrimary top-4 rounded-sm p-2 z-30">
          <div className="flex relative">
            <input
              type="number"
              className=" mb-4 px-2 .remove_number_input_arrow"
              style={{
                width: "100px",
              }}
            />
            <span className="absolute right-0">%</span>
          </div>
          <button
            style={{ width: "100px", height: "33px" }}
            type="button"
            className="text-xxs text-primary bg-primaryLight"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currencies: state.settings.coins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCoinSettingsAction: (state, successCallback) =>
      dispatch(changeCoinSettingsAction(state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateSelector);
