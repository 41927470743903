import styled from "styled-components";

export const SavingsTableHeaderStyle = styled.table`
  background: #e7eaf4;

  @media (max-width: 768px) {
    &.w-full {
      width: 160% !important;
    }
  }
  @media (max-width: 992px) {
    &.w-full {
      width: 160% !important;
    }
  }
  .Table-heading {
    background: #e7eaf4;
  }
`;

export const SavingsTableStyle = styled.table`
  @media (max-width: 768px) {
    &.w-full {
      width: 160% !important;
    }
  }
  @media (max-width: 992px) {
    &.w-full {
      width: 160% !important;
    }
  }
  .Table-heading {
    background: #e7eaf4;
  }
`;

export const SavingsModalStyle = styled.div`
  .border-bottom {
    border-bottom: 1px solid #eff0f5;
  }
  .Actions {
    background: ${(props) => (props.bg ? props.bg : "#005674")};
    padding: ${(props) => (props.p ? props.p : "12px")};
    border-radius: ${(props) =>
      props.borderRadius ? props.borderRadius : "4px"};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Transaction-Approved {
    width: 100%;
    height: 441px;
    height: ${(props) => (props.h ? props.h : "auto")};
    display: flex;
    align-items: center;
    justify-content: center;
    // background: ${(props) => (props.bg ? props.bg : "#005674")};
    border-radius: 4px;
  }
`;
