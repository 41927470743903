export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const groupActivitiesByDaysOrMonth = (transactions, str) => {
  let result;
  // str(, for days grouping, / for months grouping)

  // sort the transaction by recent date first
  let transactionsSort =
    transactions &&
    transactions.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  // create the groups
  let groups =
    transactionsSort &&
    transactionsSort.reduce(function (r, o) {
      // by day or by month using created_at
      var m = new Date(o.created_at)
        .toLocaleString()
        .split(str === "month" ? "/" : str === "days" ? "," : ",")[0];
      // console.log(m);

      r[m] ? r[m].data.push(o) : (r[m] = { group: o.created_at, data: [o] });

      return r;
    }, {});

  result = Object.keys(groups ? groups : []).map(function (k) {
    return groups[k];
  });

  return result;
};

export const customGroupActivitiesByDaysOrMonth = (transactions, str, dateField) => {
  let result;
  // str(, for days grouping, / for months grouping)

  // sort the transaction by recent date first
  let transactionsSort =
    transactions &&
    transactions.sort(
      (a, b) => new Date(b[dateField]) - new Date(a[dateField])
    );
  // create the groups
  let groups =
    transactionsSort &&
    transactionsSort.reduce(function (r, o) {
      // by day or by month using created_at
    var m = new Date(o[dateField])
        .toLocaleString()
        .split(str === "month" ? "/" : str === "days" ? "," : ",")[0];
      // console.log(m);

      r[m] ? r[m].data.push(o) : (r[m] = { group: o[dateField], data: [o] });

      return r;
    }, {});

  result = Object.keys(groups ? groups : []).map(function (k) {
    return groups[k];
  });

  return result;
};

export const addTransactionsBasedOnCurrency = (
  transactions,
  prices,
  currencies,
  currencyType,
  usdPrice,
  amountType
) => {
  if (transactions && prices && currencies) {
    let convertedAmounts = [];
    let added;

    currencies.map((curr, idx, arr) => {
      if (curr.symbol !== "NGN") {
        if (prices[curr.symbol] && prices[curr.symbol][currencyType]) {
          let converted =
            prices[curr.symbol][currencyType] *
            transactions[curr.symbol][amountType];
          convertedAmounts.push(converted);
        }
      } else {
        if (
          curr.symbol &&
          currencyType &&
          currencyType === "usd" &&
          curr.symbol
        ) {
          convertedAmounts.push(
            transactions[curr.symbol][amountType] * usdPrice
          );
        }
        // else if(curr.symbol && currencyType && currencyType==="ngn" && curr.symbol){
        //   convertedAmounts.push(transactions[curr.symbol][amountType] * usdPrice)
        // }
        else {
          convertedAmounts.push(transactions[curr.symbol][amountType]);
        }
      }
      return convertedAmounts;
    });
    added =
      convertedAmounts && convertedAmounts.length !== 0
        ? convertedAmounts.reduce((acc, curr) => (curr += acc))
        : 0;
    return added;
  }
};

export const addTransactionsCurrencies = (
  transactions,
  prices,
  currencies,
  currencyType
) => {
  if (transactions && prices && currencies) {
    let convertedAmounts = [];
    let added;

    currencies.map((curr, idx, arr) => {
      if (curr.symbol !== "NGN") {
        if (prices[curr.symbol] && prices[curr.symbol][currencyType]) {
          let converted =
            prices[curr.symbol][currencyType] * transactions[curr.symbol];
          convertedAmounts.push(converted);
        }
      } else {
        if (currencyType === "usd") {
          const convertedNGNToUsd =
            transactions[curr.symbol] / prices["USD"]["ngn"];
          convertedAmounts.push(convertedNGNToUsd);
        } else {
          convertedAmounts.push(transactions[curr.symbol]);
        }
      }
      return convertedAmounts;
    });
    added =
      convertedAmounts && convertedAmounts.length !== 0
        ? convertedAmounts.reduce((acc, curr) => (curr += acc))
        : 0;
    return added;
  }
};

export const addTotalCrypto = (
  transactions,
  prices,
  currencies,
  currencyType,
  amountType
) => {
  if (transactions && prices && currencies) {
    let convertedAmounts = [];
    let added;
    currencies.map((curr, idx, arr) => {
      if (curr.symbol !== "NGN") {
        if (prices[curr.symbol] && prices[curr.symbol][currencyType]) {
          let converted =
            prices[curr.symbol][currencyType] *
            transactions[curr.symbol][amountType];
          convertedAmounts.push(converted);
        }
      }
      return convertedAmounts;
    });
    added =
      convertedAmounts && convertedAmounts.length !== 0
        ? convertedAmounts.reduce((acc, curr) => (curr += acc))
        : 0;
    return added;
  }
};

export const roundOne = (n, d) =>
  Math.round(n * Math.pow(10, d)) / Math.pow(10, d);

export const addTransactions = (transaction) => {
  let addedAmountsArr = [];
  let addedTransactions;

  if (transaction) {
    transaction &&
      transaction.map((curr, idx, arr) => {
        addedAmountsArr.push(curr.amount);
        return addedAmountsArr;
      });
    addedTransactions =
      addedAmountsArr && addedAmountsArr.length !== 0
        ? addedAmountsArr.reduce((acc, curr) => (curr += acc)).toLocaleString()
        : 0;
    return addedTransactions;
  }
};

export const filterPendingOrCompletedTransactions = (transaction, status, type) => {
  let pendingTransactionArr = [];
  let addedPendingTransactions;

  if (transaction) {
    let filteredTransaction = transaction.filter(
      (curr) => curr.status === status && curr.type === type
    );
    transaction &&
      filteredTransaction.map((curr, idx, arr) => {
        pendingTransactionArr.push(curr.amount);
        return pendingTransactionArr;
      });
    addedPendingTransactions =
      pendingTransactionArr && pendingTransactionArr.length !== 0
        ? pendingTransactionArr
            .reduce((acc, curr) => (curr += acc))
            .toLocaleString()
        : 0;
    return addedPendingTransactions;
  }
};

export const filterTransactionsByType = (transaction, type) => {
  let pendingTransactionArr = [];
  let addedPendingTransactions;

  if (transaction) {
    let filteredTransaction = transaction.filter(
      (curr) => curr.type === type
    );
    transaction &&
      filteredTransaction.map((curr, idx, arr) => {
        pendingTransactionArr.push(curr.amount);
        return pendingTransactionArr;
      });
    addedPendingTransactions =
      pendingTransactionArr && pendingTransactionArr.length !== 0
        ? pendingTransactionArr
            .reduce((acc, curr) => (curr += acc))
            .toLocaleString()
        : 0;
    return addedPendingTransactions;
  }
};

export const filterActivitiesBasedOnCoinKeyword = (
  transactions,
  keyword,
  transactionProperty
) => {
  if (transactions) {
    if (keyword) {
      if (keyword !== "All") {
        let filteredTransactions = transactions.filter(
          (curr) => curr[transactionProperty] === keyword
        );
        return filteredTransactions;
      }
    }
    return transactions;
  }
  return null;
};

export const filterTradeBasedOnCurrency = (
  transactions,
  keyword,
  source_currency,
  destination_currency
) => {
  if (transactions) {
    if (keyword) {
      if (keyword !== "All") {
        let filteredTransactions = transactions.filter(
          (curr) =>
            curr[destination_currency] === keyword ||
            curr[source_currency] === keyword
        );
        return filteredTransactions;
      }
    }
    return transactions;
  }
  return null;
};

export const filterActivitiesSummaryBasedOnTime = (activities, keyword) => {
  if (activities) {
    if (keyword) {
      if (keyword !== "All") {
        let filteredTransactions = activities.filter(
          (curr) => Date.now() - new Date(curr.created_at) <= keyword
        );
        return filteredTransactions;
      }
    }
    return activities;
  }
  return null;
};

export const getCurrencyStrings = (currencies) => {
  if (currencies) {
    let convertedAmounts = [];
    currencies.map((curr, idx, arr) => {
      // if(curr.symbol !== "NGN"){
      let converted = { text: curr.symbol };
      convertedAmounts.push(converted);
      return convertedAmounts;
      // }
    });
    return convertedAmounts;
  }
};

export const GetCryptoStrings = (prices) => {
  let string = [];
  if (prices) {
    string = Object.keys(prices)
      .map((curr) => {
        return { text: curr };
      })
      .filter((curr) => curr.text !== "USD");
    string.push({ text: "All" });
    return string;
  }
  return null;
};

export const compareBy = (key, ascending) => {
  let reverse = ascending ? 1 : -1;
  return function (a, b) {
    if (a[key] < b[key]) return -1 * reverse;
    if (a[key] > b[key]) return 1 * reverse;
    return 0;
  };
};

export const getLevel = (user) => {
  if (user) {
    if (user.kyc_verifications.length === 0) {
      return 0;
    }
    return Math.max(
      ...user.kyc_verifications
        .filter((kyc) => kyc.is_approved)
        .map((kyc) => kyc.kyc_verification_level.index)
    );
  }
};

export const filterUsersonChange = (listOfUsers, keyword) => {
  if (listOfUsers) {
    // return listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.includes(keyword)) && getLevel(user)=== 2)
    // return listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.startsWith(keyword) ) )
    // return listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.match(keyword) ) )
    // let users= listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.match(keyword ? keyword.replace(/[^a-z0-9]/gi, ''): keyword) ) );

    let users = listOfUsers.filter((user) =>
      Object.values(user.profile || user).some(
        (val) =>
          typeof val === "string" && new RegExp("^" + keyword, "i").test(val)
      )
    );
    if (users === null || users === undefined || users.length === 0) {
      return listOfUsers;
    }
    return users;
  }
  return null;
};

export const filterVerificationUsers = (listOfUsers, keyword) => {
  if (listOfUsers) {
    let users = listOfUsers.filter((user) =>
      Object.values(user.account || user).some(
        (val) =>
          typeof val === "string" && new RegExp("^" + keyword, "i").test(val)
      )
    );
    if (users === null || users === undefined || users.length === 0) {
      return listOfUsers;
    }
    return users;
  }
  return null;
};

export const filterUsersBasedOnLevel = (listOfUsers, keyword, level) => {
  if (listOfUsers) {
    // return listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.startsWith(keyword)) && getLevel(user)=== level)
    // return listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.match(keyword)) && getLevel(user)=== level)
    // let Level3users= listOfUsers.filter(user => Object.values(user).some(val => typeof val === "string" && val.match(keyword ? keyword.replace(/[^a-z0-9]/gi, ''): keyword)) && getLevel(user)=== level);

    let Level3users = listOfUsers.filter(
      (user) =>
        Object.values(user.profile || user).some(
          (val) =>
            typeof val === "string" && new RegExp("^" + keyword, "i").test(val)
        ) && getLevel(user) === level
    );
    if (
      Level3users === null ||
      Level3users === undefined ||
      Level3users.length === 0
    ) {
      // return listOfUsers.filter(user => getLevel(user)=== level);
      return null;
    }
    return Level3users;
  }
  return null;
};

export const filterUsersOnLevel = (listOfUsers, level) => {
  if (listOfUsers) {
    let Level3users = listOfUsers.filter((user) => getLevel(user) === level);
    if (
      Level3users === null ||
      Level3users === undefined ||
      Level3users.length === 0
    ) {
      // return listOfUsers.filter(user => getLevel(user)=== level);
      return null;
    }
    return Level3users;
  }
  return null;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return null;
};

export const ActivitiesSummary = (activities, type, currency) => {
  if (activities) {
    let addedAmounts = [];
    let added;
    activities &&
      activities.map((curr, idx, arr) => {
        if (curr.currency === currency && curr.type === type) {
          addedAmounts.push(curr.amount * 1000000);
        }
        return addedAmounts;
      });
    added =
      addedAmounts && addedAmounts.length !== 0
        ? addedAmounts.reduce((acc, curr) => (curr += acc))
        : 0;
    return added / 1000000;
  }
  return null;
};

export const cumulativeAdditionOfActivitiesCurrencies = (
  activities,
  prices,
  currencies,
  type,
  currencyType
) => {
  let currAndEquivalent = [];
  if (activities && prices && currencies) {
    Object.values(currencies).map((curr) => {
      if (curr.symbol !== "NGN") {
        if (prices[curr.symbol] && prices[curr.symbol][currencyType]) {
          currAndEquivalent.push({
            coin: curr.symbol,
            addedBalance: ActivitiesSummary(activities, type, curr.symbol),
            dollarEq:
              prices[curr.symbol][currencyType] *
              ActivitiesSummary(activities, type, curr.symbol),
          });
        }
      }
      return currAndEquivalent;
    });
    return currAndEquivalent;
  }
  return currAndEquivalent;
};

// export const getMerchantsStrings = (merchants)=>{
//   if(merchants){
//     let convertedMerchants= [];
//     merchants.map((curr, idx, arr)=>{
//       let converted= {text: curr.account.username}
//       convertedMerchants.push(converted);
//       return convertedMerchants;
//     })
//     return convertedMerchants;
//   }
// }

export const getMerchantsStrings = (merchants) => {
  let string = [];
  if (merchants) {
    string = merchants.map((curr) => {
      return { text: curr.account.username };
    });
    string.push({ text: "All" });
    return string;
  }
  return null;
};

export const filterTransactionsBasedOnMerchants = (transactions, keyword) => {
  if (transactions) {
    if (keyword) {
      if (keyword !== "All") {
        let filteredTransactions = transactions.filter(
          (curr) =>
            curr.transactionType === "Deposit" &&
            curr.merchant.account.username === keyword
        );
        return filteredTransactions;
      }
    }
    return transactions;
  }
  return null;
};

export const OrderCoins = (data) => {
  if (data) {
    let getCoins = data;
    console.log("coins", data);
    const COIN_ORDER = ["NGN", "BTC", "ETH", "LTC", "BCH"];
    let orderedCoins = Object.entries(getCoins).sort((a, b) => {
      return COIN_ORDER.indexOf(a[0]) - COIN_ORDER.indexOf(b[0]);
    });
    let neworderedCoins = Object.fromEntries(orderedCoins);
    console.log("new ordered coins", neworderedCoins);
    return neworderedCoins;
  }
  return null;
};

export const OrderCurrencies = (data) => {
  if (data) {
    const getCoins = data; // api
    const COIN_ORDER = ["NGN", "BTC", "ETH", "BNB", "LTC", "BCH", "DASH"];
    const arrangedCoin = [];
    for (const item of COIN_ORDER) {
      for (const coin of getCoins) {
        if (item === coin.symbol) {
          arrangedCoin.push(coin);
        }
      }
    }
    const mergeArrays = new Set([...arrangedCoin, ...getCoins]);
    const orderedCoins = [];
    for (const data of mergeArrays) {
      orderedCoins.push(data);
    }
    return orderedCoins;
  }
  return null;
};

export const toPlainString = (num) => {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
};

export const formatNumberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
