import axios from "axios";
import {
  GET_OVERVIEW_DATA,
  GET_TRANSACTIONS,
  GET_DEPOSITS,
  GET_WITHDRAWALS,
  GET_CURRENCIES,
  GET_TOTAL_USERS,
  GET_PRICES,
  GET_MATCHED_DEPOSITS,
  GET_MATCHED_WITHDRAWALS,
  GET_WALLETS_DATA,
  OVERVIEW_LOADING,
  OVERVIEW_ERROR,
  GET_PENDING_DEPOSITS,
  GET_PENDING_WITHDRAWALS,
  FEES_LOADING,
  GET_FEES,
  FEES_ERROR,
} from "../Types/Types";

import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout, messages } from "./AuthActions";
import Api from "./api";

export const overviewAction = (query) => {
  return async (dispatch, getState) => {
    dispatch({ type: OVERVIEW_LOADING });
    dispatch(showLoading());
    if ("logout_message" in messages) return;
    try {
      const response = await Api.Overview(query);
      console.log(response);
      dispatch({ type: GET_OVERVIEW_DATA, overview: response.data });
      dispatch({
        type: GET_TRANSACTIONS,
        transactions: response.data.total_transactions,
      });
      dispatch({ type: GET_DEPOSITS, deposits: response.data.total_deposits });
      dispatch({
        type: GET_WITHDRAWALS,
        withdrawals: response.data.total_withdrawals,
      });
      dispatch({ type: GET_CURRENCIES, currencies: response.data.currencies });
      dispatch({
        type: GET_TOTAL_USERS,
        total_users: response.data.total_users,
      });
      dispatch({
        type: GET_MATCHED_DEPOSITS,
        total_matched_deposits: response.data.total_matched_deposits,
      });
      dispatch({
        type: GET_MATCHED_WITHDRAWALS,
        total_matched_withdrawals: response.data.total_matched_withdrawals,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: OVERVIEW_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const pricesAction = () => {
  return async (dispatch, getState) => {
    dispatch({ type: OVERVIEW_LOADING });
    dispatch(showLoading());
    if ("logout_message" in messages) return;
    try {
      const response = await Api.Prices();
      // console.log(response.data);
      dispatch({ type: GET_PRICES, prices: response.data });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: OVERVIEW_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const walletsAction = () => {
  return async (dispatch, getState) => {
    dispatch({ type: OVERVIEW_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.Wallets();
      console.log(response);
      dispatch({ type: GET_WALLETS_DATA, wallets: response.data });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: OVERVIEW_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getFeesAction = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: FEES_LOADING });
    dispatch(showLoading());
    if ("logout_message" in messages) return;
    try {
      const response = await Api.getFees(payload);
      console.log(response.data);
      dispatch({ type: GET_FEES, fee: response.data.fee });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: FEES_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};
