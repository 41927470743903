import React, { useState, useEffect } from "react";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import { ToggleButton } from "../../../../../UI toolkit/Input/Input";
import { Card } from "../../../../Units/Card.styled";
import {
  CardHolder,
  CardTitle,
  TabNav,
} from "../../../../Units/CardTitle.styled";
import ic_overview from "../../../../../assets/images/ic-overview-db.svg";
import { connect } from "react-redux";
import Statbox from "./Statbox";
import { getSavingsOverviewStats } from "../../../../../store/Actions/SavingsActions";

const Overview = ({ overviewAction, token, overview }) => {
  const TABS = [
    { label: "Today", queryParam: "today" },
    { label: "7 days", queryParam: "7d" },
    { label: "30 days", queryParam: "30d" },
    { label: "All time", queryParam: "all" },
  ];

  const [activeTab, handleTab] = useState(3);

  const [state, setstate] = useState({
    key: null,
    value: null,
  });
  const [queryParam, setqueryParam] = useState("all");

  useEffect(() => {
    console.log(queryParam);
    overviewAction(queryParam);
  }, [queryParam, overviewAction, token]);
  return (
    <div>
      <Card>
        <CardHolder>
          <div className="flex">
            <div className="w-6/12">
              <CardTitle img={ic_overview} text={"Overview"} />
            </div>
            <div className="w-6/12">
              <TabNav
                tabs={TABS}
                activeTab={activeTab}
                handleTab={handleTab}
                p={"4px 8px 3px 8px"}
                Keys={["label", "queryParam"]}
                // borderRadius={"0px"}
                boxShadow={"0px"}
                callback={(curr) => {
                  setqueryParam(curr["queryParam"]);
                }}
              />
            </div>
          </div>
        </CardHolder>
        <div
          className="flex py-6 pl-4"
          style={{ borderBottom: "1px solid #EFF0F5" }}
        >
          <Statbox
            name="TOTAL SAVED"
            value={overview ? overview.totalAmountSaved : null}
            isMoney={true}
          />
          <Statbox
            name="TOTAL PAID"
            value={overview ? overview.totalPaid : null}
            isMoney={true}
          />
          <Statbox
            name="TOTAL SAVINGS COUNT"
            value={overview ? overview.totalSavingsCount : null}
          />
        </div>

        <div
          className="flex py-6 pl-4"
          style={{ borderBottom: "1px solid #EFF0F5" }}
        >
          <Statbox
            name="TOTAL ONGOING FLEXIBLE"
            value={overview ? overview.totalOngoingFlexibleSavings : null}
            isMoney={true}
          />
          <Statbox
            name="TOTAL FLEXIBLE PAYABLE"
            value={overview ? overview.totalFlexiblePayable : null}
            isMoney={true}
          />
          <Statbox
            name="TOTAL FLEXIBLE COUNT"
            value={overview ? overview.totalFlexibleSavingsCount : null}
          />
        </div>
        <div className="flex py-6 pl-4">
          <Statbox
            name="TOTAL ONGOING FIXED"
            value={overview ? overview.totalOngoingFixedSavings : null}
            isMoney={true}
          />
          <Statbox
            name="TOTAL FIXED PAYABLE"
            value={overview ? overview.totalFixedPayable : null}
            isMoney={true}
          />
          <Statbox
            name="TOTAL FIXED COUNT"
            value={overview ? overview.totalFixedSavingsCount : null}
          />
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    overview: state.savings.overviewStats,
    loading: state.savings.loading,
    error: state.savings.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: (query) => dispatch(getSavingsOverviewStats(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
