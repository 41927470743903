import styled from "styled-components";

export const NotFoundBaseStyle = styled.div`
  // background: #112E46;
  // color: #fff;

  background: ${(props) => props.theme.colors.bg || "#112E46"};
  color: ${(props) => props.theme.colors.color || "#FFFFFF"};

  z-index: 2;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
