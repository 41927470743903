import React, { useState } from "react";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import {
  ActivitiesTableStyle,
  SendReceiveTableStyle,
  ActivitiesTablesHeaderStyle,
} from "./Activities.styled";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ic_sent_icon from "../../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../../assets/images/ic-received-coin.svg";
import ic_bought_coin from "../../../../../assets/images/ic-bought-coin.svg";
import ic_sold_coin from "../../../../../assets/images/ic-sold-coin.svg";
import ic_swapped_coin from "../../../../../assets/images/ic-swapped-coin.svg";
import { capitalizeFirstLetter } from "../../../../../utils/specialFunctions";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

const ActivitiesTable = ({ activities }) => {
  const bg = (curr) => {
    return curr.status === "active"
      ? "#FFEAB6"
      : curr.status === "completed"
      ? "#D1F7C4"
      : curr.status === "stopped"
      ? "#FFDCE5"
      : "";
  };

  const color = (curr) => {
    return curr.status === "active"
      ? "#606060"
      : curr.status === "completed"
      ? "#606060"
      : curr.status === "stopped"
      ? "#531423"
      : "";
  };

  const statusKeys = {
    stopped: "broken",
    active: "ongoing",
    completed: "completed",
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {activities &&
        activities.map((activityGroup, idx, arr) => {
          return (
            <SendReceiveTableStyle
              className={idx % 2 === 0 ? "w-full my-10" : "w-full"}
              key={idx}
            >
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div className="pt-4 pb-4 px-1.5">
                      <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                        {`${timeAgo.format(
                          new Date(activityGroup.group)
                        )} — ${new Date(activityGroup.group).toDateString()}`}
                      </P>
                    </div>
                  </td>
                </tr>
                {activityGroup.data &&
                  activityGroup.data.map((activity, idx, arr) => {
                    return (
                      <tr
                        className="grid grid-cols-5 justify-center items-center"
                        style={{
                          borderBottom: "1px solid #EFF0F5",
                          width: "100%",
                        }}
                        key={activity.id}
                      >
                        <td className="pt-4 pb-4 col-start-1 col-end-2">
                          <div className="flex">
                            <div className="pr-2">
                              <img
                                src={
                                  activity.savings_type.type === "fixed"
                                    ? ic_sent_icon
                                    : activity.savings_type.type === "flexible"
                                    ? ic_receive_icon
                                    : activity.savings_type.type === "swap"
                                    ? ic_swapped_coin
                                    : ""
                                }
                                className="h-auto w-auto"
                              />
                            </div>
                            <div>
                              <P
                                fontSize="13px"
                                color={"#374072"}
                                fontWeight="500"
                              >{`${capitalizeFirstLetter(
                                activity.savings_type.type
                              )}`}</P>
                              <P fontSize="10px" color={"#7A809B"}>
                                {
                                  new Date(activity.created_at)
                                    .toLocaleString()
                                    .split(",")[1]
                                }
                              </P>
                            </div>
                          </div>
                        </td>

                        <td className="col-start-2 col-end-4">
                          <div>
                            <P
                              fontSize="13px"
                              color={"#374072"}
                              fontWeight="500"
                            >
                              {activity &&
                              activity.account &&
                              activity.account.email
                                ? activity.account.email
                                : "no email"}
                            </P>
                          </div>
                        </td>
                        <td>
                          <div>
                            <P
                              fontSize="13px"
                              color={"#374072"}
                              fontWeight="500"
                            >
                              {`${
                                activity.currency === "USDT"
                                  ? "$"
                                  : activity.currrency === "NGN"
                                  ? "₦"
                                  : ""
                              } ${Number(activity.amount).toFixed(2)}`}
                            </P>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${
                              activity.status === "pending" &&
                              activity.type === "send"
                                ? "w-3/12 text-right"
                                : "w-6/12 flex justify-end"
                            }`}
                          >
                            <Badge bg={bg(activity)} color={color(activity)}>
                              {statusKeys[activity.status]}
                            </Badge>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </SendReceiveTableStyle>
          );
        })}
    </div>
  );
};

export default ActivitiesTable;
