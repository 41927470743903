import axios from "axios";
import Api from "./api";
import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";
import {
  GET_SAVINGS,
  GET_SAVINGS_OVERVIEW_STATS,
  GET_USER_SAVINGS,
  GET_USER_SAVINGS_STATS,
  GET_USER_SAVINGS_WALLET,
  SAVINGS_ERROR,
  SAVINGS_LOADING,
  SET_USER_SAVINGS_FILTER,
  UPDATE_FIXED_ACTIVE_TAB,
  UPDATE_FIXED_NAVIGATOR,
  UPDATE_FLEXIBLE_ACTIVE_TAB,
  UPDATE_FLEXIBLE_NAVIGATOR,
  UPDATE_SAVINGS_ACTIVE_STATUS,
  UPDATE_SAVINGS_ACTIVE_TAB,
  UPDATE_SAVINGS_PAGE,
  UPDATE_TOTAL_SAVINGS,
} from "../Types/Types";
const FileDownload = require("js-file-download");

export const getSavingsOverviewStats = (query) => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVINGS_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.getSavingStats(query);
      console.log(response);
      dispatch({
        type: GET_SAVINGS_OVERVIEW_STATS,
        overviewStats: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: SAVINGS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getSavings = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVINGS_LOADING });
    dispatch(showLoading());

    try {
      const { savingsActiveStatus, savingsPage, savingsActiveTab } =
        getState().savings;

      const currentStatus = savingsActiveStatus[savingsActiveTab];
      const currentPage = savingsPage[savingsActiveTab][currentStatus];

      const query = {
        type: savingsActiveTab,
        page: currentPage,
        status: currentStatus,
      };
      const response = await Api.getSavings(query);
      dispatch({
        type: GET_SAVINGS,
        savings: response.data.rows,
      });
      dispatch({
        type: UPDATE_TOTAL_SAVINGS,
        payload: {
          tab: query.type,
          status: query.status,
          value: response.data.totalPages,
        },
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: SAVINGS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getUserSavingsStats = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVINGS_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.getUserSavingsStats(id);
      //      console.log(response);
      dispatch({
        type: GET_USER_SAVINGS_STATS,
        user_savings_stats: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: SAVINGS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getUserSavings = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVINGS_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.getUserSavings(id);
      dispatch({
        type: GET_USER_SAVINGS,
        user_savings: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: SAVINGS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const getUsersSavingsWalletStats = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SAVINGS_LOADING });
    dispatch(showLoading());
    try {
      const response = await Api.getSavingWalletStats();
      dispatch({
        type: GET_USER_SAVINGS_WALLET,
        walletStats: response.data,
      });
      dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: SAVINGS_ERROR });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const setUserSavingsFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_SAVINGS_FILTER,
      user_savings_filter: payload,
    });
  };
};

export const updateFlexibleActiveTab = (tab) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_FLEXIBLE_ACTIVE_TAB, payload: tab });
  };
};

export const updateFlexibleNavigator = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_FLEXIBLE_NAVIGATOR, payload });
  };
};

export const updateFixedActiveTab = (tab) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_FIXED_ACTIVE_TAB, payload: tab });
  };
};

export const updateFixedNavigator = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_FIXED_NAVIGATOR, payload });
  };
};

export const updateSavingsActiveTab = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SAVINGS_ACTIVE_TAB, payload });
  };
};

export const updateSavingsActiveStatus = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SAVINGS_ACTIVE_STATUS, payload });
  };
};

export const updateSavingsPage = (payload) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SAVINGS_PAGE, payload });
  };
};
