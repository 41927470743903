import {
  GET_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENTS,
  LOADING_ANNOUNCEMENTS,
  ERROR_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENTS_NAVIGATOR,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  announcementsList: null,
  annoucements_filters: null,
  announcementsNavigator: {
    startIndex: 0,
    endIndex: 6,
    currentIndex: 0,
  },
};

const AnnouncementReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      return {
        ...state,
        announcementsList: action.announcementsList,
        annoucements_filters: action.filters,
        loading: false,
      };
    case LOADING_ANNOUNCEMENTS:
      return {
        ...state,
        loading: true,
      };
    case ERROR_ANNOUNCEMENTS:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case UPDATE_ANNOUNCEMENTS_NAVIGATOR:
      return {
        ...state,
        announcementsNavigator: {
          ...state.announcementsNavigator,
          [action.payload.field]: action.payload.value,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default AnnouncementReducer;
