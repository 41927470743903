import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const DefaultFont = createGlobalStyle`
* {
    // @import url('../../font.css');
    font-family: Vazir !important;
  }
`;

export const H1 = styled.h1`
  font-size: ${(props) => props.fontSize || "40px"};
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;
export const H2 = styled.h2`
  font-size: ${(props) => props.fontSize || "32px"};
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;
export const H3 = styled.h3`
  font-size: ${(props) => props.fontSize || "28px"};
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;
export const H4 = styled.h4`
  font-size: ${(props) => props.fontSize || "24px"};
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;
export const H5 = styled.h5`
  font-size: ${(props) => props.fontSize || "20px"};
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;
export const H6 = styled.h6`
  font-size: ${(props) => props.fontSize || "16px"};
  color: ${(props) => props.color || "#005674"};
  font-weight: ${(props) => props.fontWeight};
`;
export const SubHeader = styled.p`
  font-size: ${(props) => props.fontSize || "12px"};
  letter-spacing: 0.96px;
  color: #7c8191;
  text-transform: uppercase;
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;

export const P = styled.p`
  font-size: ${(props) => props.fontSize || "12px"};
  color: #7c8191;
  color: ${(props) => props.color || "#464646"};
  font-weight: ${(props) => props.fontWeight};
`;
