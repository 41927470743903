import React, { useEffect, useState } from "react";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder } from "../../../Units/CardTitle.styled";
import { AdminTableStyle } from "./Settings.Styled";
import {
  changeCoinSettingsAction,
  getCoinsAction,
} from "../../../../store/Actions/SettingsActions";
import { connect } from "react-redux";
import { ToggleButton } from "../../../../UI toolkit/Input/Input";

const Coins = ({ currencies, getCoins, changeCoinSettingsAction }) => {
  const [state, setstate] = useState({
    key: null,
    value: null,
    currency: null,
  });
  useEffect(() => {
    getCoins();
  }, [getCoins]);

  useEffect(() => {
    console.log(state);
    if (state.key !== null && state.value !== null && state.currency !== null) {
      changeCoinSettingsAction(state, (response) => {});
    }
  }, [state, changeCoinSettingsAction]);

  return (
    <div>
      <Card>
        <CardHolder>
          <div className="flex items-center">
            <div className="w-10/12">
              <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
                Coins list
              </P>
            </div>
          </div>
        </CardHolder>

        <div className="">
          <div style={{ overflowX: "auto" }}>
            <AdminTableStyle className="w-full">
              <thead style={{ background: "#E7EAF4" }}>
                <tr className="Table-heading">
                  <td width="20%" className="px-4 pt-4 pb-4">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      Name
                    </SubHeader>
                  </td>

                  <td width="20%" className="px-4 pt-4 pb-4">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        BUYING
                      </SubHeader>
                    </div>
                  </td>

                  <td width="20%" className="px-4 pt-4 pb-4">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        SELLING
                      </SubHeader>
                    </div>
                  </td>
                  <td width="20%" className="px-4 pt-4 pb-4">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        SWAPPING
                      </SubHeader>
                    </div>
                  </td>

                  <td width="20%" className="px-4 pt-4 pb-4">
                    <div className="">
                      <SubHeader
                        fontSize="12px"
                        color={"#7C8191"}
                        fontWeight="500"
                      >
                        SENDING
                      </SubHeader>
                    </div>
                  </td>
                </tr>
              </thead>

              <tbody>
                {currencies &&
                  currencies.map((curr) => {
                    return (
                      <tr
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #EFF0F5",
                        }}
                        key={curr.id}
                      >
                        <td width="20%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr.symbol}
                          </P>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <div className="flex w-6/12">
                            <ToggleButton
                              toggleId={curr.id + "buy"}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setstate({
                                  ...state,
                                  key: "buy",
                                  value: e.target.checked,
                                  currency: curr.symbol,
                                });
                              }}
                              checked={curr.buy_enabled}
                            />
                            <P
                              className="ml-2"
                              color={"#374072"}
                              fontSize={"13px"}
                              fontWeight="500"
                            >
                              {curr.buy_enabled ? "On" : "Off"}
                            </P>
                          </div>
                        </td>
                        <td width="20%" className="px-4 pt-4 pb-4">
                          <div className="flex w-6/12">
                            <ToggleButton
                              toggleId={curr.id + "sell"}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setstate({
                                  ...state,
                                  key: "sell",
                                  value: e.target.checked,
                                  currency: curr.symbol,
                                });
                              }}
                              checked={curr.sell_enabled}
                            />
                            <P
                              className="ml-2"
                              color={"#374072"}
                              fontSize={"13px"}
                              fontWeight="500"
                            >
                              {curr.sell_enabled ? "On" : "Off"}
                            </P>
                          </div>
                        </td>
                        <td width="20%" className="px-4 pt-4 pb-4">
                          <div className="flex w-6/12">
                            <ToggleButton
                              toggleId={curr.id + "swap"}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setstate({
                                  ...state,
                                  key: "swap",
                                  value: e.target.checked,
                                  currency: curr.symbol,
                                });
                              }}
                              checked={curr.swap_enabled}
                            />
                            <P
                              className="ml-2"
                              color={"#374072"}
                              fontSize={"13px"}
                              fontWeight="500"
                            >
                              {curr.swap_enabled ? "On" : "Off"}
                            </P>
                          </div>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <div className="flex w-6/12">
                            <ToggleButton
                              toggleId={curr.id + "send"}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setstate({
                                  ...state,
                                  key: "send",
                                  value: e.target.checked,
                                  currency: curr.symbol,
                                });
                              }}
                              checked={curr.send_enabled}
                            />
                            <P
                              className="ml-2"
                              color={"#374072"}
                              fontSize={"13px"}
                              fontWeight="500"
                            >
                              {curr.send_enabled ? "On" : "Off"}
                            </P>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </AdminTableStyle>
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currencies: state.settings.coins,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCoins: () => dispatch(getCoinsAction()),
    changeCoinSettingsAction: (state, successCallback) =>
      dispatch(changeCoinSettingsAction(state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coins);
