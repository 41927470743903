import React from "react";
import { Link } from "react-router-dom";
import { H5, SubHeader } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import {
  CardHolder,
  CardTitle,
  CardTitleEllipse,
} from "../../../Units/CardTitle.styled";
import ic_crypto from "../../../../assets/images/ic-crypto.svg";
import { connect } from "react-redux";
import { addTotalCrypto } from "../../../../utils/specialFunctions";
import config from "../../../../config";

const TotalCrypto = ({
  deposits,
  withdrawals,
  users,
  transactions,
  prices,
  currencies,
}) => {
  let totalCrypto =
    transactions && prices && currencies
      ? addTotalCrypto(transactions, prices, currencies, "usd", "external")
      : null;
  let usersCash =
    transactions && prices && currencies
      ? addTotalCrypto(transactions, prices, currencies, "usd", "users")
      : null;
  let cryptoWallet =
    transactions && prices && currencies
      ? addTotalCrypto(transactions, prices, currencies, "usd", "external") -
        addTotalCrypto(transactions, prices, currencies, "usd", "users")
      : null;

  return (
    <div className="pt-6">
      <Card>
        <CardHolder>
          <div className="flex items-center">
            <div className="w-10/12">
              <CardTitle img={ic_crypto} text={"Total crypto"} />
            </div>
            <div className="w-2/12 flex justify-end">
              <CardTitleEllipse />
            </div>
          </div>
        </CardHolder>

        <div className="px-4">
          <div className="py-6" style={{ borderBottom: "1px solid #EFF0F5" }}>
            <H5 color={"#112E46"} fontWeight="700">
              $ {totalCrypto ? totalCrypto.toLocaleString() : null}
            </H5>
          </div>

          <div className="pt-6">
            <SubHeader color={"#7C8191"} fontWeight="500">
              USERS CASH
            </SubHeader>
            <div className="pb-4">
              <H5 color={"#112E46"} fontWeight="700">
                ~ $ {usersCash ? usersCash.toLocaleString() : null}
              </H5>
            </div>
          </div>

          <SubHeader color={"#7C8191"} fontWeight="500">
            {config.appName}
          </SubHeader>
          <div className="pb-4">
            <H5
              color={
                cryptoWallet > 0
                  ? "#24B57A"
                  : cryptoWallet < 0
                  ? "#D60000"
                  : "#112E46"
              }
              fontWeight="700"
            >
              ~ $ {cryptoWallet && cryptoWallet.toLocaleString()}
            </H5>
          </div>

          <div className="py-4">
            <Link to="/dashboard/wallet" className="viewAll">
              View all
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.overview.loading,
    error: state.overview.error,
  };
};

export default connect(mapStateToProps, null)(TotalCrypto);
