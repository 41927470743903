import React, { useState, useEffect } from "react";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { ToggleButton } from "../../../../UI toolkit/Input/Input";
import { Card } from "../../../Units/Card.styled";
import { CardHolder } from "../../../Units/CardTitle.styled";
import { connect } from "react-redux";
import {
  adminSettingsAction,
  changeAdminSettingsAction,
} from "../../../../store/Actions/SettingsActions";

const Wallet = ({ admin_settings, changeAdminSettingsAction }) => {
  const cash = ["DEPOSITS_ENABLED", "WITHDRAWALS_ENABLED"];
  const crypto = [
    "SEND_ENABLED",
    "RECEIVE_ENABLED",
    "BUY_ENABLED",
    "SELL_ENABLED",
    "SWAP_ENABLED",
  ];

  const mapper = {
    DEPOSITS_ENABLED: "Deposits",
    WITHDRAWALS_ENABLED: "Withdrawals",
    SWAP_ENABLED: "Swap",
    SELL_ENABLED: "Sell",
    SEND_ENABLED: "Send",
    RECEIVE_ENABLED: "Recieve",
    BUY_ENABLED: "Buy",
  };

  const [state, setstate] = useState({
    key: null,
    value: null,
  });

  useEffect(() => {
    if (state.key !== null && state.value !== null) {
      changeAdminSettingsAction(state, (response) => {});
    }
  }, [state, changeAdminSettingsAction]);

  return (
    <div>
      <Card>
        <CardHolder>
          <div className="w-12/12">
            <P color={"#FFF"} fontSize={"14px"} fontWeight="500">
              Set Services Status
            </P>
          </div>
        </CardHolder>

        <div className="px-6 py-6">
          <div className="my-4">
            <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
              CASH
            </SubHeader>

            <div className="flex flex-wrap overflow-hidden h-full mt-4">
              {admin_settings &&
                admin_settings
                  .filter((curr) => cash.includes(curr.key))
                  .map((curr, idx, arr) => {
                    return (
                      <div className={`w-6/12 flex mt-4`} key={idx}>
                        <div className="flex w-6/12">
                          <P
                            color={"#374072"}
                            fontSize={"13px"}
                            fontWeight="500"
                          >
                            {mapper[curr.key]}
                          </P>
                        </div>
                        <div className="flex w-6/12">
                          <ToggleButton
                            toggleId={idx + "cash"}
                            onChange={(e) => {
                              console.log(e.target.checked);
                              setstate({
                                ...state,
                                key: curr.key,
                                value: e.target.checked,
                              });
                            }}
                            checked={curr.value}
                          />
                          <P
                            className="ml-2"
                            color={"#374072"}
                            fontSize={"13px"}
                            fontWeight="500"
                          >
                            {curr.value ? "On" : "Off"}
                          </P>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>

          <hr />

          <div className="mt-4">
            <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
              CRYPTO
            </SubHeader>

            <div className="mt-4">
              <div className="flex flex-wrap overflow-hidden h-full mt-4">
                {admin_settings &&
                  admin_settings
                    .filter((curr, idx, arr) => crypto.includes(curr.key))
                    .map((curr, idx, arr) => {
                      return (
                        <div className={`w-6/12 flex mt-4`} key={idx}>
                          <div className="flex w-6/12">
                            <P
                              color={"#374072"}
                              fontSize={"13px"}
                              fontWeight="500"
                            >
                              {mapper[curr.key]}
                            </P>
                          </div>
                          <div className="flex w-6/12">
                            <ToggleButton
                              toggleId={idx + "cr"}
                              onChange={(e) => {
                                console.log(e.target.checked);
                                setstate({
                                  ...state,
                                  key: curr.key,
                                  value: e.target.checked,
                                });
                              }}
                              checked={curr.value}
                            />
                            <P
                              className="ml-2"
                              color={"#374072"}
                              fontSize={"13px"}
                              fontWeight="500"
                            >
                              {curr.value ? "On" : "Off"}
                            </P>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>

          <div
            className="mt-6 px-3 py-2 text-center"
            style={{ background: "#EFF0F3", color: "#7A809B" }}
          >
            <P
              className="italic"
              color={"#374072"}
              fontSize={"14px"}
              fontWeight="500"
            >
              Please note: If a service is turned off, an error message
              (“Service under maintenance” OR “Channel unavailable”) will be
              displayed to the user each time they try to use the service on
              your app.
            </P>
          </div>
        </div>
      </Card>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    admin_settings: state.settings.admin_settings
      ? Object.keys(state.settings.admin_settings).map((key) => ({
          key,
          value: state.settings.admin_settings[key],
        }))
      : null,
    loading: state.settings.loading,
    error: state.settings.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
