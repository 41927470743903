import React, { useEffect } from "react";
import { TableTabStyle } from "../../../Units/TabsAndLinkStyled";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "../../../../UI toolkit/Button/Button";
import { getPendingTransfersCountAction } from "../../../../store/Actions/TransactionActions";

const ActivitiesTableTab = ({
  links,
  activeLink1,
  transfersCount,
  loading,
  getCount,
}) => {
  useEffect(() => {
    if (!transfersCount) {
      getCount();
    }
  }, []);

  return (
    <div>
      {links &&
        links.map((curr, idx, arr) => (
          <Link to={`/dashboard/activities/${curr.link}`} key={idx}>
            <TableTabStyle
              key={idx}
              className={`${idx < arr.length - 1 ? "mr-2 mr-8" : ""} ${
                idx === activeLink1 ? "active" : ""
              }`}
              color={"#646A86"}
              p={"16px 0px"}
              activeBg={"#FAFBFD"}
              activeColor={"#005674"}
              activeOutline={"none"}
              activeOutlineBottom={"2px solid #005674"}
              boxShadow={"0px"}
              borderRadius={"0px"}
              // handleDispatch={() => {
              //   console.log(curr.text);
              //   history.push(`/dashboard/activities/${curr.link}`);
              // }}
              // onClick={() => {
              //   history.push(`/dashboard/activities/${curr.link}`);
              //   console.log(curr.text);
              //   console.log(curr.link);
              // }}
            >
              {curr.text}
              <span className="ml-3">
                {curr.text === "Incoming" ? (
                  <div className="inline-block ">
                    {!loading ? (
                      <Badge bg={"#FFEAB6"} color={"#606060"}>
                        {transfersCount
                          ? transfersCount.totalIncomingTransfers
                          : null}
                      </Badge>
                    ) : (
                      <div className="spinner"></div>
                    )}
                  </div>
                ) : curr.text === "Pending Transfers" ? (
                  <div className="inline-block ">
                    {!loading ? (
                      <Badge bg={"#FFEAB6"} color={"#606060"}>
                        {transfersCount
                          ? transfersCount.totalPendingTransfers
                          : null}
                      </Badge>
                    ) : (
                      <div class="spinner"></div>
                    )}
                  </div>
                ) : null}
              </span>
            </TableTabStyle>
          </Link>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    transfersCount: state.transactions.transfersCount,
    loading: state.transactions.transfersCountloading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCount: (tab) => dispatch(getPendingTransfersCountAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesTableTab);
