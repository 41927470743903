import styled from "styled-components";
import React from "react";
// import search_icon from '../../assets/search.svg'

// inputs

export const Input = styled.input`
  height: ${(props) => props.h};
  max-height: ${(props) => props.maxH};
  min-height: ${(props) => props.minH};
  width: ${(props) => props.w || `100%`};
  max-width: ${(props) => props.maxW};
  min-width: ${(props) => props.minW};
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  color: ${(props) => props.color || "#262626"};
  background: ${(props) => props.bg || "#ffffff"};
  padding: ${(props) => props.p || "11px 16px 10px 16px"};
  font-size: ${(props) => props.fontSize || "15px"};
  transition: 0.3s ease-out;
  box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  outline: none;

  &:placeholder {
    color: #646a8635;
    font-family: "medium", sans-serif;
    font-size: 14px;
  }

  @media (min-width: 1000px) {
    width: ${(props) => props.largeW};
  }

  &:focus {
    border: ${(props) => `1px solid ${props.outline}` || "1px solid #DEDEDF"};
    color: ${(props) => props.outline || "#7A809B"};
  }

  &:focus::-webkit-input-placeholder {
    color: ${(props) => props.outline || "#7A809B"};
  }
  &:-moz-placeholder {
    color: ${(props) => props.outline || "#7A809B"};
  }
  &:focus:-moz-placeholder {
    color: ${(props) => props.outline || "#7A809B"};
  }

  &::-moz-placeholder {
    color: ${(props) => props.outline || "#7A809B"};
  }

  &:focus::-moz-placeholder {
    color: ${(props) => props.outline || "#7A809B"};
  }

  ::-webkit-input-placeholder {
    color: #646a86;
    font-size: 14px;
    font-weight: medium;
    color: #646a86;
    opacity: 0.35;
  }
  :-moz-placeholder {
    color: #646a86;
    font-size: 14px;
    font-weight: medium;
    color: #646a86;
    opacity: 0.35;
  }
  ::-moz-placeholder {
    color: #646a86;
    font-size: 14px;
    font-weight: medium;
    color: #646a86;
    opacity: 0.35;
  }
  :-ms-input-placeholder {
    color: #646a86;
    font-size: 14px;
    font-weight: medium;
    color: #646a86;
    opacity: 0.35;
  }
`;

export const SearchInputBase = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.h};
  width: ${(props) => props.w};
  min-width: ${(props) => props.minW};
  padding-left: 10px;
  background: ${(props) => props.bg};
  padding: ${(props) => props.p || "11px 16px 10px 16px"};
  border-radius: 4px;
  box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
  border: 1px solid #dededf;
  transition: 0.3s ease-out;

  @media (max-width: 1000px) {
    width: ${(props) => props.smallW};
  }

  img {
    margin-right: 2px;
    height: 1.5rem;
  }

  input {
    height: 100%;
    width: 100%;
    border: none;
    color: #262626;
    background: transparent;
    font-size: ${(props) => props.fontSize};

    &:placeholder {
      color: #646a86;
      font-size: 14px;
      font-weight: medium;
      color: #646a86;
      opacity: 0.35;
    }

    ::-webkit-input-placeholder {
      color: #646a86;
      font-size: 14px;
      font-weight: medium;
      color: #646a86;
      opacity: 0.35;
    }
    :-moz-placeholder {
      color: #646a86;
      font-size: 14px;
      font-weight: medium;
      color: #646a86;
      opacity: 0.35;
    }
    ::-moz-placeholder {
      color: #646a86;
      font-size: 14px;
      font-weight: medium;
      color: #646a86;
      opacity: 0.35;
    }
    :-ms-input-placeholder {
      color: #646a86;
      font-size: 14px;
      font-weight: medium;
      color: #646a86;
      opacity: 0.35;
    }

    &:focus {
      outline: none;
    }
  }
`;
export const SearchInput = (props) => {
  return (
    <SearchInputBase
      bg={props.bg}
      h={props.h}
      w={props.w}
      p={props.p}
      minH={props.minH}
      boxShadow={props.boxShadow}
      fontSize={props.fontSize}
      smallW={props.smallW}
    >
      <img src={props.search_icon} alt="hjuYy" className="mr-2 h-6" />
      <input
        type="search"
        className="flex-grow"
        placeholder={props.placeholder}
        // ref={ref}
        name={props.name}
        required
        onChange={(e) => {
          props.callback && props.callback(e);
        }}
      />
    </SearchInputBase>
  );
};

export const FormLabelInputBase = styled.div`
  p {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${(props) => props.outline || "#7A809B"};
    text-transform: capitalize;
    opacity: 1;
    margin-bottom: 8px;
  }
`;
export const FormLabelInput = (props) => {
  return (
    <FormLabelInputBase>
      <p>{props.label}</p>
      <Input placeholder={props.placeholder} outline={props.outline} />
    </FormLabelInputBase>
  );
};

// checkbox

export const CheckboxBase = styled.div`
  display: block;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: ${(props) => props.top || "5px"};
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border: 1px solid #dadee2;
    border-radius: 3px;
  }

  &:hover input ~ .checkmark {
    background: #00af7220;
  }

  & input:checked ~ .checkmark {
    background-color: #00af72;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 4.5px;
    top: 2.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Checkbox = (props) => {
  return (
    <CheckboxBase>
      <label className="container">
        <input type="checkbox" />
        <span className="checkmark"></span>
      </label>
    </CheckboxBase>
  );
};

export const CheckboxLabelBase = styled.div`
  display: flex;

  p {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
    margin-bottom: 8px;
    color: #646a86;
  }
`;

export const CheckboxLabel = (props) => {
  return (
    <CheckboxLabelBase>
      <Checkbox />
      <p>{props.label}</p>
    </CheckboxLabelBase>
  );
};

export const FieldsetInputBase = styled.label`

    &, &::before, &::after {
        box-sizing: border-box;
    }
    
    & {
        position: relative;
        font-size: 14px;
        padding-top: 20px;
        margin-bottom: 5px;
      }

      .placeholder{
        color: ${(props) => props.labelColor || "#7A809B"};
      }
      
      & input {
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none !important;
        transition: border-color 0.3s ease;

        padding: ${(props) => props.p || "12px 16px 12px 16px"};
        margin: 0;
        border: ${(props) => props.border || "1px solid #DEDEDF"};
        border-radius: 4px;
        box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
        color: ${(props) => props.color || "#000"};



        &.dirty,
        &:valid,
        &:focus {
            border-color: ${(props) => props.borderColorFocus || "#ccc"};
            transition-delay: 0.1s
        }

        &:focus + .placeholder{
            color: ${(props) => props.labelColorFocus || "#ccc"};
        }
      }
      
      & .placeholder {
        position: absolute;
        top: 3px;
        left: 12px;
        transform: translateY(-50%);
        transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
        padding: 0 5px;
        font-size: ${(props) => props.labelfontSize || "#fff"};12px;
        color: #ddd;
        background: ${(props) => props.labelBg || "#fff"};
      }
    
      

   



      & select {
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        transition: border-color 0.3s ease;

        padding: ${(props) => props.p || "12px 16px 12px 16px"};
        margin: 0;
        border: ${(props) => props.border || "1px solid #DEDEDF"};
        border-radius: 4px;
        box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
        color: ${(props) => props.color || "#000"};

    
        &.dirty,
        &:focus {
            border-color: ${(props) => props.borderColorFocus || "#ccc"};
            transition-delay: 0.1s
        }
        
        &:focus + .placeholder{
            color: ${(props) => props.labelColorFocus || "#ccc"};
        }
      }
`;

export const FieldsetInput1 = () => {
  return (
    <FieldsetInputBase>
      <input type="text" required />
      <span className="placeholder">Enter Text</span>
    </FieldsetInputBase>
  );
};

const ToggleStyle = styled.input`
  display: none;
  + .tgl-btn {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 2px;
    transition: all 0.4s ease;
    &:after {
      width: 22px;
      height: 22px;
      border: 1px solid #DEDFDF;
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
    }
  }

  &:checked + .tgl-btn {
    background: #00B46F;
  }

  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .tgl-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .tgl-btn {
    outline: 0;
    display: flex;
    align-items: center;
    width: 49px;
    height: 22px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
    }

    &:after {
      left: -1px;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .tgl-btn:after {
    left: 58%;
  }
}
`;

export const ToggleButton = ({ toggleId, checked, onChange, ...rest }) => {
  return (
    <>
      <ToggleStyle
        type="checkbox"
        id={toggleId}
        checked={checked}
        onChange={onChange}
        {...rest}
      ></ToggleStyle>
      <label className="tgl-btn" htmlFor={toggleId}></label>
    </>
  );
};
