import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Dashboard from "../components/pages/Dashboard/Dashboard";

const ProtectedRoute = ({ component: Component, token, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        user && token ? (
          <Dashboard {...props}>{Component}</Dashboard>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
