import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { Input } from "../../../../../UI toolkit/Input/Input";
import { Modal } from "../../../../../UI toolkit/Modal/Modal";
import { P } from "../../../../../UI toolkit/Text/Text";
import BVNVerification from "./BVNVerification";
import EmailAlert from "./EmailAlert";
import SmsVerification from "./SmsVerification";

const ApiKey = ({ match }) => {
  const [openModal, setopenModal] = useState(false);

  const [state, setstate] = useState({
    account_id: match.params.id,
    amount: null,
    currency: null,
    password: null,
  });

  const AddBalance = (e) => {
    setopenModal(true);
  };

  const onSubmit = (e) => {
    console.log(state);
    setopenModal(false);
  };

  const handleChange = (e) => {
    switch (e.target.type) {
      case "number":
        setstate({
          ...state,
          [e.target.name]: parseFloat(e.target.value),
        });
        break;
      case "checkbox":
        setstate({
          ...state,
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        setstate({
          ...state,
          [e.target.name]: e.target.value,
        });
    }
  };

  return (
    <div>
      <div>
        <SmsVerification />
        <EmailAlert />
        <BVNVerification />

        {openModal === true ? (
          <Modal>
            <div className="pt-4 flex justify-end">
              <div>
                <DefaultButton
                  bg={"#FF6A6A"}
                  color={"#fff"}
                  p={"2px 4px"}
                  onClick={() => {
                    setopenModal(false);
                  }}
                >
                  Cancel
                </DefaultButton>
              </div>
            </div>
            <div className="pt-4">
              <P fontSize="12px" color={"#7A809B"}>
                Password of Admin
              </P>
            </div>

            <div className="pt-4">
              <Input
                type="password"
                name="password"
                color={"#646A86"}
                fontWeight="500"
                border="none"
                onChange={handleChange}
                value={state.password}
              />
            </div>

            <div className="py-4">
              <DefaultButton
                bg={"#005674"}
                color={"#fff"}
                p={"8px 16px"}
                onClick={onSubmit}
              >
                Credit Balance
              </DefaultButton>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApiKey));
