import React from "react";

const DownPointer = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6L0 0L8 0L4 6Z" fill="#112E46" />
    </svg>
  );
};

export default DownPointer;
