import styled from "styled-components";

// primary buttons

export const DefaultButton = styled.button`
  background: ${(props) => props.bg || "#E0E1E2"};
  box-shadow: ${(props) => props.boxShadow || "0px 1px 3px #0000001A"};
  border: ${(props) => props.border || "1px solid #E0E1E2"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  opacity: ${(props) => props.opacity || "1"};
  color: ${(props) => props.color || "#00000099"};
  // padding: ${(props) =>
    props.small
      ? "7px 19px 7px 19px"
      : props.p
      ? props.p
      : "9px 16px 9px 16px"};
  padding: ${(props) => props.p || "9px 16px 9px 16px"};
  cursor: pointer;
  font-size: ${(props) => (props.small ? "14px" : "16px")};
  font-weight: bold;
  display: inline-block;
  width: ${(props) => props.width};
  outline: none !important;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

// outline button

export const OutlineButton = styled.button`
  background: ${(props) => props.bg || "#fff"};
  border: ${(props) => props.border || "1px solid #0039D6"};
  border-radius: 4px;
  opacity: ${(props) => props.opacity || "1"};
  padding: ${(props) => props.p || "9px 16px 9px 16px"};
  color: ${(props) => props.color || "#0039D6"};
  box-shadow: ${(props) => props.boxShadow || "0px 1px 3px #0000001A"};
  text-transform: capitalize;
  outline: none !important;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
`;

// status buttons

export const Badge = styled.div`
  font-size: 13px;
  font-weight: medium;
  text-align: center;
  color: ${(props) => props.color || "#333333"};
  opacity: ${(props) => props.opacity || "1"};
  background: ${(props) => props.bg || "#D1F7C4"};
  text-transform: capitalize;
  border-radius: 11px;
  outline: none;
  display: inline-block;
  padding: 1px 9px 2px 10px;
  text-align: center;
  font-weight: bold;
`;

//tabs

export const TabButton = styled.div`
  font-size: ${(props) => props.fontSize || "13px"};
  font-weight: ${(props) => props.fontSize || "medium"};
  background: ${(props) => props.bg || "#2843B4"};
  color: ${(props) => props.color || "#FFFFFF"};
  text-transform: capitalize;
  padding: ${(props) => props.p || "7px 37.5px 7px 37.5px"};
  box-shadow: ${(props) => props.boxShadow || "0px 1px 2px #3F3F444D"};
  border-radius: ${(props) => props.borderRadius || "3px"};
  opacity: ${(props) => props.opacity || "1"};
  outline: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;

  &.active {
    background: ${(props) => props.activeBg};
    color: ${(props) => props.activeColor};
    border: ${(props) => props.activeOutline || "none"};
    border-bottom: ${(props) => props.activeOutlineBottom} !important;
    box-shadow: ${(props) => props.boxShadow || "0px 1px 5px #3F3F4419"};
    border-radius: ${(props) => props.borderRadius || "4px 0px 0px 0px"};
    opacity: 1;
    outline: none;
  }
`;
