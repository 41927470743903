import React, { useEffect, useState, useRef, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { SavingsTableTab, TableTab } from "../../../Units/TabsAndLinkStyled";
import DashboardHeader from "../DashboardHeader";
import Fixed from "./Fixed/index";
import Flexible from "./Flexible/index";
import Overview from "./Overview/index";
import { updateSavingsActiveTab } from "../../../../store/Actions/SavingsActions";
import { connect } from "react-redux";

const Savings = ({ activeTab, updateActiveTab }) => {
  const location = useLocation();
  // const [activeType, handleActiveType] = useState(0);

  const currentTab = [];
  const links = [
    { text: "Overview", tab: "all" },
    { text: "Flexible", tab: "flexible" },
    { text: "Fixed", tab: "fixed" },
    // { text: "Activities" },
  ];

  const linkKeys = {
    all: 0,
    flexible: 1,
    fixed: 2,
  };

  const handleTabChange = (tab) => {
    updateActiveTab(links[tab].tab);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Savings</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <DashboardHeader>Savings</DashboardHeader>
      <div>
        <SavingsTableTab
          margin
          tabs={links}
          activeTab={linkKeys[activeTab]}
          handleTab={handleTabChange}
          p={"16px 0px"}
          boxShadow={"0px"}
          borderRadius={"0px"}
          activeBg={"#FAFBFD"}
          activeColor={"#005674"}
          color={"#646A86"}
          activeOutline={"none"}
          activeOutlineBottom={"2px solid #005674"}
          tailwindpad
          callback={(active) => {
            console.log("hello");
            console.log(active);
            // updateActiveTab(links[active].tab);
          }}
        />
      </div>
      {activeTab === "all" ? (
        <div className="">
          <Overview />
        </div>
      ) : activeTab === "flexible" ? (
        <div>
          <Flexible />
        </div>
      ) : activeTab === "fixed" ? (
        <div>
          <Fixed />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.savings.savingsActiveTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveTab: (tab) => dispatch(updateSavingsActiveTab(tab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Savings);
