import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Dropdown } from "../../../../UI toolkit/Dropdown/Dropdown";
import { FieldsetInputBase, Input } from "../../../../UI toolkit/Input/Input";
import { P } from "../../../../UI toolkit/Text/Text";
import { TableTab } from "../../../Units/TabsAndLinkStyled";
import caret_down from "../../../../assets/images/caret-down.svg";
import {
  getFeesAction,
  overviewAction,
  pricesAction,
} from "../../../../store/Actions/OverviewActions";
import { getCurrencyStrings } from "../../../../utils/specialFunctions";
import { sendCryptoAction,getOtpAction } from "../../../../store/Actions/TransactionActions";
import { customToast } from "../../../../utils/customToast";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import QRComponent from "./QRReader";
import { withTheme } from "styled-components";

const SendCrypto = ({
  crypto,
  currencies,
  prices,
  loading,
  error,
  sendCryptoAction,
  getOtpAction,
  history,
  theme,
  getFeesAction,
  fee,
  feesLoading,
}) => {
  const [activeTab1, handleActiveTab1] = useState(0);

  const links2 = [{ text: "Address" }];

  const [openModal, setopenModal] = useState(false);

  const [state, setstate] = useState({
    currency: "",
    amount: "",
    amount_currency: "",
    recipient_type: "address",
    recipient: "",
  });

  const handleChange = (e) => {
    switch (e.target.type) {
      case "number":
        setstate({
          ...state,
          [e.target.name]: parseFloat(e.target.value),
        });
        break;
      case "checkbox":
        setstate({
          ...state,
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        setstate({
          ...state,
          [e.target.name]: e.target.value,
        });
    }
  };

  const handleGetOtp = () => {
   
    getOtpAction()
    customToast.success("OTP sent")
  }

  const handleSubmit = (e) => {
    console.log(state);
    e.preventDefault();
    if (crypto) {
      sendCryptoAction(state, (response) => {
        history.push({
          pathname: "/dashboard/crypto-Successfully-sent",
          state: {
            details: {
              ...state,
              timestamp: Date.now(),
              priceNG: prices
                ? prices[state.currency].ngn * state.amount
                : null,
            },
          },
        });
      });
    } else {
      customToast.error(
        "you cannot perform this action due to not selecting the crypto type.. reload to see the popup for crypto type"
      );
    }
  };

  const conversionValue = (inputValue) => {
    return prices && state.currency
      ? prices[state.currency].usd
      : 0 * inputValue;
  };

  useEffect(() => {
    setstate({
      ...state,
      currency: crypto ? crypto : null,
      amount_currency: crypto ? crypto : null,
    });
  }, [crypto]);

  useEffect(() => {
    if (state.amount) {
      const payload = {
        currency: state.currency,
        amount_currency: state.currency,
        recipient_type: "address",
        amount: Number(state.amount),
      };
      getFeesAction(payload);
    }
  }, [state.currency, getFeesAction, state.amount]);

  const coinsWithFees = ["BTC", "DASH", "LTC", "BCH"];

  return (
    <div>
      <div className="p-3">
        <div
          className="mt-6"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TableTab
            margin
            tabs={links2}
            activeTab={activeTab1}
            handleTab={handleActiveTab1}
            p={"2px 10px 1px 11px"}
            bg={"transparent"}
            border={"1px solid #006F95"}
            borderRadius={"30px"}
            color={"#006F95"}
            activeOutline={"1px solid #112E46"}
            activeColor={"#FFFFFF"}
            activeBg={"#112E46"}
            callback={(active) => {
              // console.log(active)
            }}
          />
        </div>

        <div className="w-full mt-8">
          <FieldsetInputBase
            labelColor={"#7A809B"}
            p={"13px 16px 13px 16px"}
            border={"1px solid #DEDEDF"}
            color="#00BFFF"
            borderColorFocus={"#112E46"}
            labelColorFocus={"#112E46"}
            labelBg={"#fff"}
            labelfontSize={"12px"}
          >
            <Input
              value={state.recipient}
              type="text"
              name="recipient"
              color={"#646A86"}
              fontWeight="500"
              border="0px"
              boxShadow="0px"
              required
              onChange={handleChange}
            />

            <span className="placeholder">{crypto} address</span>
          </FieldsetInputBase>
        </div>

        <div className="w-full mt-2">
          <DefaultButton
            small
            bg={theme.colors.bg}
            color={"#FFF"}
            p={"12px 0px 11px 0px"}
            width={"100%"}
            onClick={() => {
              setopenModal(true);
            }}
          >
            Scan QR
          </DefaultButton>
        </div>

        {openModal === true ? (
          <Modal>
            <div className="pt-4 flex justify-end">
              <div>
                <DefaultButton
                  bg={"#FF6A6A"}
                  color={"#fff"}
                  p={"2px 4px"}
                  onClick={() => {
                    setopenModal(false);
                  }}
                >
                  Cancel
                </DefaultButton>
              </div>
            </div>

            <div>
              <QRComponent
                callback={(res) => {
                  if (res) {
                    console.log(res);
                    setopenModal(false);
                    setstate({ ...state, recipient: res });
                    customToast.success("successfully got your address");
                  }
                }}
              />
            </div>
          </Modal>
        ) : null}

        <div className="flex mt-8">
          {crypto && state.currency && state.amount_currency ? (
            <div className="w-3/12 pr-1.5">
              <FieldsetInputBase
                labelColor={"#7A809B"}
                p={"14.7px 16px 14.75px 16px"}
                border={"1px solid #DEDEDF"}
                color={"#112E46"}
                borderColorFocus={"#112E46"}
                labelColorFocus={"#112E46"}
                labelBg={"#fff"}
                labelfontSize={"12px"}
              >
                <Dropdown
                  noDefaultOption
                  items={
                    currencies
                      ? getCurrencyStrings(currencies).filter(
                          (curr) => curr.text === state.amount_currency
                        )
                      : null
                  }
                  bgImg={caret_down}
                  bgColor={"transparent"}
                  color={"#fff"}
                  p={"0px"}
                  callback={(e) => {
                    console.log(e.target.value, e.target.name);
                    setstate({
                      ...state,
                      currency: e.target.value,
                      amount_currency: e.target.value,
                    });
                  }}
                />
                <span className="placeholder">Currency</span>
              </FieldsetInputBase>
            </div>
          ) : (
            <div className="w-3/12 pr-1.5 flex items-center justify-center">
              <P fontSize="16px" color={"#BFC4D5"}>
                NO COIN
              </P>
            </div>
          )}

          <div className="w-9/12 pl-1.5">
            <FieldsetInputBase
              labelColor={"#7A809B"}
              p={"13px 16px 13px 16px"}
              border={"1px solid #DEDEDF"}
              color={"#112E46"}
              borderColorFocus={"#112E46"}
              labelColorFocus={"#112E46"}
              labelBg={"#fff"}
              labelfontSize={"12px"}
            >
              <Input
                value={state.amount}
                type="number"
                name="amount"
                color={"#646A86"}
                fontWeight="500"
                border="0px"
                boxShadow="0px"
                required
                onChange={handleChange}
              />
              <span className="placeholder">Amount</span>
            </FieldsetInputBase>

            <P fontSize="10px" color={"#BFC4D5"}>
              ≈ ₦ {state.amount ? conversionValue(state.amount) : 0}
            </P>
          </div>
        </div>
        <div className="w-full mt-8">
          <FieldsetInputBase
            labelColor={"#7A809B"}
            p={"13px 16px 13px 16px"}
            border={"1px solid #DEDEDF"}
            color="#00BFFF"
            borderColorFocus={"#112E46"}
            labelColorFocus={"#112E46"}
            labelBg={"#fff"}
            labelfontSize={"12px"}
          >
            <Input
              // value={state.recipient}
              type="text"
              name="otp"
              color={"#646A86"}
              fontWeight="500"
              border="0px"
              boxShadow="0px"
              required
              onChange={handleChange}
            />

            <span className="placeholder">OTP</span>
          </FieldsetInputBase>
        </div>
        <div className="mt-3">
          <DefaultButton
            small
            bg={theme.colors.bg}
            color={"#FFFFFF"}
            p={"12px 12px 12px 12px"}
            width={"30%"}
            border={"1px solid #00BFFF"}
            onClick={handleGetOtp}
            borderRadius={"14px"}
          >
            Get OTP
          </DefaultButton>
        </div>

        <div className="mt-12 mb-10 text-center">
          <P fontSize="12px" color={"#112E46"}>
            {state.currency && prices
              ? coinsWithFees.includes(state.currency) === false
                ? `Exchange Rate: ≈ 1 ${
                    state.currency ? state.currency : null
                  } / ${
                    prices && state.currency
                      ? prices[state.currency].usd.toLocaleString()
                      : 0
                  } USD`
                : state.amount
                ? feesLoading
                  ? "Loading..."
                  : `Fee: ≈ ${fee} ${state.currency} / ${
                      Number(prices[state.currency].usd) * Number(fee)
                    } USD`
                : "..."
              : `Fee: ≈ ${0} BTC / ${0} USD`}
          </P>
          <P fontSize="16px" color={"#112E46"} fontWeight="600">
            {state.amount && prices
              ? `Total: ${state.amount ? state.amount : null} / ${
                  prices && state.currency && state.amount
                    ? (state.amount * prices[state.currency].usd).toFixed(2)
                    : 0
                } USD`
              : `Total: ${0} BTC / ${0} USD`}
          </P>
        </div>

        <div className="px-5 mb-10">
          <DefaultButton
            small
            bg={theme.colors.bg}
            color={"#FFFFFF"}
            p={"12px 12px 12px 12px"}
            width={"100%"}
            border={"1px solid #00BFFF"}
            onClick={handleSubmit}
          >
            Send
          </DefaultButton>
        </div>

        <div className="text-center">
          <Link to="/dashboard/overview">
            <P fontSize="12px" color={"#006F95"}>
              Cancel
            </P>
          </Link>
        </div>
      </div>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
    loading: state.overview.loading,
    error: state.overview.error,
    feesLoading: state.overview.feesLoading,
    fee: state.overview.fee,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: () => dispatch(overviewAction()),
    pricesAction: () => dispatch(pricesAction()),
    getOtpAction:()=>dispatch(getOtpAction()),
    sendCryptoAction: (state, successCallback) =>
      dispatch(sendCryptoAction(state, successCallback)),
    getFeesAction: (payload) => dispatch(getFeesAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(SendCrypto)));
