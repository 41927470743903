import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "../../../Units/Card.styled";
import { SendRecieveStyle, SendRecieveCoinsModal } from "./Send-Recieve.Styled";
import { TableTab } from "../../../Units/TabsAndLinkStyled";
import SendCrypto from "./SendCrypto";
import ReceiveCrypto from "./ReceiveCrypto";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { P } from "../../../../UI toolkit/Text/Text";
import ic_back_white from "../../../../assets/images/ic-back-white.svg";
import { withTheme } from "styled-components";
import {
  overviewAction,
  pricesAction,
} from "../../../../store/Actions/OverviewActions";

const SendRecieveindex = ({
  deposits,
  withdrawals,
  prices,
  currencies,
  theme,
  overviewAction,
  pricesAction,
}) => {
  const location = useLocation();
  const [activeTab, handleActiveTab] = useState(
    location && location.state ? location.state.Tab : 0
  );

  const links1 = [{ text: "Send" }, { text: "Receieve" }];

  const [openModal, setopenModal] = useState(
    location && location.state && location.state.crypto ? false : true
  );

  const [state, setstate] = useState({
    currency:
      location && location.state && location.state.crypto
        ? location.state.crypto
        : null,
  });

  useEffect(() => {
    overviewAction();
    pricesAction();
  }, [overviewAction, pricesAction]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Send-Recieve</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        {openModal === true ? (
          <Modal
            cursor
            callback={() => {
              setopenModal(false);
            }}
          >
            <SendRecieveCoinsModal>
              <div className="mt-6">
                <P color={"#112E46"} fontSize={"16px"} fontWeight={600}>
                  Select crypto
                </P>
              </div>
              {currencies ? (
                currencies.map((curr, idx, arr) => {
                  if (curr.symbol !== "NGN") {
                    return (
                      <div
                        key={idx}
                        value={curr}
                        onClick={() => {
                          setopenModal(false);
                          setstate({ ...state, currency: curr.symbol });
                        }}
                        className="flex my-6 content-center items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="flex content-center items-center my-1">
                          <div className="mr-2">
                            <img
                              src={curr.image}
                              alt="icon"
                              width="24px"
                              height="24px"
                            />
                          </div>

                          <div className="ml-2">
                            <P color="#646A86" fontSize="13px">
                              {curr.name}
                            </P>
                          </div>
                          <div className="ml-2">
                            <P color={"#BCC2D5"} fontSize="13px">
                              (₦{" "}
                              {prices
                                ? prices[curr.symbol].ngn.toLocaleString()
                                : null}
                              )
                            </P>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return null;
                })
              ) : (
                <div className="mt-6">
                  <P color={"#005674"} fontSize={"16px"} fontWeight={600}>
                    Loading.....
                  </P>
                </div>
              )}
            </SendRecieveCoinsModal>
          </Modal>
        ) : null}

        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <SendRecieveStyle width="70%" cryptoBannerBg="#112E46">
            <div className="typeOfCryptoBanner text-center py-4 flex">
              <div className="w-1/12 flex items-center content-center justify-end">
                <Link to="/dashboard/overview" className="">
                  <img src={ic_back_white} alt="back" />
                </Link>
              </div>

              <div className="w-10/12 flex items-center content-center justify-center">
                <P color="#FFF" fontSize="13px">
                  {activeTab === 0 ? "Send" : "Recieve"}{" "}
                  {state.currency ? state.currency : "Coin"}
                </P>
              </div>
            </div>

            <Card p={"0"}>
              <TableTab
                width={"50%"}
                tabs={links1}
                activeTab={activeTab}
                handleTab={handleActiveTab}
                p={"16px 65px 13px 57px"}
                // bg={"#7CC5DE"}
                // color={"#B4CFD9"}
                // activeBg={"transparent"}
                // color={"#FFF"}
                // activeBg={"#0087B5"}
                // activeColor="#00BFFF"
                bg={theme.colors.bg}
                color="#FFF"
                activeColor={"#112E46"}
                activeBg={"transparent"}
                activeOutline="1px solid #fff"
                callback={(active) => {
                  console.log(active);
                }}
              />

              <div className="p-3">
                {activeTab === 0 ? (
                  <div className="">
                    <SendCrypto crypto={state.currency} />
                  </div>
                ) : activeTab === 1 ? (
                  <div className="">
                    <ReceiveCrypto crypto={state.currency} />
                  </div>
                ) : null}
              </div>
            </Card>
          </SendRecieveStyle>
        </div>
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    deposits: state.overview.deposits,
    withdrawals: state.overview.withdrawals,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: () => dispatch(overviewAction()),
    pricesAction: () => dispatch(pricesAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SendRecieveindex));
