import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { SideBarStyle } from "./Dashboard.styled";
import flitbase_logo from "../../../assets/images/flitbase-alt.svg";
import { Logout } from "../../../store/Actions/AuthActions";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import config from "../../../config";
import { useDispatch } from "react-redux";
import ic_nav from "../../../assets/images/ic-nav.svg";
import caret_down from "../../../assets/images/caret-down.svg";

const Sidebar = ({ Logout, logo, theme, mobileNav }) => {
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch({ type: "TOGGLE_MOBILE_NAV", mobileNav: !mobileNav });
  };
  return (
    <SideBarStyle mobileWidth={mobileNav}>
      <div className="lg:hidden p-5">
        <img
          src={caret_down}
          style={{ transform: "rotateY(-180deg)" }}
          className="bg-white w-100 h-auto"
          alt="nav"
          onClick={handleToggle}
        />
      </div>
      <div className="skye_logo">
        <img
          src={theme.colors.logo}
          alt={`${config.appName} logo`}
          className=""
        />
      </div>

      <ul className="menu">
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/overview">Dashboard</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/wallet">Wallets</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/transactions">Transactions</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/activities/trades">Activities</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/cards">Cards</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/announcements">Announcements</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/support">Support</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/savings">Savings</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/verifications">Verifications</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/users">Users</NavLink>{" "}
        </li>
        <li className="mb-2" onClick={handleToggle}>
          <NavLink to="/dashboard/settings">Settings</NavLink>{" "}
        </li>
        <li className="mb-2 logout" onClick={Logout}>
          Logout
        </li>
      </ul>

      <div className="skye_logo">
        <img src={flitbase_logo} alt="skye_logo" className="h-auto w-auto" />
      </div>
    </SideBarStyle>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    logo: state.UI.logo,
    mobileNav: state.UI.mobileNav,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    Logout: () => dispatch(Logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(Sidebar)));
