import React from "react";
import { P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";

const PriceAPISourceList = ({ images }) => {
  return (
    <div className="flex h-100">
      {images &&
        images.map((curr, idx, arr) => {
          return (
            <div className="w-3/12 mr-6" key={idx}>
              <Card bg={"#fff"} className="py-4 px-3">
                <div>
                  <img
                    src={curr}
                    alt="img"
                    style={{ width: "150px", height: "30px" }}
                  />
                </div>
                <div className="mt-2">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight="500">
                    binance.com
                  </P>
                </div>
              </Card>
            </div>
          );
        })}
    </div>
  );
};

export default PriceAPISourceList;
