import React, { lazy, useEffect } from "react";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
import { Card } from "../../../../Units/Card.styled";
import { CardHolder, CardTitle } from "../../../../Units/CardTitle.styled";
//import { ActivitiesTable } from "./ActivitiesTable";
import ic_activities from "../../../../../assets/images/ic-activities-db.svg";
import { groupActivitiesByDaysOrMonth } from "../../../../../utils/specialFunctions";
import { Link, withRouter } from "react-router-dom";
import { ActivitiesTableCardStyle } from "./Activities.styled";
import { connect } from "react-redux";
import {
  getSavings,
  updateSavingsActiveTab,
} from "../../../../../store/Actions/SavingsActions";
import { getAnnouncementAction } from "../../../../../store/Actions/Messages_AnnouncementsAction";
import ActivitiesTable from "./ActivitiesTable";

const ActivitiesCardTable = ({
  activities,
  getSavings,
  updateActiveTab,
  // getAnnouncementAction,
}) => {
  useEffect(() => {
    getSavings();
  }, [getSavings]);

  return (
    <ActivitiesTableCardStyle className="pt-6">
      <Card>
        <CardHolder>
          <div className="flex">
            <div className="w-12/12">
              <CardTitle img={ic_activities} text={"Activities"} />
            </div>
          </div>
        </CardHolder>

        <div className="lg:px-4">
          <ActivitiesTable
            activities={groupActivitiesByDaysOrMonth(
              activities ? activities : null,
              "days"
            ).slice(0, 5)}
          />
          {activities && activities.length !== 0 ? (
            <div className="text-center pt-5 flex justify-center">
              <button
                className="viewAll"
                onClick={(e) => {
                  e.preventDefault();
                  updateActiveTab("flexible");
                }}
              >
                See more
              </button>
            </div>
          ) : null}
        </div>
      </Card>
    </ActivitiesTableCardStyle>
  );
};

const mapStateToProps = (state) => {
  return {
    activities: state.savings.savings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSavings: (query) => dispatch(getSavings(query)),
    updateActiveTab: (tab) => dispatch(updateSavingsActiveTab(tab)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesCardTable);
