import React, { useState } from "react";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Dropdown } from "../../../../UI toolkit/Dropdown/Dropdown";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import caret_down from "../../../../assets/images/caret-down.svg";
import { Input } from "../../../../UI toolkit/Input/Input";
import { connect } from "react-redux";
import { creditUser, debitUser } from "../../../../store/Actions/UsersAction";
import { withRouter } from "react-router-dom";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { getCurrencyStrings } from "../../../../utils/specialFunctions";
import times_solid from "../../../../assets/images/times-solid.svg";

const DeleteBalance = ({ user, singleUser, match, debitUser, currencies }) => {
  const [openModal, setopenModal] = useState(false);
  const [state, setstate] = useState({
    account_id: match.params.id,
    amount: null,
    currency: null,
    password: null,
  });

  const DeleteBalance = (e) => {
    setopenModal(true);
  };

  const handleChange = (e) => {
    switch (e.target.type) {
      case "number":
        setstate({
          ...state,
          [e.target.name]: parseFloat(e.target.value),
        });
        break;
      case "checkbox":
        setstate({
          ...state,
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        setstate({
          ...state,
          [e.target.name]: e.target.value,
        });
    }
  };

  const onSubmit = (e) => {
    setopenModal(false);
    debitUser(match.params.id, state);
  };

  return (
    <div>
      <div className="flex pt-6">
        <div className="w-7/12">
          <Card>
            <div>
              <P fontSize="12px" color={"#7A809B"}>
                Coin/Currency
              </P>
              <Dropdown
                items={currencies ? getCurrencyStrings(currencies) : null}
                bgImg={caret_down}
                bgColor={"transparent"}
                color={"#374072"}
                p={"5px 0px"}
                boxShadow={"none"}
                border={"none"}
                callback={(e) => {
                  console.log(e.target.value);
                  setstate({ ...state, currency: e.target.value });
                  console.log(state.currency);
                }}
              />
            </div>
          </Card>
        </div>

        <div className="flex w-3/12 px-6 items-center">
          <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
            Available balance:{" "}
            {singleUser && state.currency
              ? singleUser.wallets.wallets[state.currency].available_balance
              : 0}
          </SubHeader>
        </div>
      </div>

      {user.role === "super-admin" ? (
        <>
          <div className="pt-6">
            <div className="w-7/12">
              <Card>
                <div>
                  <P fontSize="12px" color={"#7A809B"}>
                    Amount
                  </P>
                  <Input
                    type="number"
                    color={"#646A86"}
                    name="amount"
                    fontWeight="500"
                    border="none"
                    onChange={handleChange}
                    value={state.amount}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className="py-6">
            <DefaultButton
              bg={"#FF6A6A"}
              color={"#fff"}
              p={"8px 16px"}
              onClick={DeleteBalance}
            >
              Remove balance
            </DefaultButton>
          </div>
        </>
      ) : null}

      {openModal === true ? (
        <Modal>
          <div className="pt-4 flex justify-end">
            <div className="flex">
              <div>
                <img
                  src={times_solid}
                  alt="cancel"
                  style={{ height: "15px", width: "15px", cursor: "pointer" }}
                  onClick={() => {
                    setopenModal(false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-4">
            <P fontSize="12px" color={"#7A809B"}>
              Password of Admin
            </P>
          </div>

          <div className="pt-4">
            <Input
              type="password"
              name="password"
              color={"#646A86"}
              fontWeight="500"
              border="none"
              onChange={handleChange}
              value={state.password}
            />
          </div>

          <div className="py-4">
            <DefaultButton
              bg={"#FF6A6A"}
              color={"#fff"}
              p={"8px 16px"}
              onClick={onSubmit}
            >
              Debit Balance
            </DefaultButton>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currencies: state.overview.currencies,
    prices: state.overview.prices,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    debitUser: (id, state) => dispatch(debitUser(id, state)),
    creditUser: (id, state) => dispatch(creditUser(id, state)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeleteBalance));
