import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../DashboardHeader";
import CardTransactions from "./CardTransactions";
import VirtualCardDashBoard from "./VirtualCardDashboard";

const VirtualCards = () => {
  const location = useLocation();
  const [activeTab, handleActiveTab] = useState(0);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cards</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <DashboardHeader>Cards</DashboardHeader>
      <div>
        {activeTab === 0 ? (
          <div className="">
            <VirtualCardDashBoard handleActiveTab={handleActiveTab} />
          </div>
        ) : activeTab === 1 ? (
          <div>
            <CardTransactions handleActiveTab={handleActiveTab} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default VirtualCards;
