import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  removeMerchant,
  toggleMerchantVisibility,
} from "../../../../store/Actions/MerchantsAction";
import { getUser } from "../../../../store/Actions/UsersAction";
import { Badge } from "../../../../UI toolkit/Button/Button";
import { ToggleButton } from "../../../../UI toolkit/Input/Input";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import {
  capitalizeFirstLetter,
  compareBy,
  getLevel,
} from "../../../../utils/specialFunctions";
import { Card } from "../../../Units/Card.styled";
import { AddMerchantModal, UpdateMerchantModal } from "./AddMerchantModal";

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    singleUser: state.users.singleUser,
    loading: state.merchants.loading,
    error: state.merchants.error,
  };
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getUser(id)),
    toggleMerchantVisibility: (state, merchantId, successCallback) =>
      dispatch(toggleMerchantVisibility(state, merchantId, successCallback)),
    removeMerchant: (merchantId) => dispatch(removeMerchant(merchantId)),
  };
};

export const MerchantsListTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    user,
    users,
    toggleMerchantVisibility,
    removeMerchant,
    getUser,
    singleUser,
  }) => {
    const [usersData, setusersData] = useState(null);
    const [tableKey, settableKey] = useState(null);
    const [tableKeyToggle, settableKeyToggle] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [updateMerchantModal, setupdateMerchantModal] = useState(false);
    const [userToMerchant, setuserToMerchant] = useState(null);

    const sortByJoinTime = (tableKey) => {
      settableKey(tableKey);
      settableKeyToggle(!tableKeyToggle);
    };

    const [state, setstate] = useState({
      is_online: null,
      userId: null,
    });

    useEffect(() => {
      setusersData(users);
      console.log(users)
    }, [users]);

    useEffect(() => {
      if (state && state.is_online !== null && state.userId) {
        toggleMerchantVisibility(state, state.userId, (response) => {});
      }
    }, [state, toggleMerchantVisibility]);

    return (
      <div style={{ overflowX: "auto" }}>
        <Card>
          <table className="w-full">
            <thead style={{ background: "#E7EAF4" }}>
              <tr className="Table-heading -px-1.5">
                <td
                  width="15%"
                  className="pt-4 pb-4 px-4"
                  onClick={() => {
                    sortByJoinTime("account");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      USERNAME
                    </SubHeader>
                  </div>
                </td>
                <td
                  width="20%"
                  className="pt-4 pb-4 px-4"
                  onClick={() => {
                    sortByJoinTime("account");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      EMAIL
                    </SubHeader>
                  </div>
                </td>
                <td
                  width="25%"
                  className="pt-4 pb-4 px-4"
                  onClick={() => {
                    sortByJoinTime("min_amount");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="">
                    <SubHeader
                      fontSize="12px"
                      color={"#7C8191"}
                      fontWeight="500"
                    >
                      MIN
                    </SubHeader>
                  </div>
                </td>
                <td
                  width="20%"
                  className="pt-4 pb-4 px-4"
                  onClick={() => {
                    sortByJoinTime("max_amount");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    MAX
                  </SubHeader>
                </td>

                <td
                  width="20%"
                  className="pt-4 pb-4 px-4"
                  onClick={() => {
                    sortByJoinTime("is_online");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    ONLINE
                  </SubHeader>
                </td>
              </tr>
            </thead>
            <tbody>
              {usersData &&
                usersData
                  .sort(compareBy(tableKey, tableKeyToggle))
                  .map((curr, idx, arr) => {
                    return (
                      <tr
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #EFF0F5",
                        }}
                        key={idx}
                      >
                        <td width="15%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {capitalizeFirstLetter(curr.username)}
                          </P>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr.account.email}
                          </P>
                        </td>

                        <td width="25%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#005674"} fontWeight="500">
                            {curr.min_amount}
                          </P>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <P fontSize="13px" color={"#005674"} fontWeight="500">
                            {curr.max_amount}
                          </P>
                        </td>

                        <td width="20%" className="px-4 pt-4 pb-4">
                          <div className="flex justify-between">
                            <div className="pr-6">
                              <ToggleButton
                                toggleId={idx + "ca"}
                                onChange={(e) => {
                                  console.log(e.target.checked);
                                  setstate({
                                    ...state,
                                    userId: curr.id,
                                    is_online: e.target.checked,
                                  });
                                }}
                                checked={curr.is_online}
                                disabled={
                                  user.role === "super-admin" ? false : true
                                }
                              />
                            </div>
                            {
                              <div>
                                <P
                                  fontSize="13px"
                                  color={"#0087B5"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setuserToMerchant(curr);
                                    setupdateMerchantModal(true);
                                    getUser(curr.account_id);
                                  }}
                                >
                                  Edit
                                </P>
                              </div>
                            }

                            {user.role === "super-admin" ? (
                              <div>
                                <P
                                  fontSize="13px"
                                  color={"#D60000"}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    // setuserToMerchant(curr);
                                    // setopenModal(true);
                                    removeMerchant(curr.id);
                                  }}
                                >
                                  Remove
                                </P>
                              </div>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {openModal ? (
            <AddMerchantModal
              width={"30%"}
              border={"none"}
              closeModalCallback={() => {
                setopenModal(false);
              }}
              merchant={userToMerchant}
            />
          ) : null}

          {updateMerchantModal ? (
            <UpdateMerchantModal
              width={"30%"}
              border={"none"}
              closeModalCallback={() => {
                setupdateMerchantModal(false);
              }}
              merchant={userToMerchant}
            />
          ) : null}
        </Card>
      </div>
    );
  }
);

export const AddUserToMerchantTable = ({ users }) => {
  const [usersData, setusersData] = useState(null);
  const [tableKey, settableKey] = useState(null);
  const [tableKeyToggle, settableKeyToggle] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [userToMerchant, setuserToMerchant] = useState(null);

  const sortByJoinTime = (tableKey) => {
    settableKey(tableKey);
    settableKeyToggle(!tableKeyToggle);
  };

  useEffect(() => {
    setusersData(users);
  }, [users]);

  return (
    <div style={{ overflowX: "auto" }}>
      <Card>
        <table
          className="w-full"
          onClick={() => {
            sortByJoinTime("email");
          }}
        >
          <tbody>
            {usersData &&
              usersData
                .sort(compareBy(tableKey, tableKeyToggle))
                .map((user, idx, arr) => {
                  return (
                    <tr
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EFF0F5",
                      }}
                      key={user.id}
                    >
                      <td width="15%" className="px-4 pt-4 pb-4">
                        <Badge
                          color={
                            getLevel(user) === 0
                              ? "#337138"
                              : getLevel(user) === 1
                              ? "#606060"
                              : getLevel(user) === 2
                              ? "#335F71"
                              : getLevel(user) === 3
                              ? "#531423"
                              : ""
                          }
                          bg={
                            getLevel(user) === 0
                              ? "#FFDCE5"
                              : getLevel(user) === 1
                              ? "#FFEAB6"
                              : getLevel(user) === 2
                              ? "#D0F0FD"
                              : getLevel(user) === 3
                              ? "#D1F7C4"
                              : "#337138"
                          }
                        >
                          {getLevel(user)}
                        </Badge>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#374072"} fontWeight="500">
                          {user.first_name}
                        </P>
                      </td>

                      <td width="25%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#005674"} fontWeight="500">
                          {user.email}
                        </P>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <P fontSize="13px" color={"#005674"} fontWeight="500">
                          {new Date(user.created_at).toLocaleString()}
                        </P>
                      </td>

                      <td width="20%" className="px-4 pt-4 pb-4">
                        <div className="flex justify-end">
                          <div className="pr-6">
                            <P
                              fontSize="13px"
                              color={"#0087B5"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setuserToMerchant(user);
                                setopenModal(true);
                                console.log(user);
                              }}
                            >
                              Select
                            </P>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {openModal ? (
          <AddMerchantModal
            width={"30%"}
            border={"none"}
            closeModalCallback={() => {
              setopenModal(false);
            }}
            merchant={userToMerchant}
          />
        ) : null}
      </Card>
    </div>
  );
};
