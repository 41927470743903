import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { Input } from "../../../../../UI toolkit/Input/Input";
import { P } from "../../../../../UI toolkit/Text/Text";
import { Card } from "../../../../Units/Card.styled";
import { CardHolder } from "../../../../Units/CardTitle.styled";
import {
  adminSettingsAction,
  changeAdminSettingsAction,
} from "../../../../../store/Actions/SettingsActions";
import { customToast } from "../../../../../utils/customToast";

const EmailAlert = ({ match, changeAdminSettingsAction, admin_settings }) => {
  const [state, setstate] = useState({
    key: null,
    value: null,
  });

  const handleChange = (e) => {
    switch (e.target.type) {
      case "number":
        setstate({
          ...state,
          [e.target.name]: parseFloat(e.target.value),
        });
        break;
      case "checkbox":
        setstate({
          ...state,
          [e.target.name]: e.target.checked,
        });
        break;
      default:
        setstate({
          ...state,
          [e.target.name]: e.target.value,
        });
    }
  };

  useEffect(() => {
    setstate({
      ...state,
      key: "SENDGRID_API_KEY",
      value: admin_settings.SENDGRID_API_KEY,
    });
  }, []);

  const handleSubmit = () => {
    if (state.key !== null && state.value !== null) {
      changeAdminSettingsAction(state, (response) => {});
    } else {
      customToast.error(
        "unable to implement action...provide a value for the input provided"
      );
      return;
    }
  };

  return (
    <div className="pt-6">
      <Card>
        <CardHolder>
          <P fontSize="14px" color={"#FFFFFF"} fontWeight="700">
            Email Alert External API [Sendgrid]
          </P>
        </CardHolder>
        <div className="pt-6">
          <div className="w-7/12">
            <Card>
              <div>
                <P fontSize="12px" color={"#7A809B"}>
                  API Key
                </P>
                <Input
                  type="text"
                  name="value"
                  color={"#646A86"}
                  fontWeight="500"
                  border="none"
                  boxShadow="none"
                  onChange={handleChange}
                  value={state.value}
                />
              </div>
            </Card>
          </div>

          <div className="py-6">
            <DefaultButton
              bg={"#E5EEF1"}
              color={"#005674"}
              p={"7px 19px"}
              onClick={handleSubmit}
            >
              Update EMAIL key
            </DefaultButton>
          </div>
        </div>
      </Card>
    </div>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    admin_settings: state.settings.admin_settings,
    loading: state.settings.loading,
    error: state.settings.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    changeAdminSettingsAction: (state, successCallback) =>
      dispatch(changeAdminSettingsAction(state, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailAlert));
