import axios from "axios";
import {
  GET_MERCHANTS,
  LOADING_MERCHANTS,
  ERROR_MERCHANTS,
} from "../Types/Types";

import { customToast } from "../../utils/customToast";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Logout } from "./AuthActions";
import Api from "./api";

export const getMerchants = () => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANTS });
    dispatch(showLoading());
    try {
      const response = await Api.getMerchants();
      dispatch({ type: GET_MERCHANTS, merchantsList: response.data });
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_MERCHANTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const createMerchant = (state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANTS });
    dispatch(showLoading());
    try {
      const response = await Api.createMerchant(state);
      console.log(response);
      customToast.success(`merchant was created`);
      dispatch(getMerchants());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_MERCHANTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const updateMerchant = (merchantId, state, successCallback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANTS });
    dispatch(showLoading());
    try {
      const response = await Api.updateMerchant(state, merchantId);
      customToast.success(`merchant was updated`);
      dispatch(getMerchants());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_MERCHANTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const removeMerchant = (merchantId) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANTS });
    dispatch(showLoading());
    try {
      const response = await Api.removeMerchant(merchantId);
      console.log(response);
      customToast.success(`merchant was deleted`);
      dispatch(getMerchants());
      // dispatch(hideLoading());
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_MERCHANTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const toggleMerchantVisibility = (
  state,
  merchantId,
  successCallback
) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_MERCHANTS });
    dispatch(showLoading());
    try {
      const response = await Api.toggleMerchantVisibility(merchantId, state);
      console.log(response);
      customToast.success(`merchant visibility updated`);
      dispatch(getMerchants());
      dispatch(hideLoading());
      if (response) {
        if (successCallback && typeof successCallback === "function") {
          successCallback(response);
        }
      }
    } catch (error) {
      if (error.response) return customToast.error(error.response.data);
      if (error.request) return console.log(error.request);
      dispatch({ type: ERROR_MERCHANTS });
      return console.log(error.message);
    } finally {
      dispatch(hideLoading());
    }
  };
};
