import styled from "styled-components";

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 64px 0;

  @media (max-width: 376px) {
    > .holder {
      padding-top: 12rem;
    }
  }
`;

export const ModalBackgroundCancel = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -10;
  background: transparent;
  overflow: hidden;
  cursor: ${(props) => (props.cursor ? "pointer" : "pointer")};
`;

export const ModalBase = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#FDFDFD")};
  border-radius: 4px;
  border: ${(props) => (props.border ? props.border : "1px solid #E7EAF4")};
  box-shadow: 0px 1px 5px #3f3f4419;
  opacity: 1;
  padding: ${(props) => (props.p ? props.p : "0px 32px 0px 32px")};
  width: ${(props) => props.width || "50%"};

  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 992px) {
    width: 50%;
  }
`;

export const Modal = ({ children, width, bg, border, callback, cursor }) => {
  return (
    <ModalBackground>
      <ModalBackgroundCancel
        cursor={cursor}
        onClick={() => {
          callback && callback();
        }}
      ></ModalBackgroundCancel>
      <ModalBase bg={bg} width={width} border={border}>
        {children}
      </ModalBase>
    </ModalBackground>
  );
};

// cancel modal
export const ModalCancelBase = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#FDFDFD")};
  border-radius: 4px;
  position: relative;
  min-width: 332px;
  max-width: 994px;
  border: 1px solid #e7eaf4;
  box-shadow: 0px 1px 5px #3f3f4419;
  opacity: 1;
  padding: ${(props) => (props.p ? props.p : "0px 32px 0px 32px")};

  @media (max-width: 500px) {
    max-width: 80vw;
  }
`;

export const ModalTitle = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#FFF")};
  position: relative;
  border: 1px solid #e7eaf4;
  box-shadow: 0px 1px 5px #3f3f4419;
  opacity: 1;
  padding: ${(props) => (props.p ? props.p : "0px 32px 0px 32px")};
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    max-width: 80vw;
  }
`;

export const ModalCancel = ({ children, width, bg }) => {
  return (
    <ModalBackground>
      <ModalCancelBase bg={bg} width={width}>
        <ModalTitle>
          <p></p>
          <img src="" alt="texticon" />
        </ModalTitle>
        {children}
      </ModalCancelBase>
    </ModalBackground>
  );
};
