import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Select } from "../../../../../UI toolkit/Dropdown/Dropdown";
import { ArrowLeftIcon } from "../../../../../UI toolkit/icons";
import { groupActivitiesByDaysOrMonth } from "../../../../../utils/specialFunctions";
import { Card } from "../../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../../Units/TabsAndLinkStyled";
import CardTransactionsTable from "./CardTransactionsTable";
import caret_down from "../../../../../assets/images/caret-down1.svg";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import {
  getCardActivities,
  updateCardActivitiesActiveStatus,
  updateCardActivitiesActiveTab,
  updateCardActivitiesNavigator,
  updateCardActivitiesPage,
} from "../../../../../store/Actions/CardsAction";
import { range } from "lodash";
import { DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P } from "../../../../../UI toolkit/Text/Text";

const CardTransactions = ({
  theme,
  handleActiveTab,
  activeTab,
  updateActiveTab,
  activities,
  page,
  totalPages,
  activeStatus,
  getActivities,
  loading,
  updatePage,
  updateActiveStatus,
}) => {
  const location = useLocation();
  const currentStatus = activeStatus[activeTab];
  const currentPage = page[activeTab][currentStatus];
  const totalPage = totalPages[activeTab][currentStatus];
  const tabs = [
    { text: "All", value: "all" },
    { text: "Funding", value: "fund" },
    { text: "Withdrawals", value: "withdrawal" },
    { text: "Created", value: "create" },
    { text: "Freeze", value: "inactive" },
    { text: "Unfreeze", value: "active" },
  ];

  const statusTabs = [
    { text: "All", value: "all" },
    { text: "Pending", value: "pending" },
    { text: "Failed", value: "failed" },
    { text: "Completed", value: "completed" },
  ];

  // const tabs = [
  //   { text: "All", value: "all" },
  //   //active completed stopped
  //   { text: "Active", value: "active" },
  //   { text: "Completed", value: "completed" },
  //   { text: "Broken", value: "stopped" },
  // ];

  const tabKeys = {
    all: 0,
    fund: 1,
    withdrawal: 2,
    create: 3,
    inactive: 4,
    active: 5,
  };

  const handleStatusChange = (status) => {
    console.log("status", status);
    updateActiveStatus({
      tab: activeTab,
      value: status,
    });
  };

  const handleTabChange = (type) => {
    updateActiveTab(type);
  };

  const handleNextPage = () => {
    console.log(currentPage, totalPage);
    if (currentPage < totalPage - 1) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage + 1,
      });
    }
  };

  const prevButtonDisabled = () => {
    return !(currentPage > 0);
  };

  const nextButtonDisabled = () => {
    return !(currentPage < totalPage - 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      updatePage({
        tab: activeTab,
        status: currentStatus,
        value: currentPage - 1,
      });
    }
  };

  useEffect(() => {
    getActivities();
  }, [getActivities, activeTab, page, activeStatus]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Card Transactions</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => handleActiveTab(0)}
      >
        <ArrowLeftIcon />
        <h3 className="text-primary underline ml-2">Back</h3>
      </div>
      <div className="pt-6">
        <Card>
          <TabHeader>
            <div className="lg:flex justify-between">
              <div className="lg:w-8/12">
                <TableTab
                  margin
                  tabs={tabs}
                  activeTab={activeTab}
                  handleDispatch={handleTabChange}
                  callback={(active) => {
                    console.log(active);
                  }}
                />
              </div>
              <div className="lg:w-4/12 flex justify-end my-3 mx-3 lg:my-1.5 lg:mx-1.5 py-5 lg:py-0">
                <div className="w-6/12">
                  <Select
                    bgImg={caret_down}
                    // bgColor={"#112E46"}
                    // color={"#fff"}
                    bgColor={theme.colors.bg}
                    color={theme.colors.color}
                    border={"1px solid #fff"}
                    borderRadius={"4px"}
                    p={"5px 15px"}
                    data={statusTabs}
                    defaultValue={"-- Status --"}
                    properyToShow={"text"}
                    hoverBg={"#FFF"}
                    hoverColor={"#112E46"}
                    fontSize={"16px"}
                    callback={(option, idx, arr) => {
                      handleStatusChange(option.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </TabHeader>
          <div className="px-0">
            <CardTransactionsTable
              links1={groupActivitiesByDaysOrMonth(
                activities ? activities : null,
                "days"
              )}
            />
          </div>
          {!loading && (
            <div className="flex justify-between gap-2 items-center py-4 w-full">
              <p className="">
                {/* Page no */}
                {/* {pagination.per_page}  */}
              </p>
              <div className="flex items-center">
                <DefaultButton
                  bg={theme.colors.bg}
                  color={theme.colors.color}
                  p={"8px 16px"}
                  onClick={handlePrevPage}
                  disabled={prevButtonDisabled()}
                >
                  Prev
                </DefaultButton>
                <div className="px-4">
                  <P color={"#646A86"} fontSize="16px" fontWeight="700">
                    Page {currentPage + 1} of {totalPage !== 0 ? totalPage : 1}
                  </P>
                </div>
                <DefaultButton
                  bg={theme.colors.bg}
                  color={theme.colors.color}
                  p={"8px 16px"}
                  onClick={handleNextPage}
                  disabled={nextButtonDisabled()}
                >
                  Next
                </DefaultButton>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.cards.loading,
    error: state.cards.error,
    activities: state.cards.cardActivities,
    activeTab: state.cards.cardActivitiesActiveTab,
    activeStatus: state.cards.cardActivitiesActiveStatus,
    page: state.cards.cardActivitiesPage,
    totalPages: state.cards.cardActivitiesTotalPages,
    navigator: state.cards.cardActivitiesFilters,
    filters: state.cards.cardActivitiesFilters,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getActivities: () => dispatch(getCardActivities()),
    updateActiveTab: (tab) => dispatch(updateCardActivitiesActiveTab(tab)),
    updateActiveStatus: (status) =>
      dispatch(updateCardActivitiesActiveStatus(status)),
    updatePage: (page) => dispatch(updateCardActivitiesPage(page)),
    updateNavigator: (navigator) =>
      dispatch(updateCardActivitiesNavigator(navigator)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(CardTransactions)));
