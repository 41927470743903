import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Dropdown, Select } from "../../../../UI toolkit/Dropdown/Dropdown";
import { H5, P } from "../../../../UI toolkit/Text/Text";
import caret_down from "../../../../assets/images/caret-down.svg";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withRouter, Redirect, useLocation } from "react-router-dom";
import { Input } from "../../../../UI toolkit/Input/Input";
import times_solid from "../../../../assets/images/times-solid.svg";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { PreviewModal } from "./PreviewModal";

const NewMessage = ({ user, token, theme, history }) => {
  const location = useLocation();
  const someRef = useRef();

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [Images, setImages] = useState([]);

  const content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "Initialized from content state.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  };

  const contentState = convertFromRaw(content);
  const editorState = EditorState.createEmpty();

  const [state, setstate] = useState({
    ...contentState,
    ...editorState,
  });

  const onContentStateChange = (contentState) => {
    console.log(draftToHtml(contentState));
    setstate({ ...state, contentState: draftToHtml(contentState) });
  };

  const onEditorStateChange = (editorState) => {
    console.log(editorState);
    setstate({ ...state, editorState });
  };

  const uploadCallback = (file) => {
    let uploadedImages = Images;
    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    uploadedImages.push(imageObject);
    setImages({ uploadedImages: uploadedImages });
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  const config = {
    image: { uploadCallback },
  };

  if (user && token) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>New Message</title>
          <link rel="canonical" href={location.pathname} />
        </Helmet>
        <div className="px-6 py-6 md:px-12 md:py-12">
          <div className="md:flex md:justify-between px-0.5">
            <H5 color={"#112E46"} fontWeight="700" className="pr-4">
              New message
            </H5>
            <div className="mt-6 md:mt-0 flex justify-between">
              <div className="mr-3">
                <DefaultButton
                  small
                  p={"7px 16px"}
                  color={"#005674"}
                  bg={"#E4EDF0"}
                  placeholder="Set Fee"
                  type="number"
                  name="setfee"
                  value={2}
                  required
                >
                  Save draft
                </DefaultButton>
              </div>

              <div className="mx-3">
                <DefaultButton
                  small
                  p={"7px 16px"}
                  color={"#FFFFFF"}
                  bg={"#005674"}
                  placeholder="Set Fee"
                  type="number"
                  name="setfee"
                  value={2}
                  required
                  onClick={() => {
                    setOpenPreviewModal(true);
                  }}
                >
                  Preview & send
                </DefaultButton>
              </div>
              <div className="flex items-center">
                <div>
                  <img
                    src={times_solid}
                    alt="cancel"
                    style={{ height: "100%", width: "26px", cursor: "pointer" }}
                    onClick={() => {
                      history.push("/dashboard/messages");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex px-0.5 mt-11">
            <div className="md:w-3/12">
              <div className="">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Sender
                  </P>
                </div>
                <Select
                  bgImg={caret_down}
                  bgColor={"transparent"}
                  color={"#374072"}
                  border={"1px solid #DEDEDF"}
                  borderRadius={"4px"}
                  p={"11px 16px 10px 16px"}
                  data={null}
                  defaultValue={"— Select Sender —"}
                  properyToShow={"text"}
                  hoverBg={"#FFF"}
                  hoverColor={"#112E46"}
                  fontSize={"16px"}
                  callback={(option, idx, arr) => {
                    console.log(option, idx, arr);
                  }}
                />
              </div>

              {/* <div className="mt-0">
                                <div className="mb-1.5">
                                    <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>Sender</P>
                                </div>
                                <Dropdown 
                                    items={null} 
                                    bgImg={caret_down} 
                                    bgColor={"transparent"}
                                    color={"#374072"}
                                    p={"11px 16px 10px 16px"}
                                    callback={(e)=>{
                                        console.log(e.target.value);
                                    }}
                                />
                            </div> */}

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Subject
                  </P>
                </div>
                <Input
                  placeholder="Enter email subject"
                  type="text"
                  name="email"
                  required
                />
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Send to:
                  </P>
                </div>
                <Dropdown
                  items={null}
                  bgImg={caret_down}
                  bgColor={"transparent"}
                  color={"#374072"}
                  p={"11px 16px 10px 16px"}
                  defaultValue={"— Select recipients —"}
                  callback={(e) => {
                    console.log(e.target.value);
                  }}
                />
              </div>

              <div className="mt-6">
                <div className="mb-1.5">
                  <P color={"#7A809B"} fontSize={"13px"} fontWeight={700}>
                    Schedule:
                  </P>
                </div>
                <Dropdown
                  items={null}
                  bgImg={caret_down}
                  bgColor={"transparent"}
                  color={"#374072"}
                  p={"11px 16px 10px 16px"}
                  defaultValue={"— Send immediately —"}
                  callback={(e) => {
                    console.log(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="md:w-9/12" style={{ height: "100vh" }}>
              <div className="md:pl-12 pl-0 h-full">
                <div style={{ border: "1px solid #ccc", height: "100vh" }}>
                  <Editor
                    editorClassName={"report-editor"}
                    editorState={state.editorState}
                    onEditorStateChange={onEditorStateChange}
                    onContentStateChange={onContentStateChange}
                    toolbar={config}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {openPreviewModal ? (
            <PreviewModal
              content={state.contentState}
              closeModal={() => {
                setOpenPreviewModal(false);
              }}
            />
          ) : null}
        </>
      </>
    );
  } else {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    trades: state.transactions.trades,
    transfers: state.transactions.transfers,
    transactionsList: state.transactions.transactionsList,
    loading: state.transactions.loading,
    error: state.overview.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTheme(NewMessage)));
