import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, DefaultButton } from "../../../../../UI toolkit/Button/Button";
import { P, SubHeader } from "../../../../../UI toolkit/Text/Text";
import {
  TransactionsTableStyle,
  TransactionsTableHeaderStyle,
} from "./CardTransactions.styled";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { connect, useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "../../../../../utils/specialFunctions";
import ic_sent_icon from "../../../../../assets/images/ic-sent-coin.svg";
import ic_receive_icon from "../../../../../assets/images/ic-received-coin.svg";
import ic_swapped_coin from "../../../../../assets/images/ic-swapped-coin.svg";
import CardTransactionsModal from "./CardTransactionsModal";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

// Redux store mapstatetoprops and mapdispatchtoprops

const CardTransactionsTable = ({ links1 }) => {
  const [openModal, setopenModal] = useState(false);

  const dispatch = useDispatch();

  const [activity, setActivity] = useState(null);

  const statusKeys = {
    completed: "completed",
    pending: "pending",
    failed: "failed",
  };

  const typeKeys={
    active:"Unfreeze",
    inactive:"Freeze",
    cancel:"Cancel",
    create:"Create",
    fund:"Fund",
    withdrawal:"Withdrawal",
  }

  const bgColor = (status) => {
    return status === "completed"
      ? "#D1F7C4"
      : status === "pending"
      ? "#FFEAB6"
      : status === "matched"
      ? "#D0F0FD"
      : status === "failed"
      ? "#FFDCE5"
      : status === "cancelled"
      ? "#FFDCE5"
      : "#D1F7C4";
  };

  const color = (status) => {
    return status === "completed"
      ? "#337138"
      : status === "pending"
      ? "#606060"
      : status === "matched"
      ? "#335F71"
      : status === "failed"
      ? "#531423"
      : status === "cancelled"
      ? "#531423"
      : "";
  };

  // const bg = (curr) => {
  //   return curr.status === "completed"
  //     ? "#D1F7C4"
  //     : curr.status === "pending"
  //     ? "#FFEAB6"
  //     : curr.status === "matched"
  //     ? "#D0F0FD"
  //     : curr.status === "failed"
  //     ? "#FFDCE5"
  //     : curr.status === "cancelled"
  //     ? "#FFDCE5"
  //     : "#D1F7C4";
  // };

  // const color = (curr) => {
  //   return curr.status === "completed"
  //     ? "#337138"
  //     : curr.status === "pending"
  //     ? "#606060"
  //     : curr.status === "matched"
  //     ? "#335F71"
  //     : curr.status === "failed"
  //     ? "#531423"
  //     : curr.status === "cancelled"
  //     ? "#531423"
  //     : "";
  // };

  return (
    <div style={{ overflowX: "auto" }}>
      <TransactionsTableHeaderStyle className="w-full">
        <tbody className="w-full">
          <tr className="Table-heading -px-1.5">
            <td width="15%" className="pt-4 pb-4"></td>

            <td width="15%" className="pt-4 pb-4"></td>

            <td width="20%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  USER
                </SubHeader>
              </div>
            </td>
            <td width="20%" className="pt-4 pb-4">
              <div className="flex justify-center">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  AMOUNT
                </SubHeader>
              </div>
            </td>
            <td width="15%" className="pt-4 pb-4"></td>
          </tr>
        </tbody>
      </TransactionsTableHeaderStyle>
      {links1 &&
        links1.map((transactionGroup, idx, arr) => {
          return (
            <TransactionsTableStyle className="w-full" key={idx}>
              <tbody>
                <tr>
                  <td colSpan="5">
                    <div className="pt-4 pb-4 px-1.5">
                      <P fontSize="13px" color={"#7C8191"} fontWeight="500">
                        {`${timeAgo.format(
                          new Date(transactionGroup.group)
                        )} — ${new Date(
                          transactionGroup.group
                        ).toDateString()}`}
                      </P>
                    </div>
                  </td>
                </tr>

                {transactionGroup.data.map((curr, idx, arr) => {
                  const status =
                    curr.type === "inactive" ||
                    curr.type === "active" ||
                    curr.type === "canceled"
                      ? curr.status
                      : curr.type === "withdrawal" ||
                        curr.type === "fund" ||
                        curr.type === "create"
                      ? curr.transactions.status
                      : "";

                  const amount =
                    curr.type === "inactive" ||
                    curr.type === "active" ||
                    curr.type === "canceled"
                      ? ""
                      : curr.type === "withdrawal" ||
                        curr.type === "fund" ||
                        curr.type === "create"
                      ? `${
                          curr?.transactions?.currency === "USD"
                            ? "$"
                            : curr.transactions?.currency === "NGN"
                            ? "₦"
                            : ""
                        } ${Number(curr.transactions?.source_amount).toFixed(
                          2
                        )}`
                      : "";
                  return (
                    <tr
                      className="px-1.5"
                      style={{ borderBottom: "1px solid #EFF0F5" }}
                      key={idx}
                    >
                      <td width="15%" className="pt-4 pb-4 px-1.5">
                        <Badge color={color(status)} bg={bgColor(status)}>
                          {statusKeys[status]}
                        </Badge>
                      </td>
                      <td width="15%" className="col-start-2 col-end-4">
                        <div>
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr && curr.type ? typeKeys[curr.type] : ""}
                          </P>
                        </div>
                      </td>
                      <td width="20%" className="col-start-2 col-end-4">
                        <div>
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {curr && curr.account && curr.account.email
                              ? curr.account.email
                              : "no email"}
                          </P>
                        </div>
                      </td>
                      <td width="20%" className="col-start-2 col-end-4">
                        <div>
                          <P fontSize="13px" color={"#374072"} fontWeight="500">
                            {amount}
                          </P>
                        </div>
                      </td>
                      <td width="15%" className="pt-4 pb-4 px-1.5">
                        <div className="flex">
                          <div className="">
                            <P
                              fontSize="13px"
                              color={"#0087B5"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setActivity(curr);
                                setopenModal(true);
                              }}
                            >
                              View
                            </P>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TransactionsTableStyle>
          );
        })}
      <div>
        {openModal ? (
          <CardTransactionsModal
            width={"30%"}
            border={"none"}
            closeModalCallback={() => {
              setopenModal(false);
            }}
            activity={activity}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardTransactionsTable);
