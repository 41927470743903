import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { P } from "../Text/Text";

export const DropdownStyle = styled.select`
  display: inline-block;
  width: ${(props) => props.width || "100%"};
  color: ${(props) => props.color || "#4a4a4a"};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  padding: ${(props) => props.p || "10px 16px"};
  padding-right: ${(props) => props.p || "30px"};
  background: ${(props) => `url(${props.bgImg})`};
  background-position: right;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-color: ${(props) => props.bgColor || "#f2f2f2"};
  -webkit-appearance: none;
  opacity: 1;
  outline: none;
`;

export const OptionStyle = styled.option`
  display: inline-block;
  width: ${(props) => props.width || "100%"};
  color: ${(props) => props.color || "#4a4a4a"};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  box-shadow: 0px 1px 4px #3f3f4419;
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  padding: ${(props) => props.p || "13px 15px"};
  padding-right: ${(props) => props.pr || "30px"};
  background: ${(props) => `url(${props.bgImg})`};
  background-position: right;
  background-color: ${(props) => props.bgColor || "#f2f2f2"};
  background-repeat: no-repeat;
  background-size: 30px 30px;
  -webkit-appearance: none;
  opacity: 1;
`;

export const Dropdown = ({
  border,
  p,
  pr,
  borderRadius,
  boxShadow,
  bgImg,
  bgColor,
  width,
  color,
  items,
  callback,
  noDefaultOption,
  defaultValue,
}) => {
  return (
    <DropdownStyle
      color={color}
      border={border}
      boxShadow={boxShadow}
      p={p}
      pr={pr}
      borderRadius={borderRadius}
      bgImg={bgImg}
      bgColor={bgColor}
      width={width}
      onChange={(e) => {
        callback && callback(e);
      }}
    >
      {noDefaultOption ? null : (
        <OptionStyle
          border={border}
          p={p}
          pr={pr}
          borderRadius={borderRadius}
          bgImg={bgImg}
          bgColor={bgColor}
          width={width}
        >
          {defaultValue || "-- Select --"}
        </OptionStyle>
      )}

      {items &&
        items.map((curr, idx, arr) => {
          return (
            <OptionStyle
              id={idx}
              name={idx}
              key={idx}
              border={border}
              p={p}
              pr={pr}
              borderRadius={borderRadius}
              bgImg={bgImg}
              bgColor={bgColor}
              width={width}
            >
              {curr.text}
            </OptionStyle>
          );
        })}
    </DropdownStyle>
  );
};

const SelectBaseStyle = styled.div`
  display: inline-block;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  width: ${(props) => props.width || "100%"};
  color: ${(props) => props.color || "#4a4a4a"};
  font-size: ${(props) => props.fontSize || "16px"};
  box-shadow: ${(props) => props.boxShadow || "0px 1px 4px #3F3F4419"};
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  border-radius: ${(props) => props.borderRadius || "4px"};
  padding: ${(props) => props.p || "10px 16px"};
  padding-right: ${(props) => props.p || "30px"};
  background: ${(props) => `url(${props.bgImg})`};
  background-position: right;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-color: ${(props) => props.bgColor || "#f2f2f2"};
  -webkit-appearance: none;
  opacity: 1;
  outline: none;

  // background-color: ${(props) => props.theme.colors.bg};
  // color: ${(props) => props.theme.colors.color};

  & li {
    list-style-type: none;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const SelectDropDownBaseStyle = styled.ul`
  position: absolute;
  left: 0%;
  top: 100%;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  width: ${(props) => props.width || "100%"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  box-shadow: 0px 1px 4px #3f3f4419;
  border: ${(props) => props.border || "1px solid #DEDEDF"};
  // border-radius: ${(props) => props.borderRadius || "4px"};
  border-radius: "0px";
  background-color: ${(props) => props.bgColor || "#f2f2f2"};
  opacity: 1;
  list-style-type: none;

  // background-color: ${(props) => props.theme.colors.bg};
  // color: ${(props) => props.theme.colors.color};

  & li {
    list-style-type: none;
    color: ${(props) => props.color || "#4a4a4a"};
    padding: ${(props) => props.p || "10px 10px"};
    padding-right: ${(props) => props.pr || "10px"};
    box-shadow: ${(props) =>
      props.dropdownListborderBottom || "0px 1px 4px #3F3F4419"};

    img {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background: ${(props) => props.hoverBg || "transparent"};
      color: ${(props) => props.hoverColor || "#000"};
    }
  }
`;

export const Select = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(null);

  const selectOption = (option, idx, arr) => {
    setValue(option[props.properyToShow]);
    setExpanded(!expanded);
    props.callback && props.callback(option, idx, arr);
  };
  const expandOption = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <div className="relative">
      <SelectBaseStyle
        className="flex items-center justify-between p-2 py-1 cursor-pointer"
        onClick={expandOption}
        fontSize={props.fontSize}
        ref={props.ref}
        color={props.color}
        border={props.border}
        boxShadow={props.boxShadow}
        p={props.p}
        pr={props.pr}
        borderRadius={props.borderRadius}
        bgImg={props.bgImg}
        bgColor={props.bgColor}
        width={props.width}
        hoverBg={props.hoverBg}
        hoverColor={props.hoverColor}
      >
        {value ? (
          <li>
            <div className="flex content-center p1-2">
              <div>{value}</div>
            </div>
          </li>
        ) : (
          <P fontSize="13px">loading.....</P>
        )}
      </SelectBaseStyle>

      {expanded && (
        <SelectDropDownBaseStyle
          color={props.color}
          border={props.border}
          boxShadow={props.boxShadow}
          p={props.p}
          pr={props.pr}
          borderRadius={props.borderRadius}
          bgImg={props.bgImg}
          bgColor={props.bgColor}
          width={props.width}
          hoverBg={props.hoverBg}
          hoverColor={props.hoverColor}
          dropdownListborderBottom={props.dropdownListborderBottom}
        >
          {props.data &&
            props.data.map((curr, idx, arr) => {
              return (
                <li
                  key={idx}
                  value={curr}
                  onClick={() => selectOption(curr, idx, arr)}
                  className=""
                  style={{ cursor: "pointer" }}
                >
                  <div className="">{curr[props.properyToShow]}</div>
                </li>
              );
            })}
        </SelectDropDownBaseStyle>
      )}
    </div>
  );
};

/* <Dropdown 
    items={links1} 
    bgImg={caret_down} 
    bgColor={"transparent"}
    color={"#fff"}
    p={"5px 15px"}
    callback={(e)=>{
        console.log(e)
    }}
/> */
