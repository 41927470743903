import React, { useEffect } from "react";
import { connect } from "react-redux";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import { Modal } from "../../../../UI toolkit/Modal/Modal";
import { H1, H6 } from "../../../../UI toolkit/Text/Text";
import { TransactionsModalStyle } from "../Transactions/Transactions.styled";
import {
  retryTransferAction,
  rejectTransferAction,
} from "../../../../store/Actions/TransactionActions";
import ic_appr from "../../../../assets/images/ic-appr.svg";

// Redux store mapstatetoprops and mapdispatchtoprops
const mapStateToProps = (state) => {
  return {};
};

// Link Actions to components
const mapDispatchToProps = (dispatch) => {
  return {
    retryTransferAction: (id, successCallback) =>
      dispatch(retryTransferAction(id, successCallback)),
    rejectTransferAction: (id, successCallback) =>
      dispatch(rejectTransferAction(id, successCallback)),
  };
};

// named exported components

export const ConfirmTransferModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    width,
    border,
    closeModalCallbackstate,
    closeModalCallback,
    approveCallback,
    id,
    retryTransferAction,
    rejectTransferAction,
    transaction,
    typeOfTransfer,
  }) => {
    return (
      <Modal
        width={width}
        callback={() => {
          closeModalCallback();
        }}
      >
        <div className="flex items-center justify-center">
          <div>
            <div className="pt-4">
              <H1 fontSize="16px" fontWeight="700" color={"#7A809B"}>
                Do you want to{" "}
                {`${typeOfTransfer === "retry" ? "Approve" : "Reject"}`} this
                transaction ?
              </H1>
            </div>

            <div className="flex items-center justify-center">
              <div className="py-4 pr-6">
                <DefaultButton
                  bg={"#112E46"}
                  color={"#fff"}
                  p={"8px 16px"}
                  onClick={() => {
                    if (typeOfTransfer === "retry") {
                      retryTransferAction &&
                        retryTransferAction(transaction.id, (response) => {
                          closeModalCallback();
                        });
                    } else if (typeOfTransfer === "reject") {
                      rejectTransferAction &&
                        rejectTransferAction(transaction.id, (response) => {
                          closeModalCallback();
                        });
                    }
                  }}
                >
                  Yes
                </DefaultButton>
              </div>

              <div className="py-4">
                <DefaultButton
                  bg={"#FF6A6A"}
                  color={"#fff"}
                  p={"8px 16px"}
                  onClick={closeModalCallback}
                >
                  No
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

// named exported components

export const RetryApprovedModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ width, bg, border, closeModal }) => {
  return (
    <div>
      <Modal
        width={width}
        bg={bg}
        border={border}
        callback={() => {
          closeModal();
        }}
      >
        <TransactionsModalStyle h={"441px"}>
          <div className="Transaction-Approved flex items-center justify-center">
            <div>
              <div className="my-12">
                <img src={ic_appr} alt="text" width="93" height="93" />
              </div>

              <div className="">
                <H6 color={"#FFFFFF"} className="text-center" fontWeight="700">
                  Approved!
                </H6>
              </div>
            </div>
          </div>
        </TransactionsModalStyle>
      </Modal>
    </div>
  );
});

// <div>
//     <Modal width={'30%'} bg={"#005674"} border={'none'} callback={()=>{
//         setopenModal(false)
//     }}>
//         <TransactionsModalStyle h={"441px"}>
//             <div className="Transaction-Approved flex items-center justify-center">
//                 <div>
//                     <div className="my-12">
//                         <img src={ic_appr} alt="text" width="93" height="93"/>
//                     </div>

//                     <div className="">
//                         <H6 color={"#FFFFFF"} className="text-center" fontWeight="700">Approved!</H6>
//                     </div>
//                 </div>
//             </div>
//         </TransactionsModalStyle>
//     </Modal>
// </div>
