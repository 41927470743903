import React from "react";
import { ToggleButton } from "../../../../UI toolkit/Input/Input";
import { P, SubHeader } from "../../../../UI toolkit/Text/Text";
import { connect } from "react-redux";
import {
  publishAnnouncementAction,
  unpublishAnnouncementAction,
} from "../../../../store/Actions/Messages_AnnouncementsAction";

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    publishAnnouncementAction: (announcementId, successCallback) =>
      dispatch(publishAnnouncementAction(announcementId, successCallback)),
    unpublishAnnouncementAction: (announcementId, successCallback) =>
      dispatch(unpublishAnnouncementAction(announcementId, successCallback)),
  };
};

export const AnnouncementTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    announcements,
    publishAnnouncementAction,
    unpublishAnnouncementAction,
  }) => {
    
    const AnnouncementAction = (announcement, idx, e) => {
      console.log(e.target.checked);
      if(e.target.checked === true || announcement.is_published === false){
        console.log('announcement true')
        publishAnnouncementAction(idx, () => {});
      }else if(e.target.checked === false || announcement.is_published === true){
        console.log('announcement false')
        unpublishAnnouncementAction(idx, () => {});
      }
    };

    return (
      <div style={{ overflowX: "auto" }}>
        <table className="w-full">
          <thead style={{ background: "#E7EAF4" }}>
            <tr className="Table-heading -px-1.5">
              <td width="25%" className="pt-4 pb-4 px-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    id
                  </SubHeader>
                </div>
              </td>
              <td width="25%" className="pt-4 pb-4 px-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    Title
                  </SubHeader>
                </div>
              </td>
              <td width="25%" className="pt-4 pb-4 px-4">
                <div className="">
                  <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                    Body
                  </SubHeader>
                </div>
              </td>
              <td width="25%" className="pt-4 pb-4 px-4 text-right">
                <SubHeader fontSize="12px" color={"#7C8191"} fontWeight="500">
                  Action
                </SubHeader>
              </td>
            </tr>
          </thead>

          <tbody>
            {announcements &&
              announcements.map((announcement, idx) => {
                return (
                  <tr
                    style={{ width: "100%", borderBottom: "1px solid #EFF0F5" }}
                    key={announcement.id}
                  >
                    <td width="25%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {announcement.id}
                      </P>
                    </td>

                    <td width="25%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#374072"} fontWeight="500">
                        {announcement.title}
                      </P>
                    </td>

                    <td width="25%" className="px-4 pt-4 pb-4">
                      <P fontSize="13px" color={"#005674"} fontWeight="500">
                        {announcement.body.replace(/(<([^>]+)>)/gi, "")}
                      </P>
                    </td>

                    <td width="25%" className="px-4 pt-4 pb-4">
                      <div className="flex justify-end">
                        <ToggleButton
                          toggleId={"announcement" + announcement.id}
                          onChange={(e) =>
                            AnnouncementAction(announcement, announcement.id, e)
                          }
                          checked={announcement.is_published}
                          // disabled={
                          //   user.role === "super-admin" ? false : true
                          // }
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
);
