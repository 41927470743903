import {
  GET_SAVINGS,
  GET_SAVINGS_OVERVIEW_STATS,
  GET_USER_SAVINGS,
  GET_USER_SAVINGS_STATS,
  GET_USER_SAVINGS_WALLET,
  SAVINGS_ERROR,
  SAVINGS_LOADING,
  SET_USER_SAVINGS_FILTER,
  UPDATE_FIXED_ACTIVE_TAB,
  UPDATE_FIXED_NAVIGATOR,
  UPDATE_FLEXIBLE_ACTIVE_TAB,
  UPDATE_FLEXIBLE_NAVIGATOR,
  UPDATE_SAVINGS_ACTIVE_STATUS,
  UPDATE_SAVINGS_ACTIVE_TAB,
  UPDATE_SAVINGS_PAGE,
  UPDATE_TOTAL_SAVINGS,
} from "../Types/Types";

const initState = {
  loading: null,
  error: null,
  flexibleSavings: null,
  fixedSavings: null,
  overviewStats: null,
  user_savings: null,
  user_savings_stats: null,
  user_savings_filter: null,
  flexible_filters: null,
  flexibleNavigator: {
    startIndex: 0,
    endIndex: 6,
    currentIndex: 0,
  },
  flexibleActiveTab: 0,
  savingsPage: {
    fixed: {
      active: 0,
      completed: 0,
      pending: 0,
      paid: 0,
      all: 0,
    },
    flexible: {
      active: 0,
      completed: 0,
      stopped: 0,
      all: 0,
    },
    all: {
      active: 0,
      completed: 0,
      pending: 0,
      paid: 0,
      all: 0,
    },
  },
  savingsTotalPages: {
    fixed: {
      active: 0,
      completed: 0,
      pending: 0,
      paid: 0,
      all: 0,
    },
    flexible: {
      active: 0,
      completed: 0,
      stopped: 0,
      all: 0,
    },
    all: {
      active: 0,
      completed: 0,
      pending: 0,
      paid: 0,
      all: 0,
    },
  },
  savingsActiveTab: "all",
  savingsActiveStatus: {
    all: "all",
    flexible: "all",
    fixed: "all",
  },
  fixed_filters: null,
  fixedNavigator: {
    startIndex: 0,
    endIndex: 6,
    currentIndex: 0,
  },
  fixedActiveTab: 0,
};

const savingsReducer = (state = initState, action) => {
  switch (action.type) {
    case SAVINGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SAVINGS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_SAVINGS_OVERVIEW_STATS:
      return {
        ...state,
        overviewStats: action.overviewStats,
        loading: false,
      };
    case GET_USER_SAVINGS_WALLET:
      return {
        ...state,
        walletStats: action.walletStats,
        loading: false,
      };
    case GET_SAVINGS:
      // const type = action.filters.type;

      // let savings = {};

      // if (type === "flexible") {
      //   savings = {
      //     flexibleSavings: action.savings,
      //   };
      // } else if (type === "fixed") {
      //   savings = {
      //     fixedSavings: action.savings,
      //   };
      // }
      return {
        ...state,
        savings: action.savings,
        loading: false,
      };
    case GET_USER_SAVINGS_STATS:
      return {
        ...state,
        user_savings_stats: action.user_savings_stats,
        loading: false,
      };
    case GET_USER_SAVINGS:
      return {
        ...state,
        user_savings: action.user_savings,
        loading: false,
      };

    case SET_USER_SAVINGS_FILTER:
      return { ...state, user_savings_filter: action.user_savings_filter };

    case UPDATE_FLEXIBLE_ACTIVE_TAB:
      return {
        ...state,
        flexibleActiveTab: action.payload,
        loading: false,
      };

    case UPDATE_FLEXIBLE_NAVIGATOR:
      return {
        ...state,
        //update field in the object tradeNavigator
        flexibleNavigator: {
          ...state.flexibleNavigator,
          [action.payload.field]: action.payload.value,
        },
        loading: false,
      };

    case UPDATE_FIXED_ACTIVE_TAB:
      return {
        ...state,
        fixedActiveTab: action.payload,
        loading: false,
      };

    case UPDATE_FIXED_NAVIGATOR:
      return {
        ...state,
        //update field in the object tradeNavigator
        fixedNavigator: {
          ...state.fixedNavigator,
          [action.payload.field]: action.payload.value,
        },
        loading: false,
      };
    case UPDATE_SAVINGS_ACTIVE_TAB:
      return {
        ...state,
        savingsActiveTab: action.payload,
        loading: false,
      };

    case UPDATE_SAVINGS_ACTIVE_STATUS:
      return {
        ...state,
        savingsActiveStatus: {
          ...state.savingsActiveStatus,
          [action.payload.tab]: action.payload.value,
        },
        loading: false,
      };
    case UPDATE_TOTAL_SAVINGS:
      return {
        ...state,
        savingsTotalPages: {
          ...state.savingsTotalPages,
          [action.payload.tab]: {
            [action.payload.status]: action.payload.value,
          },
        },
      };
    case UPDATE_SAVINGS_PAGE:
      return {
        ...state,
        savingsPage: {
          ...state.savingsPage,
          [action.payload.tab]: {
            ...state.savingsPage[action.payload.tab],
            [action.payload.status]: action.payload.value,
          },
        },
      };
    default:
      return state;
  }
};

export default savingsReducer;
