import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Card } from "../../../Units/Card.styled";
import { TabHeader, TableTab } from "../../../Units/TabsAndLinkStyled";
import DashboardHeader from "../DashboardHeader";
import { Div } from "../Users/Users.styled";
import { AnnouncementTable } from "./AnnouncementTable";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { getUsers } from "../../../../store/Actions/UsersAction";
import {
  filterUsersonChange,
  getLevel,
} from "../../../../utils/specialFunctions";
import { DefaultButton } from "../../../../UI toolkit/Button/Button";
import {
  getAnnouncementAction,
  updateAnnouncementsNavigator,
} from "../../../../store/Actions/Messages_AnnouncementsAction";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import ReactSelect from "react-select";
import { range } from "lodash";

const Announcement = ({
  loading_announcement,
  announcementsList,
  getAnnouncementAction,
  theme,
  filters,
  navigator,
  updateNavigator,
}) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState(0);
  const pageCount = Math.ceil(Number(filters?.count) / Number(filters?.limit));
  let pages = range(1, pageCount + 1);

  const links1 = [
    { text: "All Announcement" },
    { text: "Published" },
    { text: "Unpublished" },
    { text: "Failed" },
  ];

  const pagesFromBack = 4;
  const rowsOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handleNext = () => {
    if (navigator.currentIndex < pages.length - 1) {
      updateNavigator({
        field: "currentIndex",
        value: navigator.currentIndex + 1,
      });

      getAnnouncementAction({
        page: filters.pagination.next.page,
        limit: filters.limit,
      });

      if (navigator.currentIndex === navigator.endIndex) {
        console.log("hello");
        updateNavigator({
          field: "startIndex",
          value: navigator.startIndex + 1,
        });
        updateNavigator({ field: "endIndex", value: navigator.endIndex + 1 });
      }
    }
  };

  const handlePrev = () => {
    if (navigator.currentIndex > 0) {
      updateNavigator({
        field: "currentIndex",
        value: navigator.currentIndex - 1,
      });
      getAnnouncementAction({
        page: filters.pagination.prev.page,
        limit: filters.limit,
      });

      if (navigator.currentIndex === navigator.startIndex) {
        updateNavigator({
          field: "startIndex",
          value: navigator.startIndex - 1,
        });
        updateNavigator({ field: "endIndex", value: navigator.endIndex - 1 });
      }
    }
  };

  const handleMorePages = () => {
    // setStartIndex(startIndex + 5);
    // setEndIndex(endIndex + 5);
    updateNavigator({ field: "startIndex", value: navigator.startIndex + 5 });
    updateNavigator({ field: "endIndex", value: navigator.endIndex + 5 });
  };

  const handleChangePage = (option) => {
    console.log(option);

    // setCurrentIndex(option - 1);

    updateNavigator({ field: "currentIndex", value: option - 1 });

    getAnnouncementAction({
      page: option,
      limit: filters.limit,
    });

    // console.log(filters);
    // if (filters?.pagination[option]) {
    //   getAnnouncementAction({
    //     page: filters.pagination[option].page,
    //     limit: filters.pagination[option].limit,
    //
    //   });
    // }
  };

  const handleChangeRowsNo = (option) => {
    console.log(option);
    getAnnouncementAction({
      page: 1,
      limit: option.value,
    });

    updateNavigator({ field: "currentIndex", value: 0 });
    updateNavigator({ field: "startIndex", value: 0 });
    updateNavigator({ field: "endIndex", value: 6 });
  };

  useEffect(() => {
    getAnnouncementAction(
      {
        page: 1,
        limit: 10,
      },
    );
  }, [getAnnouncementAction]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Announcements</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        <DashboardHeader>Announcements</DashboardHeader>

        <Div className="pb-6 flex justify-between items-center">
          <Link to={"/dashboard/overview"} className="backTo">
            &nbsp; Back to Overview
          </Link>

          <div>
            <DefaultButton
              small
              p={"8px 16px"}
              color={theme.colors.color}
              bg={theme.colors.bg}
            >
              <Link to={"/dashboard/announcements/+"}>New announcement</Link>
            </DefaultButton>
          </div>
        </Div>

        <Card>
          <TabHeader>
            <div className="flex">
              <div className="w-12/12">
                <TableTab
                  tabs={links1}
                  activeTab={activeLink1}
                  handleTab={handleActiveLink1}
                  callback={(active) => {
                    // console.log(active)
                  }}
                />
              </div>
            </div>
          </TabHeader>

          {/* {loading_announcement ? (
            <div className="flex items-center justify-center h-full h-screen">
              <h1>loading...</h1>
            </div>
          ) : (
            <div>
              {activeLink1 === 0 ? (
                <div className="">
                  <AnnouncementTable announcements={announcementsList} />
                </div>
              ) : activeLink1 === 1 ? (
                <div className=""></div>
              ) : activeLink1 === 2 ? (
                <div className="">
                  <Card></Card>
                </div>
              ) : null}
            </div>
          )} */}
          <div className="">
            <AnnouncementTable announcements={announcementsList} />
          </div>
          <div className="flex justify-between gap-2 items-center py-4 w-full">
            <p className="">
              Page no. {filters?.page} out of {pageCount}
              {/* {pagination.per_page}  */}
            </p>

            <div className="flex items-center">
              <AiOutlineLeft
                onClick={handlePrev}
                className="mr-4 cursor-pointer"
                size={40}
              />
              {pages.map((page, index) => {
                if (
                  index >= navigator.startIndex &&
                  index <= navigator.endIndex
                ) {
                  return (
                    <div
                      key={index}
                      className={`${
                        index === navigator.currentIndex ? "bg-greyBg" : ""
                      }  cursor-pointer w-8 flex justify-center`}
                      onClick={() => handleChangePage(page)}
                    >
                      <p className="text-sm ">{page}</p>
                    </div>
                  );
                }
                if (index === navigator.endIndex + 1) {
                  return (
                    <div
                      key={index}
                      className="cursor-pointer w-8 flex justify-center"
                      onClick={handleMorePages}
                    >
                      <p className="text-sm ">...</p>
                    </div>
                  );
                }
                if (index > pageCount - pagesFromBack) {
                  return (
                    <div
                      key={index}
                      className={`${
                        index === navigator.currentIndex ? "bg-greyBg" : ""
                      }  cursor-pointer w-8 flex justify-center`}
                      onClick={() => handleChangePage(page)}
                    >
                      <p className="text-sm ">{page}</p>
                    </div>
                  );
                }
              })}

              <AiOutlineRight
                onClick={handleNext}
                className="mr-4 cursor-pointer"
                size={40}
              />
            </div>
            {/* ReactSelect component without text typing cursor */}

            <div className="">
              <ReactSelect
                isSearchable={false}
                options={rowsOptions}
                placeholder="Number of rows"
                menuPlacement="top"
                onChange={handleChangeRowsNo}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    announcementsList: state.announcement.announcementsList,
    loading_announcement: state.announcement.loading,
    filters: state.announcement.annoucements_filters,
    navigator: state.announcement.announcementsNavigator,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (page, keyword) => dispatch(getUsers(page, keyword)),
    getAnnouncementAction: (query) => dispatch(getAnnouncementAction(query)),
    updateNavigator: (navigator) =>
      dispatch(updateAnnouncementsNavigator(navigator)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Announcement));
