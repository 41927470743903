import React, { useState, useEffect } from "react";
import { Card } from "../../../../Units/Card.styled";
import {
  CardHolder,
  CardTitle,
  TabNav,
} from "../../../../Units/CardTitle.styled";
import ic_overview from "../../../../../assets/images/ic-overview-db.svg";
import { connect } from "react-redux";
import Statbox from "./Statbox";
import { getSavingsOverviewStats } from "../../../../../store/Actions/SavingsActions";
import { getCardStats } from "../../../../../store/Actions/CardsAction";

const Overview = ({ overviewAction, token, overview }) => {
  const TABS = [
    { label: "Today", queryParam: "today" },
    { label: "7 days", queryParam: "7d" },
    { label: "30 days", queryParam: "30d" },
    { label: "All time", queryParam: "all" },
  ];

  const [activeTab, handleTab] = useState(3);

  const [state, setstate] = useState({
    key: null,
    value: null,
  });
  const [queryParam, setqueryParam] = useState("all");

  useEffect(() => {
    console.log(queryParam);
    overviewAction(queryParam);
  }, [queryParam, overviewAction, token]);
  return (
    <div>
      <Card>
        <CardHolder>
          <div className="flex">
            <div className="w-6/12">
              <CardTitle img={ic_overview} text={"Overview"} />
            </div>
            <div className="w-6/12">
              <TabNav
                tabs={TABS}
                activeTab={activeTab}
                handleTab={handleTab}
                p={"4px 8px 3px 8px"}
                Keys={["label", "queryParam"]}
                // borderRadius={"0px"}
                boxShadow={"0px"}
                callback={(curr) => {
                  setqueryParam(curr["queryParam"]);
                }}
              />
            </div>
          </div>
        </CardHolder>
        <div
          className="flex py-6 pl-4 justify-between"
          style={{ borderBottom: "1px solid #EFF0F5" }}
        >
          <Statbox name="CARDS" value={overview ? overview.totalCards : 0} />

          <Statbox
            name="TRANSACTIONS"
            isMoney={true}
            symbol="₦"
            value={overview ? Number(overview.transactions.ngn.total) : 0}
          />
        </div>

        <div
          className="flex py-6 pl-4 justify-between"
          style={{ borderBottom: "1px solid #EFF0F5" }}
        >
          <Statbox
            name="ACTIVE"
            value={overview ? overview.totalActiveCards : 0}
          />

          <Statbox
            isMoney={true}
            symbol="₦"
            name="FUNDED"
            value={overview ? Number(overview.transactions.ngn.fund) : 0}
          />
        </div>
        <div
          className="flex py-6 pl-4 justify-between"
          style={{ borderBottom: "1px solid #EFF0F5" }}
        >
          <Statbox
            name="INACTIVE"
            value={overview ? overview.totalInActiveCards : 0}
          />

          <Statbox
            isMoney={true}
            symbol="₦"
            name="WITHDRAWALS"
            value={overview ? Number(overview.transactions.ngn.withdrawal) : 0}
          />
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    overview: state.cards.cardStats,
    loading: state.cards.loading,
    error: state.cards.error,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    overviewAction: (query) => dispatch(getCardStats(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
