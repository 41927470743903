import styled from "styled-components";

export const SendRecieveStyle = styled.div`
  width: ${(props) => props.width || "100%"};
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }

  .typeOfCryptoBanner {
    // background: ${(props) => props.cryptoBannerBg || "#006F95"};
    // color: #fff;

    background: ${(props) => props.theme.colors.bg || "#112E46"};
    color: ${(props) => props.theme.colors.color || "#FFFFFF"};
  }

  .Success-banner {
    // background: ${(props) => props.successBannerBg || "#006F95"};
    // color: #fff;

    background: ${(props) => props.theme.colors.bg || "#112E46"};
    color: ${(props) => props.theme.colors.color || "#FFFFFF"};

    height: 405px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success-img-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success-img-div img {
    width: 93px;
    height: 93px;
    display: block;
  }

  .recieve-img-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .recieve-img-div img {
    width: 93px;
    height: 93px;
    display: block;
  }

  .backTo {
    transform: rotateY(-180deg);
  }
`;

export const SendRecieveCoinsModal = styled.div`
  max-height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }
`;
