import React, { useEffect, useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { P } from "../../../../UI toolkit/Text/Text";
import { Card } from "../../../Units/Card.styled";
import { CardHolder } from "../../../Units/CardTitle.styled";
import { TabHeader, TableTab } from "../../../Units/TabsAndLinkStyled";
import { Div } from "./Users.styled";
import AddBalance from "./AddBalance";
import DeleteBalance from "./DeleteBalance";
import { UserBalanceTable } from "./UsersTables";
import { connect } from "react-redux";
import { getUser, getUsers } from "../../../../store/Actions/UsersAction";
import DashboardHeader from "../DashboardHeader";

const Balance = ({
  getUser,
  singleUser,
  loading,
  history,
  match,
  listOfUsers,
  getUsers,
}) => {
  const location = useLocation();
  const [activeLink1, handleActiveLink1] = useState(0);

  const links1 = [
    { text: "Add Balance", route: "/dashboard/" },
    { text: "Remove Balance", route: "/dashboard/wallet" },
  ];

  useEffect(() => {
    getUser(match.params.id);
    getUsers();
  }, [getUser, getUsers, match.params.id]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Balance</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div>
        {loading ? (
          <div
            className="flex items-center justify-center h-100"
            style={{ height: "100vh" }}
          >
            <h1>Loading........</h1>
          </div>
        ) : (
          <div>
            <DashboardHeader>
              Users({listOfUsers ? listOfUsers.length : 0})
              {activeLink1 === 0
                ? "\n — Add Balance"
                : activeLink1 === 1
                ? "\n — Remove Balance"
                : activeLink1 === 2
                ? " "
                : null}
            </DashboardHeader>

            <Div className="pb-6">
              <Link
                to={"/dashboard/users/" + match.params.id}
                className="backTo"
              >
                &nbsp; Back to{" "}
                {singleUser ? singleUser.profile.first_name : null}
              </Link>
            </Div>

            <Card>
              <TabHeader>
                <TableTab
                  margin
                  tabs={links1}
                  activeTab={activeLink1}
                  handleTab={handleActiveLink1}
                  callback={(active) => {
                    // console.log(active)
                  }}
                />
              </TabHeader>

              {activeLink1 === 0 ? (
                <div className="">
                  <AddBalance singleUser={singleUser ? singleUser : null} />
                </div>
              ) : activeLink1 === 1 ? (
                <div className="">
                  <DeleteBalance singleUser={singleUser ? singleUser : null} />
                </div>
              ) : activeLink1 === 2 ? (
                <div className="">{/* <UserBank/> */}</div>
              ) : null}
            </Card>

            <div className="pt-6">
              <Card>
                <CardHolder>
                  <P fontSize="14px" color={"#FFFFFF"} fontWeight="700">
                    User balance details
                  </P>
                </CardHolder>

                <div>
                  <UserBalanceTable user={singleUser ? singleUser : null} />
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// Link Reducer props to component
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    singleUser: state.users.singleUser,
    loading: state.users.loading,
    listOfUsers: state.users.listOfUsers,
  };
};

// Link Actions to component
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getUser(id)),
    getUsers: () => dispatch(getUsers()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Balance));
