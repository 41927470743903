import React from "react";
import { Link } from "react-router-dom";
import { H5, P, SubHeader } from "../../../../UI toolkit/Text/Text";

const TransactionsOverview = ({ totalCash, totalCashDollarEq }) => {
  return (
    <div className="w-6/12">
      <SubHeader color={"#7C8191"} fontWeight="500">
        TRANSACTIONS
      </SubHeader>
      <div className="flex pt-4">
        <H5 color={"#112E46"} fontWeight="700" className="pr-4">
          ₦ {totalCash ? totalCash.toLocaleString() : 0}
        </H5>
        <div>{/* <img src="" alt="graph"/> */}</div>
      </div>
      <div className="flex pt-1 pb-4">
        <P fontSize="14px" color={"#7C8191"} fontWeight="500">
          ~ USD {totalCashDollarEq ? totalCashDollarEq.toLocaleString() : 0}
        </P>
      </div>

      <div className="">
        <Link to="/dashboard/transactions" className="viewAll">
          View all
        </Link>
      </div>
    </div>
  );
};

export default TransactionsOverview;
