import styled from "styled-components";

export const AdminTableStyle = styled.table`
  .admin-actions {
    display: none;
  }

  .admin-actions > div:hover {
    cursor: pointer;
  }

  // .Admin:hover ~ .admin-actions{
  //     display: flex;
  // }

  .Admin:hover > .admin-actions {
    display: flex;
  }
`;
